import React, {useRef, useState,useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Button , Container ,Typography,Grid,Paper}from '@material-ui/core';
import MainFooter from "../../components/footer";
import MainNavBar from "../../components/navBar"
import Banner from "../../public/assets/images/s2.png"
import { Link } from 'react-router-dom'
import { useLocation, useHistory } from 'react-router-dom'

import {firestore} from "../../firebase"
import SimpleForm from "../../pages/SimpleForm";
import Hident  from "../../public/assets/images/hident.png"
import Logo from "../../public/assets/images/newlogo.png"

import CloseIcon from '@material-ui/icons/Close';


import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import CallIcon from "@material-ui/icons/Call";
import "aos/dist/aos.css"
import AOS from 'aos';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import {primaryColor} from "../../constants";
import CarouselCardLogo from "../../public/assets/images/customerlogo.png"
import CarouselCard from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ChooseUs1 from "../../public/assets/images/why1.png"
import ChooseUs2 from "../../public/assets/images/why2.png"
import ChooseUs3 from "../../public/assets/images/why3.png"
import ChooseUs4 from "../../public/assets/images/why4.png"


const useStyles = makeStyles((theme) => ({
    root: {
        flex:1,
    },
    navbar:{
        backgroundColor:'#fff'
    },
    navbar2:{
        backgroundColor:'#fff',
        marginTop: 75
    },
    logoimage:{
        width:200,
        height:60,
        objectFit:'contain'
    },
    navlist2:{
        fontSize:16,
        marginRight: 30,
        fontFamily: 'Poppins',
        fontWeight:600,
        color: 'white',
        [theme.breakpoints.only('xs')]: {
            fontSize: 10
        },
    },
    headetitle:{
        fontSize:30,
        marginLeft:30,
        fontFamily: 'Poppins',
    },
    bookappointment:{
        backgroundColor:'#DE1C49',
        color:'#fff',
        fontFamily: 'Poppins',
        margin:'0px 40px',
        "&:hover": {
            backgroundColor: "#DE1C49"
        }
    },
    bookappointment2:{
        backgroundColor:'#DE1C49',
        color:'#fff',
        fontFamily: 'Poppins',
        fontWeight: 'bold',
        margin:'0px 40px',
        [theme.breakpoints.only('xs')]: {
            display: 'none'
        },
        "&:hover": {
            backgroundColor: "#DE1C49"
        }
    },
    bookappointment2button:{
        backgroundColor:'#DE1C49',
        color:'#fff',
        fontFamily: 'Poppins',
        // width: '200px',
        fontWeight: 'bold',
        margin:'0px 500px',
        alignItems:'center',
        [theme.breakpoints.only('xs')]: {
            margin:'0px',
        },
        "&:hover": {
            backgroundColor: "#DE1C49"
        }
    },
    navlist:{
        fontSize:20,
        fontWeight:600,
        fontFamily: 'Poppins',
    },
    headerbanner:{
        marginTop:"0px",
        [theme.breakpoints.only('xs')]: {
            marginTop:"0px",
            display: "none",
        },
    },
    headerbannerMobile:{
        marginTop:"0px",
        display: "none",
        [theme.breakpoints.only('xs')]: {
            marginTop:"0px",
            display: "block",
        },
    },
    banner:{
        width:'100%',
        fontFamily: 'Poppins',
    },
    bannerImage:{
        width:'100%',
        // height:460,
        objectFit:'cover',
        fontFamily: 'Poppins',
        marginTop:110,
        [theme.breakpoints.only('xs')]: {
            marginTop: '125px',
            width:'100%',
            objectFit:'contain',
        },
    },
    header:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        padding:"30px 100px",
        fontFamily: 'Poppins',
        [theme.breakpoints.only('xs')]: {
            padding:"10px 6px",
        },
    },
    headertitle:{
        fontSize:24,
        fontWeight:'600',
        fontFamily: 'Poppins',
        [theme.breakpoints.only('xs')]: {
            padding: 6,
        },
    },
    headertitlefree:{
        fontSize:16,
        fontWeight:'600',
        fontFamily: 'Poppins',
        [theme.breakpoints.only('xs')]: {
            padding: 6,
        },
    },

    headertitle2:{
        fontSize:24,
        fontWeight:'600',
        textAlign:'left',
        padding: 10,
        fontFamily: 'Poppins',
        scrollMargin: "220px",
        marginTop:80,
        marginBottom:30,
        [theme.breakpoints.only('xs')]: {
            marginTop:30,
            marginBottom:20
        },
    },
    headertitle2examine:{
        fontSize:24,
        fontWeight:'600',
        textAlign:'center',
        padding: 10,
        fontFamily: 'Poppins',
        scrollMargin: "220px",
        marginTop:80,
        marginBottom:30,
        [theme.breakpoints.only('xs')]: {
            marginTop:30,
            marginBottom:20
        },
    },
    headertitle3:{
        fontSize:20,
        fontWeight:'600',
        textAlign:'center',
        padding: 10,
        fontFamily: 'Poppins',
        // backgroundColor: "#efefef",
        borderRadius: 8,
        cursor:'pointer',
        "&:hover":{
            textDecoration:'none'
        }

    },
    text:{
        fontSize:16,
        fontWeight:500,
        textAlign:'justify',
        fontFamily: 'Poppins',
    },
    card:{
        width:'100%',
        height:300,
        marginTop:20,
        objectFit: 'cover',
        borderRadius:8,
        backgroundColor:'#cdcdcd',
        fontFamily: 'Poppins',
        [theme.breakpoints.only('xs')]: {
            height:150,
        },
    },
    dialogtitle:{
        fontSize:18,
        fontWeight:600,
        padding:'15px 0px',
        fontFamily: 'Poppins',
    },
    textfeild:{
        border:'none',
        backgroundColor:'#FFF8F2',
        fontFamily: 'Poppins',
        margin:'5px 0px'
    },
    textfiledroot: {
        '& $disabled': {
            borderColor: 'orange'
        }
    },
    disabled: {},

    dialogbuttoncontainer:{
        display:'flex',
        flexDirection:'row',
        fontFamily: 'Poppins',
        justifyContent:'center',
        margin:'20px 0px'
    },
    gridnames:{
        marginTop: 30,
        marginBottom: 30,
        [theme.breakpoints.only('xs')]: {
            margin: 0,
        },
    },
    logo:{
        height:350,
        marginTop:160,
        marginLeft:50,
        objectFit:'contain',
        [theme.breakpoints.only('xs')]: {
            height:100
        },
    },
    listtileheader:{
        width:'100px',
        color:"#000",
        marginTop:130,
        fontSize:30,
        fontFamily:'Poppins',
        marginLeft:"10%",
        fontWeight:700,
        [theme.breakpoints.only('xs')]: {
            fontSize:12,
            marginLeft:"5px",
        },
    },

    listtitlecontainer:{
        color:"#000",
        top:200,
        marginLeft:"13%",
        marginTop:20,
        [theme.breakpoints.only('xs')]: {

        },

    },
    listtitle:{
        fontSize:16,
        fontFamily:'Poppins',
        fontWeight:700,
        marginTop:10,
        cursor:'pointer',
        textAlign:'left',
        // paddingLeft:'12%',
        [theme.breakpoints.only('xs')]: {
            fontSize:8,
        },
        "&:hover":{
            color:primaryColor
        }

    },
    linkbutton:{
        "&:hover":{
            textDecoration:'none'
        }
    },
    rightrow:{
        display:'flex',
        [theme.breakpoints.only('xs')]: {
            flexDirection:'column-reverse'
        }
    },
    dialogtitle2:{
        fontSize:16,
        fontFamily: 'Poppins',
        fontWeight:600,
        padding:'15px 0px',
        textAlign: 'center',
        color: '#DE1C49'
    },
    titlesmilelogolarge:{
        width: "150px",
        position: 'absolute',
        left: '15%',
        opacity: '1',
        zIndex:-1,
        [theme.breakpoints.only('xs')]: {
            left: '15%',
        },
    },
    titlesmilelogosmall:{
        width: "50px",
        position: 'absolute',
        left: '5%',
        opacity: '1',
        zIndex:-1,
        marginTop:15,
        [theme.breakpoints.only('xs')]: {
            left: '10%',
        },
    },
    titlesmilelogomedium:{
        width: "130px",
        position: 'absolute',
        left: '8%',
        opacity: '1',
        zIndex:-1,
        [theme.breakpoints.only('xs')]: {
            left: '14%',
        },
    },
    girdrow:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        [theme.breakpoints.only('xs')]: {
            // padding:30
        },
    },
    gridlefttitle:{
        color:'#DE1C49',
        fontSize:80,
        fontWeight:800,
        fontFamily:"Poppins",
        paddingRight:10
    },
    gridrighttitle:{
        textAlign:'start',
        fontSize:25,
        fontWeight:800,
        paddingTop:20
    },
    gridrightdesc:{
        textAlign:'start',
        fontSize:20,
        width:300,
        [theme.breakpoints.only('xs')]: {
           width:'100%'
        },
    },
    choosecard:{
        width:'250px',
        marginLeft:'2%',
        [theme.breakpoints.only('xs')]: {
            marginTop:10
         },
    },
    chooseimage:{
        objectFit:'cover',
        borderRadius:20,
        width:'100%',
        fontFamily: 'Poppins',
        height:250,
        verticalAlign:'middle',
        // boxShadow: '15px 15px #DE1C49'
    },
    cardtitle:{
        fontSize:18,
        fontWeight:600,
        fontFamily: 'Poppins',
        textAlign:'start',
        paddingLeft:10,
        paddingTop:20
    },
    bookappointmentfixed:{
       display:'none',
       [theme.breakpoints.only('xs')]: {
        display:'flex',
        position:'absolute'
    },
    },
    heading:{
        fontWeight:'900',
        color: 'crimson',
        fontSize: 20
    }


}))
export default function Service1() {

    const classes = useStyles();
    const [index, setIndex] = useState(0);
    const [open, setOpen] = React.useState(false);
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [value, setValue] = React.useState('No');
    const [appointmentDate,setAppointmentDate] = useState("")
    const [appointmentTime,setAppointmentTime] = useState("")
    const [name,setName] = useState("")
    const [phone,setPhone] = useState("")
    const [ampm,setampm] = useState("AM")
    const [time,settime] = useState("9")
    const [notes,setNotes] = useState("")
    const [email,setEmail] = useState("")
     const [age,setAge] = useState("")
     const history = useHistory();
    const [openSnackbar, setOpenSnackBar] = React.useState(false);
    let [showChat, setShowChat] = React.useState(false);

    const startChat = () => { setShowChat(true); }
    const hideChat = () => { setShowChat(false); }

    useEffect(() => {
        document.title = "Painless root canal treatment Houston | Hi Dental Care"

        AOS.init({
            duration : 2000
        });
        window.scrollTo(0, 0);
    }, []);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };



    const handleDateChange = (date) => {
        console.log(date)
        var localTime = date.toString();
        setAppointmentDate(localTime.slice(0,15))
        console.log(localTime.slice(15,25))
        setAppointmentTime(localTime.slice(15,25))
        setSelectedDate(date);
    };

    const handleSubmit = () => {
        console.log(phone,name,appointmentDate,appointmentTime,value)
        if(phone =="" || name == ""){
            alert("Please enter Phone, Name and Date")
        }
        else{
       firestore.collection('leads').doc(Date.now().toString()).set({
           name: name ,
           phone: phone,
           age: age,
           appointmentDate: appointmentDate,
           appointmentTime: time + " " + ampm,
           emergency:value,
           email:email,
           notes:notes,
           timestamp: Date.now()
           },{merge: true})

    }
   }

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const responsivechooseus = {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };
    return (
        <div className={classes.root}>
            <MainNavBar />

            <Grid container className={classes.headerbanner}>
                <Grid xs={12}>
                    <img src={Banner} className={classes.bannerImage} />
                </Grid>
            </Grid>

            <Grid container className={classes.headerbannerMobile}>
                <Grid xs={12}>
                    <img src={Banner} className={classes.bannerImage} />
                </Grid>
            </Grid>

            <div className={classes.header}>
                <Typography className={classes.headertitle}>Painless Root Canal</Typography>
                <img src={CarouselCardLogo} className={classes.titlesmilelogomedium} style={{left:'10%'}} />
                <div>
                <Typography className={classes.headertitlefree}>Free Consultation</Typography>
                <Button variant="contained" className={classes.bookappointment2} onClick={() =>  history.push('./new-patient-form')}>Book Now</Button>
                </div>
            </div>
            
            <Container>
                <Typography className={classes.text}>
                The root canal treatment or endodontic treatment is used when a tooth has badly broken, infected, or abscessed, and it is performed with a proper painless procedure at Hi Dental Care. A general dentist or family dentist will usually handle simple root canal cases, while the more complex cases are usually referred to an endodontist. Root canal therapy treats the infected tooth, preventing decay from spreading to the adjacent teeth, and in most cases, it prevents the need for tooth extraction. It is fantastic that a root canal could be done without pain. We all want non-invasive, natural ways to heal our bodies from aches and pains, but sometimes that seems impossible to achieve. Getting a root canal isn't painful at all.  
                </Typography>
                <div className={classes.header}>
                    <Typography className={classes.headertitle}>Painless Root Canal At Hi Dental Care</Typography>
                    {/* <img src={CarouselCardLogo} className={classes.titlesmilelogomedium} style={{left:'10%'}} /> */}
               </div>
                

                <Container>
                <Grid container spacing={4} alignContent="center" alignItems="center">
                    <Grid item xs={12} sm={6}>
                        <div className={classes.girdrow}>
                            <Typography className={classes.gridlefttitle}>1</Typography>
                            <div>
                            <Typography className={classes.gridrighttitle}>Free Consultation</Typography>
                            <Typography className={classes.gridrightdesc}>An average root canal treatment lasts two Visits. In the beginning, a check-up is done and the infection is cleared from the inside of the tooth. The treated tooth is sealed with a tooth-colored filling.</Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div className={classes.girdrow}>
                            <Typography className={classes.gridlefttitle}>2</Typography>
                            <div>
                            <Typography className={classes.gridrighttitle}>Tooth Secure</Typography>
                            <Typography className={classes.gridrightdesc}>The second visit is for securing the tooth as the dentist will cover the tooth with a dental crown providing a new and beautiful appearance.
                            </Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div className={classes.girdrow}>
                            <Typography className={classes.gridlefttitle}>3</Typography>
                            <div>
                            <Typography className={classes.gridrighttitle}>Healing Process </Typography>
                            <Typography className={classes.gridrightdesc}>In the following few days, you may feel some soreness or tenderness in your mouth, but that will quickly fade. </Typography>
                            </div>
                        </div>
                    </Grid>
                     <Grid item xs={12} sm={6}>
                      <div className={classes.girdrow}>
                            <Typography className={classes.gridlefttitle}>4</Typography>
                            <div>
                           <Typography className={classes.gridrighttitle}>Advanced Conditions</Typography>
                          <Typography className={classes.gridrightdesc}>If the patient has advanced conditions or already having a swollen mouth and, as well as gum infections, then the treatment can last for longer than 2-3 days.</Typography>
                          </div>
                       </div>
                    </Grid> 
                </Grid>
                </Container>
            </Container>
            <Container>
            <div className={classes.header}>
                    <Typography className={classes.headertitle}>Benefits For Painless Root Canal </Typography>
                    {/* <img src={CarouselCardLogo} className={classes.titlesmilelogomedium} style={{left:'10%'}} /> */}
               </div>
            
                <Container>
                <Grid container spacing={4} alignContent="center" alignItems="center">
                    <Grid item xs={12} sm={6}>
                        <div className={classes.girdrow}>
                            <Typography className={classes.gridlefttitle}>1</Typography>
                            <div>
                            <Typography className={classes.gridrighttitle}>Boosts Oral Health</Typography>
                            <Typography className={classes.gridrightdesc}> Root canal therapy ensures good oral health that helps boost your overall health
                            </Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div className={classes.girdrow}>
                            <Typography className={classes.gridlefttitle}>2</Typography>
                            <div>
                            <Typography className={classes.gridrighttitle}>Prevents Jawbone Degeneration. </Typography>
                            <Typography className={classes.gridrightdesc}>By using this process, the tooth can be saved from being extracted. As a result of bacteria exposure and other factors, the jawbone easily degenerates with missing teeth.Thus, root canals prevent future complications of the jawbone.</Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div className={classes.girdrow}>
                            <Typography className={classes.gridlefttitle}>3</Typography>
                            <div>
                            <Typography className={classes.gridrighttitle}>Preserves Natural Teeth</Typography>
                            <Typography className={classes.gridrightdesc}>Root canal therapy preserves your natural teeth, so you do not have to worry about losing one or developing a gap between them.
                            </Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div className={classes.girdrow}>
                            <Typography className={classes.gridlefttitle}>4</Typography>
                            <div>
                            <Typography className={classes.gridrighttitle}>Prevents Infection</Typography>
                            <Typography className={classes.gridrightdesc}>Root Canal Treatment Prevents the spread of infection to neighboring teeth.
                            </Typography>
                            </div>
                        </div>
                    </Grid>
                </Grid>
                </Container>
                <Typography className={classes.headertitle2}>Why Choose Us?</Typography>
                <div className={classes.rootGrid}>
        <Grid container >
        <Grid item xs={12} sm={3}>
        <div className={classes.choosecard}>
                    <img src={ChooseUs1} alt="happyface1" className={classes.chooseimage} />
            </div>
        </Grid>
        <Grid item xs={12} sm={3}>
        <div className={classes.choosecard}>
                    <img src={ChooseUs2} alt="happyface1" className={classes.chooseimage} />
                </div>
        </Grid>
    
        <Grid item xs={12} sm={3}>
        <div className={classes.choosecard}>
                    <img src={ChooseUs3} alt="happyface1" className={classes.chooseimage} />
                </div>
        </Grid>
        <Grid item xs={12} sm={3}>
        <div className={classes.choosecard}>
                    <img src={ChooseUs4} alt="happyface1" className={classes.chooseimage} />
                </div>
        </Grid>
      </Grid>
      </div>

            </Container>

            <Container>

            


            <Typography className={classes.headertitle2}>More about Painless Root Canals</Typography>
                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>Is it possible for an infected tooth to heal itself?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography style={{textAlign: 'left'}}>
                        No, an infected tooth cannot heal itself. An infection that occurs in one area of your jaw can spread to other areas, causing serious pain. Medications and root canals are needed to treat an infected tooth. Schedule your appointment If you are suffering from chronic pain.

                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>Can root canals cause a lot of pain?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography style={{textAlign: 'left'}}>
                        At Hi Dental we provide painless root canal procedures. Before proceeding with the root canal treatment, a dentist will use local anesthesia to numb the infected area ensuring a painless treatment.

                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>What is the average recovery time?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography style={{textAlign: 'left'}}>
                    In a few days, a treated tooth will heal after undergoing root canal therapy. Dentists use local anesthesia to numb the infection before beginning root canal treatment, ensuring painless treatment. Patients may experience some pain once they awaken from anesthesia; this is likely to end within a few days due to medication.
                </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>Is it possible to perform RCT in one day?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography style={{textAlign: 'left'}}>
                    The majority of root canal treatments can be completed in one day by a dentist. In some cases, a dentist may recommend 2-3 days for the treatment of an infection or back tooth.

                </Typography>
                </AccordionDetails>
            </Accordion>
            <div style={{display:'flex',justifyContent:'center', flexDirection:'column', alignContent:'center'}}>
                <Typography className={classes.headertitle2examine}>Find out if a Painless Root Canal is right for you?</Typography>
                <Button variant="contained" className={classes.bookappointment2button} onClick={() => history.push('./new-patient-form')}>Let's examine it!</Button>
                </div>

            </Container>



            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={"sm"} maxWidth={'sm'}>
        <DialogContent>
            <div>
                <img style={{width: '100px', height: '40px', objectFit: 'contain'}} src={Logo}/>
            </div>
            <Typography className={classes.dialogtitle2}>Book your first appointment for free!</Typography>

            {/* <Typography className={classes.dialogtitle}>Name</Typography> */}
                <TextField
                   autoFocus
                    id="name"
                    variant="outlined"
                    fullWidth
                    className={classes.textfeild}
                    placeholder="Enter Name"
                    onChange = {(e)=>setName(e.target.value)}
                    classes={{notchedOutline:classes.input}}
                    disableUnderline={false}
                    InputProps={{
                        classes: {
                           root: classes.textfiledroot,
                           disabled: classes.disabled
                        }
                     }}
                />

                {/* <Typography className={classes.dialogtitle}>PhoneNumber</Typography> */}
                <TextField

                    id="name"
                    variant="outlined"
                    fullWidth
                    className={classes.textfeild}
                    onChange = {(e)=>setPhone(e.target.value)}
                    placeholder="Enter PhoneNumber"
                    classes={{notchedOutline:classes.input}}
                    disableUnderline={false}
                    InputProps={{
                        classes: {
                           root: classes.textfiledroot,
                           disabled: classes.disabled
                        }
                     }}
                />
            {/* <Typography className={classes.dialogtitle}>Age</Typography> */}

            <TextField

                id="name"
                variant="outlined"
                fullWidth
                className={classes.textfeild}
                onChange = {(e)=>setAge(e.target.value)}
                placeholder="Enter Your Age"
                classes={{notchedOutline:classes.input}}
                disableUnderline={false}
                InputProps={{
                    classes: {
                        root: classes.textfiledroot,
                        disabled: classes.disabled
                    }
                }}
            />
            <TextField

                id="name"
                variant="outlined"
                fullWidth
                className={classes.textfeild}
                onChange = {(e)=>setEmail(e.target.value)}
                placeholder="Enter Your Email"
                classes={{notchedOutline:classes.input}}
                disableUnderline={false}
                InputProps={{
                    classes: {
                        root: classes.textfiledroot,
                        disabled: classes.disabled
                    }
                }}
            />
            <TextField

                id="name"
                variant="outlined"
                fullWidth
                className={classes.textfeild}
                onChange = {(e)=>setNotes(e.target.value)}
                placeholder="Enter Description/Notes"
                classes={{notchedOutline:classes.input}}
                disableUnderline={false}
                InputProps={{
                    classes: {
                        root: classes.textfiledroot,
                        disabled: classes.disabled
                    }
                }}
            />

              <Grid container>
                  <Typography className={classes.dialogtitle}>Select a date for appointment</Typography>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around">
                        <KeyboardDatePicker
                        minDate={new Date()}
                        margin="normal"
                        id="date-picker-dialog"
                        label="Pick a Date"
                        format="MM/dd/yyyy"
                        value={selectedDate}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        />
                        <div style={{margin: 40}}>
                            <select
                                style={{padding: 5, fontSize: 16, marginRight: 5}}
                                value={time}
                                onChange={(e) => settime(e.target.value)}
                            >
                                <option value="8">8:00</option>
                                <option value="9">9:00</option>
                                <option value="10">10:00</option>
                                <option value="11">11:00</option>
                                <option value="12">12:00</option>
                                <option value="1">1:00</option>
                                <option value="2">2:00</option>
                                <option value="3">3:00</option>
                                <option value="4">4:00</option>
                                <option value="5">5:00</option>
                                <option value="6">6:00</option>
                                <option value="7">7:00</option>
                            </select>
                            <select
                                style={{padding: 5, fontSize: 16, marginRight: 5}}
                                value={ampm}
                                onChange={(e) => setampm(e.target.value)}
                            >
                                <option value="AM">AM</option>
                                <option value="PM">PM</option>
                            </select>
                            {/*<p>{message}</p>*/}
                        </div>

                    </Grid>
                    </MuiPickersUtilsProvider>
              </Grid>


              <FormControl component="fieldset">
                <RadioGroup aria-label="gender"  name="gender1" value={value} onChange={handleChange}>
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                    <Typography className={classes.dialogtitle} style={{paddingRight:'20px'}}>Emergency ?</Typography>
                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="No" control={<Radio />} label="No" />
                    </div>
                </RadioGroup>
            </FormControl>
            <p style={{fontSize: 12}}>Disclaimer: This website is designed to let you know more about our practice and our team and provide some general information about the treatment we provide.
                                    The website is made available subject to this disclaimer, our terms of use, privacy policy and booking and cancellation policy.
                                </p>
        </DialogContent>
        <div className={classes.dialogbuttoncontainer}>
        <Button variant="contained" className={classes.bookappointmentbutton} onClick={handleClose}>Close</Button>
        <Button variant="contained" className={classes.bookappointment} onClick={handleSubmit}>Submit</Button>
        </div>
         </Dialog>
 <MainFooter id="contactus"/>
 <div className = "bot">
                <div style ={{display: showChat ? "" : "none"}}>
                    <SimpleForm></SimpleForm>
                </div>
                {/* <div> {showChat ? <SimpleForm></SimpleForm> : null} </div> */}
                <div>
                    {!showChat
                        ? <button className="btn" onClick={() => startChat()}><div style={{backgroundColor: "#DE1C49", padding: 20, borderRadius: 100}}>
                            <img src={Hident} alt="logo" style={{width:30, height:30, backgroundColor: '#ffffff',borderRadius:30,}} />
                            {/* <QuestionAnswerIcon style={{width:30, height:30, color: '#ffffff',}}/> */}
                        </div></button>
                        : <button className="btn" onClick={() => hideChat()}><div style={{backgroundColor:"#DE1C49", padding: 20, borderRadius: 100}}>
                            <CloseIcon style={{width:30, height:30, color: '#ffffff',}}/>
                        </div></button>}
                </div>
            </div>
</div>
    )
}
