import React, {useRef, useState,useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Button , Container ,Typography,Grid,Paper,TextField}from '@material-ui/core';
import MainFooter from "../components/BlogFooter";
import "aos/dist/aos.css"
import AOS from 'aos';
import MainNavBar from '../components/navBar';
import {primaryColor} from "../constants";
import DateFnsUtils from '@date-io/date-fns';
import ".././HomePageStyles.css"
import BlogImage1 from "../public/assets/blogimages/blogimage1.png";

import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
  import Radio from '@material-ui/core/Radio';
  import RadioGroup from '@material-ui/core/RadioGroup';
  import FormControlLabel from '@material-ui/core/FormControlLabel';
  import FormControl from '@material-ui/core/FormControl';
  import FormLabel from '@material-ui/core/FormLabel';
  import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
  import { Map, GoogleApiWrapper,InfoWindow, Marke } from 'google-maps-react';
  import { styles } from '@material-ui/pickers/views/Calendar/Calendar';
  import GoogleMapReact from 'google-map-react';
  import Snackbar from '@material-ui/core/Snackbar';
  import MuiAlert from '@material-ui/lab/Alert';
  import {firestore} from "../firebase"



const useStyles = makeStyles((theme) => ({
    root: {
        flex:1,
    },
    gridcontainer:{
        marginTop:120,
        width:'100%'
    },
    grid1:{
        padding:'20px 30px'
    },
    grid2:{
        backgroundColor:'white',
        right:0,
        padding:'0px 20px',
        position: "fixed",
        zIndex:2,
        display: 'block',
        [theme.breakpoints.only('xs')]: {
           display: 'none'
        },

    },
    form:{
        padding:10,
        paddingBottom:0
    },
    formContainer:{
        borderRadius:20,
        // position:'fixed',

    },
    formheader:{
        backgroundColor:'#DE1C49',
        padding:10,
        color:"#fff",
        fontSize:16,
        fontWeight:600,
        borderTopLeftRadius:16,
        borderTopRightRadius:16
    },
    textfeild:{
        border:'none',
        fontFamily: 'Poppins',
        "&, .MuiOutlinedInput-input":{
            padding:"8px 5px"
        }
    },
    textfiledroot: {
     },

     dialogbuttoncontainerform:{
        display:'flex',
        flexDirection:'row',
        fontFamily: 'Poppins',
        justifyContent:'center',
        paddingBottom:20
    },
    miantitle:{
        fontSize:24,
        fontWeight:'800',
        padding:'30px 0px',
        textAlign:'start'
    },
    mythtitle:{
        fontSize:18,
        fontWeight:'600',
        padding:'10px 0px',
        textAlign:'start'
    },
    mythtruth:{
        fontSize:18,
        fontWeight:'400',
        padding:'10px 0px',
        textAlign:'start'
    },
    miandesc:{
        fontSize:18,
        fontWeight:'400',
        textAlign:'start'
    },
    submitbutton:{
        backgroundColor:'#DE1C49',
        color:'#fff',
        fontFamily: 'Poppins',
        fontWeight: "800",
        margin:'10px 10px',
        padding:"5px 30%",
        "&:hover": {
            backgroundColor: "#DE1C49"
        }
    },
    dialogtitle:{
        fontSize:12,
        fontFamily: 'Poppins',
        fontWeight:600,
        padding:'10px 0px'
    },
    bannerImage:{
        width:'100%',
        height:300,
        objectFit:'cover',
        borderRadius:20
    }
}))
export default function BlogPage() {
    const classes = useStyles();
    const [index, setIndex] = useState(0);
    const [open, setOpen] = React.useState(false);
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [value, setValue] = React.useState('No');
    const [appointmentDate,setAppointmentDate] = useState("")
    const [appointmentTime,setAppointmentTime] = useState("")
    const [name,setName] = useState("")
    const [phone,setPhone] = useState("")
    const [ampm,setampm] = useState("AM")
    const [time,settime] = useState("9")
    const [notes,setNotes] = useState("")
    const [email,setEmail] = useState("")
     const [age,setAge] = useState("")
    const [openSnackbar, setOpenSnackBar] = React.useState(false);
    const [bottom,setBottom] = useState(true)

    useEffect(() => {
        AOS.init({
            duration : 2000
        });
        window.scrollTo(0, 0);
    }, []);

    const handleDateChange = (date) => {
        console.log(date)
        var localTime = date.toString();
        setAppointmentDate(localTime.slice(0,15))
        console.log(localTime.slice(15,25))
        setAppointmentTime(localTime.slice(15,25))
        setSelectedDate(date);
    };

   const handleSubmit = () => {
       console.log(phone,name,appointmentDate,appointmentTime,value)
       if(phone =="" || name == ""){
           alert("Please enter Phone, Name and Date")
       }
       else{
      firestore.collection('leads').doc(Date.now().toString()).set({
          name: name ,
          phone: phone,
          age: age,
          appointmentDate: appointmentDate,
          appointmentTime: time + " " + ampm,
          emergency:value,
          email:email,
          notes:notes,
          timestamp: Date.now()
          },{merge: true})

      setTimeout(function(){ handleClickSnackbar() }, 300);
      handleClickSnackbar()
   }
  }

  const handleClickSnackbar = () => {
    setOpenSnackBar(true);
  };

    const handleChange = (event) => {
      setValue(event.target.value);
    };

    const K_HEIGHT = 40;
    const K_WIDTH = 40;
  const greatPlaceStyle = {
      // initially any map object has left top corner at lat lng coordinates
      // it's on you to set object origin to 0,0 coordinates
      position: 'absolute',
      width: K_WIDTH,
      height: K_HEIGHT,
      left: -K_WIDTH / 2,
      top: -K_HEIGHT / 2,
      objectFit:'contain',
      border: '1px solid #f44336',
      borderRadius: K_HEIGHT,
      backgroundColor: 'white',
      textAlign: 'center',
      color: '#3f51b5',
      fontSize: 16,
      fontWeight: 'bold',
      padding: 4
    };


    return (
        <div>
        <div className={classes.root}>
        <MainNavBar />

            <div>
            <Grid container className={classes.gridcontainer}>
                <Grid item xs={12} sm={8} className={classes.grid1}>
                <img src={BlogImage1} className={classes.bannerImage} />
                   <Typography className={classes.miantitle}>Busting 6 Important And Basic Dental Myths</Typography>
                   <Typography className={classes.miandesc}>
                   There are lots of dental myths viral on the internet about oral health and these may seem to be true but they will give serious effects. It is hard to determine which information is true and which is not. So, here we break all the myths by dropping some facts about the dental topic. At Hi Dental, we care about your dazzling smile so, we want to make sure that you have your facts straight when it comes to taking care of your teeth!                   </Typography>
                   <Typography className={classes.mythtitle}>Myth 1:<span className={classes.mythtitle}>Brushing too hard will make teeth more bright and white</span></Typography>
                   <Typography className={classes.mythtitle}>Truth:<span className={classes.mythtruth}> Brushing too hard or vigorously is never a solution to clean or whiten your teeth but it only decays your tooth enamel and hurts your gums. You’ll be at greater risk for cavities, decay, gum disease, and gum recession.You must use a soft toothbrush and be gentle while brushing!
                    </span></Typography>
                    <Typography className={classes.mythtitle}>Myth 2:<span className={classes.mythtitle}>Do your gums bleed when flossing?</span></Typography>
                   <Typography className={classes.mythtitle}>Truth:<span className={classes.mythtruth}> When you floss for the first time the gum bleeding is normal. This bleeding occurs when your gums are inflamed, which is caused by all the plaque and bacteria between your teeth.Routinely and steadily flossing between teeth will reduce the inflammation and bleeding will die down. If after few weeks bleeding does no stop then you must visit a dentist as it is a sign of gum disease.
                    </span></Typography>
                    <Typography className={classes.mythtitle}>Myth 3:<span className={classes.mythtitle}>Always purchase Hard bristles brush  </span></Typography>
                   <Typography className={classes.mythtitle}>Truth:<span className={classes.mythtruth}> Using Hard bristles brush damage teeth and gums. Always Use a brush with smooth bristles and with a normal head that can easily reach the end of your mouth, molars, and at the end of one’s jaw.
                    </span></Typography>
                    <Typography className={classes.mythtitle}>Myth 4:<span className={classes.mythtitle}>Sugar is the only reason for bad teeth.</span></Typography>
                   <Typography className={classes.mythtitle}>Truth:<span className={classes.mythtruth}> We as children have always listened from our parents to eat fewer sweets or else our teeth will rot. This is the biggest myth as sweets are not the only reason for cavities or rotten teeth but mostly the acids and the carbs affect the teeth and the gums which gives space to the cavities if not brushed properly. Food such as chips, white bread, etc is acidic and no filled with sugar but erodes your tooth enamel and gives cavities.
                    </span></Typography>
                    <Typography className={classes.mythtitle}>Myth 5:<span className={classes.mythtitle}>If you have bad breath it means you don't brush properly?</span></Typography>
                   <Typography className={classes.mythtitle}>Truth:<span className={classes.mythtruth}> This is a big myth of all time, if you have a bad breath it can be quickly fixed by brushing properly, flossing, and eating mint gums. But if you always seem to have bad breath then brushing and eating mint gums won't do anything, as there will be a serious issue. Bad breath may be a symptom of gum disease or acid reflux. If you suffer from constant bad breath, no number of mints can fix the problem. It’s time to visit your dentist.

                    </span></Typography>
                    <Typography className={classes.mythtitle}>Myth 6:<span className={classes.mythtitle}>Brushing for a long time is healthy for teeth</span></Typography>
                   <Typography className={classes.mythtitle}>Truth:<span className={classes.mythtruth}> A maximum of 5 minutes of brushing does the job. Dentists recommend brushing your teeth twice a day at night and in the morning for proper oral hygiene. At night it's important to brush to avoid bacterial growth on the leftover food particles inside the mouth. The bacterial growth in the mouth leads to tooth decay or cavity.
                    </span></Typography>


                </Grid>
                <Grid item xs={12} sm={4} className={classes.grid2} >
                <Paper className={classes.formContainer}>
                <div className={classes.formheader}>
                    Contact Us
                </div>
                <div  className={classes.form}>
                <TextField
                    autoFocus
                    id="name"
                    variant="outlined"
                    fullWidth
                    className={classes.textfeild}
                    placeholder="Enter Name"
                    onChange = {(e)=>setName(e.target.value)}
                    classes={{notchedOutline:classes.input}}
                    // disableUnderline={false}
                    InputProps={{
                        classes: {
                        root: classes.textfiledroot,
                        // disabled: classes.disabled
                        }
                    }}
                />
                <TextField

                    id="name"
                    variant="outlined"
                    fullWidth

                    className={classes.textfeild}
                    onChange = {(e)=>setPhone(e.target.value)}
                    placeholder="Enter PhoneNumber"
                    classes={{notchedOutline:classes.input}}
                    disableUnderline={false}
                    InputProps={{
                        classes: {
                        root: classes.textfiledroot,
                        // disabled: classes.disabled
                        }
                    }}
                />
                <TextField
                id="name"
                variant="outlined"
                fullWidth
                className={classes.textfeild}
                onChange = {(e)=>setAge(e.target.value)}
                placeholder="Enter Your Age"
                classes={{notchedOutline:classes.input}}
                // disableUnderline={false}
                InputProps={{
                    classes: {
                        root: classes.textfiledroot,
                        // disabled: classes.disabled
                    }
                }}
                    />
                <TextField
                id="name"
                variant="outlined"
                fullWidth
                className={classes.textfeild}
                onChange = {(e)=>setEmail(e.target.value)}
                placeholder="Enter Your Email"
                classes={{notchedOutline:classes.input}}
                // disableUnderline={false}
                InputProps={{
                    classes: {
                        root: classes.textfiledroot,
                        // disabled: classes.disabled
                    }
                }}
                    />
                    <TextField
                id="name"
                variant="outlined"
                fullWidth
                className={classes.textfeild}
                onChange = {(e)=>setNotes(e.target.value)}
                placeholder="Enter Descriptons/Notes"
                classes={{notchedOutline:classes.input}}
                // disableUnderline={false}
                InputProps={{
                    classes: {
                        root: classes.textfiledroot,
                        // disabled: classes.disabled
                    }
                }}
                    />
            <Grid container>
                <Typography className={classes.dialogtitle}>Select a date for appointment</Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around" style={{height:'70px'}}>
                        <Grid item xs={12} sm={6}>
                        <KeyboardDatePicker
                            minDate={new Date()}
                        margin="normal"
                        id="date-picker-dialog"
                        label="Pick a Date"
                        format="MM/dd/yyyy"
                        value={selectedDate}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <div style={{margin: 40,display:'flex'}}>
                            <select
                                style={{padding: 5, fontSize: 16, marginRight: 5,}}
                                value={time}
                                onChange={(e) => settime(e.target.value)}
                            >
                                <option value="8">8:00</option>
                                <option value="9">9:00</option>
                                <option value="10">10:00</option>
                                <option value="11">11:00</option>
                                <option value="12">12:00</option>
                                <option value="1">1:00</option>
                                <option value="2">2:00</option>
                                <option value="3">3:00</option>
                                <option value="4">4:00</option>
                                <option value="5">5:00</option>
                                <option value="6">6:00</option>
                                <option value="7">7:00</option>
                            </select>
                            <select
                                style={{padding: 5, fontSize: 16, marginRight: 5}}
                                value={ampm}
                                onChange={(e) => setampm(e.target.value)}
                            >
                                <option value="AM">AM</option>
                                <option value="PM">PM</option>
                            </select>
                        </div>
                        </Grid>
                    </Grid>
                    </MuiPickersUtilsProvider>
            </Grid>
            <FormControl component="fieldset">
                <RadioGroup aria-label="gender"  name="gender1" value={value} onChange={handleChange}>
                    <div style={{display:'flex',justifyContent:'space-between',paddingTop:10}}>
                    <Typography className={classes.dialogtitle} style={{paddingRight:'20px'}}>Emergency ?</Typography>
                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="No" control={<Radio />} label="No" />
                    </div>
                </RadioGroup>
                    </FormControl>
                    </div>

                    <div className={classes.dialogbuttoncontainerform}>
                    <Button variant="contained" className={classes.submitbutton} onClick={handleSubmit}>Submit</Button>
                    </div>
                    </Paper>
                </Grid>

            </Grid>

        </div>

        </div>
        <MainFooter id="contactus"/>
        </div>
    )
}
