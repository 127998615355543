import React, {useRef, useState,useEffect} from 'react'
import { Navbar, Nav , Carousel,Row, Col } from 'react-bootstrap'
import { makeStyles } from '@material-ui/core/styles';
import CallIcon from '@material-ui/icons/Call';
import Logo from "../public/assets/images/newlogo.png"
import { useLocation, useHistory } from 'react-router-dom'
import Service1 from "../public/assets/serviceIamgesUpdated/image14.jpg"
import Service2 from "../public/assets/serviceImages/image21.jpg"
import Service3 from "../public/assets/serviceImages/image1.jpg"
import Service4 from "../public/assets/serviceIamgesUpdated/image1.jpg"
import Service5 from "../public/assets/serviceImages/image17.jpg"
import Service6 from "../public/assets/serviceImages/image21.jpg"
import Service7 from "../public/assets/serviceImages/image15.png"
import Service8 from "../public/assets/serviceImages/image24.jpg"
import Service9 from "../public/assets/serviceImages/image16.jpg"
import Service10 from "../public/assets/serviceImages/image123.jpg"
import Service11 from "../public/assets/serviceImages/image5.png"
import Service12 from "../public/assets/serviceImages/image25.jpg"
import Service13 from "../public/assets/serviceIamgesUpdated/image4.jpg"
import Service14 from "../public/assets/serviceImages/image3.jpg"
import Service15 from "../public/assets/serviceImages/image44.jpg"
import Service16 from "../public/assets/serviceImages/image49.jpg"
import LogoSmile from "../public/assets/serviceImages/servicesmile.png"
import { Button , Container ,Typography,Grid,Paper}from '@material-ui/core';
import MainFooter from "../components/footer";
import Banner from "../public/assets/serviceImages/banner1.png"
import { Link } from 'react-router-dom'
import {firestore} from "../firebase"
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import "aos/dist/aos.css"
import AOS from 'aos';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import Offer1 from "../public/assets/OffersImages/offer1.png"
import Offer2 from "../public/assets/OffersImages/offer2.png"
import Offer3 from "../public/assets/OffersImages/offer3.png"
import Offer4 from "../public/assets/OffersImages/offer4.png"
import Offer5 from "../public/assets/OffersImages/offer5.png"
import Offer6 from "../public/assets/OffersImages/offer6.png"
import Offer7 from "../public/assets/OffersImages/offer7.png"
import Offer8 from "../public/assets/OffersImages/offer8.png"
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import RoomIcon from '@material-ui/icons/Room';






const useStyles = makeStyles((theme) => ({
    root: {
      flex:1,
        scrollBehavior: 'smooth',
        overflowX:'hidden'
    },
    navbar:{
        backgroundColor:'#fff',
        // zIndex:1
    },
    navbar2:{
        backgroundColor:'#fff',
        marginTop: 75,
        // zIndex:1
    },
    logoimage:{
        width:200,
        height:60,
        objectFit:'contain'
    },
    headetitle:{
        fontSize:30,
        marginLeft:30,
        fontFamily: 'Poppins',
        fontStyle:'italic',
        [theme.breakpoints.only('xs')]: {
            fontSize: 15
        },
    },
    bookappointment:{
        backgroundColor:'#DE1C49',
        color:'#fff',
        fontFamily: 'Poppins',
        padding: 12,
        fontWeight: "800",
        margin:'0px 20px',
        "&:hover": {
            backgroundColor: "#DE1C49"
        },
        [theme.breakpoints.only('xs')]: {
           marginTop:20
        },
    },
    submitbutton:{
        backgroundColor:'#DE1C49',
        color:'#fff',
        fontFamily: 'Poppins',
        fontWeight: "800",
        margin:'0px 20px',
        padding:"10px 30%",
        "&:hover": {
            backgroundColor: "#DE1C49"
        }
    },
    navlist:{
        fontSize:20,
        fontFamily: 'Poppins',
        fontWeight:600
    },
    navlist2:{
        fontSize:16,
        marginRight: 12,
        fontFamily: 'Poppins',
        fontWeight:600,
        color: 'white',
        [theme.breakpoints.only('xs')]: {
            fontSize: 20,
            padding:8
        },
    },
    linkbutton:{
        "&:hover":{
            textDecoration:'none'
        }
    },
    conatinerheader:{
        marginTop:80,
        backgroundColor:'#DE1C49',
        borderBottomLeftRadius:"50px",
        borderBottomRightRadius:"50px",
    },
    headertitle:{
        fontSize:100,
        fontFamily:'Cinzel',
        letterSpacing:2,
        color:'#fff'
    },
    cardroot: {
        maxWidth: "100%",
        margin:'50px 0px',
        "&:hover": {
            background: 'transaparent',
          },
          height:300
      },
      media: {
        height: 150,
      },
      cardprice:{
        textAlign:'center',
        fontSize:45,
        fontFamily:'Poppins',
        color:'#fff',
        fontStyle:'italic',
        paddingTop:30
      },
      onlytext:{
          fontSize:20,
          marginTop:20,
          position:'absolute',
          marginLeft:5
      },
      cardtitle:{
          fontSize:20,
          fontFamily:'Poppins',
          fontWeight:600,
          minHeight:50,
          textAlign:'center',
          width:'100%'
      },
      cardbutton:{
          backgroundColor:'#DE1C49',
          margin:"20px 0px",
          padding:"5px 50px",
          "&:hover":{
            backgroundColor:'#DE1C49',
        }
      },
      dialogtitle:{
        fontSize:18,
        fontFamily: 'Poppins',
        fontWeight:600,
        padding:'15px 0px'
    },
    dialogtitle3:{
        fontSize:18,
        fontFamily: 'Poppins',
        fontWeight:600,
        padding:'15px 0px',
        textAlign: 'left',
    },
    dialogtitle2:{
        fontSize:18,
        fontFamily: 'Poppins',
        fontWeight:600,
        padding:'10px 0px',
        textAlign: 'center',
        color: '#DE1C49'
    },
    dialogtitle3:{
        fontSize:13,
        fontFamily: 'Poppins',
        fontWeight:400,
        textAlign: 'center',
        color: '#DE1C49'
    },
    textfeild:{
        border:'none',
        fontFamily: 'Poppins',
        backgroundColor:'#FFF8F2',
        margin:'5px 0px'
    },
    textfiledroot: {
        '& $disabled': {
           borderColor: 'orange'
        }
     },
     disabled: {},

     dialogbuttoncontainer:{
         display:'flex',
         flexDirection:'row',
         fontFamily: 'Poppins',
         justifyContent:'center',
         margin:'20px 0px',
     },
     dialogbuttoncontainerform:{
        display:'flex',
        flexDirection:'row',
        fontFamily: 'Poppins',
        justifyContent:'center',
        margin:'20px 0px',
        paddingBottom:20
    },
    bookappointmentnav2:{
        display:'none',
        [theme.breakpoints.only('xs')]: {
            display:'block',
            backgroundColor:'#fff',
            color:'#DE1C49',
            fontFamily: 'Poppins',
            // padding: 8,
            fontWeight: "800",
            fontSize:16,
            marginRight: 10,
            margin:'0px 8px',
            "&:hover": {
                backgroundColor: "#fff",
                textDecoration:'none'
            },
        },
    },
    emailandnum:{
        display:'flex',
        flexDirection:'row',
        justifyContent: 'flex-end',
        padding: 8,
        marginRight: 10,
        [theme.breakpoints.only('xs')]: {
            display:'flex',
            flexDirection:'row',
            marginRight: 0,
            padding: 0,
            justifyContent: 'space-between',
            alignItems: 'flex-start'
        },
    },


  }));


export default function MainNavBar() {
    const classes = useStyles();
    const [index, setIndex] = useState(0);
    const [open, setOpen] = React.useState(false);
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [value, setValue] = React.useState('No');
    const [appointmentDate,setAppointmentDate] = useState("")
    const [appointmentTime,setAppointmentTime] = useState("")
    const [name,setName] = useState("")
    const [phone,setPhone] = useState("")
    const [notes,setNotes] = useState("")
    const [email,setEmail] = useState("")
    const [age,setAge] = useState("")
    const [ampm,setampm] = useState("AM")
    const [time,settime] = useState("9")
    const [offerType,setOfferType] = useState("")
    const [openSnackbar, setOpenSnackBar] = React.useState(false);
    const history = useHistory();


    useEffect(() => {
        AOS.init({
          duration : 2000
        });
        window.scrollTo(0, 0);

      }, []);


    const handleClickOpen = (type) => {
        console.log(type)
        setOpen(true);
        setOfferType(type)
      };

      const handleClose = () => {
        setOpen(false);
      };

      const handleChange = (event) => {
        setValue(event.target.value);
      };
      const handleDateChange = (date) => {
        console.log(date)
        var localTime = date.toString();
        setAppointmentDate(localTime.slice(0,15))
        console.log(localTime.slice(15,25))
        setAppointmentTime(localTime.slice(15,25))
        setSelectedDate(date);
    };


    const handleSubmit = () => {
        console.log(phone,name,appointmentDate,appointmentTime,value)
        if(phone =="" || name == ""){
            alert("Please enter Phone, Name and Date")
        }
        else{
            firestore.collection('leads').doc(Date.now().toString()).set({
                name: name ,
                phone: phone,
                age: age,
                appointmentDate: appointmentDate,
                appointmentTime: time + " " + ampm,
                emergency:value,
                email:email,
                notes:notes,
                timestamp: Date.now()
            },{merge: true})

            setTimeout(function(){ handleClickSnackbar() }, 300);
            handleClose()
            handleClickSnackbar()
        }
    }


   function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

const handleClickSnackbar = () => {
    setOpenSnackBar(true);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackBar(false);
  };


    return (
        <div className={classes.root}>

        <Navbar collapseOnSelect expand="lg" variant="light" fixed="top" style={{backgroundColor:'#fff',}} className={classes.navbar}>
                <a href="/"><img
                    className={classes.logoimage}
                    src={Logo}
                    alt="React Bootstrap logo"
                /></a>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ml-auto">
                        <Nav.Link href="/" data-toggle="collapse" data-target=".navbar-collapse.show" className={classes.navlist}>Home</Nav.Link>
                        <Nav.Link href="/" data-toggle="collapse" data-target=".navbar-collapse.show" className={classes.navlist}>Services</Nav.Link>
                        <Nav.Link href="/"  className={classes.navlist}>Team</Nav.Link>
                        <Nav.Link href="/allpost" className={classes.navlist}>Blog</Nav.Link>
                        <Nav.Link href="/" className={classes.navlist}>About Us</Nav.Link>
                        <Nav.Link  href="/" className={classes.navlist}>Contact us</Nav.Link>
                        <Link to="/specialoffer" className={classes.linkbutton}> <Button variant="contained" className={classes.bookappointment} >Special Offers</Button></Link>
                        <Button variant="contained" className={classes.bookappointment} onClick={()=>  history.push('./new-patient-form')}>Book Appointment</Button>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <Navbar fixed="top" collapseOnSelect variant="light" style={{backgroundColor: '#DE1C49', padding:'1px',}} className={[classes.navbar2, {color:'#DE1C49'}]}>
                <Navbar.Collapse id="responsive-navbar-nav" className={classes.emailandnum}>
                    <Button variant="contained" className={classes.bookappointmentnav2} onClick={() =>  history.push('./new-patient-form')}>Book Now</Button>
                    <a href="tel:713-820-3360"  style={{color:'#ffffff'}}  className={classes.navlist2}><CallIcon/>+1-713-820-3360</a>
                </Navbar.Collapse>
            </Navbar>

            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackBar}>
                <Alert onClose={handleCloseSnackBar} severity="success">
                    SuccessFully Submited!!
                </Alert>
            </Snackbar>






            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={"sm"} maxWidth={'sm'}>
        {/* <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
        <DialogContent>
            <div>
                <img style={{width: '100px', height: '40px', objectFit: 'contain'}} src={Logo}/>
            </div>
            <Typography className={classes.dialogtitle2}>Book your first appointment for free!</Typography>

            {/* <Typography className={classes.dialogtitle}>Name</Typography> */}
                <TextField
                   autoFocus
                    id="name"
                    variant="outlined"
                    fullWidth
                    className={classes.textfeild}
                    placeholder="Enter Name"
                    onChange = {(e)=>setName(e.target.value)}
                    classes={{notchedOutline:classes.input}}
                    disableUnderline={false}
                    InputProps={{
                        classes: {
                           root: classes.textfiledroot,
                           disabled: classes.disabled
                        }
                     }}
                />

                {/* <Typography className={classes.dialogtitle}>PhoneNumber</Typography> */}
                <TextField

                    id="name"
                    variant="outlined"
                    fullWidth
                    className={classes.textfeild}
                    onChange = {(e)=>setPhone(e.target.value)}
                    placeholder="Enter PhoneNumber"
                    classes={{notchedOutline:classes.input}}
                    disableUnderline={false}
                    InputProps={{
                        classes: {
                           root: classes.textfiledroot,
                           disabled: classes.disabled
                        }
                     }}
                />
            {/* <Typography className={classes.dialogtitle}>Age</Typography> */}

            <TextField

                id="name"
                variant="outlined"
                fullWidth
                className={classes.textfeild}
                onChange = {(e)=>setAge(e.target.value)}
                placeholder="Enter Your Age"
                classes={{notchedOutline:classes.input}}
                disableUnderline={false}
                InputProps={{
                    classes: {
                        root: classes.textfiledroot,
                        disabled: classes.disabled
                    }
                }}
            />
            <TextField

                id="name"
                variant="outlined"
                fullWidth
                className={classes.textfeild}
                onChange = {(e)=>setEmail(e.target.value)}
                placeholder="Enter Your Email"
                classes={{notchedOutline:classes.input}}
                disableUnderline={false}
                InputProps={{
                    classes: {
                        root: classes.textfiledroot,
                        disabled: classes.disabled
                    }
                }}
            />
            <TextField

                id="name"
                variant="outlined"
                fullWidth
                className={classes.textfeild}
                onChange = {(e)=>setNotes(e.target.value)}
                placeholder="Enter Description/Notes"
                classes={{notchedOutline:classes.input}}
                disableUnderline={false}
                InputProps={{
                    classes: {
                        root: classes.textfiledroot,
                        disabled: classes.disabled
                    }
                }}
            />

              <Grid container>
                  <Typography className={classes.dialogtitle}>Select a date for appointment</Typography>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around">
                        <KeyboardDatePicker
                        minDate={new Date()}
                        margin="normal"
                        id="date-picker-dialog"
                        label="Pick a Date"
                        format="MM/dd/yyyy"
                        value={selectedDate}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        />
                        <div style={{margin: 40}}>
                            <select
                                style={{padding: 5, fontSize: 16, marginRight: 5}}
                                value={time}
                                onChange={(e) => settime(e.target.value)}
                            >
                                <option value="8">8:00</option>
                                <option value="9">9:00</option>
                                <option value="10">10:00</option>
                                <option value="11">11:00</option>
                                <option value="12">12:00</option>
                                <option value="1">1:00</option>
                                <option value="2">2:00</option>
                                <option value="3">3:00</option>
                                <option value="4">4:00</option>
                                <option value="5">5:00</option>
                                <option value="6">6:00</option>
                                <option value="7">7:00</option>
                            </select>
                            <select
                                style={{padding: 5, fontSize: 16, marginRight: 5}}
                                value={ampm}
                                onChange={(e) => setampm(e.target.value)}
                            >
                                <option value="AM">AM</option>
                                <option value="PM">PM</option>
                            </select>
                            {/*<p>{message}</p>*/}
                        </div>

                    </Grid>
                    </MuiPickersUtilsProvider>
              </Grid>


              <FormControl component="fieldset">
                <RadioGroup aria-label="gender"  name="gender1" value={value} onChange={handleChange}>
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                    <Typography className={classes.dialogtitle} style={{paddingRight:'20px'}}>Emergency ?</Typography>
                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="No" control={<Radio />} label="No" />
                    </div>
                </RadioGroup>
            </FormControl>
            <p style={{fontSize: 12}}>Disclaimer: This website is designed to let you know more about our practice and our team and provide some general information about the treatment we provide.
                                    The website is made available subject to this disclaimer, our terms of use, privacy policy and booking and cancellation policy.
                                </p>
        </DialogContent>
        <div className={classes.dialogbuttoncontainer}>
        <Button variant="contained" className={classes.bookappointmentbutton} onClick={handleClose}>Close</Button>
        <Button variant="contained" className={classes.bookappointment} onClick={handleSubmit}>Submit</Button>
        </div>
         </Dialog>
        </div>
    )
}
