import React from "react";
import {MDBCol, MDBContainer, MDBFooter, MDBRow} from "mdbreact";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import BusinessIcon from '@material-ui/icons/Business';
import {Nav} from "react-bootstrap";
import CallIcon from '@material-ui/icons/Call';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import Logo from "../public/assets/images/newlogo.png"

function MainFooterBlog(){
    return(
        <MDBFooter color="stylish-color-dark" style={{backgroundColor: '#DE1C49',position:'absolute',zIndex:2}} className="page-footer font-small pt-4 mt-4">
            <MDBContainer fluid className="text-center text-md-left">
                <MDBRow>
                    <MDBCol md="4">
                        {/* <h5 style={{color: '#ffffff'}}  className="text-uppercase mb-4 mt-3 font-weight-bold">
                            Hi Dental
                        </h5> */}
                        <img src={Logo} style={{height:40,width:200,backgroundColor:'#fff',borderRadius:8,objectFit:'contain'}} />
                        <p style={{color: '#ffffff',marginTop:30}}>
                            At Hi Dental we always want our clients to be happy, that is why we have several treatments that are flawlessly designed to your needs, lifestyle, and budget.
                        </p>
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-around',width:'200px'}}>
                            {/*<LinkedInIcon style={{color:'#fff',fontSize:30}} />*/}
                            <a href="https://www.facebook.com/hidentalhouston/"><FacebookIcon style={{color:'#fff',fontSize:30}} /></a>
                            <a href="https://www.instagram.com/hidentalhouston/"><InstagramIcon style={{color:'#fff',fontSize:30}} /></a>
                        </div>
                    </MDBCol>
                    <hr className="clearfix w-100 d-md-none" />
                    <MDBCol md="4">
                        <h5 style={{color: '#ffffff'}} className="text-uppercase mb-4 mt-3 font-weight-bold">
                            Important Links
                        </h5>
                        <ul style={{color: '#ffffff'}} className="list-unstyled">
                            <li>
                                <a style={{color: '#ffffff'}} href="#aboutus">About us</a>
                            </li>
                            <li>
                                <a style={{color: '#ffffff'}} href="#services">Our Services</a>
                            </li>
                            <li>
                                <a style={{color: '#ffffff'}} href="/privacypolicy">Privacy Policy</a>
                            </li>
                            <li>
                                <a style={{color: '#ffffff'}} href="/new-patient-form">New Patient Form</a>
                            </li>
                            {/* <li>
                                <a style={{color: '#ffffff'}} href="/public/sitemap.xml">SiteMap</a>
                            </li> */}
                            {/*<li>*/}
                            {/*    <a href="/admin">Admin Login</a>*/}
                            {/*</li>*/}
                        </ul>
                    </MDBCol>
                    <hr className="clearfix w-100 d-md-none" />
                    <MDBCol style={{color: '#ffffff'}} md="4">
                        <h5 className="text-uppercase mb-4 mt-3 font-weight-bold">
                            Contact Us!
                        </h5>
                        <ul className="list-unstyled">
                            <li>
                                <p style={{color: '#ffffff'}}><a href="tel:713-820-3360" style={{color: '#ffffff', marginBottom: 20}}><CallIcon/>{'  '} +1-713-820-3360</a></p>
                            </li>
                            <li>
                                <p style={{color: '#ffffff'}}><MailOutlineIcon/><a style={{color: '#ffffff'}} href = "mailto:contact@hidentalhouston.com">
                                    {'  '}contact@hidentalhouston.com</a></p>

                            </li>
                            {/*<li>*/}
                            {/*    <a href="#!">+91-9587917917</a>*/}
                            {/*</li>*/}
                            <li>
                                <p href="#!">
                                    <BusinessIcon/>
                                    {'  '}Hi Dental Care, 13319 East Freeway</p>
                                <p href="#!">
                                    Houston, Texas 77015</p>
                            </li>
                        </ul>
                    </MDBCol>
                    <hr className="clearfix w-100 d-md-none" />
                    {/*<MDBCol md="3">*/}
                    {/*    <h5 className="text-uppercase mb-4 mt-3 font-weight-bold">*/}
                    {/*        Download our app now!*/}
                    {/*    </h5>*/}
                    {/*    <ul className="list-unstyled">*/}
                    {/*        <li>*/}
                    {/*            <a href={'https://play.google.com/store/apps/details?id=com.xpertfinance&hl=en_IN'}><img src={'googleplay2.png'} width={'200'}/></a>*/}
                    {/*        </li>*/}
                    {/*    </ul>*/}
                    {/*</MDBCol>*/}
                </MDBRow>
            </MDBContainer>
            <hr />
        </MDBFooter>
    )
}

export default MainFooterBlog;