import React, {useEffect} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import Fuse from 'fuse.js';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { mainListItems, secondaryListItems } from './listItems';
import Chart from './Chart';
import Deposits from './Deposits';
import Orders from './Orders';
import {primaryColor} from "../constants";
import {firestore} from "../firebase";
import {useHistory} from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ListItemText from "@material-ui/core/ListItemText";
import PeopleIcon from "@material-ui/icons/People";
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import ExpertOrders from "./ExpertOrders";
import TextField from "@material-ui/core/TextField";
import Title from "./Title";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Logo from '../public/assets/images/newlogo.png'


function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © Hi Dental Designed by the Internet Funnel '}
            {/*<Link color="inherit" href="https://kraneapps.com/">*/}
            {/*    KraneApps.com*/}
            {/*</Link>{' '}*/}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}


// Generate Order Data
function createData(type, name, phone, email,city, timestamp) {
    return { type, name, phone, email,city, timestamp };
}

const drawerWidth = 240;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24,
        backgroundColor: primaryColor// keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 200,
        paddingTop: 30
    },

}));

export default function Dashboard() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [openDialog, setOpenDialog] = React.useState(false);

    const [pattern, setPattern] = React.useState('');

    const history = useHistory();
    const [allLeads, setAllLeads] = React.useState([]);
    const [searchLeads, setSearchLeads] = React.useState([]);

    const [openLeads, setOpenLeads] = React.useState(0);
    const [hotLeads, setHotLeads] = React.useState(0);
    const [closedLeads, setClosedLeads] = React.useState(0);
    const [converted, setConverted] = React.useState(0);


    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleSearch = () => {
        console.log("Searching for....", pattern);
        const options = {
                    isCaseSensitive: false,
                    threshold: 0.3,
                    keys: [
                        "name",
                        "age",
                        "email",
                        "emergency",
                        "notes",
                        "phone",
                        "appointmentDate",
                        "appointmentTime",
                        "status"
                    ]
                };

        const fuseCategory = new Fuse(allLeads, options);
        const toSearch = pattern;
        var temp = fuseCategory.search(toSearch);
        console.log("searched leads ==",temp);
        setSearchLeads(temp);
        setOpenDialog(true);
    }


   

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

    useEffect(() => {
        firestore.collection('admin').doc('login').get().then(snapshot => {
            const currentUser = localStorage.getItem('xpertusername');
            if(currentUser == snapshot.data().username ){

            }else{
                history.push('/admin');
            }
        })
        firestore.collection("leads").get().then(snapshot => {
            var leads = [];
            let open = 0;
            let hot = 0;
            let closed = 0;
            let converted = 0;
            snapshot.docs.map(doc => {
                leads.push(doc.data())
                if(doc.data().status == undefined || doc.data().status == null || doc.data().status == "" || doc.data().open){
                    open += 1;
                }
                if(doc.data().status == 'hot'){
                    hot += 1;
                }
                if(doc.data().status == 'closed'){
                    closed += 1;
                }
                if(doc.data().status == 'converted'){
                    converted += 1;
                }
            })
            console.log('all numbers ==', open, hot, closed)
            setOpenLeads(open)
            setClosedLeads(closed)
            setHotLeads(hot)
            setAllLeads(leads)
            setConverted(converted)
            console.log('all heads in dahsboard',leads);
        })
    },[])

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                        Admin Dashboard
                    </Typography>
                    <IconButton color="inherit">
                        {/*<Badge badgeContent={4} color="secondary">*/}
                            <NotificationsIcon />
                        {/*</Badge>*/}
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                }}
                open={open}
            >
                <div className={classes.toolbarIcon}>
                    <div>

                        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                            <img src={Logo} width={'160'}/>
                        </Typography>

                    </div>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <List>
                    <div>
                        <ListItem button>
                            <ListItemIcon>
                                <DashboardIcon />
                            </ListItemIcon>
                            <ListItemText primary="All Leads" />
                        </ListItem>
                        {/*<ListItem button>*/}
                        {/*    <ListItemIcon>*/}
                        {/*        <PeopleIcon />*/}
                        {/*    </ListItemIcon>*/}
                        {/*  <a href="/investmenttable" style={{color:'black'}}><ListItemText primary="Expert Requests" /></a>*/}
                        {/*</ListItem>*/}
                    </div>
                </List>
                <Divider />
                <ListItem button onClick={() => {
                    localStorage.removeItem('xpertusername');
                    history.push('/admin')
                }}>
                    <ListItemIcon>
                        <PowerSettingsNewIcon />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                </ListItem>
                {/*<List>{secondaryListItems}</List>*/}
            </Drawer>
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>

                    <Grid container spacing={2}>
                        {/* Chart */}
                        
                        <Grid item xs={2}>
                            <Paper className={fixedHeightPaper}>
                                <Title>NEW</Title>
                                <Typography component="p" variant="h4">
                                    {openLeads}
                                </Typography>
                                <Typography color="textSecondary" sx={{ flex: 1 }}>
                                    as on {new Date().getDate()}/{new Date().getMonth()+1}/{new Date().getFullYear()}
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={2}>
                            <Paper className={fixedHeightPaper}>
                            <Title>HOT</Title>
                                <Typography component="p" variant="h4">
                                    {hotLeads}
                                </Typography>
                                <Typography color="textSecondary" sx={{ flex: 1 }}>
                                    as on {new Date().getDate()}/{new Date().getMonth()+1}/{new Date().getFullYear()}
                                </Typography>
                            </Paper>
                        </Grid>
                        {/* Recent Deposits */}
                        <Grid item xs={2}>
                            <Paper className={fixedHeightPaper}>
                            <Title>CLOSED </Title>
                                <Typography component="p" variant="h4">
                                    {closedLeads}
                                </Typography>
                                <Typography color="textSecondary" sx={{ flex: 1 }}>
                                    as on {new Date().getDate()}/{new Date().getMonth()+1}/{new Date().getFullYear()}
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={2}>
                            <Paper className={fixedHeightPaper}>
                            <Title>CONVERTED </Title>
                                <Typography component="p" variant="h4">
                                    {converted}
                                </Typography>
                                <Typography color="textSecondary" sx={{ flex: 1 }}>
                                    as on {new Date().getDate()}/{new Date().getMonth()+1}/{new Date().getFullYear()}
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={4} >
                            <Paper className={fixedHeightPaper}>
                                <Title>Search</Title>
                                <TextField id="outlined-basic" onChange={(event) => setPattern(event.target.value)} variant="outlined" />
                                <Button color="inherit" onClick={() => handleSearch()} style={{marginTop: 10}}>
                                    Search Term
                                </Button>
                            </Paper>
                        </Grid>
                        {/* Recent Orders */}
          
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <ExpertOrders />
                            </Paper>
                        </Grid>
                    </Grid>
                    <Box pt={4} className={classes.copyrights} >
                        <Copyright />
                    </Box>
                </Container>
            </main>
            <Dialog fullScreen open={openDialog} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar color={primaryColor}>
                        <IconButton edge="start" color={primaryColor} onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Searched Enquiries
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div style={{marginTop:30}}>
                    <Title>All Enquiries</Title>
                    <Table size="small">
        <TableHead>
          <TableRow>
          <TableCell>Sr. No</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Phone number</TableCell>
            <TableCell>age</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Emergency</TableCell>
            <TableCell>Appointment Date</TableCell>
            <TableCell>Appointment Time</TableCell>
            <TableCell>Notes</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Created At</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {searchLeads.map((row,index) => (
            <TableRow key={row.profession}>
              <TableCell>{index+1}</TableCell>
              <TableCell>{row.item.name}</TableCell>
              <TableCell>{row.item.phone}</TableCell>
              <TableCell>{row.item.age}</TableCell>
              <TableCell>{row.item.email}</TableCell>
              <TableCell>{row.item.emergency}</TableCell>
              <TableCell>{row.item.appointmentDate}</TableCell>
              <TableCell>{row.item.appointmentTime}</TableCell>
              <TableCell>{row.item.notes}</TableCell>
              <TableCell>{row.item.status == 'hot'? "Hot Lead": row.item.status == 'closed'? "Closed Lead" : "Open Lead"}</TableCell>
              <TableCell align="right">{new Date(row.item.timestamp).toLocaleString()}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
                </div>
            </Dialog>
        </div>
    );
}
