import React,{useEffect} from 'react'
import {makeStyles} from "@material-ui/core/styles";
import MainFooter from '../components/footer';
import MainNavBar from '../components/navBar';
import Image from "../public/assets/images/404page.png"
import {useHistory} from "react-router-dom";


const drawerWidth = 50;




const useStyles = makeStyles((theme) => ({
    root: {
       flex:1,
       height:'100vh'
    },
    image:{
        height:600,
        width:'100%',
        marginTop:130,
        objectFit:'contain'
    }


}));
export default function Terms1() {
    const classes = useStyles();
    const history = useHistory()

    useEffect(() => {
        setTimeout(() => {
            history.push('./')
        },2000)
    }, []);

    return (
        <div className={classes.root}>
        <MainNavBar />
            <div className={classes.imageroot}>
                <img src={Image} alt="not found" className={classes.image} />
            </div>
        <MainFooter />
        </div>
    )
}
