import React,{useState} from 'react'
import { Navbar, Nav , Carousel, } from 'react-bootstrap'
import { makeStyles } from '@material-ui/core/styles';
import Logo from "../public/assets/images/hident.png"
import { Button , Container ,Typography,Grid,Paper}from '@material-ui/core';
import MainFooter from "../components/footer";
import Banner from "../public/assets/images/carousel1.png"
import { Link } from 'react-router-dom'
import {firestore} from "../firebase"


import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import MainNavBar from '../components/navBar';



const useStyles = makeStyles((theme) => ({
    root: {
      flex:1
    },
    navbar:{
        backgroundColor:'#fff'
    },
    logoimage:{
        width:40,
        height:40,
        objectFit:'contain'
    },
    headetitle:{
        fontSize:30,
        marginLeft:30
    },
    bookappointment:{
        backgroundColor:'#DE1C49',
        color:'#fff',
        margin:'0px 40px',
        "&:hover": {
            backgroundColor: "#DE1C49"
        }
    },
    navlist:{
        fontSize:20,
        fontWeight:600
    },
    banner:{
        width:'100%'
    },
    bannerImage:{
        width:'100%',
        height:300,
        objectFit:'cover',
        borderBottomLeftRadius:20,
        borderBottomRightRadius:20
    },
    header:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        padding:"30px 100px"
    },
    headertitle:{
        fontSize:35,
        fontWeight:'600'
    },
    text:{
        fontSize:16,
        fontWeight:500,
        textAlign:'justify'
    },
    card:{
        width:'100%',
        height:300,
        borderRadius:20,
        backgroundColor:'#cdcdcd'
    },
    dialogtitle:{
        fontSize:18,
        fontWeight:600,
        padding:'15px 0px'
    },
    textfeild:{
        border:'none',
        backgroundColor:'#FFF8F2'
    },
    textfiledroot: {
        '& $disabled': {
           borderColor: 'orange'
        }
     },
     disabled: {},

     dialogbuttoncontainer:{
         display:'flex',
         flexDirection:'row',
         justifyContent:'center',
         margin:'20px 0px'
     }

}))
export default function Oral() {
    const classes = useStyles();
    const [index, setIndex] = useState(0);
    const [open, setOpen] = React.useState(false);
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [value, setValue] = React.useState('No');
    const [appointmentDate,setAppointmentDate] = useState("")
    const [appointmentTime,setAppointmentTime] = useState("")
    const [name,setName] = useState("")
    const [phone,setPhone] = useState("")



    const handleClickOpen = () => {
        setOpen(true);
      };

      const handleClose = () => {
        setOpen(false);
      };



      const handleDateChange = (date) => {
          console.log(date)
          var localTime = date.toString();
          setAppointmentDate(localTime.slice(0,15))
          console.log(localTime.slice(15,25))
          setAppointmentTime(localTime.slice(15,25))
          setSelectedDate(date);
      };

     const handleSubmit = () => {
         console.log(phone,name,appointmentDate,appointmentTime,value)
         if(phone =="" || name == ""){
             alert("please enter the name and phoneNumber")
         }
         else{
        firestore.collection('leads').doc(Date.now().toString()).set({
            name: name ,
            phone: phone,
            appointmentDate: appointmentDate,
            appointmentTime:appointmentTime,
            emergency:value,
            timestamp: Date.now()
            },{merge: true})
        handleClose()
        alert("Yor appointment fixed on "+appointmentDate+" at "+appointmentTime)
     }
    }

      const handleChange = (event) => {
        setValue(event.target.value);
      };


    return (
        <div className={classes.root}>
            <MainNavBar />
        {/* <Navbar collapseOnSelect expand="lg" variant="light" sticky="top" style={{backgroundColor:'#fff'}} className={classes.navbar}>
            <img
                className={classes.logoimage}
                src={Logo}
                alt="React Bootstrap logo"
            />
        <Navbar.Brand href="/" className={classes.headetitle}>Dental Care</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ml-auto">
            <Nav.Link href="/" className={classes.navlist}>Home</Nav.Link>
            <Nav.Link href="#aboutus" className={classes.navlist}>About Us</Nav.Link>
            <Nav.Link href="#teams" className={classes.navlist}>Team</Nav.Link>
            <Nav.Link href="#services" className={classes.navlist}>Our Services</Nav.Link>
            <Nav.Link href="#contactus" className={classes.navlist}>Contact us</Nav.Link>
            <Button variant="contained" className={classes.bookappointment} onClick={handleClickOpen} >Book Appointment</Button>
            </Nav>
        </Navbar.Collapse>
        </Navbar> */}

        <div className={classes.banner}>
            <img src={Banner} className={classes.bannerImage} />
        </div>

        <div className={classes.header}>
            <Typography className={classes.headertitle}>Night Guards</Typography>
            <Button variant="contained" className={classes.bookappointment} onClick={handleClickOpen}>Book Appointment</Button>
        </div>

        <Container>
            <Typography className={classes.text}>
                At Hi Dental we assure you that we are focused on protecting your teeth from decay. Teeth grinding (also known as bruxism) at night is a basic problem that causes damage to your teeth, gums, and jaw. While the most visible sign of damage is to the surface of the teeth. Custom night guards are made from high-quality materials and high technology to fit you easily and prevent damage from teeth grinding and jaw clenching, supporting you to achieve a good night’s sleep. To diagnose bruxism and determine if
                you would be benefited from a nightguard or not, our team will perform a dental examination. A Hi dental expert will take impressions and digital x-rays of your mouth, so your custom night guard can be designed. We will provide you all the instructions on how to take care of your night guard, so it remains in a good position for a long time.
            </Typography>

            <Grid container spacing={4} style={{margin:'40px 0px'}}>
                <Grid item xs={6} sm={4}>
                    <div className={classes.card}></div>
                </Grid>
                <Grid item xs={6} sm={4}>
                    <div className={classes.card}></div>
                </Grid>
                <Grid item xs={6} sm={4}>
                    <div className={classes.card}></div>
                </Grid>
            </Grid>

            {/*<Typography className={classes.text}>*/}
            {/*    Nakul Rathi, after his dental degree, pursued his interest in implants from*/}
            {/*    New York University, with a year-long fellowship program. He then went*/}
            {/*    on to do Masters of Science (MS) and Advanced Prosthodontics Clinical*/}
            {/*    Residency Program from The Ohio State University, Columbus (OSU) that*/}
            {/*    is the oldest Prosthodontics program in the USA. His two-year experience at*/}
            {/*    the Implant Clinic at OSU gave him a chance to work in a clinic that has*/}
            {/*    treated over 25000 implants.    Nakul Rathi, after his dental degree, pursued his interest in implants from*/}
            {/*    New York University, with a year-long fellowship program. He then went*/}
            {/*    on to do Masters of Science (MS) and Advanced Prosthodontics Clinical*/}
            {/*    Residency Program from The Ohio State University, Columbus (OSU) that*/}
            {/*    is the oldest Prosthodontics program in the USA. His two-year experience at*/}
            {/*    the Implant Clinic at OSU gave him a chance to work in a clinic that has*/}
            {/*    treated over 25000 implants.    Nakul Rathi, after his dental degree, pursued his interest in implants from*/}
            {/*    New York University, with a year-long fellowship program. He then went*/}
            {/*    on to do Masters of Science (MS) and Advanced Prosthodontics Clinical*/}
            {/*    Residency Program from The Ohio State University, Columbus (OSU) that*/}
            {/*    is the oldest Prosthodontics program in the USA. His two-year experience at*/}
            {/*    the Implant Clinic at OSU gave him a chance to work in a clinic that has*/}
            {/*    treated over 25000 implants.    Nakul Rathi, after his dental degree, pursued his interest in implants from*/}
            {/*    New York University, with a year-long fellowship program. He then went*/}
            {/*    on to do Masters of Science (MS) and Advanced Prosthodontics Clinical*/}
            {/*    Residency Program from The Ohio State University, Columbus (OSU) that*/}
            {/*    is the oldest Prosthodontics program in the USA. His two-year experience at*/}
            {/*    the Implant Clinic at OSU gave him a chance to work in a clinic that has*/}
            {/*    treated over 25000 implants.*/}
            {/*</Typography>*/}

        </Container>



        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={"sm"} maxWidth={'sm'}>
        {/* <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
        <DialogContent>

            <Typography className={classes.dialogtitle}>Name</Typography>
                <TextField
                    autoFocus
                    id="name"
                    variant="outlined"
                    fullWidth
                    className={classes.textfeild}
                    placeholde="Enter Name"
                    onChange = {(e)=>setName(e.target.value)}
                    classes={{notchedOutline:classes.input}}
                    disableUnderline={false}
                    InputProps={{
                        classes: {
                           root: classes.textfiledroot,
                           disabled: classes.disabled
                        }
                     }}
                />

                <Typography className={classes.dialogtitle}>PhoneNumber</Typography>
                <TextField
                    id="name"
                    variant="outlined"
                    fullWidth
                    className={classes.textfeild}
                    onChange = {(e)=>setPhone(e.target.value)}
                    placeholde="Enter PhoneNumber"
                    classes={{notchedOutline:classes.input}}
                    disableUnderline={false}
                    InputProps={{
                        classes: {
                           root: classes.textfiledroot,
                           disabled: classes.disabled
                        }
                     }}
                />

              <Grid container>

                  <Typography className={classes.dialogtitle}>Date of Appointment</Typography>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around">
                        <KeyboardDatePicker
                            minDate={new Date()}
                        margin="normal"
                        id="date-picker-dialog"
                        label="Pick a Date"
                        format="MM/dd/yyyy"
                        value={selectedDate}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        />
                        <KeyboardTimePicker
                        margin="normal"
                        id="time-picker"
                        label="Pick Time"
                        value={selectedDate}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change time',
                        }}
                        />
                    </Grid>
                    </MuiPickersUtilsProvider>
              </Grid>

              <Typography className={classes.dialogtitle}>Emergency ?</Typography>
              <FormControl component="fieldset">
                <RadioGroup aria-label="gender"  name="gender1" value={value} onChange={handleChange}>
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="No" control={<Radio />} label="No" />
                    </div>
                </RadioGroup>
            </FormControl>

        </DialogContent>
        <div className={classes.dialogbuttoncontainer}>
        <Button variant="contained" className={classes.bookappointment} onClick={handleSubmit}>Submit</Button>
        <Button variant="contained" className={classes.bookappointment} onClick={handleClose}>Close</Button>
        </div>
         </Dialog>



            <Container id="contactus">
                <MainFooter/>
        </Container>


        </div>
    )
}
