import React, {useRef, useState,useEffect} from 'react'
import { Navbar, Nav , Carousel,Row, Col } from 'react-bootstrap'
import { makeStyles } from '@material-ui/core/styles';
import Logo from "../public/assets/images/newlogo.png"
import Service1 from "../public/assets/serviceIamgesUpdated/image16.png"
import Service2 from "../public/assets/serviceImages/image21.jpg"
import Service3 from "../public/assets/serviceImages/image1.jpg"
import Service4 from "../public/assets/serviceIamgesUpdated/image22.png"
import Service5 from "../public/assets/serviceImages/image17.jpg"
import Service6 from "../public/assets/serviceImages/image21.jpg"
import Service7 from "../public/assets/serviceImages/image15.png"
import Service8 from "../public/assets/serviceIamgesUpdated/image15.jpg"
import Service9 from "../public/assets/serviceImages/image16.jpg"
import Service10 from "../public/assets/serviceImages/image123.jpg"
import Service11 from "../public/assets/serviceImages/image5.png"
import Service12 from "../public/assets/serviceImages/image25.jpg"
import Service13 from "../public/assets/serviceIamgesUpdated/image8.jpg"
import Service14 from "../public/assets/serviceImages/image3.jpg"
import Service15 from "../public/assets/serviceImages/image44.jpg"
import Service16 from "../public/assets/serviceImages/image49.jpg"
import LogoSmile from "../public/assets/serviceImages/servicesmile.png"
import { Button , Container ,Typography,Grid,Paper}from '@material-ui/core';
import MainFooter from "../components/footer";
import Banner from "../public/assets/serviceImages/banner6.png"
import { Link } from 'react-router-dom'
import {firestore} from "../firebase"


import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import CallIcon from "@material-ui/icons/Call";
import "aos/dist/aos.css"
import AOS from 'aos';
import MainNavBar from '../components/navBar';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import {primaryColor} from "../constants";


const useStyles = makeStyles((theme) => ({
    root: {
        flex:1,
    },
    navbar:{
        backgroundColor:'#fff'
    },
    navbar2:{
        backgroundColor:'#fff',
        marginTop: 75
    },
    logoimage:{
        width:200,
        height:60,
        objectFit:'contain'
    },
    navlist2:{
        fontSize:16,
        marginRight: 30,
        fontFamily: 'Poppins',
        fontWeight:600,
        color: 'white',
        [theme.breakpoints.only('xs')]: {
            fontSize: 10
        },
    },
    headetitle:{
        fontSize:30,
        marginLeft:30,
        fontFamily: 'Poppins',
    },
    bookappointment:{
        backgroundColor:'#DE1C49',
        color:'#fff',
        fontFamily: 'Poppins',
        margin:'0px 40px',
        "&:hover": {
            backgroundColor: "#DE1C49"
        }
    },
    bookappointment2:{
        backgroundColor:'#DE1C49',
        color:'#fff',
        fontFamily: 'Poppins',
        margin:'0px 40px',
        [theme.breakpoints.only('xs')]: {
            display: 'none'
        },
        "&:hover": {
            backgroundColor: "#DE1C49"
        }
    },
    navlist:{
        fontSize:20,
        fontWeight:600,
        fontFamily: 'Poppins',
    },
    headerbanner:{
        marginTop:"0px",
        [theme.breakpoints.only('xs')]: {
            display: "none",
        },
    },
    headerbannerMobile:{
        marginTop:"0px",
        display: "none",
        [theme.breakpoints.only('xs')]: {
            marginTop:"0px",
            display: "block",
        },
    },
    banner:{
        width:'100%',
        fontFamily: 'Poppins',
    },
    bannerImage:{
        width:'100%',
        height:400,
        objectFit:'cover',
        fontFamily: 'Poppins',
        marginTop:110,
        [theme.breakpoints.only('xs')]: {
            height:'100%',
            objectFit:'contain',
        },
    },
    header:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        padding:"30px 100px",
        fontFamily: 'Poppins',
        [theme.breakpoints.only('xs')]: {
            padding:"10px 6px",
        },
    },
    headertitle:{
        fontSize:24,
        fontWeight:'600',
        fontFamily: 'Poppins',
        [theme.breakpoints.only('xs')]: {
            padding: 6,
        },
    },

    headertitle2:{
        fontSize:24,
        fontWeight:'600',
        textAlign:'left',
        padding: 10,
        fontFamily: 'Poppins',
        scrollMargin: "220px",
    },
    headertitle3:{
        fontSize:20,
        fontWeight:'600',
        textAlign:'center',
        padding: 10,
        fontFamily: 'Poppins',
        // backgroundColor: "#efefef",
        borderRadius: 8,
        cursor:'pointer',
        "&:hover":{
            textDecoration:'none'
        }

    },
    text:{
        fontSize:16,
        fontWeight:500,
        textAlign:'justify',
        fontFamily: 'Poppins',
    },
    card:{
        width:'100%',
        height:300,
        marginTop:20,
        objectFit: 'cover',
        borderRadius:8,
        backgroundColor:'#cdcdcd',
        fontFamily: 'Poppins',
        [theme.breakpoints.only('xs')]: {
            height:150,
        },
    },
    dialogtitle:{
        fontSize:18,
        fontWeight:600,
        padding:'15px 0px',
        fontFamily: 'Poppins',
    },
    textfeild:{
        border:'none',
        backgroundColor:'#FFF8F2',
        fontFamily: 'Poppins',
        margin:'5px 0px'
    },
    textfiledroot: {
        '& $disabled': {
            borderColor: 'orange'
        }
    },
    disabled: {},

    dialogbuttoncontainer:{
        display:'flex',
        flexDirection:'row',
        fontFamily: 'Poppins',
        justifyContent:'center',
        margin:'20px 0px'
    },
    gridnames:{
        marginTop: 30,
        marginBottom: 30,
        [theme.breakpoints.only('xs')]: {
            margin: 0,
        },
    },
    logo:{
        height:350,
        marginTop:160,
        marginLeft:50,
        objectFit:'contain',
        [theme.breakpoints.only('xs')]: {
            height:100
        },
    },
    listtileheader:{
        width:'100px',
        color:"#000",
        marginTop:130,
        fontSize:30,
        fontFamily:'Poppins',
        marginLeft:"10%",
        fontWeight:700,
        [theme.breakpoints.only('xs')]: {
            fontSize:12,
            marginLeft:"5px",
        },
    },

    listtitlecontainer:{
        color:"#000",
        top:200,
        marginLeft:"13%",
        marginTop:20,
        [theme.breakpoints.only('xs')]: {

        },

    },
    listtitle:{
        fontSize:16,
        fontFamily:'Poppins',
        fontWeight:700,
        marginTop:10,
        cursor:'pointer',
        textAlign:'left',
        // paddingLeft:'12%',
        [theme.breakpoints.only('xs')]: {
            fontSize:8,
        },
        "&:hover":{
            color:primaryColor
        }

    },
    rightrow:{
        display:'flex',
        [theme.breakpoints.only('xs')]: {
            flexDirection:'column-reverse'
        }
    },
    dialogtitle2:{
        fontSize:16,
        fontFamily: 'Poppins',
        fontWeight:600,
        padding:'15px 0px',
        textAlign: 'center',
        color: '#DE1C49'
    },


}))
export default function Pediatric() {
    const classes = useStyles();
    const [index, setIndex] = useState(0);
    const [open, setOpen] = React.useState(false);
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [value, setValue] = React.useState('No');
    const [appointmentDate,setAppointmentDate] = useState("")
    const [appointmentTime,setAppointmentTime] = useState("")
    const [name,setName] = useState("")
    const [phone,setPhone] = useState("")
    const [ampm,setampm] = useState("AM")
    const [time,settime] = useState("9")
    const [notes,setNotes] = useState("")
    const [email,setEmail] = useState("")
     const [age,setAge] = useState("")
    const [openSnackbar, setOpenSnackBar] = React.useState(false);

    useEffect(() => {
        AOS.init({
            duration : 2000
        });
        window.scrollTo(0, 0);
    }, []);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };



    const handleDateChange = (date) => {
        console.log(date)
        var localTime = date.toString();
        setAppointmentDate(localTime.slice(0,15))
        console.log(localTime.slice(15,25))
        setAppointmentTime(localTime.slice(15,25))
        setSelectedDate(date);
    };

    const handleSubmit = () => {
        console.log(phone,name,appointmentDate,appointmentTime,value)
        if(phone =="" || name == ""){
            alert("Please enter Phone, Name and Date")
        }
        else{
       firestore.collection('leads').doc(Date.now().toString()).set({
           name: name ,
           phone: phone,
           age: age,
           appointmentDate: appointmentDate,
           appointmentTime: time + " " + ampm,
           emergency:value,
           email:email,
           notes:notes,
           timestamp: Date.now()
           },{merge: true})

    }
   }

    const handleClickSnackbar = () => {
        setOpenSnackBar(true);
      };

      const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }

        setOpenSnackBar(false);
      };


    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const PediatricRef = useRef(null)
    const OralRef = useRef(null)
    const TeethRef = useRef(null)
    const ToothColoredRef = useRef(null)
    const TMDTherapy = useRef(null)
    const RootPlanning = useRef(null)
    const NightGuard = useRef(null)

    const executeScroll = (ref) => ref.current.scrollIntoView({behavior: 'smooth',block: 'start', inline: 'start'})

    return (
        <div className={classes.root}>
            <MainNavBar />
            {/* <Navbar collapseOnSelect expand="lg" variant="light" fixed="top" style={{backgroundColor:'#fff'}} className={classes.navbar}>
                <img
                    className={classes.logoimage}
                    src={Logo}
                    alt="React Bootstrap logo"
                />
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ml-auto">
                        <Nav.Link href="/" className={classes.navlist}>Home</Nav.Link>
                        <Nav.Link href="/#aboutus" className={classes.navlist}>About Us</Nav.Link>
                        <Nav.Link href="/#teams" className={classes.navlist}>Team</Nav.Link>
                        <Nav.Link href="/#services" className={classes.navlist}>Our Services</Nav.Link>
                        <Nav.Link href="/#contactus" className={classes.navlist}>Contact us</Nav.Link>
                        <Button variant="contained" className={classes.bookappointment} onClick={handleClickOpen}>Book Appointment</Button>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <Navbar fixed="top" collapseOnSelect variant="light" style={{backgroundColor: '#DE1C49'}} className={[classes.navbar2, {color:'#DE1C49'}]}>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ml-auto">
                        <Nav.Link href="#" style={{color:'#ffffff'}} className={classes.navlist2}>CALL NOW +1 713-451-3333</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar> */}


            <Grid container className={classes.headerbanner}>
                <Grid xs={8}>
                    <img src={Banner} className={classes.bannerImage} />
                </Grid>
                <Grid xs={4}>
                    <div >
                        <Typography className={classes.listtileheader}>Cosmetic Dentistry </Typography>
                        {/* <img src={LogoSmile} class="service-header-smile" className={classes.logo} /> */}
                        <div className={classes.listtitlecontainer}>
                            <ul>
                                <li><Typography className={classes.listtitle} onClick={()=> executeScroll(PediatricRef)} >Smile Makeover</Typography></li>
                                <li><Typography className={classes.listtitle} onClick={()=> executeScroll(OralRef)}>Veneers</Typography></li>
                                <li><Typography className={classes.listtitle} onClick={()=> executeScroll(TeethRef)}>Invisalign</Typography></li>
                                <li><Typography className={classes.listtitle} onClick={()=> executeScroll(ToothColoredRef)}>Orthodontic Treatment</Typography></li>
                            </ul>

                        </div>
                    </div>
                </Grid>
            </Grid>
            <Grid container className={classes.headerbannerMobile}>
                <Grid xs={12}>
                    <img src={Banner} className={classes.bannerImage} />
                </Grid>
            </Grid>

            {/* <div className={classes.banner}>
            <img src={Banner} className={classes.bannerImage} />
        </div> */}

            <div className={classes.header}>
                <Typography className={classes.headertitle}>Cosmetic Dentistry </Typography>
                <Button variant="contained" className={classes.bookappointment2} onClick={handleClickOpen}>Book Appointment</Button>
            </div>
            <Container>
                <Typography className={classes.text}>
                    As our tagline says, "where the smiles come alive"  cosmetic dentistry improves the appearance of your smile through one or more procedures.
                    If your teeth are stained, discolored, crooked, broken, misaligned, or have gaps between them, modern cosmetic dentistry can give you a better smile. A “smile makeover” enhances the appearance of your smile. At Hi dental, Our Cosmetic dentistry experts work with you to develop a custom plan. Below you’ll find some information that can assist you to learn more about the various types of Cosmetic Dental Treatments available at our Hi Dental care clinic.
                </Typography>
            </Container>
            <Container>



                <Grid container spacing={4} data-aos="fade-up" data-aos-duration="500"  style={{marginTop: 20, marginBottom: 20,overflowX:'hidden'}} data-aos="fade-up" data-aos-duration="500" >
                    <Grid item xs={12} sm={4}>
                        <img className={classes.card} src={Service1}/>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Typography data-aos="fade-up" data-aos-duration="500" className={classes.headertitle2} ref={PediatricRef}>Smile Makeover</Typography>
                        <Typography  data-aos="fade-up" data-aos-duration="500"  className={classes.text}>
                            Smile Makeover is a method of improving the looks of a smile through various procedures which are cosmetic or surgical.
                            A smile makeover includes the tooth color, tooth size, tooth shape, tooth position, lip position, lip length, gum position, and gum color. A smile makeover may or may not involve some procedures depending on the patient's examination, that is teeth whitening, to correct stains and discoloration on your enamel. Other cosmetic treatments that may be included in your treatment are veneers and contouring to improve the shape and length of teeth while covering small gaps between your teeth. Restorations such as crowns, bridges, or dental implants may be used to restore a missing tooth. One must opt for Smile Makeover especially because it increases the self-confidence and self-esteem of patients. It also contributes to helping patients to lead a more comfortable lifestyle and smile like never before. After this treatment, the patient must take care of oral hygiene. At Hi Dental, we recommend that you visit us every six months for a routine checkup and minor repairs.

                        </Typography>
                    </Grid>
                </Grid>
                {/*end*/}

                {/*start*/}



                <Grid container spacing={4}  style={{marginTop: 20, marginBottom: 20,overflowX:'hidden'}} data-aos="fade-up" data-aos-duration="500" className={classes.rightrow}>
                    <Grid item xs={12} sm={8}>
                        <Typography data-aos="fade-up" data-aos-duration="500"  className={classes.headertitle2} ref={OralRef}>Veneers</Typography>
                        <Typography className={classes.text} data-aos="fade-up" data-aos-duration="500" >
                            Veneers or Porcelain veneers are thin covers that adhere to teeth to give teeth and are placed over the front (visible) part of the tooth. They look like original teeth and a more classically shaped look.
                            Veneers are made of either porcelain or composite resin material. Hi Dental Experts, will assist you to decide the material that is most suited to you. Each type of veneer has its benefits.
                            A porcelain veneer is a thin covering shell of the teeth that is customized to fit as per the client's teeth shape and size and put on the front part of the tooth.
                            Benefits of porcelain veneers:
                            They are durable and long-lasting
                            They have a natural-looking cover
                            Tess tooth enamel to be extracted than with a crown or cap
                            They do not discolor easily
                            Our Hi dental professional will guide you at every point of treatment and after completing the procedure they will let you know how the veneers need to be cleaned and how to take care of them for long-lasting results.

                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <img className={classes.card} src={Service4}/>
                    </Grid>
                </Grid>
                {/*end*/}

                {/*start*/}


                <Grid container spacing={4} style={{marginTop: 20, marginBottom: 20,overflowX:'hidden'}} data-aos="fade-up" data-aos-duration="500" >
                    <Grid item xs={12} sm={4}>
                        <img className={classes.card} src={Service13}/>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Typography className={classes.headertitle2} data-aos="fade-up" data-aos-duration="500"  ref={TeethRef}>Invisalign </Typography>
                        <Typography className={classes.text} data-aos="fade-up" data-aos-duration="500" >
                            Invisalign is a new approach to straighten the teeth. As they are removable, invisible, and very convenient to use clear aligners. This treatment option gives you a confident smile. Unlike the old treatment of braces, Invisalign aligners are made up of BPA-free plastic and not metal, they do not create skin irritation and cuts inside your cheeks and are removable. It reduces the number of dental visits and is hassle-free. As compared to traditional braces, this provides an easy cleaning system to maintain hygiene.
                            Invisalign treatment is done in several cases like crooked teeth, heredity, thumb-sucking as a child, the loss of a tooth, or a traumatic misfortune to the face. At Hi Dental, professionals use a technique to give teeth an ideal position. In this treatment, a set of clear plastic trays are fixed over the teeth to achieve the exact position of the teeth.
                            The best advantage of the approach is that they can be removed easily while eating and cleaning. The time duration of wearing these aligners depends on your teeth but mostly Hi Dental, professionals recommend wearing them at least one year.
                        </Typography>
                    </Grid>
                </Grid>
                {/*end*/}

                {/*start*/}


                <Grid container spacing={4} style={{marginTop: 20, marginBottom: 20}} data-aos="fade-up" data-aos-duration="500" className={classes.rightrow}>
                    <Grid item xs={12} sm={8}>
                        <Typography className={classes.headertitle2} data-aos="fade-up" data-aos-duration="500"  ref={ToothColoredRef}>Orthodontic Treatment With And Without Braces</Typography>
                        <Typography className={classes.text} data-aos="fade-up" data-aos-duration="500" >
                            In recent years, there have been numerous advancements in dental and orthodontic care for patients of all generations. Conventional metallic braces continue as one of our most successful treatments at Hi dental. To make your smile look gorgeous. Hi Dental, orthodontics always ensures you provide optimal results. One should go for this treatment if they have
                            Bad bite
                            Jaw alignment issues
                            Crowded or gapped teeth
                            Under or over-bites
                            Protruding smiles
                            Metallic braces are more affordable as compared to others. Our metallic braces are made of genuine stainless steel, that is attached to each tooth with an archwire which is gradually tightened gently guiding your teeth into positions.
                            Ceramic braces are a little different as they are made up of ceramic material, that is matched to your tooth color and are less visible from 2 -3 meters away. There is no proper age to wear braces, the most suitable orthodontic treatment period is when you notice this problem or
                            anticipate it. During your consultation, Our experts will give you orthodontic treatment options and help you choose the method that suits your requirements, goals, and budget.

                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <img className={classes.card} src={Service8}/>
                    </Grid>
                </Grid>
                {/*end*/}


                {/*start*/}


                {/*<Grid container spacing={4} style={{marginTop: 20, marginBottom: 20}} data-aos="fade-up" data-aos-duration="500" >*/}
                {/*    <Grid item xs={12} sm={8}>*/}
                {/*        <Typography className={classes.headertitle2}data-aos="fade-up" data-aos-duration="500"  ref={TMDTherapy}>12. TMJ/TMDTherapy</Typography>*/}
                {/*        <Typography className={classes.text} data-aos="fade-up" data-aos-duration="500" >*/}
                {/*            TMD (temporomandibular joint dysfunction)The temporomandibular joint (TMJ) is the place where your jaw joins to your skull to enable you to open and close your jaw easily. Sometimes the joint is hurt or damaged, leading to severe symptoms and complexities. If you are feeling pain or hearing noises when you move your jaw then you must visit your dentist or schedule a consultation with Hi Dental certified dental professionals to determine if you have TMD. To Diagnose TMD our expert will listen to your concerns carefully and will guide you and then perform physical examinations and digital x-rays of your lower jaw to accurately assess the movement of your jaw, look for signs of misalignment, teeth grinding, also look for the areas for tenderness and inflammation. Based on the x-rays and examination our experts will recommend treatment options.*/}
                {/*        </Typography>*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={12} sm={4}>*/}
                {/*        <img className={classes.card} src={Service16}/>*/}
                {/*    </Grid>*/}
                {/*</Grid>*/}
                {/*end*/}

                {/*start*/}


                {/*<Grid container spacing={4} style={{marginTop: 20, marginBottom: 20}} data-aos="fade-up" data-aos-duration="500" >*/}
                {/*    <Grid item xs={12} sm={4} >*/}
                {/*        <img className={classes.card} src={Service9}/>*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={12} sm={8}>*/}
                {/*        <Typography className={classes.headertitle2}data-aos="fade-up" data-aos-duration="500"  ref={NightGuard}>7. Night Guards</Typography>*/}
                {/*        <Typography className={classes.text} data-aos="fade-up" data-aos-duration="500" >*/}
                {/*            At Hi Dental we assure you that we are focused on protecting your teeth from decay. Teeth grinding (also known as bruxism) at night is a basic problem that causes damage to your teeth, gums, and jaw. While the most visible sign of damage is to the surface of the teeth. Custom night guards are made from high-quality materials and high technology to fit your mouth easily and prevent damage from teeth grinding and jaw clenching, supporting you to achieve a good night’s sleep. To diagnose bruxism and determine if you would be benefited from a nightguard or not, our team will perform a dental examination. At Hi dental expert will take impressions and digital x-rays of your mouth, so your custom night guard can be designed. We will provide you all the instructions on how to take care of your night guard, so it remains in a good position for a long time.*/}
                {/*        </Typography>*/}
                {/*    </Grid>*/}
                {/*</Grid>*/}
                {/*end*/}
                <Typography className={classes.headertitle2}>FAQs</Typography>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>Why should I visit the dentist every 6 months?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography style={{textAlign: 'left'}}>
                            Most people do not care or think about visiting a dentist until they feel pain or cavities in the mouth as many dental problems do not show any symptoms at an initial stage but in the advanced stage when it becomes severe. They feel that they would save some money out of it but it ends ups in costing more as people become careless. That is why it is necessary to visit a dentist every after 6 months so we can check your oral health and let that flawless smile recapitulate.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>How to prevent cavities?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography style={{textAlign: 'left'}}>
                            To prevent cavities one should always brush their teeth and spend 2 -3 minutes brushing and flossing and rinse their mouth after every meal. One must not forget to visit their dentists for a regular checkup and good oral health.
                        </Typography>
                    </AccordionDetails>
                </Accordion><
                Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>Why is flossing important after brushing?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography style={{textAlign: 'left'}}>
                        Flossing is important after brushing because it gets rids of bacteria that are not gone by brushing. if you do not floss between the teeth you allow plaque to remain within the teeth. These microscopic bacteria hide between the tiny spaces of teeth.
                        Ask your dentist to show you the proper way to floss. You will notice the huge difference at the next cleaning appointment.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            </Container>



<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={"sm"} maxWidth={'sm'}>
{/* <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
<DialogContent>

    <Typography className={classes.dialogtitle2}>Book your first appointment for free!</Typography>

    {/* <Typography className={classes.dialogtitle}>Name</Typography> */}
        <TextField
            autoFocus
            id="name"
            variant="outlined"
            fullWidth
            className={classes.textfeild}
            placeholder="Enter Name"
            onChange = {(e)=>setName(e.target.value)}
            classes={{notchedOutline:classes.input}}
            disableUnderline={false}
            InputProps={{
                classes: {
                   root: classes.textfiledroot,
                   disabled: classes.disabled
                }
             }}
        />

        {/* <Typography className={classes.dialogtitle}>PhoneNumber</Typography> */}
        <TextField
            autoFocus
            id="name"
            variant="outlined"
            fullWidth
            className={classes.textfeild}
            onChange = {(e)=>setPhone(e.target.value)}
            placeholder="Enter PhoneNumber"
            classes={{notchedOutline:classes.input}}
            disableUnderline={false}
            InputProps={{
                classes: {
                   root: classes.textfiledroot,
                   disabled: classes.disabled
                }
             }}
        />
    {/* <Typography className={classes.dialogtitle}>Age</Typography> */}

    <TextField
        autoFocus
        id="name"
        variant="outlined"
        fullWidth
        className={classes.textfeild}
        onChange = {(e)=>setAge(e.target.value)}
        placeholder="Enter Your Age"
        classes={{notchedOutline:classes.input}}
        disableUnderline={false}
        InputProps={{
            classes: {
                root: classes.textfiledroot,
                disabled: classes.disabled
            }
        }}
    />
    <TextField
        id="name"
        variant="outlined"
        fullWidth
        className={classes.textfeild}
        onChange = {(e)=>setEmail(e.target.value)}
        placeholder="Enter Your Email"
        classes={{notchedOutline:classes.input}}
        disableUnderline={false}
        InputProps={{
            classes: {
                root: classes.textfiledroot,
                disabled: classes.disabled
            }
        }}
    />
    <TextField
        autoFocus
        id="name"
        variant="outlined"
        fullWidth
        className={classes.textfeild}
        onChange = {(e)=>setNotes(e.target.value)}
        placeholder="Enter Description/Notes"
        classes={{notchedOutline:classes.input}}
        disableUnderline={false}
        InputProps={{
            classes: {
                root: classes.textfiledroot,
                disabled: classes.disabled
            }
        }}
    />

      <Grid container>
          <Typography className={classes.dialogtitle}>Select a date for appointment</Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justify="space-around">
                <KeyboardDatePicker
                    minDate={new Date()}
                margin="normal"
                id="date-picker-dialog"
                label="Pick a Date"
                format="MM/dd/yyyy"
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                />
                <div style={{margin: 40}}>
                    <select
                        style={{padding: 5, fontSize: 16, marginRight: 5}}
                        value={time}
                        onChange={(e) => settime(e.target.value)}
                    >
                        <option value="8">8:00</option>
                        <option value="9">9:00</option>
                        <option value="10">10:00</option>
                        <option value="11">11:00</option>
                        <option value="12">12:00</option>
                        <option value="1">1:00</option>
                        <option value="2">2:00</option>
                        <option value="3">3:00</option>
                        <option value="4">4:00</option>
                        <option value="5">5:00</option>
                        <option value="6">6:00</option>
                        <option value="7">7:00</option>
                    </select>
                    <select
                        style={{padding: 5, fontSize: 16, marginRight: 5}}
                        value={ampm}
                        onChange={(e) => setampm(e.target.value)}
                    >
                        <option value="AM">AM</option>
                        <option value="PM">PM</option>
                    </select>
                    {/*<p>{message}</p>*/}
                </div>

            </Grid>
            </MuiPickersUtilsProvider>
      </Grid>


      <FormControl component="fieldset">
        <RadioGroup aria-label="gender"  name="gender1" value={value} onChange={handleChange}>
            <div style={{display:'flex',justifyContent:'space-between'}}>
            <Typography className={classes.dialogtitle} style={{paddingRight:'20px'}}>Emergency ?</Typography>
            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="No" control={<Radio />} label="No" />
            </div>
        </RadioGroup>
    </FormControl>

</DialogContent>
<div className={classes.dialogbuttoncontainer}>
<Button variant="contained" className={classes.bookappointment} onClick={handleSubmit}>Submit</Button>
<Button variant="contained" className={classes.bookappointment} onClick={handleClose}>Close</Button>
</div>
 </Dialog>

            <MainFooter id="contactus"/>

        </div>
    )
}
