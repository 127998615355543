import Firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/messaging';

var firebaseConfig = {
    apiKey: "AIzaSyBdvu-k4F_qtF_xReq1NOjeZub_fV-cemg",
    authDomain: "hi-dental-579bf.firebaseapp.com",
    projectId: "hi-dental-579bf",
    storageBucket: "hi-dental-579bf.appspot.com",
    messagingSenderId: "229827894067",
    appId: "1:229827894067:web:b3fd528b30c36fd6d43c46"
};

// Initialize Firebase
const firebase = Firebase.initializeApp(firebaseConfig);
const database = firebase.database();
export {database};
const auth = firebase.auth();
export {auth}
const firestore = firebase.firestore();
export {firestore}
const storage = firebase.storage();
export {storage}

export const placeholder = "https://firebasestorage.googleapis.com/v0/b/smd-firebase-ca66a.appspot.com/o/placeholder_logo.png?alt=media&token=5d957eb4-8643-4988-b368-4723343b91fa"

// const messaging = firebase.messaging();
//
// messaging.onMessage((payload) => {
//     console.log('Message received. ', payload);
//     // ...
// });
// export {messaging}