import React, {useRef, useState,useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Button , Container ,Typography,Grid,Paper,TextField}from '@material-ui/core';
import MainFooter from "../components/footer";
import "aos/dist/aos.css"
import AOS from 'aos';
import MainNavBar from '../components/navBar';
import {primaryColor} from "../constants";
import DateFnsUtils from '@date-io/date-fns';
import thanks from '../public/assets/images/thankyou.png'
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
  import Radio from '@material-ui/core/Radio';
  import RadioGroup from '@material-ui/core/RadioGroup';
  import FormControlLabel from '@material-ui/core/FormControlLabel';
  import FormControl from '@material-ui/core/FormControl';
  import FormLabel from '@material-ui/core/FormLabel';
  import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
  import { Map, GoogleApiWrapper,InfoWindow, Marke } from 'google-maps-react';
  import { styles } from '@material-ui/pickers/views/Calendar/Calendar';
  import GoogleMapReact from 'google-map-react';
  import Snackbar from '@material-ui/core/Snackbar';
  import MuiAlert from '@material-ui/lab/Alert';
  import {firestore} from "../firebase"
import {useHistory} from "react-router-dom";
import Logo from "../public/assets/images/newlogo.png";



const useStyles = makeStyles((theme) => ({
    root: {
        flex:1,
    },
    gridcontainer:{
        marginTop:120,
        width:'100%'
    },
    grid1:{
        // padding:'20px 30px',
        alignItems:'center',
        alignSelf:'center'
    },
    grid2:{
        backgroundColor:'white',
        right:30,
        padding:'10px 20px',
        zIndex:-1,
    },
    form:{
        padding:10
    },
    formContainer:{
        borderRadius:20,
        // position:'fixed',

    },
    formheader:{
        backgroundColor:'#DE1C49',
        padding:12,
        color:"#fff",
        fontSize:20,
        fontWeight:600,
        borderTopLeftRadius:16,
        borderTopRightRadius:16
    },
    textfeild:{
        border:'none',
        fontFamily: 'Poppins',
        "&, .MuiOutlinedInput-input":{
            padding:"10px 5px"
        }
    },
    textfiledroot: {
     },

     dialogbuttoncontainerform:{
        display:'flex',
        flexDirection:'row',
        fontFamily: 'Poppins',
        justifyContent:'center',
        margin:'5px 0px',
        paddingBottom:20
    },
    miantitle:{
        fontSize:24,
        fontWeight:'800',
        padding:'30px 0px',
        textAlign:'center'
    },
    miantitle2:{
        fontSize:12,
        fontWeight:'800',
        padding:'30px 0px',
        textAlign:'center'
    },
    mythtitle:{
        fontSize:18,
        fontWeight:'600',
        padding:'10px 0px',
        textAlign:'center'
    },
    mythtruth:{
        fontSize:18,
        fontWeight:'400',
        padding:'10px 0px',
        textAlign:'center'
    },
    miandesc:{
        fontSize:18,
        fontWeight:'400',
        textAlign:'center'
    },
    submitbutton:{
        backgroundColor:'#DE1C49',
        color:'#fff',
        fontFamily: 'Poppins',
        fontWeight: "800",
        margin:'10px 10px',
        padding:"5px 30%",
        "&:hover": {
            backgroundColor: "#DE1C49"
        }
    },
    thanksimage:{
        width: 350,
        height: 350,
        alignSelf: "center"
    }
}))
export default function BlogPage() {
    const classes = useStyles();
    const [index, setIndex] = useState(0);
    const [open, setOpen] = React.useState(false);
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [value, setValue] = React.useState('No');
    const [appointmentDate,setAppointmentDate] = useState("")
    const [appointmentTime,setAppointmentTime] = useState("")
    const [name,setName] = useState("")
    const [phone,setPhone] = useState("")
    const [ampm,setampm] = useState("AM")
    const [time,settime] = useState("9")
    const [notes,setNotes] = useState("")
    const [email,setEmail] = useState("")
     const [age,setAge] = useState("")
    const [openSnackbar, setOpenSnackBar] = React.useState(false);
    const [bottom,setBottom] = useState(true)
    const history = useHistory();

    useEffect(() => {
        AOS.init({
            duration : 2000
        });
        window.scrollTo(0, 0);
        setTimeout(() => {
            history.push('./')
        },6000)
    }, []);

    const handleDateChange = (date) => {
        console.log(date)
        var localTime = date.toString();
        setAppointmentDate(localTime.slice(0,15))
        console.log(localTime.slice(15,25))
        setAppointmentTime(localTime.slice(15,25))
        setSelectedDate(date);
    };


    const K_HEIGHT = 40;
    const K_WIDTH = 40;
  const greatPlaceStyle = {
      // initially any map object has left top corner at lat lng coordinates
      // it's on you to set object origin to 0,0 coordinates
      position: 'absolute',
      width: K_WIDTH,
      height: K_HEIGHT,
      left: -K_WIDTH / 2,
      top: -K_HEIGHT / 2,
      objectFit:'contain',
      border: '1px solid #f44336',
      borderRadius: K_HEIGHT,
      backgroundColor: 'white',
      textAlign: 'center',
      color: '#3f51b5',
      fontSize: 16,
      fontWeight: 'bold',
      padding: 4
    };


    return (
        <div>
        <div className={classes.root}>
        <MainNavBar />
            <div>
                <div style={{display:'flex', justifyContent: 'center', marginTop: 200, flexDirection:'column'}}>
                    <img className={classes.thanksimage} src={thanks} width={350} height={350}/>
                    <Typography className={classes.miantitle}>Thanks for submitting your details</Typography>
                    <p style={{fontSize: 12}}>Disclaimer: This website is designed to let you know more about our practice and our team and provide some general information about the treatment we provide.
                                    The website is made available subject to this disclaimer, our terms of use, privacy policy and booking and cancellation policy.
                                </p>

                </div>
        <MainFooter id="contactus"/>
        </div>

        </div>

        </div>
    )
}
