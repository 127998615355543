import React, {useRef, useState,useEffect} from 'react'
import { Navbar, Nav , Carousel,Row , Col} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.css';
import Logo from "./public/assets/images/newlogo.png"
import { makeStyles } from '@material-ui/core/styles';
import { Button , Container ,Typography,Grid,Paper}from '@material-ui/core';
import Carousel1 from "./public/assets/images/banner1.png"
import CookieConsent, { Cookies } from "react-cookie-consent"
import Carousel2 from "./public/assets/images/banner2.png"
import Carousel3 from "./public/assets/images/banner3.png"
import Carousel4 from "./public/assets/images/banner5.png"
import Carousel5 from "./public/assets/images/banner6.png"
import AboutImage from "./public/assets/images/aboutimage.png"
import googleReview from "./public/assets/images/googlereview.png"
import stars from "./public/assets/images/stars.png"
import Hident  from "./public/assets/images/hident.png"
import WhyUs  from "./public/assets/images/whyus.jpg"
import CallIcon from '@material-ui/icons/Call';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import RoomIcon from '@material-ui/icons/Room';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import styled, { keyframes } from 'styled-components';
import { MDBRipple } from 'mdb-react-ui-kit';
import SimpleForm from "./pages/SimpleForm";
import CancelIcon from '@material-ui/icons/Cancel';
import Aos from "aos"
import CloseIcon from '@material-ui/icons/Close';
import 'aos/dist/aos.css';
import "./HomePageStyles.css"
import CarouselCard from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import BlogImage1 from "./public/assets/blogimages/blogimage1.png"
import BlogImage2 from "./public/assets/blogimages/blogimage2.png"
import BlogImage3 from "./public/assets/blogimages/blogimage3.png"
import BlogImage4 from "./public/assets/blogimages/blogimage4.png"
import BlogImage5 from "./public/assets/blogimages/blogimage5.png"
import BlogImage6 from "./public/assets/blogimages/blogimage6.png"
import BlogImage7 from "./public/assets/blogimages/blogimage7.png"
import BlogImage8 from "./public/assets/blogimages/blogimage8.png"
import BlogImage9 from "./public/assets/blogimages/blogimage9.png"
import BlogImage10 from "./public/assets/blogimages/blogimage10.png"
import BlogImage11 from "./public/assets/blogimages/BlogImage11.png"
import BlogImage12 from "./public/assets/blogimages/blogImage12.png"
import BlogImage13 from "./public/assets/blogimages/blogImage13.png"

import Category1 from "./public/assets/images/categoryname1.png"
import Category2 from "./public/assets/images/categoryname2.png"
import Category3 from "./public/assets/images/categoryname3.png"
import Category4 from "./public/assets/images/categoryname4.png"
import Category5 from "./public/assets/images/categoryname5.png"
import Category6 from "./public/assets/images/categoryname6.png"
import BA1 from "./public/assets/beforeafterremade/1A.png"
import BA2 from "./public/assets/beforeafterremade/1B.png"
import BA3 from "./public/assets/beforeafterremade/2A.png"
import BA4 from "./public/assets/beforeafterremade/2B.png"
import BA5 from "./public/assets/beforeafterremade/3A.png"
import BA6 from "./public/assets/beforeafterremade/3B.png"
import BA7 from "./public/assets/beforeafterremade/4A.png"
import BA8 from "./public/assets/beforeafterremade/4B.png"
import BA9 from "./public/assets/beforeafterremade/5A.png"
import BA10 from "./public/assets/beforeafterremade/5B.png"
import BA11 from "./public/assets/beforeafterremade/6A.png"
import BA12 from "./public/assets/beforeafterremade/6B.png"
import BA13 from "./public/assets/patientPictures/Fullmouth1.png"
import BA14 from "./public/assets/patientPictures/fullmouth2.png"
import BA15 from "./public/assets/patientPictures/premiumdentures1.png"
import BA16 from "./public/assets/patientPictures/premiumdentures2.png"
import BA17 from "./public/assets/patientPictures/Snaponsmile1.png"
import BA18 from "./public/assets/patientPictures/Snaponsmile2.png"
import footer from './components/footer'
import MainFooter from "./components/footer";
import Team1 from './public/assets/images/team1.jpeg'
import Team2 from './public/assets/images/team2.jpeg'
import Gamble from './public/assets/images/drgamble.png'
import Arun from './public/assets/images/drarun.jpg'
import Team3 from './public/assets/images/team3.png'
import {Link} from 'react-router-dom'
import { useLocation, useHistory } from 'react-router-dom'
import {firestore} from "./firebase"
import {fadeIn, fadeInLeft, fadeInRight} from 'react-animations';
import { fadeInUpBig} from 'react-animations';
import { zoomInUp } from 'react-animations';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import "aos/dist/aos.css"
import AOS from 'aos';
import { Map, GoogleApiWrapper,InfoWindow, Marke } from 'google-maps-react';
import { styles } from '@material-ui/pickers/views/Calendar/Calendar';
import GoogleMapReact from 'google-map-react';
import {primaryColor, whiteColor} from "./constants";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import MapImage from "../src/public/assets/images/map1.png"
import CarouselCardLogo from "./public/assets/images/customerlogo2.png"
import ChooseUs1 from "./public/assets/images/why1.png"
import ChooseUs2 from "./public/assets/images/why2.png"
import ChooseUs3 from "./public/assets/images/why3.png"
import ChooseUs4 from "./public/assets/images/why4.png"
import Bannervideo from "./public/assets/vidoes/bgvideo.mp4"

import xtype from 'xtypejs'
import Imag from "./public/assets/images/smilelanding.png";
const fadeinnRightAnimation = keyframes`${fadeInRight}`
const fadeInAnimation = keyframes`${fadeIn}`
const fadeinleftAnimation = keyframes`${fadeInLeft}`;
const fadeinupAnimation = keyframes`${fadeInUpBig}`;
const bounceAnimation = keyframes`${zoomInUp}`;

const BouncyDiv = styled.div`
  animation: 1s ${bounceAnimation};
`;
const FadeInDiv = styled.div`
  animation: 1s ${fadeInAnimation};
`;
const FadeinLeftDiv = styled.div`
  animation: 2s ${fadeinleftAnimation};
`;

const FadeinRightDiv = styled.div`
  animation: 2s ${fadeinnRightAnimation};
`;
const FadeinupDiv = styled.div`
  animation: 2.5s ${fadeinupAnimation};
`;
const useStyles = makeStyles((theme) => ({
    root: {
      flex:1,
        scrollBehavior: 'smooth',
        overflow:'hidden'
    },
    rootGrid: {
    flexGrow: 1,
    },
    navbar:{
        backgroundColor:'#fff',
        zIndex:1
    },
    navbar2:{
        backgroundColor:'#fff',
        marginTop: 75,
        zIndex:1
    },
    logoimage:{
        width:200,
        height:60,
        objectFit:'contain'
    },
    headetitle:{
        fontSize:30,
        marginLeft:30,
        fontFamily: 'Poppins',
        fontStyle:'italic',
        [theme.breakpoints.only('xs')]: {
            fontSize: 15
        },
    },
    bookappointment:{
        backgroundColor:'#DE1C49',
        color:'#fff',
        fontFamily: 'Poppins',
        padding: 12,
        fontWeight: "800",
        margin:'0px 20px',
        "&:hover": {
            backgroundColor: "#DE1C49",
            textDecoration:'none'
        },
        [theme.breakpoints.only('xs')]: {
            margin:'20px 20px',
        },
    },
    bookappointmentbutton:{
        backgroundColor:'#ffffff',
        color:'#000000',
        fontFamily: 'Poppins',
        padding: 12,
        fontWeight: "800",
        margin:'0px 20px',
        [theme.breakpoints.only('xs')]: {
            margin:'20px 20px',
        },
    },
    submitbutton:{
        backgroundColor:'#DE1C49',
        color:'#fff',
        fontFamily: 'Poppins',
        fontWeight: "800",
        margin:'10px 10px',
        padding:"5px 30%",
        "&:hover": {
            backgroundColor: "#DE1C49"
        }
    },
    navlist:{
        fontSize:20,
        fontFamily: 'Poppins',
        fontWeight:600
    },
    navlist2:{
        fontSize:16,
        marginRight: 12,
        fontFamily: 'Poppins',
        fontWeight:600,
        color: 'white',
        [theme.breakpoints.only('xs')]: {
            fontSize: 20,
            padding:8
        },
    },
    carousel:{
        padding:'20px 10px',
        marginTop: 120,
        fontFamily: 'Poppins',
        overflowY:'hidden'

    },
    carouselimage:{
        borderRadius:40,
        // marginTop: 100,
    },
    pagetitles:{
        textAlign:'start',
        fontFamily: 'Poppins',
        margin:'0px 10px',
        fontSize:26,
        fontWeight:600,
        color: '#000',
        marginTop:100,
        scrollMarginTop: '120px',
        marginBottom: '-15px',
    },
    pagetitlesTop:{
        textAlign:'center',
        margin:'10px 5px',
        fontSize:32,
        fontFamily: 'Poppins',
        fontWeight:600,
        color: 'black',
        fontStyle:'italic',
        scrollMarginTop: '120px',
        [theme.breakpoints.only('xs')]: {
            fontSize: 24,
            margin:'2px 2px',
        },
    },
    pagetitles2:{
        textAlign:'start',
        margin:'20px 10px',
        fontFamily: 'Poppins',
        fontSize:20,
        fontWeight:600,
        scrollMarginTop: '120px',
    },
    cardimage:{
        objectFit:'cover',
        borderRadius:20,
        width:'100%',
        fontFamily: 'Poppins',
        height:400,
        verticalAlign:'middle',
    },
    happyfacetitle:{
        fontSize:26,
        fontWeight:700,
        fontFamily: 'Poppins',
        fontStyle: 'italic'

    },
    happyfacesubtitle:{
        fontSize:20,
        fontWeight:400,
        fontFamily: 'Poppins',
       fontStyle: 'italic'
    },
    happyfacedesc:{
        fontSize:16,
        margin:'20px 0px',
        fontFamily: 'Poppins',
       fontStyle: 'italic',
        textAlign:'justify'
    },
    serviceimage:{
        objectFit:'cover',
        borderRadius:20,
        width:'100%',
        height:250,
        verticalAlign:'middle',
        fontFamily: 'Poppins',
        zIndex:1,
    } ,
    servicecard:{
        padding:'20px',
        fontFamily: 'Poppins',
        borderRadius: 20,
        [theme.breakpoints.only('xs')]: {
            padding:'5px',
        },
        "&:hover":{
            // transform: "scale(1.2)",
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            height: "100%",
            width: "100%",
            transition: ".5s ease",
            backgroundColor: "#DE1C49",
        }
    },
    servicecardopen:{
        padding:'20px',
        fontFamily: 'Poppins',
    },
    cardtitle:{
        fontSize:18,
        fontWeight:600,
        fontFamily: 'Poppins',
        textAlign:'start',
        paddingLeft:10,
        paddingTop:20
    },
    carddesc:{
        fontSize:16,
        fontFamily: 'Poppins',
        textAlign:'start',
        paddingLeft:10,
        paddingTop:10
    },
    serviceimagepen:{
        objectFit:'cover',
        // width:'250px',
        fontFamily: 'Poppins',
        height:'250px',
        verticalAlign:'middle',
        zIndex:1,
        borderRadius: 16,
        [theme.breakpoints.only('xs')]: {
            width:'250px',
        },
    },
    buttonbelowcontainer:{
        margin:"40px 0px"
    },
    buttonbelowcontainerform:{
        margin:"10px 0px",
        paddingBottom:"20px"
    },
    buttonbelowcontainerform2:{
        margin:"10px 0px",
        paddingBottom:"20px",
        display:'none',
            [theme.breakpoints.only('xs')]: {
                display:'block',
                flexDirection:'column'
        },
    },
    footerroot :{
        backgroundColor:'#DE1C49',
        padding:'30px 20px',
        display:'flex',
        fontFamily: 'Poppins',
        justifyContent:'space-around',
        flexWrap:'wrap',
        [theme.breakpoints.only('xs')]: {
          display:'flex',
          flexDirection:'column'
        },
    },
    div1:{
        width:'40%',
        padding:'0% 0px',
        [theme.breakpoints.only('xs')]: {
          width:'100%'
        },
    },
    // div2:{
    //     width:'5%',
    // },
    logoview:{
        display:'flex',
    },
    appname:{
        fontSize:30,
        marginLeft:10,
        fontFamily: 'Poppins',
        fontWeight: 600,
        color:'#fff'
    },
    footertext:{
        fontSize:16,
        width:'100%',
        margin:'30px 0px',
        textAlign:'start',
        fontFamily: 'Poppins',
        color:'#fff',
        [theme.breakpoints.only('xs')]: {
            textAlign:'center',
            margin:'10px 0px',

        },
    },
    aboutAppstext:{
        fontSize:20,
        marginLeft:0,
        fontFamily: 'Poppins',
        fontWeight:600,
        margin:'20px 0px',  textAlign:'start',
        color:'#fff',
    },
    linksview:{
        marginTop:'30%',
        fontFamily: 'Poppins',
        color:'#fff',
        [theme.breakpoints.only('xs')]: {
            marginTop:20
        },
    },
    aboutustitle:{
        fontSize:35,
        padding:'30px 0px',
        fontFamily: 'Poppins',
        fontWeight:'600',
        [theme.breakpoints.only('xs')]: {
            fontSize: 24
        },
    },
    abouttextspacing:{
        fontSize:16,
        fontFamily: 'Poppins',
        letterSpacing:1,
        padding:'10px 0px',
        textAlign: 'left'
    },
    abouttext:{
        fontSize:18,
        letterSpacing:1,
        fontFamily: 'Poppins',
        textAlign: 'justify',
    },
    abouttextfooter:{
        position:'absolute',
        fontSize:18,
        letterSpacing:1,
        fontFamily: 'Poppins',
        textAlign: 'justify',
        marginTop:60,
        fontWeight:'600',
        paddingLeft:10
    },
    carousellist:{
        fontSize:20,
        fontWeight:'600',
        fontFamily: 'Poppins',
        padding:"10px 20px",
        margin:'20px 0px',
        cursor:'pointer'
    },
    listlink:{
        color:'#000',
        "&:hover":{
            color:'#000000',
            textDecoration:'none'
        }
    },
    dialogtitle:{
        fontSize:12,
        fontFamily: 'Poppins',
        fontWeight:600,
        padding:'10px 0px'
    },
    dialogtitle3:{
        fontSize:12,
        fontFamily: 'Poppins',
        fontWeight:600,
        padding:'10px 0px',
        textAlign: 'left',
    },
    dialogtitle2:{
        fontSize:16,
        fontFamily: 'Poppins',
        fontWeight:600,
        padding:'15px 0px',
        textAlign: 'center',
        color: '#DE1C49'
    },
    textfeild:{
        border:'none',
        fontFamily: 'Poppins',
        backgroundColor:'#FFF8F2',
        margin:'5px 0px'
    },
    textfiledroot: {
        '& $disabled': {
           borderColor: 'orange'
        }
     },
     disabled: {},

     dialogbuttoncontainer:{
         display:'flex',
         flexDirection:'row',
         fontFamily: 'Poppins',
         justifyContent:'center',
         margin:'20px 0px',
     },
     dialogbuttoncontainerform:{
        display:'flex',
        flexDirection:'row',
        fontFamily: 'Poppins',
        justifyContent:'center',
        margin:'5px 0px',
        paddingBottom:20
    },
     knowmore:{
         display:'flex',
         flexDirection:'row',
         fontFamily: 'Poppins',
         justifyContent:'center',
         fontSize:20,
         paddingTop:10,
         cursor:'pointer'
     },
     knowmoretitle:{
         fontWeight:'600',
         fontFamily: 'Poppins',
     },
     carouseltitle:{
        top: "30%",
        bottom: 'auto',
        position:'absolute',
        width:'400px',
        fontSize:'40px',
        marginLeft:'10%',
        fontWeight:'800',
         fontFamily: 'Poppins',
         textAlign:'start',
        letterSpacing:1,
        [theme.breakpoints.only('xs')]: {
            top: "60%",
            bottom: 'auto',
            position:'absolute',
            fontSize:'12px',
            textAlign:'center',
            letterSpacing:1,
            width:'80%'
        },

     },
    carouseltitle3:{
        top: "10%",
        bottom: 'auto',
        fontFamily: 'Poppins',
        position:'absolute',
        width:'400px',
        fontSize:'40px',
        marginLeft:'60%',
        fontWeight:'800',
        textAlign:'start',
        letterSpacing:1,
        [theme.breakpoints.only('xs')]: {
            top: "15%",
            bottom: 'auto',
            position:'absolute',
            fontFamily: 'Poppins',
            fontSize:'12px',
            marginLeft:'40%',
            textAlign:'center',
            letterSpacing:1,
            width:'60%'
        },
    },
    googlereviewstyle:{
        display: 'flex',
        flexDirection: 'row',
        alignSelf: 'flex-star',
        width: '200px'
    },
    starstyle:{
        display: 'flex',
        flexDirection: 'row',
        alignSelf: 'flex-star',
        marginTop: 10,
        width: '180px'
    },
    carouseltitle2:{
        top: "30%",
        bottom: 'auto',
        position:'absolute',
        width:'400px',
        fontFamily: 'Poppins',
        fontSize:'40px',
        marginLeft:'60%',
        fontWeight:'800',
        textAlign:'start',
        letterSpacing:1,
        [theme.breakpoints.only('xs')]: {
            top: "15%",
            bottom: 'auto',
            position:'absolute',
            marginLeft:'40%',
            fontSize:'12px',
            textAlign:'center',
            letterSpacing:1,
            width:'60%'
        },
    },
    carouselcard:{
        height:450,
        borderRadius:20,
        padding:'20px',
        width:'95%',
        border:'1px solid #efefef',
        zIndex:2,
        justifyContent: 'space-between',
        [theme.breakpoints.only('xs')]: {
            height:550,
            padding:'20px',
            marginTop: 20
        }
    },
    carouselcardContact:{
        height:230,
        borderRadius:20,
        padding:'20px',
        width:220,
        marginBottom: 10,
        justifyContent: 'center',
        border:'1px solid #efefef',
        [theme.breakpoints.only('xs')]: {
            height:350,
            padding:'20px',
        }
    },
    carouselcard2:{
        height:300,
        borderRadius:20,
        padding:'50px',
        width:'40%',
        border:'1px solid #efefef',
        [theme.breakpoints.only('xs')]: {
            height:350,
            padding:'20px',
        }
    },
    statement:{
        fontSize:15,
        fontWeight:600,
        textAlign:'left',
        fontFamily: 'Helvetica'
    },
    statementname:{
        // position:'absolute',
        fontSize:20,
        fontWeight:500,
        bottom:20,
        textAlign:'left'
    },
    form:{
        padding:10
    },
    formContainer:{
        borderRadius:20
    },
    formheader:{
        backgroundColor:'#DE1C49',
        padding:12,
        color:"#fff",
        fontSize:20,
        fontWeight:600,
        borderTopLeftRadius:16,
        borderTopRightRadius:16
    },
    // mapStyles:{
    //     height: '500px',
    //     width: '100%',
    //     [theme.breakpoints.only('xs')]: {
    //         height:400
    //     }
    // },
    mapimage:{
        height: '550px',
        width: '100%',
        [theme.breakpoints.only('xs')]: {
            height:400
        }
    },
    rightrow:{
        display:'flex',
        [theme.breakpoints.only('xs')]: {
            flexDirection:'column-reverse'
        }
    },
    linkbutton:{
        "&:hover":{
            textDecoration:'none'
        }
    },
    contactCard:{
        padding: 20,
        marginBottom: 20,
        borderColor: '#000000',
        borderRadius:20,
        borderWidth: 1,
        fontFamily: 'Poppins',
        fontWeight: 600
    },
    blogcontainer:{
        padding:'20px',
        textAlign:'start',
        margin:'40px 10px'
    },
    blogcard:{
        padding:'10px',
        textAlign:'start',
        margin:'20px 0px',
        cursor:'pointer'
    },
    blogsubtitle:{
        color:'#DE1C49',
        fontSize:16,
        fontWeight:'500',
        textAlign:'start'
    },
    blogtitle:{
        fontSize:24,
        fontWeight:'900',
        textAlign:'start',
        height:'70px',
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    blogtag:{
        position: "absolute",
        marginTop: 20,
        background: "#DE1C49",
        padding: '5px 32px',
        borderRadius: '5px',
        color:'#fff',
        fontFamily: 'Poppins',
        fontStyle: 'italic'
    },
    blogimage:{
        width:'100%',
        height:'70%',
        borderRadius: 20,
        objectFit: 'cover'
    },
    blognameanddate:{
        fontSize:14,
        paddingTop:10,
        color:'#8c8c8c',
        textAlign:'start'
    },
    blogdesc:{
        fontSize:18,
        fontWeight:400,
        textAlign:'start',
        padding:"15px 0px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        height:'100px',
        "&:after":{
            content:'...'
        }
    },
    blogreadmore:{
        textAlign:'start',
        fontSize:18,
        fontWeight:'600',
        textDecoration:'underline',
        cursor:'pointer',
        "&:hover":{
            color:'#DE1C49'
        }

    },
    seeall:{
        paddingTop:125,
        fontSize:20,
        color:'#000',
        "&:hover":{
            color:'#DE1C49'
        }
    },
    carouselcardsmile:{
        width: '50%',
        bottom: '20px',
        marginTop: 20,
        // position: 'absolute',
        // right: '20%',
        zIndex: 0,
        opacity: 1,
    },
    choosecard:{
        width:'250px',
        marginLeft:'2%',
        [theme.breakpoints.only('xs')]: {
            marginTop:10,
            width:'300px',
        }
    },
    beforeaftercard:{
        width:'250px',
        marginLeft:'2%'
    },
    chooseimage:{
        objectFit:'cover',
        borderRadius:20,
        width:'100%',
        fontFamily: 'Poppins',
        height:250,
        verticalAlign:'middle',
        // boxShadow: '15px 15px #DE1C49'
    },
    righttext:{
        padding:'40px 40px',
        fontSize:20,
        textAlign:'center',
        [theme.breakpoints.only('xs')]: {
            padding:'10px',
        },
    },
    button:{
        marginTop:2,
        backgroundColor:'#DE1C49',
        color:'#ffffff',
        fontSize:18,
        fontWeight:600,
        fontFamily:'Poppins',
        letterSpacing:1,
        "&:hover":{
            backgroundColor:'#fff',
            color:'#DE1C49',
            fontSize:19,
            fontWeight:800,

        }
    },
    onloaddialogbutton:{
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.only('xs')]: {
            display:'none',
        },
    },
    onloaddialogbutton2:{
        display:'none',
        [theme.breakpoints.only('xs')]: {
            display:'block',
            backgroundColor:'#DE1C49',
            color:'#fff',
            fontFamily: 'Poppins',
            padding: 12,
            fontWeight: "800",
            margin:'20px 20px',
            "&:hover": {
                backgroundColor: "#DE1C49",
                textDecoration:'none'
            },
        },
    },
    bookappointmentnav2:{
        display:'none',
        [theme.breakpoints.only('xs')]: {
            display:'block',
            backgroundColor:'#fff',
            color:'#DE1C49',
            fontFamily: 'Poppins',
            padding: 8,
            fontWeight: "800",
            fontSize:12,
            marginRight: 10,
            margin:'8px 8px',
            "&:hover": {
                backgroundColor: "#fff",
                textDecoration:'none'
            },
        },
    },
    emailandnum:{
        display:'flex',
        flexDirection:'row',
        justifyContent: 'flex-end',
        padding: 8,
        marginRight: 10,
        [theme.breakpoints.only('xs')]: {
            display:'flex',
            flexDirection:'row',
            marginRight: 0,
            padding: 0,
            justifyContent: 'space-between',
            alignItems: 'flex-start'
        },
    },
    bannervideo:{
        height:500,
        width:'100%',
        objectFit: "cover",
        borderRadius:30,
        overflowY:'hidden',
        [theme.breakpoints.only('xs')]: {
           height:300
        },
    },
    titlesmilelogolarge:{
        width: "150px",
        position: 'absolute',
        left: '15%',
        opacity: '1',
        zIndex:-1,
        [theme.breakpoints.only('xs')]: {
            left: '15%',
        },
    },
    titlesmilelogosmall:{
        width: "50px",
        position: 'absolute',
        left: '5%',
        opacity: '1',
        zIndex:-1,
        marginTop:15,
        [theme.breakpoints.only('xs')]: {
            left: '10%',
        },
    },
    titlesmilelogomedium:{
        width: "130px",
        position: 'relative',
        opacity: '1',
        zIndex:-1,
        [theme.breakpoints.only('xs')]: {
        },
    }

  }));


 function Homepage(props) {
    const classes = useStyles();
    const [index, setIndex] = useState(0);
    const [open, setOpen] = React.useState(false);
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [value, setValue] = React.useState('No');
    const [appointmentDate,setAppointmentDate] = useState("")
    const [appointmentTime,setAppointmentTime] = useState("")
    const [name,setName] = useState("")
    const [phone,setPhone] = useState("")
    const [promotionDialog,setPromotionDialog] = useState(false)
    const [ampm,setampm] = useState("AM")
    const [time,settime] = useState("9")
    const [notes,setNotes] = useState("")
    const [email,setEmail] = useState("")
     const [age,setAge] = useState("")
    const [openSnackbar, setOpenSnackBar] = React.useState(false);
     const history = useHistory();
     const [data,setData] = useState([])

    const AboutUsRef = useRef(null)
    const ServicesRef = useRef(null)
    const TeamRef = useRef(null)
    const BlogRef = useRef(null)
    const FooterRef = useRef(null)
    const HomeRef = useRef(null)

     let [showChat, setShowChat] = React.useState(false);

     const startChat = () => { setShowChat(true); }
     const hideChat = () => { setShowChat(false); }

     const blogsData = [
        // {
        //     name:'Busting 6 Important And Basic Dental Myths',
        //     link:'Busting-6-Important-And-Basic-Dental-Myths',
        //     image: BlogImage1,
        //     tag:'Insight'
        // },
        {
            name:'Does Diabetes Affect Teeth - Diabetes & Teeth Problems',
            link:'Does-Diabetes-Affect-Teeth-Diabetes-Teeth-Problems',
            image:BlogImage2,
            tag:'Tips'
        },
        {
            name:'What to eat and avoid after dental surgery, wisdom tooth extraction, or implants.',
            link:'What-to-eat-and-avoid-after-dental-surgery-wisdom-tooth-extraction-or-implants',
            image:BlogImage3,
            tag:'Advice'
        },
        {
            name:'Bleeding gums: Home remedies',
            link:'Bleeding-gums-Home-remedies',
            image:BlogImage4,
            tag:'Insight'
        },
        {
            name:'Painless Root Canal Treatment: is it safe for you and what rare complications can occur',
            link:'Painless-Root-Canal-Treatment-is-it-safe-for-you-and-what-rare-complications-can-occur',
            image:BlogImage5,
            tag:'Tips'
        },
        {
            name:'Dentist and Pregnancy- Does Pregnancy affect oral health?',
            link:'Dentist-and-Pregnancy-Does-Pregnancy-affect-oral-health',
            image:BlogImage6,
            tag:'Advice'
        },
        {
            name:'Temporomandibular Joint Disorders (TMJ, TMD)',
            link:'Temporomandibular-Joint-Disorders',
            image:BlogImage7,
            tag:'Advice'
        },
        {
            name:'Main Reasons For Tooth Pain & Toothache Causes',
            link:'Main-Reasons-For-Tooth-Pain-Toothache-Causes',
            image:BlogImage8,
            tag:'Insight'
        },
        {
            name:'How To Get Through With Braces- Life With Braces',
            link:'How-To-Get-Through-With-Braces-Life-With-Braces',
            image:BlogImage9,
            tag:'Tips'
        },
        {
            name:'Getting Your Perfect Smile- Cosmetic Dentist Treatments',
            link:'Getting-Your-Perfect-Smile-Cosmetic-Dentist-Treatments',
            image:BlogImage10,
            tag:'Insight'
        },
        {
            name:'What Should You Eat After a Root Canal?',
            link:'What-Should-You-Eat-After-a-Root-Canal',
            image:BlogImage11,
            tag:'Advice'
        },
        {
            name:'All About Laser Dentistry',
            link:'All-About-Laser-Dentistry',
            image:BlogImage12,
            tag:'Insight'
        },


        {
            name:'Facts and Tips about fighting Tooth Decay',
            link:'Facts-and-Tips-about-fighting-Tooth-Decay',
            image:BlogImage13,
            tag:'Insight'
        },

    ];


    function shuffle(blogsData) {
        var currentIndex = blogsData.length,  randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {

          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;

          // And swap it with the current element.
          [blogsData[currentIndex], blogsData[randomIndex]] = [
            blogsData[randomIndex], blogsData[currentIndex]];
        }

        return blogsData;
      }


    useEffect(() => {
        document.title = "Your Nearest Dental Clinic in Houston - Hi Dental Clinic Care"
        AOS.init({
          duration : 2000
        });
        window.scrollTo(0, 0);
        // setTimeout(() => {
        //     setPromotionDialog(true)
        // }, 100)
        shuffle(blogsData);
        console.log(blogsData)
        // const blogdata = [...blogsdata]
        const data = blogsData.slice();
        setData(blogsData)
        console.log(data,"random")

      }, []);

    const executeScroll = (ref) => ref.current.scrollIntoView({behavior: 'smooth'})


    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };


    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
      }

    const handleClickSnackbar = () => {
        setOpenSnackBar(true);
      };

      const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSnackBar(false);
      };

    const responsive = {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };

      const responsivechooseus = {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };



      const handleClickOpen = () => {
        setOpen(true);
      };

      const handleClose = () => {
        setOpen(false);
      };


      const handleDateChange = (date) => {
          console.log(date)
          var localTime = date.toString();
          setAppointmentDate(localTime.slice(0,15))
          console.log(localTime.slice(15,25))
          setAppointmentTime(localTime.slice(15,25))
          setSelectedDate(date);
      };

     const handleSubmit = () => {
         if(phone =="" || name == ""){
             alert("Please enter Phone, Name and Date")
         }
         else{
        firestore.collection('leads').doc(Date.now().toString()).set({
            name: name ,
            phone: phone,
            age: age,
            appointmentDate: appointmentDate,
            appointmentTime: time + " " + ampm,
            emergency:value,
            email:email,
            notes:notes,
            timestamp: Date.now()
            },{merge: true})

        setTimeout(function(){ handleClickSnackbar() }, 300);
        history.push('./thankyou')
        handleClose()
        handleClickSnackbar()
     }
    }

      const handleChange = (event) => {
        setValue(event.target.value);
      };

      const K_HEIGHT = 40;
      const K_WIDTH = 40;
    const greatPlaceStyle = {
        // initially any map object has left top corner at lat lng coordinates
        // it's on you to set object origin to 0,0 coordinates
        position: 'absolute',
        width: K_WIDTH,
        height: K_HEIGHT,
        left: -K_WIDTH / 2,
        top: -K_HEIGHT / 2,
        objectFit:'contain',
        border: '1px solid #f44336',
        borderRadius: K_HEIGHT,
        backgroundColor: 'white',
        textAlign: 'center',
        color: '#3f51b5',
        fontSize: 16,
        fontWeight: 'bold',
        padding: 4
      };

    const AnyReactComponent = ({ text }) => <img src={Hident} style={greatPlaceStyle}/>;







    return (
        <div className={classes.root}>
       <Navbar collapseOnSelect expand="lg" variant="light" fixed="top" style={{backgroundColor:'#fff',}} className={classes.navbar}>
                <a href="/"><img
                    className={classes.logoimage}
                    src={Logo}
                    alt="React Bootstrap logo"
                /></a>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ml-auto">
                        <Nav.Link onClick={()=> executeScroll(HomeRef)}  className={classes.navlist}>Home</Nav.Link>
                        <Nav.Link onClick={()=> executeScroll(ServicesRef)} data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample"  className={classes.navlist}>Services</Nav.Link>
                        <Nav.Link onClick={()=> executeScroll(TeamRef)} data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample"  className={classes.navlist}>Team</Nav.Link>
                        <Nav.Link onClick={()=> executeScroll(BlogRef)} data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample" className={classes.navlist}>Blog</Nav.Link>
                        <Nav.Link onClick={()=> executeScroll(AboutUsRef)} data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample"  className={classes.navlist}>About Us</Nav.Link>
                        <Nav.Link onClick={()=> executeScroll(FooterRef)}  data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample" className={classes.navlist}>Contact us</Nav.Link>
                        <Link to="/specialoffer" className={classes.linkbutton}> <Button variant="contained" className={classes.bookappointment} >Special Offers</Button></Link>
                        <Button variant="contained" className={classes.bookappointment} onClick={() => history.push('./new-patient-form')}>Book Appointment</Button>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <Navbar fixed="top" collapseOnSelect variant="light" style={{backgroundColor: '#DE1C49', padding:'1px',}} className={[classes.navbar2, {color:'#DE1C49'}]}>
                <Navbar.Collapse id="responsive-navbar-nav" className={classes.emailandnum}>
                    <Button variant="contained" className={classes.bookappointmentnav2} onClick={() => history.push('./new-patient-form')}>Book Appointment</Button>
                    <a href="tel:713-820-3360"  style={{color:'#ffffff'}}  className={classes.navlist2}><CallIcon/>+1-713-820-3360</a>
                </Navbar.Collapse>
            </Navbar>

            <div style={{height: 10}} ref={HomeRef}>

            </div>

        <Container maxWidth="lg" disableGutters="true" className={classes.carousel} id="home">
        <video className='videoTag' width="100%" autoPlay loop muted playsInline className={classes.bannervideo}>
            <source src={Bannervideo} type='video/mp4'  />
        </video>

        {/* <Carousel activeIndex={index} onSelect={handleSelect} fade interval={4000} indicators={false}>
            <Carousel.Item>
                <img
                className="d-block w-100"
                src={Carousel1}
                alt="First slide"
                />
                <p>
                    <h3 style={{color:'white',fontFamily:'Poppins',fontStyle:'italic',}} className={classes.carouseltitle2}>Let us make you smile a little infinite!</h3>
                </p>
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="d-block w-100"
                src={Carousel2}
                alt="Second slide"
                />
                <p>
                    <h3 style={{color:'white',fontFamily:'Poppins',fontStyle:'italic'}} className={classes.carouseltitle3}>Modern dentistry with Affordable prices.</h3>
                </p>
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="d-block w-100"
                src={Carousel3}
                alt="Third slide"
                />
                <p>
                    <h3 style={{color:'white',fontFamily:'Poppins',fontStyle:'italic'}} className={classes.carouseltitle2}>Exceptional service. Painless approach</h3>
                </p>
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="d-block w-100"
                src={Carousel4}
                alt="Fourth slide"
                />
                <p>
                    <h3 style={{color:'white',fontFamily:'Poppins',fontStyle:'italic'}} className={classes.carouseltitle2}>Beautiful Smiles that lasts lifetime</h3>
                </p>
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="d-block w-100"
                src={Carousel5}
                alt="Fourth slide"
                />
                <p>
                    <h3 style={{color:'white',fontFamily:'Poppins',fontStyle:'italic'}} className={classes.carouseltitle2}>We Design Flawless smiles with long lasting results.</h3>
                </p>
            </Carousel.Item>
            </Carousel> */}
        </Container>

            {/* <FadeinupDiv className={classes.pagetitlesTop}>
                Where smiles come alive!
            </FadeinupDiv> */}
        <Container >

            {/* <div style={{height: 120}} ref={ServicesRef}>
                <div className={classes.buttonbelowcontainerform2} data-aos="zoom-in" data-aos-duration="500">
                    <Button variant="container"  className={classes.bookappointment} style={{marginTop:40}} onClick={handleClickOpen}>Book Appointment</Button>
                </div>
            </div> */}
        <div style={{display:'flex', flexDirection: 'row', justifyContent:'flex-start'}}>
            <div style={{display:'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Typography className={classes.pagetitles}  ref={ServicesRef} >
                Featured services 
            </Typography>
            <img src={CarouselCardLogo} className={classes.titlesmilelogomedium} />
            </div>
        </div>
        


            <Container>
                <Row data-aos="fade-up" data-aos-duration="4000">
                <Col sm  >
                    <Link className={classes.listlink} to="/invisalign">
                    <div className="service-card-container">
                        <img src={Category1} alt="Avatar" className="service-card-image" />
                        <div className="middle">
                            <div className="service-card-text">With clear alternatives to braces, Hi Dental Care gives you the flawless and perfect smile you've always wanted.</div>
                        </div>
                        </div>

                        </Link>
                    </Col>
                    <Col sm>
                    <Link className={classes.listlink} to="/dental-implants">
                    <div className="service-card-container">
                        <img src={Category2} alt="Avatar" className="service-card-image" />
                        <div className="middle">
                            <div className="service-card-text">As your personal dentist, we treat implants in one day without any hassle and are always available, in all emergencies.</div>
                        </div>
                        </div>
                        </Link>
                        </Col>
                    <Col sm>
                    <Link className={classes.listlink} to="/fixed-dentures">
                    <div className="service-card-container">
                        <img src={Category3} alt="Avatar" className="service-card-image" />
                        <div className="middle">
                            <div className="service-card-text">Because we want you to be able to smile endlessly, we offer fixed dentures that are comfy to wear.<br /><br /> A new smile awaits you!</div>
                        </div>
                        </div>
                        </Link>
                    </Col>
                </Row>
                <Row data-aos="fade-up" data-aos-duration="4000">
                    <Col sm>
                    <Link className={classes.listlink} to="/veneers">
                    <div className="service-card-container">
                        <img src={Category4} alt="Avatar" className="service-card-image" />
                        <div className="middle">
                            <div className="service-card-text">“Where the smiles come alive” is our motto and we make sure that we keep your smile healthy…comfortable…brilliant with veneers as the wonderful services we provide.</div>
                        </div>
                        </div>
                        </Link>
                        </Col>

                    <Col sm>
                    <Link className={classes.listlink} to="/teeth-whitening">
                    <div className="service-card-container">
                        <img src={Category5} alt="Avatar" className="service-card-image" />
                        <div className="middle">
                            <div className="service-card-text">When it comes to teeth whitening Hi Dental experts are committed to giving back your vibrant smile that leads to a vibrant lifestyle.</div>
                        </div>
                        </div>
                        </Link>
                        </Col>

                    <Col sm>
                    <Link className={classes.listlink} to="/painless-root-canals">
                    <div className="service-card-container">
                        <img src={Category6} alt="Avatar" className="service-card-image" />
                        <div className="middle">
                            <div className="service-card-text">Having a root canal isn't a scary procedure because we offer you painlessly and natural root canal treatment because Fillings don't hurt, only bad teeth do!</div>
                        </div>
                        </div>
                        </Link>
                    </Col>
                </Row>



            <div style={{display:'flex', flexDirection: 'row', justifyContent:'flex-start'}}>
            <div style={{display:'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Typography className={classes.pagetitles}  >
            Hi Dental Care advantage
            </Typography>
            <img src={CarouselCardLogo} className={classes.titlesmilelogomedium} />
            </div>
        </div>
        <div className={classes.rootGrid}>
        <Grid container >
        <Grid item xs={12} sm={3}>
        <div className={classes.choosecard}>
                    <img src={ChooseUs1} alt="happyface1" className={classes.chooseimage} />
            </div>
        </Grid>
        <Grid item xs={12} sm={3}>
        <div className={classes.choosecard}>
                    <img src={ChooseUs2} alt="happyface1" className={classes.chooseimage} />
                </div>
        </Grid>
    
        <Grid item xs={12} sm={3}>
        <div className={classes.choosecard}>
                    <img src={ChooseUs3} alt="happyface1" className={classes.chooseimage} />
                </div>
        </Grid>
        <Grid item xs={12} sm={3}>
        <div className={classes.choosecard}>
                    <img src={ChooseUs4} alt="happyface1" className={classes.chooseimage} />
                </div>
        </Grid>
      </Grid>
      </div>
           
            <div style={{display:'flex', flexDirection: 'row', justifyContent:'flex-start'}}>
            <div style={{display:'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Typography className={classes.pagetitles}  >
               Before and After
            </Typography>
            <img src={CarouselCardLogo} className={classes.titlesmilelogomedium} />
            </div>
        </div>


        <div className={classes.rootGrid}>
        <Grid container>
        <Grid item xs={12} sm={4}>
        <div>
                 <div className="flip-card" >
                    <div className="flip-card-inner">
                    <div className="flip-card-front">
                        <img src={BA1} alt="Avatar" className={classes.serviceimagepen}></img>
                    </div>
                    <div className="flip-card-back flip-card-back1">
                        <img src={BA2} alt="Avatar" className={classes.serviceimagepen}></img>
                    </div>
                    </div>
                </div>
                <Typography className={classes.cardtitle}>Veneers and crowns</Typography>
                </div>
        </Grid>
        <Grid item xs={12} sm={4}>
        <div className="flip-card" >
                <div className="flip-card-inner">
                    <div className="flip-card-front">
                        <img src={BA3} alt="Avatar" className={classes.serviceimagepen}></img>
                    </div>
                    <div className="flip-card-back flip-card-back1">
                        <img src={BA4} alt="Avatar" className={classes.serviceimagepen}></img>
                    </div>
                </div>
                <Typography className={classes.cardtitle}>Super strong zirconia bridge</Typography>
            </div>
        </Grid>
    
        <Grid item xs={12} sm={4}>
        <div className="flip-card" >
                <div className="flip-card-inner">
                    <div className="flip-card-front">
                        <img src={BA5} alt="Avatar" className={classes.serviceimagepen}></img>
                    </div>
                    <div className="flip-card-back flip-card-back1">
                        <img src={BA6} alt="Avatar" className={classes.serviceimagepen}></img>
                    </div>
                </div>
                <Typography className={classes.cardtitle}>Esthetic smile designing</Typography>
            </div>
        </Grid>
      </Grid>
      </div>


      <div className={classes.rootGrid}>
        <Grid container >
        <Grid item xs={12} sm={4}>
        <div className="flip-card" >
                <div className="flip-card-inner">
                    <div className="flip-card-front">
                        <img src={BA7} alt="Avatar" className={classes.serviceimagepen}></img>
                    </div>
                    <div className="flip-card-back flip-card-back1">
                        <img src={BA8} alt="Avatar" className={classes.serviceimagepen}></img>
                    </div>
                </div>
                <Typography className={classes.cardtitle}>Facially driven smile designing</Typography>
            </div>
        </Grid>
        <Grid item xs={12} sm={4}>
        <div className="flip-card" >
                <div className="flip-card-inner">
                    <div className="flip-card-front">
                        <img src={BA9} alt="Avatar" className={classes.serviceimagepen}></img>
                    </div>
                    <div className="flip-card-back flip-card-back1">
                        <img src={BA10} alt="Avatar" className={classes.serviceimagepen}></img>
                    </div>
                </div>
                <Typography className={classes.cardtitle}>Full fixed teeth with implants</Typography>
            </div>
        </Grid>
    
        <Grid item xs={12} sm={4}>
        <div className="flip-card">
                <div className="flip-card-inner">
                    <div className="flip-card-front">
                        <img src={BA11} alt="Avatar" className={classes.serviceimagepen}></img>
                    </div>
                    <div className="flip-card-back flip-card-back1">
                        <img src={BA12} alt="Avatar" className={classes.serviceimagepen}></img>
                    </div>
                </div>
                <Typography className={classes.cardtitle}>One visit painless fix teeth with implants</Typography>
            </div>
        </Grid>
      </Grid>
      </div>        
        

            </Container>
            <div style={{height: 10}} ref={TeamRef}>

            </div>
            <div style={{display:'flex', flexDirection: 'row', justifyContent:'flex-start'}}>
            <div style={{display:'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Typography className={classes.pagetitles}   >
                Team
            </Typography>
            <img src={CarouselCardLogo} style={{width: 100}} className={classes.titlesmilelogomedium} />
            </div>
        </div>
            <Grid container spacing={5}>
                <Grid item sm={3} xs={12} data-aos="fade-up" data-aos-duration="1000" >
                    <img src={Team2} alt="happyface1" className={classes.cardimage} />
                </Grid>
                <Grid item sm={9} xs={12} style={{textAlign:'start',overflowX:'hidden'}} data-aos="fade-left" data-aos-duration="1000" data-aos-delay="500">
                    <Typography className={classes.happyfacetitle}>Dr. Nakul Rathi BDS. MS.</Typography>
                    <Typography className={classes.happyfacesubtitle}>Prosthodontist (USA)</Typography>
                    <Typography className={classes.happyfacedesc}>
                    Dr.Nakul Rathi is one of the pillars of Hi Dental Care. His passion for Dental inspired him to obtain a dental degree from GDC, Nagpur, where he graduated with a degree in dentistry. He pursued post-graduation from the Ohio State University, Columbus (OSU). During the "Advanced Prosthodontics Clinical Residency Program", Dr. Rathi gained expertise in prosthodontics.  At all levels, the staff admires his work ethic and dedication to Hi dental Care. The dental implant expertise of Dr. Nakul Rathi has helped him treat thousands of implants in the past few years. And as a Prosthodontist, Dr. Nakul exemplifies the work ethic set by Hi Dental Care. 
Away from dentistry, he is a keen traveler and loves to travel with friends
and family. He likes sports and plays basketball.
Dr.Nakul Rathi is always concerned about his patient's comfort and he makes sure that he addresses every query of his patients. 
                        </Typography>
                    </Grid>
                <Grid>
                </Grid>
            </Grid>


            <Grid container spacing={5} className={classes.rightrow} >
                <Grid item sm={9} xs={12}  style={{textAlign:'right',overflowX:'hidden'}} data-aos="fade-right" data-aos-duration="3000" >
                    <Typography className={classes.happyfacetitle}>Dr. Arun Vashisht, BDS, MDS</Typography>
                    <Typography className={classes.happyfacesubtitle}>Prosthodontist (USA)</Typography>
                    <Typography className={classes.happyfacedesc}>
                    Dr.Arun Vashisht is one of the first dentists to get expertise in Digital Smile Designs. He graduated from NDC Punjab dental school in 2007. Dedicated to his work and his constant desire to learn he also completed his post-graduation from Rutgers school of dental medicine in dental science and also mastered residency in prosthodontics in 2017. In his ten years of practice, Dr. Arun Vashisht has been diagnosing and treating a variety of cases using the latest technology and up-to-date knowledge. He is the dentist at Hi Dental Care, a practice where the patient's comfort and care comes first. As a dentist, Dr. Arun Vashisht enjoys helping patients restore and maintain their beautiful smiles by relying on his experience and proficiency in dentistry.
                    </Typography>
                </Grid>
                <Grid item sm={3} xs={12}>
                    <img src={Arun} alt="happyface1" className={classes.cardimage} data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1000" />
                </Grid>
                <Grid>
                </Grid>
            </Grid>

            <Grid container spacing={5}>
                <Grid item sm={3} xs={12} >
                    <img src={Gamble} alt="happyface1" className={classes.cardimage} data-aos="fade-up" data-aos-duration="2000" />
                </Grid>
                <Grid item sm={9} xs={12} style={{textAlign:'start',overflowX:'hidden'}} data-aos="fade-left" data-aos-duration="1000"  data-aos-delay="500">
                    <Typography className={classes.happyfacetitle}>Dr.Virdisland Gamble. DDS</Typography>
                    <Typography className={classes.happyfacesubtitle}>General Dentist (USA)</Typography>
                    <Typography className={classes.happyfacedesc}>
                    Dr. Virdisland Gamble is gratified to bring quality, caring dental services to the East Side of Houston. In addition to completing his undergraduate studies at Southern A&M University, Dr. Gamble earned a dental degree from Meharry Medical College. Dr.Gamble began his career in Houston shortly after his return and now practices in Hi Dental Care, where the health and comfort of the patients are of prime importance. The American Dental Association has recognized Dr. Gamble as a member in good standing of the American Dental Association, Texas.
                    Dr.Gamble is a Board Certified, licensed Dentist, Texas. And he seeks to provide a caring, gentle environment where patients can be at ease. Dr. Gamble looks forward to providing each of his patients with A Winning Smile.


                    </Typography>
                </Grid>
                <Grid>
                </Grid>
            </Grid>



        <div style={{display:'flex', flexDirection: 'row', justifyContent:'flex-start'}}>
            <div style={{display:'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Typography className={classes.pagetitles} >Sparkling and smiling reviews</Typography>
            <img src={CarouselCardLogo}  className={classes.titlesmilelogomedium} />
            </div>
        </div>
        <Container>
        <Grid container>

        <Grid item xs={12} sm={4}>
        <Paper elevation={1} className={classes.carouselcard}>
                <img src={googleReview} className={classes.googlereviewstyle}/>
                <div className={classes.statement}>
                Dr. Arun and his team is amazing! From the time you walk in the door! Very good with relaxing the patient and making every part of the visit comfortable!  They took their time and made sure I stayed at ease the entire time. After my treatment, they even followed up checked on me the following day to make sure I was still at ease! Such a great staff!! Thanks so much!!
                </div>
                <img src={stars} alt= "logo" className={classes.starstyle}/>
                <div style={{display:'flex', justifyContent: 'flex-end'}}>
                <img src={CarouselCardLogo} alt= "logo" className={classes.carouselcardsmile}/>
                </div>
               
                <div className={classes.statementname}>Ansul Chandra</div>
            </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
        <Paper elevation={1} className={classes.carouselcard}>
            <img src={googleReview} className={classes.googlereviewstyle}/>

            <div className={classes.statement}>

            Dr Arun made sure my gums, bone, teeth, and overall health  were intact. I had a headache for years and now its gone. Then, after making sure I was healthy and happy, he gave me a beautiful and healthy smile. Loved it and you will too !!       
            <img src={stars} alt= "logo" className={classes.starstyle}/>     
            <div style={{display:'flex', justifyContent: 'flex-end'}}>
                <img src={CarouselCardLogo} alt= "logo" className={classes.carouselcardsmile}/>
                </div>
           
                <div style={{marginTop: 70}} className={classes.statementname}>Jan Christopher</div>
                </div>
            </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
        <Paper elevation={1} className={classes.carouselcard}>
                <img src={googleReview} className={classes.googlereviewstyle}/>
                <div className={classes.statement}>
                Dr. Arun was great! He was very kind and patient. He had given me my smile back and my confidence! I feel 10 years younger! His technology and his planning was great and was perfectly executed
                </div>
                <img src={stars} alt= "logo" className={classes.starstyle}/>
                <div style={{display:'flex', justifyContent: 'flex-end'}}>
                <img src={CarouselCardLogo} alt= "logo" className={classes.carouselcardsmile}/>
                </div>
               
                <div style={{marginTop: 100}} className={classes.statementname}>Paskal Setinov</div>
            </Paper>
        </Grid>

      </Grid>
    

        </Container>


            <div style={{height: 10}} ref={BlogRef}>

            </div>

        <div style={{display:'flex', flexDirection: 'row', justifyContent:'space-between'}}>
            <div style={{display:'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Typography className={classes.pagetitles} >
            Tips, Insights & Advice
            </Typography>
            <img src={CarouselCardLogo} className={classes.titlesmilelogomedium} />
            </div>
            <Link to="/allpost" className={classes.seeall}>See All </Link>
        </div>
        <Container>

                    <Grid container className={classes.blogcontainer}>
                    {
                data.slice(0, 3).map(item =>
                    <Grid item xs={12} sm={4} className={classes.blogcard} onClick={()=>history.push(item.link)}>
                        <Typography className={classes.blogtag}>{item.tag}</Typography>
                        <img src={item.image} className={classes.blogimage}/>
                        <Typography className={classes.blogtitle} style={{ wordWrap: "break-word" }} >{item.name}</Typography>
                        <div className={classes.divider}></div>
                        <div className={classes.blognameanddate}>Author | 17th July 2021</div>
                       <Link to={item.link} style={{color:'#000'}}> <Typography className={classes.blogreadmore}>Read More</Typography></Link>
                    </Grid>
                    )
                 }
                </Grid>
        </Container>



            {/* <div style={{height: 120}} ref={AboutUsRef}>

            </div> */}
             <div style={{display:'flex', flexDirection: 'row', justifyContent:'flex-start'}}>
            <div style={{display:'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Typography className={classes.pagetitles}  ref={AboutUsRef} >
               About us
            </Typography>
            <img src={CarouselCardLogo} style={{width: 100}} className={classes.titlesmilelogomedium} />
            </div>
        </div>

            <Container >

                <Grid container spacing={8} >
                    <Grid item xs={12} sm={6} data-aos="flip-up" data-aos-duration="2000">
                        <Typography className={classes.aboutustitle}>"Where Smiles Comes Alive"</Typography>
                        <Typography className={classes.abouttextspacing}>In our state-of-the-art dental clinic Houston, we provide a high level of quality dentistry. We vow to make every visit to our dental clinic a rewarding experience!</Typography>
                        <Typography className={classes.abouttextspacing}> Our dental team has been committed to dental excellence in the Houston dental community for more than 15 years. Over the past years, we have maintained a steady, long-standing relationship with many of our patients and their families. We make sure that everyone who comes to Hi Dental Care has individualized care that meets their needs, so they will keep coming back.</Typography>
                        <Typography className={classes.abouttextspacing}>Our team of Dentists is highly qualified to provide you with detailed evidence-based treatment plans, excellent package deals, and affordable pricing.</Typography>
                        <Typography className={classes.abouttextspacing}> Dental health is more than just a profession for us - we're a family. Make Hi Dental Care your family today!</Typography>

                    </Grid>
                    <Grid item xs={12} sm={6} data-aos="flip-up" data-aos-duration="2000">
                        <img src={AboutImage} alt="happyface1" className={classes.cardimage} />
                    </Grid>

                </Grid>
            </Container>




            <div ref={FooterRef}>

            </div>





        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={"sm"} maxWidth={'sm'}>
        {/* <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
        <DialogContent>
            <div>
                <img style={{width: '100px', height: '40px', objectFit: 'contain'}} src={Logo}/>
            </div>
            <Typography className={classes.dialogtitle2}>Book your first appointment for free!</Typography>

            {/* <Typography className={classes.dialogtitle}>Name</Typography> */}
                <TextField
                   autoFocus
                    id="name"
                    variant="outlined"
                    fullWidth
                    className={classes.textfeild}
                    placeholder="Enter Name"
                    onChange = {(e)=>setName(e.target.value)}
                    classes={{notchedOutline:classes.input}}
                    disableUnderline={false}
                    InputProps={{
                        classes: {
                           root: classes.textfiledroot,
                           disabled: classes.disabled
                        }
                     }}
                />

                {/* <Typography className={classes.dialogtitle}>PhoneNumber</Typography> */}
                <TextField

                    id="name"
                    variant="outlined"
                    fullWidth
                    className={classes.textfeild}
                    onChange = {(e)=>setPhone(e.target.value)}
                    placeholder="Enter PhoneNumber"
                    classes={{notchedOutline:classes.input}}
                    disableUnderline={false}
                    InputProps={{
                        classes: {
                           root: classes.textfiledroot,
                           disabled: classes.disabled
                        }
                     }}
                />
            {/* <Typography className={classes.dialogtitle}>Age</Typography> */}

            <TextField

                id="name"
                variant="outlined"
                fullWidth
                className={classes.textfeild}
                onChange = {(e)=>setAge(e.target.value)}
                placeholder="Enter Your Age"
                classes={{notchedOutline:classes.input}}
                disableUnderline={false}
                InputProps={{
                    classes: {
                        root: classes.textfiledroot,
                        disabled: classes.disabled
                    }
                }}
            />
            <TextField

                id="name"
                variant="outlined"
                fullWidth
                className={classes.textfeild}
                onChange = {(e)=>setEmail(e.target.value)}
                placeholder="Enter Your Email"
                classes={{notchedOutline:classes.input}}
                disableUnderline={false}
                InputProps={{
                    classes: {
                        root: classes.textfiledroot,
                        disabled: classes.disabled
                    }
                }}
            />
            <TextField

                id="name"
                variant="outlined"
                fullWidth
                className={classes.textfeild}
                onChange = {(e)=>setNotes(e.target.value)}
                placeholder="Enter Description/Notes"
                classes={{notchedOutline:classes.input}}
                disableUnderline={false}
                InputProps={{
                    classes: {
                        root: classes.textfiledroot,
                        disabled: classes.disabled
                    }
                }}
            />

              <Grid container>
                  <Typography className={classes.dialogtitle}>Select a date for appointment</Typography>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around">
                        <KeyboardDatePicker
                        minDate={new Date()}
                        margin="normal"
                        id="date-picker-dialog"
                        label="Pick a Date"
                        format="MM/dd/yyyy"
                        value={selectedDate}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        />
                        <div style={{margin: 40}}>
                            <select
                                style={{padding: 5, fontSize: 16, marginRight: 5}}
                                value={time}
                                onChange={(e) => settime(e.target.value)}
                            >
                                <option value="8">8:00</option>
                                <option value="9">9:00</option>
                                <option value="10">10:00</option>
                                <option value="11">11:00</option>
                                <option value="12">12:00</option>
                                <option value="1">1:00</option>
                                <option value="2">2:00</option>
                                <option value="3">3:00</option>
                                <option value="4">4:00</option>
                                <option value="5">5:00</option>
                                <option value="6">6:00</option>
                                <option value="7">7:00</option>
                            </select>
                            <select
                                style={{padding: 5, fontSize: 16, marginRight: 5}}
                                value={ampm}
                                onChange={(e) => setampm(e.target.value)}
                            >
                                <option value="AM">AM</option>
                                <option value="PM">PM</option>
                            </select>
                            {/*<p>{message}</p>*/}
                        </div>

                    </Grid>
                    </MuiPickersUtilsProvider>
              </Grid>


              <FormControl component="fieldset">
                <RadioGroup aria-label="gender"  name="gender1" value={value} onChange={handleChange}>
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                    <Typography className={classes.dialogtitle} style={{paddingRight:'20px'}}>Emergency ?</Typography>
                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="No" control={<Radio />} label="No" />
                    </div>
                </RadioGroup>
            </FormControl>
            <p style={{fontSize: 12}}>Disclaimer: This website is designed to let you know more about our practice and our team and provide some general information about the treatment we provide.
                                    The website is made available subject to this disclaimer, our terms of use, privacy policy and booking and cancellation policy.
                                </p>
        </DialogContent>
        <div className={classes.dialogbuttoncontainer}>
        <Button variant="contained" className={classes.bookappointmentbutton} onClick={handleClose}>Close</Button>
        <Button variant="contained" className={classes.bookappointment} onClick={handleSubmit}>Submit</Button>
        </div>
         </Dialog>



            <Dialog disableBackdropClick={true} open={promotionDialog} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={"md"} maxWidth={'md'} style={{borderRadius: 30}}>
            <Typography className={classes.dialogtitle2}>Book your first appointment for free!</Typography>
                <DialogContent style={{borderRadius: 30}}>
                    <Grid container>
                        <Grid xs={12} sm={6}>
                            <Typography className={classes.formtitle}>Name</Typography>
                            <TextField
                                autoFocus
                                id="name"
                                variant="outlined"
                                fullWidth
                                className={classes.textfeild}
                                placeholder="Enter Name"
                                onChange = {(e)=>setName(e.target.value)}
                                classes={{notchedOutline:classes.input}}
                                disableUnderline={false}
                                InputProps={{
                                    classes: {
                                        root: classes.textfiledroot,
                                        disabled: classes.disabled
                                    }
                                }}
                            />
                            <div className={classes.datediv}>
                                <div>
                                    <Typography className={classes.formtitle}>Phone Number</Typography>
                                    <TextField

                                        id="name"
                                        variant="outlined"
                                        fullWidth
                                        className={classes.textfeild}
                                        placeholder="Enter Phone Number"
                                        onChange = {(e)=>setPhone(e.target.value)}
                                        classes={{notchedOutline:classes.input}}
                                        disableUnderline={false}
                                        InputProps={{
                                            classes: {
                                                root: classes.textfiledroot,
                                                disabled: classes.disabled
                                            }

                                        }}
                                        inputProps={{ maxLength: 12 }}

                                    />
                                </div>
                                <div>
                                    <Typography className={classes.formtitle}>Email Address</Typography>
                                    <TextField

                                        id="name"
                                        variant="outlined"
                                        fullWidth
                                        className={classes.textfeild}
                                        placeholder="Enter Email Address"
                                        onChange = {(e)=>setEmail(e.target.value)}
                                        classes={{notchedOutline:classes.input}}
                                        disableUnderline={false}
                                        InputProps={{
                                            classes: {
                                                root: classes.textfiledroot,
                                                disabled: classes.disabled
                                            }
                                        }}

                                    />
                                </div>
                            </div>
                            <div className={classes.datediv}>
                                <div>
                                    <Typography className={classes.formtitle}>Age</Typography>
                                    <TextField

                                        id="name"
                                        variant="outlined"
                                        fullWidth
                                        className={classes.textfeild}
                                        placeholder="Enter Age"
                                        onChange = {(e)=>setAge(e.target.value)}
                                        classes={{notchedOutline:classes.input}}
                                        disableUnderline={false}
                                        InputProps={{
                                            classes: {
                                                root: classes.textfiledroot,
                                                disabled: classes.disabled
                                            }
                                        }}
                                        inputProps={{ maxLength: 2 }}
                                    />
                                </div>
                            </div>

                            <Typography className={classes.formtitle}>Description</Typography>
                            <TextField

                                id="name"
                                variant="outlined"
                                fullWidth
                                className={classes.textfeild}
                                placeholder="Enter Description"
                                multiline={true}
                                rows={3}
                                onChange = {(e)=>setNotes(e.target.value)}
                                classes={{notchedOutline:classes.input}}
                                disableUnderline={false}
                                InputProps={{
                                    classes: {
                                        root: classes.textfiledroot,
                                        disabled: classes.disabled
                                    }
                                }}
                            />


                        </Grid>
                        <Grid xs={12} sm={6}>
                            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                <img src={Logo} className={classes.landingImage} style={{width: 150, height: 110,objectFit: 'contain', borderRadius: 16}}/>
                            </div>
                            <Typography className={classes.righttext}>
                                    You are just a few steps away<br/> from a beautiful smile
                            </Typography>
                            <Typography className={classes.righttext}>
                                <p style={{fontSize: 12}}>Disclaimer: This website is designed to let you know more about our practice and our team and provide some general information about the treatment we provide.
                                    The website is made available subject to this disclaimer, our terms of use, privacy policy and booking and cancellation policy.
                                </p>
                            </Typography>
                            <div className={classes.onloaddialogbutton}>
                                <Button variant="contained" color="primary" onClick={handleSubmit} className={classes.button}>Book Appointment</Button>
                            </div>
                        </Grid>
                    </Grid>

                </DialogContent>
                <div className={classes.dialogbuttoncontainer}>
                <Button variant="contained" className={classes.bookappointmentbutton} onClick={() => {
                        console.log('sclorring back.....')
                        setPromotionDialog(false)
                    }}>Close</Button>
                    <Button variant="contained" className={classes.onloaddialogbutton2} onClick={handleSubmit}>Submit</Button>
                </div>
            </Dialog>


    <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackBar}>
        <Alert onClose={handleCloseSnackBar} severity="success">
            SuccessFully Submited!!
        </Alert>
      </Snackbar>

        </Container>
    <MainFooter handleOpen={handleClickOpen}/>


            <div className = "bot">
                <div style ={{display: showChat ? "" : "none"}}>
                    <SimpleForm></SimpleForm>
                </div>
                {/* <div> {showChat ? <SimpleForm></SimpleForm> : null} </div> */}
                <div>
                    {!showChat
                        ? <button className="btn" onClick={() => startChat()}><div style={{backgroundColor: "#DE1C49", padding: 20, borderRadius: 100}}>
                            <img src={Hident} alt="logo" style={{width:30, height:30, backgroundColor: '#ffffff',borderRadius:30,}} />
                            {/* <QuestionAnswerIcon style={{width:30, height:30, color: '#ffffff',}}/> */}
                        </div></button>
                        : <button className="btn" onClick={() => hideChat()}><div style={{backgroundColor:"#DE1C49", padding: 20, borderRadius: 100}}>
                            <CloseIcon style={{width:30, height:30, color: '#ffffff',}}/>
                        </div></button>}
                </div>
            </div>
            <CookieConsent
  location="bottom"
  buttonText="Accept Cookies"
  cookieName="myAwesomeCookieName2"
  style={{ background: "#2B373B" }}
  buttonStyle={{ backgroundColor: primaryColor, color:'#ffffff', fontSize: "13px" }}
  expires={150}
>
  This website uses cookies to enhance the user experience.{" "}
  {/* <span style={{ fontSize: "10px" }}>This bit of text is smaller :O</span> */}
</CookieConsent>
        </div>
    )
}

export default GoogleApiWrapper({
    apiKey: ("AIzaSyCXVZvIXne-QtuNZW9pof5DLuXoc1WL6R4")
  })(Homepage)
