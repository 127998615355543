import {  Link, BrowserRouter as Router, Route, Switch  } from 'react-router-dom'
import Homepage from './Homepage';
import ServicePage from "./servicePage"
import Login from './Dashboard/Login';
import Dashboard from './Dashboard/Dashboard';
import Pediatric from "./pages/pediatric";
import Whitening from "./pages/Whitening";
import ColoredFillings from "./pages/ColoredFillings";
import Root from "./pages/Root";
import TMDTherapy from "./pages/TMDTherapy";
import NightGuards from "./pages/NightGuards";
import Resotrative from "./pages/Resotrative";
import Endodontics from "./pages/Endodontics";
import Periodontics from "./pages/Periodontics";
import OfferPage from './pages/offerpage';
import PatientForm from './pages/PatientForm';
import Policy from './pages/Policy';
import React, {useEffect} from "react";
import Logo from '../src/public/assets/images/newlogo.png'
import BlogPage from "./pages/BlogPage"
import AllBlogs from './pages/AllBolgs';
import BlogPage2 from "./pages/BlogPage2"
import BlogPage3 from "./pages/BlogPage3"
import ThankyouPage from "./pages/ThankyouPage";
import {Typography} from "@material-ui/core";
import LandingPage from './pages/LandingPage';
import PageNotFound from './pages/PageNotFound';
import BlogPage4 from './pages/BlogPage4';
import BlogPage5 from './pages/BlogPage5';
import BlogPage6 from './pages/BlogPage6';
import BlogPage7 from './pages/BlogPage7';
import BlogPage8 from './pages/BlogPage8';
import BlogPage9 from './pages/BlogPage9';
import BlogPage10 from './pages/BlogPage10';
import "./App.css"
import LandingPage2 from "./pages/LandingPage2";
import BlogPage11 from './pages/BlogPage11';
import BlogPage12 from './pages/BlogPage12';
import BlogPage13 from './pages/BlogPage13';
import service1 from './pages/ServicePages/service1';
import service2 from './pages/ServicePages/service2';
import service3 from './pages/ServicePages/service3';
import service4 from './pages/ServicePages/service4';
import service5 from './pages/ServicePages/service5';
import service6 from './pages/ServicePages/service6';
import service7 from './pages/ServicePages/service7';
import service8 from './pages/ServicePages/service8';
import PatientTypeform from './pages/PatientTypeform';

function App() {
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    },1000)
  })
  if(isLoading){
    return (
        <div style={{display:'flex', justifyContent: 'center', marginTop: 200}}>
          <img src={Logo} width={250} height={100}/>
        </div>
    )
  }
  return (

      <div className="App">
      <Router>
        <Switch>
        <Route path="/admin"><Login /></Route>
        <Route path="/dashboard"><Dashboard /></Route>
        <Route exact path="/" component={Homepage} />
        {/*<Route exact path="/service" component={ServicePage} />*/}
        <Route exact path="/general-dentistry" component={Pediatric} />
        <Route exact path="/restorative-dentistry" component={Resotrative} />
        <Route exact path="/cosmetic-dentistry" component={Whitening} />
        <Route exact path="/sedation-dentistry" component={ColoredFillings} />
        <Route exact path="/periodontics" component={Periodontics} />
        <Route exact path="/endodontics" component={Endodontics} />
        <Route exact path="/specialoffer" component={OfferPage} />
        <Route exact path="/new-patient-form" component={PatientTypeform} />
        {/* <Route exact path="/new-patient-form" component={PatientForm} /> */}

        <Route exact path="/privacypolicy" component={Policy} />
        <Route exact path="/allpost" component={AllBlogs} />
        <Route exact path="/Busting-6-Important-And-Basic-Dental-Myths" component={BlogPage} />
        <Route exact path="/Does-Diabetes-Affect-Teeth-Diabetes-Teeth-Problems" component={BlogPage2} />
        <Route exact path="/What-to-eat-and-avoid-after-dental-surgery-wisdom-tooth-extraction-or-implants" component={BlogPage3} />
        <Route exact path="/Bleeding-gums-Home-remedies" component={BlogPage4} />
        <Route exact path="/Painless-Root-Canal-Treatment-is-it-safe-for-you-and-what-rare-complications-can-occur" component={BlogPage5} />
        <Route exact path="/Dentist-and-Pregnancy-Does-Pregnancy-affect-oral-health" component={BlogPage6} />
        <Route exact path="/Temporomandibular-Joint-Disorders" component={BlogPage7} />
        <Route exact path="/Main-Reasons-For-Tooth-Pain-Toothache-Causes" component={BlogPage8} />
        <Route exact path="/How-To-Get-Through-With-Braces-Life-With-Braces" component={BlogPage9} />
        <Route exact path="/Getting-Your-Perfect-Smile-Cosmetic-Dentist-Treatments" component={BlogPage10} />
        <Route exact path="/What-Should-You-Eat-After-a-Root-Canal" component={BlogPage11} />
        <Route exact path="/All-About-Laser-Dentistry" component={BlogPage12} />
        <Route exact path="/Facts-and-Tips-about-fighting-Tooth-Decay" component={BlogPage13} />

        <Route exact path="/dental-implants" component={service1} />
        <Route exact path="/painless-root-canals" component={service2} />
        <Route exact path="/veneers" component={service3} />
        <Route exact path="/invisalign" component={service4} />
        <Route exact path="/teeth-whitening" component={service5} />
        <Route exact path="/fixed-dentures" component={service6} />
        <Route exact path="/dental-implants-new" component={service7} />
        <Route exact path="/affordable-dental-implant-cost" component={service7} />
        <Route exact path="/affordable-dental-implant-houston" component={service8} />



        <Route exact path="/thankyou" component={ThankyouPage} />
        <Route exact path="/landingpage" component={LandingPage} />
        <Route exact path="/landingpage2" component={LandingPage2} />

        <Route component={PageNotFound} />
        </Switch>
        </Router>
      </div>

  );
}

export default App;

// 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