import React, {useRef, useState,useEffect} from 'react'
import { Navbar, Nav , Carousel,Row, Col } from 'react-bootstrap'
import { makeStyles } from '@material-ui/core/styles';
import Logo from "../public/assets/images/newlogo.png"
import Service1 from "../public/assets/serviceIamgesUpdated/image24.jpg"
import Service2 from "../public/assets/serviceImages/image21.jpg"
import Service3 from "../public/assets/serviceImages/image1.jpg"
import Service4 from "../public/assets/serviceIamgesUpdated/image21.jpg"
import Service5 from "../public/assets/serviceImages/image17.jpg"
import Service6 from "../public/assets/serviceImages/image21.jpg"
import Service7 from "../public/assets/serviceImages/image15.png"
import Service8 from "../public/assets/serviceIamgesUpdated/image22.png"
import Service9 from "../public/assets/serviceImages/image16.jpg"
import Service10 from "../public/assets/serviceImages/image123.jpg"
import Service11 from "../public/assets/serviceImages/image5.png"
import Service12 from "../public/assets/serviceImages/image25.jpg"
import Service13 from "../public/assets/serviceImages/image47.jpg"
import Service14 from "../public/assets/serviceImages/image3.jpg"
import Service15 from "../public/assets/serviceImages/image44.jpg"
import Service16 from "../public/assets/serviceImages/image49.jpg"
import LogoSmile from "../public/assets/serviceImages/servicesmile.png"
import { Button , Container ,Typography,Grid,Paper}from '@material-ui/core';
import MainFooter from "../components/footer"
import MainNavBar from "../components/navBar"
import Banner from "../public/assets/serviceImages/banner7.png"
import { Link } from 'react-router-dom'
import {firestore} from "../firebase"


import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import CallIcon from "@material-ui/icons/Call";
import "aos/dist/aos.css"
import AOS from 'aos';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import {primaryColor} from "../constants";


const useStyles = makeStyles((theme) => ({
    root: {
      flex:1,
    },
    navbar:{
        backgroundColor:'#fff'
    },
    navbar2:{
        backgroundColor:'#fff',
        marginTop: 75
    },
    logoimage:{
        width:200,
        height:60,
        objectFit:'contain'
    },
    navlist2:{
        fontSize:16,
        marginRight: 30,
        fontFamily: 'Poppins',
        fontWeight:600,
        color: 'white',
        [theme.breakpoints.only('xs')]: {
            fontSize: 10
        },
    },
    headetitle:{
        fontSize:30,
        marginLeft:30,
        fontFamily: 'Poppins',
    },
    bookappointment:{
        backgroundColor:'#DE1C49',
        color:'#fff',
        fontFamily: 'Poppins',
        margin:'0px 40px',
        "&:hover": {
            backgroundColor: "#DE1C49"
        }
    },
    bookappointment2:{
        backgroundColor:'#DE1C49',
        color:'#fff',
        fontFamily: 'Poppins',
        margin:'0px 40px',
        [theme.breakpoints.only('xs')]: {
            display: 'none'
        },
        "&:hover": {
            backgroundColor: "#DE1C49"
        }
    },
    navlist:{
        fontSize:20,
        fontWeight:600,
        fontFamily: 'Poppins',
    },
    headerbanner:{
        marginTop:"0px",
        [theme.breakpoints.only('xs')]: {
            marginTop:"0px",
            display: "none",
        },
    },
    headerbannerMobile:{
        marginTop:"0px",
        display: "none",
        [theme.breakpoints.only('xs')]: {
            marginTop:"0px",
            display: "block",
        },
    },
    banner:{
        width:'100%',
        fontFamily: 'Poppins',
    },
    bannerImage:{
        width:'100%',
        height:400,
        objectFit:'cover',
        fontFamily: 'Poppins',
        marginTop:110,
        [theme.breakpoints.only('xs')]: {
            height:'100%',
            objectFit:'contain',
        },
    },
    header:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        padding:"30px 100px",
        fontFamily: 'Poppins',
        [theme.breakpoints.only('xs')]: {
            padding:"10px 6px",
        },
    },
    headertitle:{
        fontSize:24,
        fontWeight:'600',
        fontFamily: 'Poppins',
        [theme.breakpoints.only('xs')]: {
            padding: 6,
        },
    },

    headertitle2:{
        fontSize:24,
        fontWeight:'600',
        textAlign:'left',
        padding: 10,
        fontFamily: 'Poppins',
    },
    headertitle3:{
        fontSize:20,
        fontWeight:'600',
        textAlign:'center',
        padding: 10,
        fontFamily: 'Poppins',
        // backgroundColor: "#efefef",
        borderRadius: 8,
        cursor:'pointer',
        "&:hover":{
            textDecoration:'none'
        }

    },
    text:{
        fontSize:16,
        fontWeight:500,
        textAlign:'justify',
        fontFamily: 'Poppins',
    },
    card:{
        width:'100%',
        height:300,
        marginTop:20,
        objectFit: 'cover',
        borderRadius:8,
        backgroundColor:'#cdcdcd',
        fontFamily: 'Poppins',
        [theme.breakpoints.only('xs')]: {
            height:150,
        },
    },
    dialogtitle:{
        fontSize:18,
        fontWeight:600,
        padding:'15px 0px',
        fontFamily: 'Poppins',
    },
    textfeild:{
        border:'none',
        backgroundColor:'#FFF8F2',
        fontFamily: 'Poppins',
        margin:'5px 0px'
    },
    textfiledroot: {
        '& $disabled': {
           borderColor: 'orange'
        }
     },
     disabled: {},

     dialogbuttoncontainer:{
         display:'flex',
         flexDirection:'row',
         fontFamily: 'Poppins',
         justifyContent:'center',
         margin:'20px 0px'
     },
    gridnames:{
        marginTop: 30,
        marginBottom: 30,
        [theme.breakpoints.only('xs')]: {
            margin: 0,
        },
    },
    logo:{
        height:350,
        marginTop:160,
        marginLeft:50,
        objectFit:'contain',
        [theme.breakpoints.only('xs')]: {
          height:100
        },
    },
    listtileheader:{
        width:'100px',
        color:"#000",
        marginTop:130,
        fontSize:30,
        fontFamily:'Poppins',
        marginLeft:"10%",
        fontWeight:700,
        [theme.breakpoints.only('xs')]: {
            fontSize:12,
            marginLeft:"5px",
        },
    },

    listtitlecontainer:{
        color:"#000",
        top:200,
        marginLeft:"13%",
        marginTop:20,
        [theme.breakpoints.only('xs')]: {

        },

    },
    listtitle:{
        fontSize:16,
        fontFamily:'Poppins',
        fontWeight:700,
        marginTop:10,
        cursor:'pointer',
        textAlign:'left',
        // paddingLeft:'12%',
        [theme.breakpoints.only('xs')]: {
            fontSize:8,
        },
        "&:hover":{
            color:primaryColor
        }

    },
    rightrow:{
        display:'flex',
        [theme.breakpoints.only('xs')]: {
            flexDirection:'column-reverse'
        }
    },
    dialogtitle2:{
        fontSize:16,
        fontFamily: 'Poppins',
        fontWeight:600,
        padding:'15px 0px',
        textAlign: 'center',
        color: '#DE1C49'
    },



}))
export default function Pediatric() {
    const classes = useStyles();
    const [index, setIndex] = useState(0);
    const [open, setOpen] = React.useState(false);
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [value, setValue] = React.useState('No');
    const [appointmentDate,setAppointmentDate] = useState("")
    const [appointmentTime,setAppointmentTime] = useState("")
    const [name,setName] = useState("")
    const [phone,setPhone] = useState("")
    const [ampm,setampm] = useState("AM")
    const [time,settime] = useState("9")
    const [notes,setNotes] = useState("")
    const [email,setEmail] = useState("")
     const [age,setAge] = useState("")
    const [openSnackbar, setOpenSnackBar] = React.useState(false);

    useEffect(() => {
        AOS.init({
          duration : 2000
        });
        window.scrollTo(0, 0);
      }, []);

    const handleClickOpen = () => {
        setOpen(true);
      };

      const handleClose = () => {
        setOpen(false);
      };



      const handleDateChange = (date) => {
          console.log(date)
          var localTime = date.toString();
          setAppointmentDate(localTime.slice(0,15))
          console.log(localTime.slice(15,25))
          setAppointmentTime(localTime.slice(15,25))
          setSelectedDate(date);
      };

      const handleSubmit = () => {
        console.log(phone,name,appointmentDate,appointmentTime,value)
        if(phone =="" || name == ""){
            alert("Please enter Phone, Name and Date")
        }
        else{
       firestore.collection('leads').doc(Date.now().toString()).set({
           name: name ,
           phone: phone,
           age: age,
           appointmentDate: appointmentDate,
           appointmentTime: time + " " + ampm,
           emergency:value,
           email:email,
           notes:notes,
           timestamp: Date.now()
           },{merge: true})
    }
   }

      const handleChange = (event) => {
        setValue(event.target.value);
      };

    const PediatricRef = useRef(null)
    const OralRef = useRef(null)
    const TeethRef = useRef(null)
    const ToothColoredRef = useRef(null)
    const TMDTherapy = useRef(null)
    const RootPlanning = useRef(null)
    const NightGuard = useRef(null)

    const executeScroll = (ref) => ref.current.scrollIntoView({behavior: 'smooth',block: 'start', inline: 'start'})

    return (
        <div className={classes.root}>

            <MainNavBar />
            {/* <Navbar collapseOnSelect expand="lg" variant="light" fixed="top" style={{backgroundColor:'#fff'}} className={classes.navbar}>
                <img
                    className={classes.logoimage}
                    src={Logo}
                    alt="React Bootstrap logo"
                />
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ml-auto">
                    <Nav.Link href="/" className={classes.navlist}>Home</Nav.Link>
                        <Nav.Link href="/" className={classes.navlist}>About Us</Nav.Link>
                        <Nav.Link href="/"  className={classes.navlist}>Team</Nav.Link>
                        <Nav.Link href="/"  className={classes.navlist}>Our Services</Nav.Link>
                        <Nav.Link href="/#contactus" className={classes.navlist}>Contact us</Nav.Link>
                        <Button variant="contained" className={classes.bookappointment} onClick={handleClickOpen}>Book Appointment</Button>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <Navbar fixed="top" collapseOnSelect variant="light" style={{backgroundColor: '#DE1C49'}} className={[classes.navbar2, {color:'#DE1C49'}]}>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ml-auto">
                        <Nav.Link href="#" style={{color:'#ffffff'}} className={classes.navlist2}>CALL NOW +1 713-451-3333</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar> */}


        <Grid container className={classes.headerbanner}>
            <Grid xs={8}>
            <img src={Banner} className={classes.bannerImage} />
            </Grid>
            <Grid xs={4}>
                <div >
                <Typography className={classes.listtileheader}>Endodontics</Typography>
                {/* <img src={LogoSmile} class="service-header-smile" className={classes.logo} /> */}
                <div className={classes.listtitlecontainer}>
                    <ul>
                        <li><Typography className={classes.listtitle} onClick={()=> executeScroll(PediatricRef)} >Root Canal Treatment</Typography></li>
                        <li><Typography className={classes.listtitle} onClick={()=> executeScroll(OralRef)}>Endodontic Retreatment</Typography></li>
                        <li><Typography className={classes.listtitle} onClick={()=> executeScroll(TeethRef)}>Apicoectomy</Typography></li>
                        <li><Typography className={classes.listtitle} onClick={()=> executeScroll(ToothColoredRef)}>Cracked Teeth</Typography></li>
                    </ul>

                </div>
                </div>
            </Grid>
        </Grid>

            <Grid container className={classes.headerbannerMobile}>
                <Grid xs={12}>
                    <img src={Banner} className={classes.bannerImage} />
                </Grid>
            </Grid>

        {/* <div className={classes.banner}>
            <img src={Banner} className={classes.bannerImage} />
        </div> */}

        <div className={classes.header}>
            <Typography className={classes.headertitle}>Endodontics</Typography>
            <Button variant="contained" className={classes.bookappointment2} onClick={handleClickOpen}>Book Appointment</Button>
        </div>
            <Container>
            <Typography className={classes.text}>
                Refer to the study of the dental pulp and the inner tooth. Endodontics is the higher level of expertise in performing root canal treatment. These experts are well-known for many diseases that require dental pulp and the procedures to treat them. Endodontics mainly focuses on caring for complex tooth infections that affect the inside of the tooth (the pulp). They perform root canal treatments and other procedures to save your natural tooth.

            </Typography>
            </Container>
        <Container>

            <div style={{height: 50}} ref={PediatricRef}>

            </div>


            <Grid container spacing={4} data-aos="fade-up" data-aos-duration="500"  style={{marginTop: 20, marginBottom: 20,overflowX:'hidden', scrollMargin: "220px",}} data-aos="fade-up" data-aos-duration="500" >
                <Grid item xs={12} sm={4}>
                    <img className={classes.card} src={Service1}/>
                </Grid>
                <Grid item xs={12} sm={8}>
                <Typography data-aos="fade-up" data-aos-duration="500" className={classes.headertitle2} >Root Canal Treatment </Typography>
                <Typography  data-aos="fade-up" data-aos-duration="500"  className={classes.text}>
                    Our Experts at Hi dental state that if the tooth becomes injured or diseased most of the time it can be saved only if the patients visit the dentist without delaying at the right time through a therapy called Root Canal Treatment. This treatment is also called endodontic treatment, or root canal therapy that treats the infected tooth, preventing decay from spreading to the adjacent teeth, and in most cases, it prevents the need for tooth extraction. The most usual symptoms of the infected tooth are when the pulp tissue becomes infected or inflamed, jaw pain, swelling, or discoloration one must visit a dentist also if delayed Without root canal therapy, both the infection and damage will continue to reach your gums and your tooth will most likely need to be removed. You must follow the directions of your dentist and should attend all of your follow-up appointments on time so that your root canal treatment can be successful and hassle-free.

                </Typography>
                </Grid>
            </Grid>
            {/*end*/}

            {/*start*/}

            <div style={{height: 50}} ref={OralRef}>

            </div>

            <Grid container spacing={4}  style={{marginTop: 20, marginBottom: 20,overflowX:'hidden'}} data-aos="fade-up" data-aos-duration="500" className={classes.rightrow}>
                <Grid item xs={12} sm={8}>
                <Typography data-aos="fade-up" data-aos-duration="500"  className={classes.headertitle2}>Endodontic Retreatment </Typography>
                <Typography className={classes.text} data-aos="fade-up" data-aos-duration="500" >
                    Endodontic retreatment refers to treatment when a root canal has previously been completed but in some cases, a tooth that has been treated does not heal properly and can become painful and irritating after years of treatment. This treatment Removes and restores materials used during a previous root canal that did not heal properly. A common symptom may occur like pain or discomfort, swelling involving the tooth that underwent root canal therapy before. Firstly, Hi Dental endodontic experts will ask you to wear the Noise-cancellation Headphones and will give a local anesthetic to numb the infected area. A sedation technique may be used if necessary and then they will reopen your tooth to enter into the root canal filling material. In many cases, complex restorative material may be disassembled and removed to permit access to the root canals. Thorough cleaning will be done and then again the tooth will be strengthened with strong materials and sealed. After the retreatment has been done properly, you need to visit your endodontist to have a new crown and other restoration processes will be done to protect the tooth and restore it to its full function.
                </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <img className={classes.card} src={Service4}/>
                </Grid>
            </Grid>
            {/*end*/}

            {/*start*/}


            <div style={{height: 50}} ref={TeethRef}>

            </div>
            <Grid container spacing={4} style={{marginTop: 20, marginBottom: 20,overflowX:'hidden'}} data-aos="fade-up" data-aos-duration="500" >
                <Grid item xs={12} sm={4}>
                    <img className={classes.card} src={Service13}/>
                </Grid>
                <Grid item xs={12} sm={8}>
                <Typography className={classes.headertitle2} data-aos="fade-up" data-aos-duration="500" >Apicoectomy</Typography>
            <Typography className={classes.text} data-aos="fade-up" data-aos-duration="500" >
                A minor surgery option when root canal treatment fails. When the tooth is not successfully treated even after root canal treatment Apicoectomy needs to be done. Apicoectomy is a minor surgery in which the tip of the tooth's root or the apex is removed and sealed. The surgery is performed by an endodontist.
                This treatment can save the crown of the teeth and tooth extraction can be forborne. This surgery may also be only surgery when a tooth has curved or arched roots that are hard to treat with traditional root canal treatment. At Hi Dental, our skilled team of dentists adopts higher methods to take a thorough look at the infected area and help patients make exemplary decisions when it comes to their oral wellness.
                When starting with Apicoectomy surgery our Hi Dental experts will ask you to wear the Noise-cancellation Headphones and will give a local anesthetic to numb the infected area also a sedation technique may be used if necessary and After this surgery, you might feel pain and numbness in your treated area for few days. Hi Dental, professionals will provide subsequent instructions and prescribe antibiotics or pain relief medications for your better recovery. Our team may call you after few days of surgery to monitor your tooth and the overall health of your mouth.
            </Typography>
                </Grid>
            </Grid>
            {/*end*/}

            {/*start*/}

            <div style={{height: 50}} ref={ToothColoredRef}>

            </div>


            <Grid container spacing={4} style={{marginTop: 20, marginBottom: 20}} data-aos="fade-up" data-aos-duration="500" className={classes.rightrow}>
                <Grid item xs={12} sm={8}>
                <Typography className={classes.headertitle2} data-aos="fade-up" data-aos-duration="500" >Cracked Teeth</Typography>
            <Typography className={classes.text} data-aos="fade-up" data-aos-duration="500" >
                A cracked tooth may be a result of grinding your teeth at night or maybe naturally as it is a common issue among men and women. It’s a common condition and the leading cause of tooth loss is age, with more teeth cracks occurring in people over 50. There are different types of cracked teeth and the cracks can appear as craze lines, Fractured cusp, Cracks that extend into the gum line, Split tooth, Vertical root fracture.
                This can cause discomfort and pain on the tooth whenever you put pressure while eating or grinding. The crack tooth is hard to find and sometimes x-rays also do not reveal the Cracked tooth, not everyone has the same symptoms. Cracked tooth symptoms include :
                Sensitivity from hot and cold
                Pain when chewing, particularly when you release the bite
                Pain that is rarely continuous
                Gums swelling around the affected tooth
                At Hi Dental, the expert's foremost purpose is to save the tooth at any cost, clean the infection and prevent further damage to the adjacent teeth. Treatment depends on your symptoms what is the size of the crack, exactly where the crack is, and whether the crack extends into the gum line. Considering these factors, Hi Dental experts may recommend root canal surgery, tooth color filling, a dental crown, or the tooth may need to be extracted if it is damaged to protect the oral health and bring back your healthy smile.
            </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <img className={classes.card} src={Service8}/>
                </Grid>
            </Grid>
            {/*end*/}
            <Typography className={classes.headertitle2}>FAQs</Typography>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classes.heading}>Why should I visit the dentist every 6 months?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography style={{textAlign: 'left'}}>
                        Most people do not care or think about visiting a dentist until they feel pain or cavities in the mouth as many dental problems do not show any symptoms at an initial stage but in the advanced stage when it becomes severe. They feel that they would save some money out of it but it ends ups in costing more as people become careless. That is why it is necessary to visit a dentist every after 6 months so we can check your oral health and let that flawless smile recapitulate.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>How to prevent cavities?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography style={{textAlign: 'left'}}>
                        To prevent cavities one should always brush their teeth and spend 2 -3 minutes brushing and flossing and rinse their mouth after every meal. One must not forget to visit their dentists for a regular checkup and good oral health.
                    </Typography>
                </AccordionDetails>
            </Accordion><
            Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
            >
                <Typography className={classes.heading}>Why is flossing important after brushing?</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography style={{textAlign: 'left'}}>
                    Flossing is important after brushing because it gets rids of bacteria that are not gone by brushing. if you do not floss between the teeth you allow plaque to remain within the teeth. These microscopic bacteria hide between the tiny spaces of teeth.
                    Ask your dentist to show you the proper way to floss. You will notice the huge difference at the next cleaning appointment.
                </Typography>
            </AccordionDetails>
        </Accordion>

        </Container>



<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={"sm"} maxWidth={'sm'}>
{/* <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
<DialogContent>

    <Typography className={classes.dialogtitle2}>Book your first appointment for free!</Typography>

    {/* <Typography className={classes.dialogtitle}>Name</Typography> */}
        <TextField
            autoFocus
            id="name"
            variant="outlined"
            fullWidth
            className={classes.textfeild}
            placeholder="Enter Name"
            onChange = {(e)=>setName(e.target.value)}
            classes={{notchedOutline:classes.input}}
            disableUnderline={false}
            InputProps={{
                classes: {
                   root: classes.textfiledroot,
                   disabled: classes.disabled
                }
             }}
        />

        {/* <Typography className={classes.dialogtitle}>PhoneNumber</Typography> */}
        <TextField

            id="name"
            variant="outlined"
            fullWidth
            className={classes.textfeild}
            onChange = {(e)=>setPhone(e.target.value)}
            placeholder="Enter PhoneNumber"
            classes={{notchedOutline:classes.input}}
            disableUnderline={false}
            InputProps={{
                classes: {
                   root: classes.textfiledroot,
                   disabled: classes.disabled
                }
             }}
        />
    {/* <Typography className={classes.dialogtitle}>Age</Typography> */}

    <TextField
        id="name"
        variant="outlined"
        fullWidth
        className={classes.textfeild}
        onChange = {(e)=>setAge(e.target.value)}
        placeholder="Enter Your Age"
        classes={{notchedOutline:classes.input}}
        disableUnderline={false}
        InputProps={{
            classes: {
                root: classes.textfiledroot,
                disabled: classes.disabled
            }
        }}
    />
    <TextField
        id="name"
        variant="outlined"
        fullWidth
        className={classes.textfeild}
        onChange = {(e)=>setEmail(e.target.value)}
        placeholder="Enter Your Email"
        classes={{notchedOutline:classes.input}}
        disableUnderline={false}
        InputProps={{
            classes: {
                root: classes.textfiledroot,
                disabled: classes.disabled
            }
        }}
    />
    <TextField
        id="name"
        variant="outlined"
        fullWidth
        className={classes.textfeild}
        onChange = {(e)=>setNotes(e.target.value)}
        placeholder="Enter Description/Notes"
        classes={{notchedOutline:classes.input}}
        disableUnderline={false}
        InputProps={{
            classes: {
                root: classes.textfiledroot,
                disabled: classes.disabled
            }
        }}
    />

      <Grid container>
          <Typography className={classes.dialogtitle}>Select a date for appointment</Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justify="space-around">
                <KeyboardDatePicker
                    minDate={new Date()}
                margin="normal"
                id="date-picker-dialog"
                label="Pick a Date"
                format="MM/dd/yyyy"
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                />
                <div style={{margin: 40}}>
                    <select
                        style={{padding: 5, fontSize: 16, marginRight: 5}}
                        value={time}
                        onChange={(e) => settime(e.target.value)}
                    >
                        <option value="8">8:00</option>
                        <option value="9">9:00</option>
                        <option value="10">10:00</option>
                        <option value="11">11:00</option>
                        <option value="12">12:00</option>
                        <option value="1">1:00</option>
                        <option value="2">2:00</option>
                        <option value="3">3:00</option>
                        <option value="4">4:00</option>
                        <option value="5">5:00</option>
                        <option value="6">6:00</option>
                        <option value="7">7:00</option>
                    </select>
                    <select
                        style={{padding: 5, fontSize: 16, marginRight: 5}}
                        value={ampm}
                        onChange={(e) => setampm(e.target.value)}
                    >
                        <option value="AM">AM</option>
                        <option value="PM">PM</option>
                    </select>
                    {/*<p>{message}</p>*/}
                </div>

            </Grid>
            </MuiPickersUtilsProvider>
      </Grid>


      <FormControl component="fieldset">
        <RadioGroup aria-label="gender"  name="gender1" value={value} onChange={handleChange}>
            <div style={{display:'flex',justifyContent:'space-between'}}>
            <Typography className={classes.dialogtitle} style={{paddingRight:'20px'}}>Emergency ?</Typography>
            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="No" control={<Radio />} label="No" />
            </div>
        </RadioGroup>
    </FormControl>

</DialogContent>
<div className={classes.dialogbuttoncontainer}>
<Button variant="contained" className={classes.bookappointment} onClick={handleSubmit}>Submit</Button>
<Button variant="contained" className={classes.bookappointment} onClick={handleClose}>Close</Button>
</div>
 </Dialog>

            <MainFooter id="contactus"/>

        </div>
    )
}
