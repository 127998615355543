import React, {useRef, useState,useEffect} from 'react'
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Container from "@material-ui/core/Container"
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
// import {Header, ProfileInfo} from "../../components/Main/styles";
import Drawer from "@material-ui/core/Drawer";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Divider from "@material-ui/core/Divider";
// import Menubar from "../../components/Menubarmobile/Menubar";
import {makeStyles} from "@material-ui/core/styles";
import MainFooter from '../components/footer';
import MainNavBar from '../components/navBar';

const drawerWidth = 50;


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        fontFamily: 'Poppins',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor:'#ffffff',
        fontFamily: 'Poppins',
    },
    backButton:{
        marginLeft:"20px",
        fontFamily: 'Poppins',
    },
    menuButton: {
        marginRight: theme.spacing(2),
        fontFamily: 'Poppins',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        fontFamily: 'Poppins',
    },

    drawerPaper: {
        width: drawerWidth,
        fontFamily: 'Poppins',
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'Poppins',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    // content: {
    //     flexGrow: 1,
    //     padding: theme.spacing(3),
    //     transition: theme.transitions.create('margin', {
    //         easing: theme.transitions.easing.sharp,
    //         duration: theme.transitions.duration.leavingScreen,
    //     }),
    //     marginLeft: -drawerWidth,
    // },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
        fontFamily: 'Poppins',
    },
    paragraph:{
        textAlign:'justify',
        fontFamily: 'Poppins',
    },
    heading:{
        fontSize:25,
        fontFamily: 'Poppins',
    },
    content:{
        marginTop:'120px',
        fontFamily: 'Poppins',
    }
}));
export default function Terms1() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    useEffect(() => {
        document.title = "Hi Dental Care | Dental Implants Procedure, Cost and Safety Measures"
    },[])
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    return (
        <div>
            <MainNavBar />
            <Grid container>
                <Grid item xs={12} sm={6} lg={12}>
                    <Paper elevation={0} className={classes.content} id="div1">
                        <Container>
                            {/*<h1 className={classes.heading}>GENERAL TERMS OF USE</h1>*/}
                            <p className={classes.paragraph}>

                                <br/><br/><h1 className={classes.heading}>PRIVACY POLICY</h1>
                                PRIVACY POLICY

                                HI DENTAL CARE, VALUES YOUR PRIVACY. HERE'S HOW WE DO IT:
                                <br/><br/><h1 className={classes.heading}>Personal Privacy</h1>
                                Your privacy is important to us. To better protect your privacy we provide this notice explaining our online information practices and the choices you can make about the way your information is collected and used. To make this notice easy to find, we make it available on our homepage and at every point where especially identifiable information may be requested. Other Third Party ad servers or ad networks may also use cookies to track users' activities on this website to measure advertisement effectiveness and other reasons that will be provided in their privacy policies, Hi Dental Care has no access or control over these cookies that may be used by third-party advertisers.

                                <br/><br/><h1 className={classes.heading}>Collection of Personal Information</h1>
                                When visiting, Hi Dental Care the IP address used to access the site will be logged along with the dates and times of access. This information is purely used to analyze trends, administer the site, track user's movements and gather broad demographic information for internal use. Most importantly, any recorded IP addresses are not linked to personally identifiable information.
                                If you submit your name and email through one of our systems on this site, that information will only be used for our purposes of contacting you. This information will not be sold or given to any outside party for any reason.

                                <br/><br/><h1 className={classes.heading}>Privacy</h1>
                                We use Google Analytics, Google AdWords Conversion tracker, and other Google services, and third-party tracking and or analytics services that place cookies on a browser across the website. These cookies help us in increasing the website's effectiveness for our visitors. These cookies are set and read by Google.
                                We use AdWords remarketing to market our sites across the web. We place a cookie on a browser, and then a 3rd party (Google) reads these cookies and may serve an ad on a 3rd party site.

                                <br/><br/><h1 className={classes.heading}>Cookies</h1>
                                A cookie is a string of information that is sent by a website and stored on your hard drive or temporarily in your computer memory. We may employ cookie technology to help you move faster through our Site. We may employ cookie technology to estimate our total audience size and traffic and to help us improve our Site experience by measuring which site areas are of greatest interest to users. You can turn off the ability to receive any of these cookies by adjusting the browser on your computer, but you should note that if you do so, this may materially affect the functionality of the Site and the information you can access through it.
                                We collect anonymous data when you visit most pages on Hi Dental Care, Your visit may automatically provide us with data that is not linked to your personal information, such as your IP (Internet Protocol) address, browser type, operating system, domain name, access times, and referring website addresses. Some parts of Hi Dental Care, use embedded pixel or javascript technologies to facilitate your use of the Web site and to track general traffic. We use your anonymous data to obtain general statistics regarding the use of Hi Dental Care, and its specific Web pages and to evaluate how our visitors use and navigate our Web site on an aggregate basis. We do not link your anonymous data with personal data.

                                <br/><br/><h1 className={classes.heading}>Use and disclosure of your personal information</h1>
                                We collect information about you at several places throughout the site. You will be informed on how this information is collected at that time. You will also have the ability to opt-in to information collection when you enter the site or use a service. Most information is collected in the following instances:

                                <br/><br/><h1 className={classes.heading}>Appointments and Patient Forms</h1>
                                To book an appointment online, you may be required to complete a registration form that requests certain information, such as name, telephone number, and email address. We may offer specialized registration for certain programs and events that may request some or all of the above information.
                                We may use your registration information to send you an email confirming your registration with our site, to respond to questions from you, or to notify you if there is a problem with any order for goods or services that you placed. We may also, from time to time, send emails, with your permission, on special promotions or newsletters we think may be of interest to you. Once you have opted in, we may send the info to you. For instance, if you were to opt in we may send information regarding your specific health condition.

                                <br/><br/><h1 className={classes.heading}>Links to third party Websites</h1>
                                We have included links on this site for your use and reference. We are not responsible for the privacy policies on these websites. You should be aware that the privacy policies of these sites may differ from our own.

                                <br/><br/><h1 className={classes.heading}>Change/Update to this privacy policy</h1>
                                The contents of this statement may be altered at any time, at our discretion.
                                If you have any questions regarding the privacy policy of Hi Dental Care, then you may contact us at +1 713-451-3333 or email us at contact@hidentalhouston.com
                            </p>
                        </Container>
                    </Paper>
                </Grid>
            </Grid>
        <MainFooter />
        </div>
    )
}
