import React, {useRef, useState,useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Button , Container ,Typography,Grid,Paper,TextField}from '@material-ui/core';
import MainFooter from "../components/BlogFooter";
import "aos/dist/aos.css"
import AOS from 'aos';
import MainNavBar from '../components/navBar';
import {primaryColor} from "../constants";
import DateFnsUtils from '@date-io/date-fns';

import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
  import Radio from '@material-ui/core/Radio';
  import RadioGroup from '@material-ui/core/RadioGroup';
  import FormControlLabel from '@material-ui/core/FormControlLabel';
  import FormControl from '@material-ui/core/FormControl';
  import FormLabel from '@material-ui/core/FormLabel';
  import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
  import { Map, GoogleApiWrapper,InfoWindow, Marke } from 'google-maps-react';
  import { styles } from '@material-ui/pickers/views/Calendar/Calendar';
  import GoogleMapReact from 'google-map-react';
  import Snackbar from '@material-ui/core/Snackbar';
  import MuiAlert from '@material-ui/lab/Alert';
  import {firestore} from "../firebase"
  import BlogImage4 from "../public/assets/blogimages/blogimage4.png";



const useStyles = makeStyles((theme) => ({
    root: {
        flex:1,
    },
    gridcontainer:{
        marginTop:120,
        width:'100%'
    },
    grid1:{
        padding:'20px 30px'
    },
    grid2:{
        backgroundColor:'white',
        right:0,
        padding:'10px 20px',
        zIndex:0,
        [theme.breakpoints.only('xs')]: {
            display: 'none'
        },
    },
    form:{
        padding:10
    },
    formContainer:{
        borderRadius:20,
        position:'fixed',

    },
    formheader:{
        backgroundColor:'#DE1C49',
        padding:12,
        color:"#fff",
        fontSize:20,
        fontWeight:600,
        borderTopLeftRadius:16,
        borderTopRightRadius:16
    },
    textfeild:{
        border:'none',
        fontFamily: 'Poppins',
        "&, .MuiOutlinedInput-input":{
            padding:"10px 5px"
        }
    },
    textfiledroot: {
        '& $disabled': {
           borderColor: 'orange'
        }
     },
     disabled: {},
     dialogbuttoncontainerform:{
        display:'flex',
        flexDirection:'row',
        fontFamily: 'Poppins',
        justifyContent:'center',
        margin:'5px 0px',
        paddingBottom:20
    },
    miantitle:{
        fontSize:24,
        fontWeight:'800',
        padding:'30px 0px',
        textAlign:'start'
    },
    miandesc:{
        fontSize:18,
        fontWeight:'400',
        textAlign:'start'
    },
    submitbutton:{
        backgroundColor:'#DE1C49',
        color:'#fff',
        fontFamily: 'Poppins',
        fontWeight: "800",
        margin:'10px 10px',
        padding:"5px 30%",
        "&:hover": {
            backgroundColor: "#DE1C49"
        }
    },
    bannerImage:{
        width:'100%',
        height:300,
        objectFit:'cover',
        borderRadius:20
    }
}))
export default function BlogPage4() {
    const classes = useStyles();
    const [index, setIndex] = useState(0);
    const [open, setOpen] = React.useState(false);
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [value, setValue] = React.useState('No');
    const [appointmentDate,setAppointmentDate] = useState("")
    const [appointmentTime,setAppointmentTime] = useState("")
    const [name,setName] = useState("")
    const [phone,setPhone] = useState("")
    const [ampm,setampm] = useState("AM")
    const [time,settime] = useState("9")
    const [notes,setNotes] = useState("")
    const [email,setEmail] = useState("")
     const [age,setAge] = useState("")
    const [openSnackbar, setOpenSnackBar] = React.useState(false);
    const [bottom,setBottom] = useState(true)

    useEffect(() => {
        AOS.init({
            duration : 2000
        });
        window.scrollTo(0, 0);
    }, []);

    const handleDateChange = (date) => {
        console.log(date)
        var localTime = date.toString();
        setAppointmentDate(localTime.slice(0,15))
        console.log(localTime.slice(15,25))
        setAppointmentTime(localTime.slice(15,25))
        setSelectedDate(date);
    };

   const handleSubmit = () => {
       console.log(phone,name,appointmentDate,appointmentTime,value)
       if(phone =="" || name == ""){
           alert("Please enter Phone, Name and Date")
       }
       else{
      firestore.collection('leads').doc(Date.now().toString()).set({
          name: name ,
          phone: phone,
          age: age,
          appointmentDate: appointmentDate,
          appointmentTime: time + " " + ampm,
          emergency:value,
          email:email,
          notes:notes,
          timestamp: Date.now()
          },{merge: true})

      setTimeout(function(){ handleClickSnackbar() }, 300);
      handleClickSnackbar()
   }
  }

  const handleClickSnackbar = () => {
    setOpenSnackBar(true);
  };

    const handleChange = (event) => {
      setValue(event.target.value);
    };

    const K_HEIGHT = 40;
    const K_WIDTH = 40;
  const greatPlaceStyle = {
      // initially any map object has left top corner at lat lng coordinates
      // it's on you to set object origin to 0,0 coordinates
      position: 'absolute',
      width: K_WIDTH,
      height: K_HEIGHT,
      left: -K_WIDTH / 2,
      top: -K_HEIGHT / 2,
      objectFit:'contain',
      border: '1px solid #f44336',
      borderRadius: K_HEIGHT,
      backgroundColor: 'white',
      textAlign: 'center',
      color: '#3f51b5',
      fontSize: 16,
      fontWeight: 'bold',
      padding: 4
    };


    return (
        <div>
        <div className={classes.root}>
        <MainNavBar />
            <div>
            <Grid container className={classes.gridcontainer}>
                <Grid item xs={12} sm={8} className={classes.grid1}>
                <img src={BlogImage4} className={classes.bannerImage} />
                   <Typography className={classes.miantitle}>Bleeding gums: Home remedies</Typography>
                   <Typography className={classes.miandesc}>
                   Bleeding gums or blisters in gums or teeth is typically not a serious but very common problem that every other person may suffer. While brushing or flossing teeth you may notice some blood which causes irritation and sensitivity in gums.
                    The bleeding gum's most common reason is the buildup of tartar and plaque above the teeth and this allows bacteria to grow in the gums as well. Only a Good oral regimen can prevent sensitivity and bleeding
                   </Typography>
                   <Typography className={classes.miandesc}>
                   What Causes Bleeding Gums?
                    </Typography>
                   <Typography className={classes.miandesc}>
                   Common problems that can cause gum bleeding, blisters, or sensitivity:
                   </Typography>
                   <ul>
                       <li><Typography className={classes.miandesc}>Not brushing your teeth twice a day</Typography></li>
                       <li><Typography className={classes.miandesc}>Poor eating habits may cause gum bleeding</Typography></li>
                       <li><Typography className={classes.miandesc}>An unhygienic mouth can give space to bacteria</Typography></li>
                       <li><Typography className={classes.miandesc}>Using a toothbrush with hard bristles or a new brush that is too stiff </Typography></li>
                       <li><Typography className={classes.miandesc}>Using an old toothbrush that is being used for more than 3 months and no longer cleans effectively</Typography></li>
                       <li><Typography className={classes.miandesc}>Flossing for the first time and don't know how to do floss. or using dental floss roughly, instead of gently pushing it down and</Typography></li>
                       <li><Typography className={classes.miandesc}>Hormonal variations during pregnancy may also be one of the reasons.</Typography></li>
                       <li><Typography className={classes.miandesc}>Taking certain medicines, such as aspirin, blood thinners.</Typography></li>
                       <li><Typography className={classes.miandesc}>Having gingivitis, early-stage gum disease, infection, or inflammation of the gums.</Typography></li>
                   </ul>
                   <Typography className={classes.miandesc}>
                   Home remedies to stop Bleeding Gums:
                    </Typography>
                    <Typography className={classes.miandesc}>
                    To stop Bleeding gums try these natural home remedies in an emergency:
                    </Typography>
                    <ol>
                       <li><Typography className={classes.miandesc}>In maximum situations, people can treat minor gum bleeding at home by following Good oral hygiene and natural remedies can treat gum bleeding.</Typography></li>
                       <li><Typography className={classes.miandesc}>Gently hold the gauze in place until the blood has stopped flowing. People with a medical condition or weakened immune system may find that their gums take a long time to stop bleeding. Talk to a dentist concerning the best way to stop the blood flow.</Typography></li>
                       <li><Typography className={classes.miandesc}>Ice packs are effective to relieve minor mouth injuries that cause swelling, such as cuts and scrapes. They also help reduce pain and swelling caused by gingivitis. Use an icepack for 10 minutes at a time and take 10-minute breaks. If still the bleeding continues, you might consider visiting a dentist.</Typography></li>
                       <li><Typography className={classes.miandesc}>Oil pulling means swishing some oil in your mouth to better fight infections that make your gums bleed. Rinse with coconut oil.</Typography></li>
                       <li><Typography className={classes.miandesc}>Antibacterial mouthwashes can treat and prevent bleeding gums. They eliminate bacteria and lessen inflammation to relieve sore, swollen, and bleeding gums. Common ingredients in mouthwashes must include chlorhexidine, hydrogen peroxide.</Typography></li>
                       <li><Typography className={classes.miandesc}>Using lukewarm saltwater can help diminish bacteria and speed up healing.</Typography></li>
                       <li><Typography className={classes.miandesc}>Turmeric is a very good healer and has natural antibacterial and anti-inflammatory results. Using a turmeric paste on the gums improves the symptoms of gingivitis and bleeding gums.</Typography></li>
                       <li><Typography className={classes.miandesc}>Avoiding Smoking</Typography></li>
                       <li><Typography className={classes.miandesc}>Eating more leafy vegetables and a healthful diet that must include plenty of fresh fruits and limiting processed foods with added sugars.</Typography></li>
                       <li><Typography className={classes.miandesc}>Changing the toothbrushes every 3 to 4 months.</Typography></li>
                       <li><Typography className={classes.miandesc}>Regularly Visting your dentist to keep a check for health problems that can contribute to gum problems, such as diabetes.</Typography></li>
                   </ol>
                   <Typography className={classes.miandesc}>
                   Using some of these effective home remedies for bleeding gums and any minor mouth problems can tremendously help you in controlling the bleeding gums problems. Schedule your appointment in Hi Dental care, for your bleeding gums for a proper solution, if these home remedies do not appear to work on your problem.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.grid2} >
                <Paper className={classes.formContainer}>
                <div className={classes.formheader}>
                    Contact Us
                </div>
                <div  className={classes.form}>
                <TextField
                    autoFocus
                    id="name"
                    variant="outlined"
                    fullWidth
                    className={classes.textfeild}
                    placeholder="Enter Name"
                    onChange = {(e)=>setName(e.target.value)}
                    classes={{notchedOutline:classes.input}}
                    // disableUnderline={false}
                    InputProps={{
                        classes: {
                        root: classes.textfiledroot,
                        // disabled: classes.disabled
                        }
                    }}
                />
                <TextField

                    id="name"
                    variant="outlined"
                    fullWidth

                    className={classes.textfeild}
                    onChange = {(e)=>setPhone(e.target.value)}
                    placeholder="Enter PhoneNumber"
                    classes={{notchedOutline:classes.input}}
                    disableUnderline={false}
                    InputProps={{
                        classes: {
                        root: classes.textfiledroot,
                        // disabled: classes.disabled
                        }
                    }}
                />
                <TextField
                id="name"
                variant="outlined"
                fullWidth
                className={classes.textfeild}
                onChange = {(e)=>setAge(e.target.value)}
                placeholder="Enter Your Age"
                classes={{notchedOutline:classes.input}}
                // disableUnderline={false}
                InputProps={{
                    classes: {
                        root: classes.textfiledroot,
                        // disabled: classes.disabled
                    }
                }}
                    />
                <TextField
                id="name"
                variant="outlined"
                fullWidth
                className={classes.textfeild}
                onChange = {(e)=>setEmail(e.target.value)}
                placeholder="Enter Your Email"
                classes={{notchedOutline:classes.input}}
                // disableUnderline={false}
                InputProps={{
                    classes: {
                        root: classes.textfiledroot,
                        // disabled: classes.disabled
                    }
                }}
                    />
                    <TextField
                id="name"
                variant="outlined"
                fullWidth
                className={classes.textfeild}
                onChange = {(e)=>setNotes(e.target.value)}
                placeholder="Enter Descriptons/Notes"
                classes={{notchedOutline:classes.input}}
                // disableUnderline={false}
                InputProps={{
                    classes: {
                        root: classes.textfiledroot,
                        // disabled: classes.disabled
                    }
                }}
                    />
            <Grid container>
                <Typography className={classes.dialogtitle}>Select a date for appointment</Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around" style={{height:'70px'}}>
                        <Grid item xs={12} sm={6}>
                        <KeyboardDatePicker
                            minDate={new Date()}
                        margin="normal"
                        id="date-picker-dialog"
                        label="Pick a Date"
                        format="MM/dd/yyyy"
                        value={selectedDate}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <div style={{margin: 40,display:'flex'}}>
                            <select
                                style={{padding: 5, fontSize: 16, marginRight: 5,}}
                                value={time}
                                onChange={(e) => settime(e.target.value)}
                            >
                                <option value="8">8:00</option>
                                <option value="9">9:00</option>
                                <option value="10">10:00</option>
                                <option value="11">11:00</option>
                                <option value="12">12:00</option>
                                <option value="1">1:00</option>
                                <option value="2">2:00</option>
                                <option value="3">3:00</option>
                                <option value="4">4:00</option>
                                <option value="5">5:00</option>
                                <option value="6">6:00</option>
                                <option value="7">7:00</option>
                            </select>
                            <select
                                style={{padding: 5, fontSize: 16, marginRight: 5}}
                                value={ampm}
                                onChange={(e) => setampm(e.target.value)}
                            >
                                <option value="AM">AM</option>
                                <option value="PM">PM</option>
                            </select>
                        </div>
                        </Grid>
                    </Grid>
                    </MuiPickersUtilsProvider>
            </Grid>
            <FormControl component="fieldset">
                <RadioGroup aria-label="gender"  name="gender1" value={value} onChange={handleChange}>
                    <div style={{display:'flex',justifyContent:'space-between',paddingTop:10}}>
                    <Typography className={classes.dialogtitle} style={{paddingRight:'20px'}}>Emergency ?</Typography>
                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="No" control={<Radio />} label="No" />
                    </div>
                </RadioGroup>
                    </FormControl>
                    </div>

                    <div className={classes.dialogbuttoncontainerform}>
                    <Button variant="contained" className={classes.submitbutton} onClick={handleSubmit}>Submit</Button>
                    </div>
                    </Paper>
                </Grid>

            </Grid>
        <MainFooter id="contactus"/>
        </div>

        </div>

        </div>
    )
}
