import React, {useRef, useState,useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Button , Container ,Typography,Grid,Paper,TextField}from '@material-ui/core';
import MainFooter from "../components/BlogFooter";
import "aos/dist/aos.css"
import AOS from 'aos';
import MainNavBar from '../components/navBar';
import {primaryColor} from "../constants";
import DateFnsUtils from '@date-io/date-fns';

import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
  import Radio from '@material-ui/core/Radio';
  import RadioGroup from '@material-ui/core/RadioGroup';
  import FormControlLabel from '@material-ui/core/FormControlLabel';
  import FormControl from '@material-ui/core/FormControl';
  import FormLabel from '@material-ui/core/FormLabel';
  import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
  import { Map, GoogleApiWrapper,InfoWindow, Marke } from 'google-maps-react';
  import { styles } from '@material-ui/pickers/views/Calendar/Calendar';
  import GoogleMapReact from 'google-map-react';
  import Snackbar from '@material-ui/core/Snackbar';
  import MuiAlert from '@material-ui/lab/Alert';
  import {firestore} from "../firebase"
  import BlogImage2 from "../public/assets/blogimages/blogimage2.png";



  const useStyles = makeStyles((theme) => ({
    root: {
        flex:1,
    },
    gridcontainer:{
        marginTop:120,
        width:'100%'
    },
    grid1:{
        padding:'20px 30px'
    },
    grid2:{
        backgroundColor:'white',
        right:0,
        padding:'10px 20px',
        zIndex:0,
        [theme.breakpoints.only('xs')]: {
            display: 'none'
        },
    },
    form:{
        padding:10
    },
    formContainer:{
        borderRadius:20,
        position:'fixed',

    },
    formheader:{
        backgroundColor:'#DE1C49',
        padding:12,
        color:"#fff",
        fontSize:20,
        fontWeight:600,
        borderTopLeftRadius:16,
        borderTopRightRadius:16
    },
    textfeild:{
        border:'none',
        fontFamily: 'Poppins',
        "&, .MuiOutlinedInput-input":{
            padding:"10px 5px"
        }
    },
    textfiledroot: {
        '& $disabled': {
           borderColor: 'orange'
        }
     },
     disabled: {},
     dialogbuttoncontainerform:{
        display:'flex',
        flexDirection:'row',
        fontFamily: 'Poppins',
        justifyContent:'center',
        margin:'5px 0px',
        paddingBottom:20
    },
    miantitle:{
        fontSize:24,
        fontWeight:'800',
        padding:'30px 0px',
        textAlign:'start'
    },
    mythtitle:{
        fontSize:18,
        fontWeight:'600',
        padding:'10px 0px',
        textAlign:'start'
    },
    mythtruth:{
        fontSize:18,
        fontWeight:'400',
        padding:'10px 0px',
        textAlign:'start'
    },
    miandesc:{
        fontSize:18,
        fontWeight:'400',
        textAlign:'start'
    },
    submitbutton:{
        backgroundColor:'#DE1C49',
        color:'#fff',
        fontFamily: 'Poppins',
        fontWeight: "800",
        margin:'10px 10px',
        padding:"5px 30%",
        "&:hover": {
            backgroundColor: "#DE1C49"
        }
    },
    bannerImage:{
        width:'100%',
        height:300,
        objectFit:'cover',
        borderRadius:20
    }
}))

export default function BlogPage2() {
    const classes = useStyles();
    const [index, setIndex] = useState(0);
    const [open, setOpen] = React.useState(false);
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [value, setValue] = React.useState('No');
    const [appointmentDate,setAppointmentDate] = useState("")
    const [appointmentTime,setAppointmentTime] = useState("")
    const [name,setName] = useState("")
    const [phone,setPhone] = useState("")
    const [ampm,setampm] = useState("AM")
    const [time,settime] = useState("9")
    const [notes,setNotes] = useState("")
    const [email,setEmail] = useState("")
     const [age,setAge] = useState("")
    const [openSnackbar, setOpenSnackBar] = React.useState(false);
    const [bottom,setBottom] = useState(true)

    useEffect(() => {
        AOS.init({
            duration : 2000
        });
        window.scrollTo(0, 0);
    }, []);

    const handleDateChange = (date) => {
        console.log(date)
        var localTime = date.toString();
        setAppointmentDate(localTime.slice(0,15))
        console.log(localTime.slice(15,25))
        setAppointmentTime(localTime.slice(15,25))
        setSelectedDate(date);
    };

   const handleSubmit = () => {
       console.log(phone,name,appointmentDate,appointmentTime,value)
       if(phone =="" || name == ""){
           alert("Please enter Phone, Name and Date")
       }
       else{
      firestore.collection('leads').doc(Date.now().toString()).set({
          name: name ,
          phone: phone,
          age: age,
          appointmentDate: appointmentDate,
          appointmentTime: time + " " + ampm,
          emergency:value,
          email:email,
          notes:notes,
          timestamp: Date.now()
          },{merge: true})

      setTimeout(function(){ handleClickSnackbar() }, 300);
      handleClickSnackbar()
   }
  }

  const handleClickSnackbar = () => {
    setOpenSnackBar(true);
  };

    const handleChange = (event) => {
      setValue(event.target.value);
    };

    const K_HEIGHT = 40;
    const K_WIDTH = 40;
  const greatPlaceStyle = {
      // initially any map object has left top corner at lat lng coordinates
      // it's on you to set object origin to 0,0 coordinates
      position: 'absolute',
      width: K_WIDTH,
      height: K_HEIGHT,
      left: -K_WIDTH / 2,
      top: -K_HEIGHT / 2,
      objectFit:'contain',
      border: '1px solid #f44336',
      borderRadius: K_HEIGHT,
      backgroundColor: 'white',
      textAlign: 'center',
      color: '#3f51b5',
      fontSize: 16,
      fontWeight: 'bold',
      padding: 4
    };


    return (
        <div>
        <div className={classes.root}>
        <MainNavBar />
            <div>
            <Grid container className={classes.gridcontainer}>
                <Grid item xs={12} sm={8} className={classes.grid1}>
                <img src={BlogImage2} className={classes.bannerImage} />
                   <Typography className={classes.miantitle}>Does Diabetes Affect Teeth - Diabetes & Teeth Problems</Typography>
                   <Typography className={classes.miandesc}>
                   Diabetes is the most serious problem that affects the whole body in one or another way Including oral health. People with diabetes have a higher risk of getting oral health problems. Diabetes can cause gum infection, cavities, and many more.
                    The connection between diabetes and oral health is high blood sugar.
                    If blood sugar is not controlled properly oral health is affected because it affects the blood supply and the count of white blood cells that fights bacteria.
                    Dental problems and age go hand in hand whether you have diabetes or not.
                   </Typography>
                   <ol>
                       <li><Typography className={classes.miandesc}>Dry Mouth: Decrease of saliva due to uncontrolled diabetes also leads to ulcers and infections.</Typography></li>
                       <li><Typography className={classes.miandesc}>Gum Inflammation and infections</Typography></li>
                       <li><Typography className={classes.miandesc}>Burning Mouth Syndrome is a combination of both dry mouth and infection</Typography></li>
                       <li><Typography className={classes.miandesc}>Slow healing of the tissues due to age factor: Most people above the age of 50 have the risk of more infection and slow healing. This means it’ll take longer to heal any gum infection or cavities. And it might get worse faster. There can also be an issue after dental surgery.</Typography></li>
                   </ol>
                   <Typography className={classes.miandesc}>
                   Also, people with diabetes and smoking habits are at a higher risk of developing periodontal disease. The risk of a smoker is 20 times more than the non-smoker developing oral disease.
                   </Typography>
                   <Typography className={classes.miandesc}>
                   At Hi Dental our dentists recommend preventing these problems at the beginning itself as diabetic patients must:
                  </Typography>
                  <ol>
                       <li><Typography className={classes.miandesc}>Keep their blood sugar in check</Typography></li>
                       <li><Typography className={classes.miandesc}>Brushing and flossing your teeth twice and with a soft bristles brush</Typography></li>
                       <li><Typography className={classes.miandesc}>Quit smoking if you are doing it</Typography></li>
                       <li><Typography className={classes.miandesc}>Visit your dentist every 6 months for a regular cleanup of gums, teeth, and checkups.</Typography></li>
                       <li><Typography className={classes.miandesc}>Always share your blood level sugars with your dentist and tell them what all medication you take and how regularly</Typography></li>
                       <li><Typography className={classes.miandesc}>if you wear dentures, remove them and clean them daily. Hi Dental Care, always want their patients to smile and are available for them in an emergency too. To keep their dazzling smile faces on-point ad hassle-free.</Typography></li>

                   </ol>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.grid2} >
                <Paper className={classes.formContainer}>
                <div className={classes.formheader}>
                    Contact Us
                </div>
                <div  className={classes.form}>
                <TextField
                    autoFocus
                    id="name"
                    variant="outlined"
                    fullWidth
                    className={classes.textfeild}
                    placeholder="Enter Name"
                    onChange = {(e)=>setName(e.target.value)}
                    classes={{notchedOutline:classes.input}}
                    // disableUnderline={false}
                    InputProps={{
                        classes: {
                        root: classes.textfiledroot,
                        // disabled: classes.disabled
                        }
                    }}
                />
                <TextField

                    id="name"
                    variant="outlined"
                    fullWidth

                    className={classes.textfeild}
                    onChange = {(e)=>setPhone(e.target.value)}
                    placeholder="Enter PhoneNumber"
                    classes={{notchedOutline:classes.input}}
                    disableUnderline={false}
                    InputProps={{
                        classes: {
                        root: classes.textfiledroot,
                        // disabled: classes.disabled
                        }
                    }}
                />
                <TextField
                id="name"
                variant="outlined"
                fullWidth
                className={classes.textfeild}
                onChange = {(e)=>setAge(e.target.value)}
                placeholder="Enter Your Age"
                classes={{notchedOutline:classes.input}}
                // disableUnderline={false}
                InputProps={{
                    classes: {
                        root: classes.textfiledroot,
                        // disabled: classes.disabled
                    }
                }}
                    />
                <TextField
                id="name"
                variant="outlined"
                fullWidth
                className={classes.textfeild}
                onChange = {(e)=>setEmail(e.target.value)}
                placeholder="Enter Your Email"
                classes={{notchedOutline:classes.input}}
                // disableUnderline={false}
                InputProps={{
                    classes: {
                        root: classes.textfiledroot,
                        // disabled: classes.disabled
                    }
                }}
                    />
                    <TextField
                id="name"
                variant="outlined"
                fullWidth
                className={classes.textfeild}
                onChange = {(e)=>setNotes(e.target.value)}
                placeholder="Enter Descriptons/Notes"
                classes={{notchedOutline:classes.input}}
                // disableUnderline={false}
                InputProps={{
                    classes: {
                        root: classes.textfiledroot,
                        // disabled: classes.disabled
                    }
                }}
                    />
            <Grid container>
                <Typography className={classes.dialogtitle}>Select a date for appointment</Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around" style={{height:'70px'}}>
                        <Grid item xs={12} sm={6}>
                        <KeyboardDatePicker
                            minDate={new Date()}
                        margin="normal"
                        id="date-picker-dialog"
                        label="Pick a Date"
                        format="MM/dd/yyyy"
                        value={selectedDate}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <div style={{margin: 40,display:'flex'}}>
                            <select
                                style={{padding: 5, fontSize: 16, marginRight: 5,}}
                                value={time}
                                onChange={(e) => settime(e.target.value)}
                            >
                                <option value="8">8:00</option>
                                <option value="9">9:00</option>
                                <option value="10">10:00</option>
                                <option value="11">11:00</option>
                                <option value="12">12:00</option>
                                <option value="1">1:00</option>
                                <option value="2">2:00</option>
                                <option value="3">3:00</option>
                                <option value="4">4:00</option>
                                <option value="5">5:00</option>
                                <option value="6">6:00</option>
                                <option value="7">7:00</option>
                            </select>
                            <select
                                style={{padding: 5, fontSize: 16, marginRight: 5}}
                                value={ampm}
                                onChange={(e) => setampm(e.target.value)}
                            >
                                <option value="AM">AM</option>
                                <option value="PM">PM</option>
                            </select>
                        </div>
                        </Grid>
                    </Grid>
                    </MuiPickersUtilsProvider>
            </Grid>
            <FormControl component="fieldset">
                <RadioGroup aria-label="gender"  name="gender1" value={value} onChange={handleChange}>
                    <div style={{display:'flex',justifyContent:'space-between',paddingTop:10}}>
                    <Typography className={classes.dialogtitle} style={{paddingRight:'20px'}}>Emergency ?</Typography>
                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="No" control={<Radio />} label="No" />
                    </div>
                </RadioGroup>
                    </FormControl>
                    </div>

                    <div className={classes.dialogbuttoncontainerform}>
                    <Button variant="contained" className={classes.submitbutton} onClick={handleSubmit}>Submit</Button>
                    </div>
                    </Paper>
                </Grid>

            </Grid>
        <MainFooter id="contactus"/>
        </div>

        </div>

        </div>
    )
}
