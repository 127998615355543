import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {firestore} from "../firebase";

const Review = (props)  => {
    const [state, setState] = useState({ name: '', intrested: '', phone: ''});

    useEffect(() => {
        const { steps } = props;
        const { name, intrested, phone } = steps;
        setState({ name, intrested, phone });
        console.log('values are here == ', name.value, intrested.value, phone.value)
        firestore.collection('leads').doc(Date.now().toString()).set({
            name: name.value ,
            phone: phone.value,
            email: 'through chatbot',
            type:intrested.value,
            city: 'through chatbot',
            timestamp: Date.now()
        },{merge: true})
    }, [])

    const { name, gender, age } = state;
    return (
        <div style={{ width: '100%' }}>
            <p>Thanks! Your data was submitted successfully. Our executive will contact you shortly!</p>
        </div>
    );
}

Review.propTypes = {
    steps: PropTypes.object,
};

Review.defaultProps = {
    steps: undefined,
};

export default Review;
