import React, {useRef, useState,useEffect} from 'react'
import { Navbar, Nav , Carousel,Row, Col } from 'react-bootstrap'
import { makeStyles } from '@material-ui/core/styles';
import Logo from "../public/assets/images/newlogo.png"
import Service1 from "../public/assets/serviceIamgesUpdated/image14.jpg"
import Service2 from "../public/assets/serviceImages/image21.jpg"
import Service3 from "../public/assets/serviceImages/image1.jpg"
import Service4 from "../public/assets/serviceIamgesUpdated/image1.jpg"
import Service5 from "../public/assets/serviceImages/image17.jpg"
import Service6 from "../public/assets/serviceImages/image21.jpg"
import Service7 from "../public/assets/serviceImages/image15.png"
import Service8 from "../public/assets/serviceImages/image24.jpg"
import Service9 from "../public/assets/serviceImages/image16.jpg"
import Service10 from "../public/assets/serviceImages/image123.jpg"
import Service11 from "../public/assets/serviceImages/image5.png"
import Service12 from "../public/assets/serviceImages/image25.jpg"
import Service13 from "../public/assets/serviceIamgesUpdated/image4.jpg"
import Service14 from "../public/assets/serviceImages/image3.jpg"
import Service15 from "../public/assets/serviceImages/image44.jpg"
import Service16 from "../public/assets/serviceImages/image49.jpg"
import LogoSmile from "../public/assets/serviceImages/servicesmile.png"
import { Button , Container ,Typography,Grid,Paper}from '@material-ui/core';
import MainFooter from "../components/footer";
import MainNavBar from "../components/navBar"
import Banner from "../public/assets/serviceImages/banner1.png"
import { Link } from 'react-router-dom'
import {firestore} from "../firebase"
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import CallIcon from "@material-ui/icons/Call";
import "aos/dist/aos.css"
import AOS from 'aos';
import {primaryColor} from "../constants";


const useStyles = makeStyles((theme) => ({
    root: {
      flex:1,
    },
    navbar:{
        backgroundColor:'#fff'
    },
    navbar2:{
        backgroundColor:'#fff',
        marginTop: 75
    },
    logoimage:{
        width:200,
        height:60,
        objectFit:'contain'
    },
    navlist2:{
        fontSize:16,
        marginRight: 30,
        fontFamily: 'Poppins',
        fontWeight:600,
        color: 'white',
        [theme.breakpoints.only('xs')]: {
            fontSize: 10
        },
    },
    headetitle:{
        fontSize:30,
        marginLeft:30,
        fontFamily: 'Poppins',
    },
    bookappointment:{
        backgroundColor:'#DE1C49',
        color:'#fff',
        fontFamily: 'Poppins',
        margin:'0px 40px',
        "&:hover": {
            backgroundColor: "#DE1C49"
        }
    },
    bookappointment2:{
        backgroundColor:'#DE1C49',
        color:'#fff',
        fontFamily: 'Poppins',
        margin:'0px 40px',
        [theme.breakpoints.only('xs')]: {
            display: 'none'
        },
        "&:hover": {
            backgroundColor: "#DE1C49"
        }
    },
    navlist:{
        fontSize:20,
        fontWeight:600,
        fontFamily: 'Poppins',
    },
    headerbanner:{
        marginTop:"0px",
        [theme.breakpoints.only('xs')]: {
            marginTop:"0px",
            display: "none",
        },
    },
    headerbannerMobile:{
        marginTop:"0px",
        display: "none",
        [theme.breakpoints.only('xs')]: {
            marginTop:"0px",
            display: "block",
        },
    },
    banner:{
        width:'100%',
        fontFamily: 'Poppins',
    },
    bannerImage:{
        width:'100%',
        height:400,
        objectFit:'cover',
        fontFamily: 'Poppins',
        marginTop:110,
        [theme.breakpoints.only('xs')]: {
            height:'100%',
            objectFit:'contain',
        },
    },
    header:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        padding:"30px 100px",
        fontFamily: 'Poppins',
        [theme.breakpoints.only('xs')]: {
            padding:"10px 6px",

        },
    },
    headertitle:{
        fontSize:35,
        fontWeight:'600',
        fontFamily: 'Poppins',
    },

    headertitle2:{
        fontSize:24,
        fontWeight:'600',
        textAlign:'left',
        padding: 10,
        fontFamily: 'Poppins',
        [theme.breakpoints.only('xs')]: {
            padding: 6,
        },
    },
    headertitle3:{
        fontSize:20,
        fontWeight:'600',
        textAlign:'center',
        padding: 10,
        fontFamily: 'Poppins',
        // backgroundColor: "#efefef",
        borderRadius: 8,
        cursor:'pointer',
        "&:hover":{
            textDecoration:'none'
        }

    },
    text:{
        fontSize:16,
        fontWeight:500,
        textAlign:'justify',
        fontFamily: 'Poppins',
    },
    card:{
        width:'100%',
        height:300,
        marginTop:20,
        objectFit: 'cover',
        borderRadius:8,
        backgroundColor:'#cdcdcd',
        fontFamily: 'Poppins',
        [theme.breakpoints.only('xs')]: {
            height:150,
        },
    },
    dialogtitle:{
        fontSize:18,
        fontWeight:600,
        padding:'15px 0px',
        fontFamily: 'Poppins',
    },
    dialogtitle2:{
        fontSize:18,
        fontFamily: 'Poppins',
        fontWeight:600,
        padding:'10px 0px',
        textAlign: 'center',
        color: '#DE1C49'
    },
    textfeild:{
        border:'none',
        fontFamily: 'Poppins',
        backgroundColor:'#FFF8F2',
        margin:'5px 0px'
    },
    textfiledroot: {
        '& $disabled': {
           borderColor: 'orange'
        }
     },
     disabled: {},

     dialogbuttoncontainer:{
         display:'flex',
         flexDirection:'row',
         fontFamily: 'Poppins',
         justifyContent:'center',
         margin:'20px 0px'
     },
    gridnames:{
        marginTop: 30,
        marginBottom: 30,
        [theme.breakpoints.only('xs')]: {
            margin: 0,
        },
    },
    logo:{
        height:350,
        marginTop:160,
        marginLeft:50,
        objectFit:'contain',
        [theme.breakpoints.only('xs')]: {
          height:100
        },
    },
    listtileheader:{
        width:'100px',
        color:"#000",
        marginTop:130,
        fontSize:30,
        fontFamily:'Poppins',
        marginLeft:"10%",
        fontWeight:700,
        [theme.breakpoints.only('xs')]: {
            fontSize:12,
            marginLeft:"5px",
        },
    },

    listtitlecontainer:{
        color:"#000",
        top:200,
        marginLeft:"13%",
        marginTop:20,
        [theme.breakpoints.only('xs')]: {

        },

    },
    listtitle:{
        fontSize:16,
        fontFamily:'Poppins',
        fontWeight:700,
        marginTop:10,
        cursor:'pointer',
        textAlign:'left',
        // paddingLeft:'12%',
        [theme.breakpoints.only('xs')]: {
            fontSize:8,
        },
        "&:hover":{
            color:primaryColor
        }


    },
    rightrow:{
        display:'flex',
        [theme.breakpoints.only('xs')]: {
            flexDirection:'column-reverse'
        }
    },


}))
export default function Pediatric() {
    const classes = useStyles();
    const [index, setIndex] = useState(0);
    const [open, setOpen] = React.useState(false);
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [value, setValue] = React.useState('No');
    const [appointmentDate,setAppointmentDate] = useState("")
    const [appointmentTime,setAppointmentTime] = useState("")
    const [name,setName] = useState("")
    const [phone,setPhone] = useState("")
    const [ampm,setampm] = useState("AM")
    const [time,settime] = useState("9")
    const [notes,setNotes] = useState("")
    const [email,setEmail] = useState("")
    const [age,setAge] = useState("")
    const [openSnackbar, setOpenSnackBar] = React.useState(false);


    useEffect(() => {
        AOS.init({
          duration : 2000
        });
        window.scrollTo(0, 0);
      }, []);

    const handleClickOpen = () => {
        setOpen(true);
      };

      const handleClose = () => {
        setOpen(false);
      };

    const handleClickSnackbar = () => {
        setOpenSnackBar(true);
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackBar(false);
    };



      const handleDateChange = (date) => {
          console.log(date)
          var localTime = date.toString();
          setAppointmentDate(localTime.slice(0,15))
          console.log(localTime.slice(15,25))
          setAppointmentTime(localTime.slice(15,25))
          setSelectedDate(date);
      };

    const handleSubmit = () => {
        console.log(phone,name,appointmentDate,appointmentTime,value)
        if(phone =="" || name == ""){
            alert("Please enter Phone, Name and Date")
        }
        else{
            firestore.collection('leads').doc(Date.now().toString()).set({
                name: name ,
                phone: phone,
                age: age,
                appointmentDate: appointmentDate,
                appointmentTime: time + " " + ampm,
                emergency:value,
                email:email,
                notes:notes,
                timestamp: Date.now()
            },{merge: true})

            setTimeout(function(){ handleClickSnackbar() }, 300);
            handleClose()
            handleClickSnackbar()
        }
    }

      const handleChange = (event) => {
        setValue(event.target.value);
      };

    const PediatricRef = useRef(null)
    const OralRef = useRef(null)
    const TeethRef = useRef(null)
    const ToothColoredRef = useRef(null)
    const TMDTherapy = useRef(null)
    const RootPlanning = useRef(null)
    const NightGuard = useRef(null)

    const executeScroll = (ref) => ref.current.scrollIntoView({behavior: 'smooth',block: 'start', inline: 'start'})

    return (
        <div className={classes.root}>

            {/* <Navbar collapseOnSelect expand="lg" variant="light" fixed="top" style={{backgroundColor:'#fff'}} className={classes.navbar}>
                <img
                    className={classes.logoimage}
                    src={Logo}
                    alt="React Bootstrap logo"
                />
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ml-auto  navcontainermedia">
                        <Nav.Link href="/" className={classes.navt}>Home</Nav.Link>
                        <Nav.Link href="/#aboutus" className={classes.navlist}>About Us</Nav.Link>
                        <Nav.Link href="/#teams" className={classes.navlist}>Team</Nav.Link>
                        <Nav.Link href="/#services" className={classes.navlist}>Our Services</Nav.Link>
                        <Nav.Link href="/#contactus" className={classes.navlist}>Contact us</Nav.Link>
                        <Button variant="contained" className={classes.bookappointment} onClick={handleClickOpen}>Book Appointment</Button>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <Navbar fixed="top" collapseOnSelect variant="light" style={{backgroundColor: '#DE1C49'}} className={[classes.navbar2, {color:'#DE1C49'}]}>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ml-auto">
                        <Nav.Link href="#" style={{color:'#ffffff'}} className={classes.navlist2}>CALL NOW +1 713-451-3333</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar> */}
            <MainNavBar />


        <Grid container className={classes.headerbanner}>
            <Grid xs={8}>
            <img src={Banner} className={classes.bannerImage} />
            </Grid>
            <Grid xs={4}>
                <div >
                <Typography className={classes.listtileheader}>General Dentistry</Typography>
                {/* <img src={LogoSmile} class="service-header-smile" className={classes.logo} /> */}
                <div className={classes.listtitlecontainer}>
                    <ul>
                        <li><Typography className={classes.listtitle} onClick={()=> executeScroll(PediatricRef)} >Kids Dentistry</Typography></li>
                        <li><Typography className={classes.listtitle} onClick={()=> executeScroll(OralRef)}>Oral Exams</Typography></li>
                        <li><Typography className={classes.listtitle} onClick={()=> executeScroll(TeethRef)}>Teeth Whitening</Typography></li>
                        <li><Typography className={classes.listtitle} onClick={()=> executeScroll(ToothColoredRef)}>Tooth-Colored Fillings</Typography></li>
                        <li><Typography className={classes.listtitle} onClick={()=> executeScroll(RootPlanning)}>Scaling and Root Planing</Typography></li>
                        <li><Typography className={classes.listtitle} onClick={()=> executeScroll(TMDTherapy)}>TMJ/TMDTherapy</Typography></li>
                        <li><Typography className={classes.listtitle} onClick={()=> executeScroll(NightGuard)}>Night Guards</Typography></li>
                    </ul>
                </div>
                </div>
            </Grid>
        </Grid>

            <Grid container className={classes.headerbannerMobile}>
            <Grid xs={12}>
            <img src={Banner} className={classes.bannerImage} />
            </Grid>
        </Grid>

        {/* <div className={classes.banner}>
            <img src={Banner} className={classes.bannerImage} />
        </div> */}

        <div className={classes.header}>
            <Typography className={classes.headertitle}>General Dentistry</Typography>
            <Button variant="contained" className={classes.bookappointment2} onClick={handleClickOpen}>Book Appointment</Button>
        </div>
            <Container>
            <Typography className={classes.text}>
                General dentists administer precautionary and remedial services, such as teeth cleanings, x-rays, and cavity fillings. Unlike professionals, who focus on one particular area of dentistry, general dentists offer an ample range of surgeries and procedures. They treat patients of all ages.
                When visiting a general dentist, whether you are going for a routine dental checkup or a minor oral surgery, there are several options to choose from.
                Services provided by general dentists cover restorative services, preventive treatments, oral surgery, and cosmetic dentistry.
            </Typography>
            </Container>
        <Container>

            <div style={{height: 50}} ref={PediatricRef}>

            </div>


            <Grid container spacing={4} data-aos="fade-up" data-aos-duration="500"  style={{marginTop: 20, marginBottom: 20,overflowX:'hidden', scrollMargin: "220px",}} data-aos="fade-up" data-aos-duration="500" >
                <Grid item xs={12} sm={4}>
                    <img className={classes.card} src={Service1}/>
                </Grid>
                <Grid item xs={12} sm={8}>
                <Typography data-aos="fade-up" data-aos-duration="500" className={classes.headertitle2} >Kids Dentistry</Typography>
                <Typography  data-aos="fade-up" data-aos-duration="500"  className={classes.text}>
                    Hi Dental, is a leading service provider of Pediatric or Kids Dentistry in Houston. We have a specialized team who attend to the dental problems of the youngsters (baby and teenager). Our experts study the kid's psychology and growth before starting the treatment. Children commonly face problems like cavities, malocclusion, crooked teeth, emergency care, etc. During the development phase of a toddler, a personal approach is required to guide the dental growth and expansion to avoid future dental problems. Hi Dental, Experts recommend that parents bring their kids to a pediatric dentist as soon as the first tooth erupts. Throughout these early appointments, our pediatric dentist focuses on preventative care and instructing parents on healthy oral hygiene, proper nutrition, and oral habits.
                    We recommend routine checkups for children as the most efficient way to maintain healthy teeth and gums to reduce the risk of cavities.  All these are crucial in setting the foundation for the endurance of healthy smiles.

                </Typography>
                </Grid>
            </Grid>

            {/*end*/}

            {/*start*/}

            <div style={{height: 50}} ref={OralRef}>

            </div>

            <Grid container spacing={4}  style={{marginTop: 20, marginBottom: 20,overflowX:'hidden'}} data-aos="fade-up" data-aos-duration="500" className={classes.rightrow} >
                <Grid item xs={12} sm={8}>
                <Typography data-aos="fade-up" data-aos-duration="500"  className={classes.headertitle2}>Oral Exams</Typography>
                <Typography className={classes.text} data-aos="fade-up" data-aos-duration="500" >
                    An oral exam refers to the physical health of your teeth and gums. Throughout this procedure, your dentist or dental hygienist will examine your mouth and look for ailments if there are any such as tooth decay, gum disease, and oral cancer. Because of Regular checkups, oral health problems are detected at an early stage when they are easily treated. A member of Hi Dental Clinic will take digital x-rays of your mouth. At Hi Dental our dental professional will go over your results and if any problems are found, your treatment options will be analyzed. Dentists, recommend patients visit them every six months for a routine oral exam. Children should have their first oral exam between the ages of 6 and 12 months, and every six months thereafter. This enables dentists to keep a check on patient's tooth alignment issues and refer young patients to an orthodontist for braces or Invisalign as needed.
                </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <img className={classes.card} src={Service4}/>
                </Grid>
            </Grid>
            {/*end*/}

            {/*start*/}


            <div style={{height: 50}} ref={TeethRef}>

            </div>
            <Grid container spacing={4} style={{marginTop: 20, marginBottom: 20,overflowX:'hidden'}} data-aos="fade-up" data-aos-duration="500" >
                <Grid item xs={12} sm={4}>
                    <img className={classes.card} src={Service13}/>
                </Grid>
                <Grid item xs={12} sm={8}>
                <Typography className={classes.headertitle2} data-aos="fade-up" data-aos-duration="500" >Teeth Whitening</Typography>
            <Typography className={classes.text} data-aos="fade-up" data-aos-duration="500" >
                Teeth whitening is a way to an attractive smile that could win the hearts of people on any occasion. But maximum people believe that teeth whitening is dangerous as it may reduce the strength and life of teeth.     Hi Dental, experts have, however, burst these parables and asserted that anyone can get their teeth whitened without settling on health and longevity. The Teeth whitening treatment at Hi Dental begins by removing plaque and tartar buildup on your teeth. Advanced techniques are used to eliminate tartar without hurting the tooth or causing discomfort to the patient. During your whole dental cleaning, we ensure that you will feel relaxed and comfortable.
            </Typography>
                </Grid>
            </Grid>
            {/*end*/}

            {/*start*/}

            <div style={{height: 50}} ref={ToothColoredRef}>

            </div>


            <Grid container spacing={4} style={{marginTop: 20, marginBottom: 20}} data-aos="fade-up" data-aos-duration="500" className={classes.rightrow}>
                <Grid item xs={12} sm={8}>
                <Typography className={classes.headertitle2} data-aos="fade-up" data-aos-duration="500" >Tooth-Colored Fillings</Typography>
            <Typography className={classes.text} data-aos="fade-up" data-aos-duration="500" >
                Hi Dental, is a leading service provider of Pediatric Dentistry in Houston. we have a specialized team who attend to the dental problems for the youngsters (baby and teenager). Our experts study the kid's psychology and growth before starting the treatment. Children commonly face problems like cavities, malocclusion, crooked teeth, emergency care, etc. During the development phase of a toddler, a personal approach is required to guide the dental growth and expansion so as to avoid future dental problems. Hi Dental, Experts recommend that parents bring their kids to a pediatric dentist as soon as the first tooth erupts. Throughout these early appointments, our pediatric dentist focuses on preventative care and instructing parents on healthy oral hygiene, proper nutrition, and oral habits.
                We recommend routine checkups for children as the most efficient way to maintain healthy teeth and gums and to reduce the risk of the cavity.  All these are crucial in setting the foundation for the endurance of healthy smiles.
            </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <img className={classes.card} src={Service8}/>
                </Grid>
            </Grid>
            {/*end*/}

            {/*start*/}

            <div style={{height: 50}} ref={RootPlanning}>

            </div>
            <Grid container spacing={4} style={{marginTop: 20, marginBottom: 20}} data-aos="fade-up" data-aos-duration="500" >
                <Grid item xs={12} sm={4}>
                    <img className={classes.card} src={Service11}/>
                </Grid>
                <Grid item xs={12} sm={8}>
                <Typography className={classes.headertitle2} data-aos="fade-up" data-aos-duration="500"  >Scaling and Root Planing</Typography>
            <Typography className={classes.text} data-aos="fade-up" data-aos-duration="500" >
                If you have indications of gum disease, a conventional cleaning maybe not that effective which is why Hi dental has advanced techniques to treat the earliest stage of gum disease (gingivitis) with scaling and root planing (SRP). Our purpose is to get advanced of dental conditions with regular checkups and dental cleanings, so this procedure is performed by one of our certified doctors to help eradicate gum disease by removing plaque and tartar that was built up under the gumline. This procedure cleans out the gum pockets, smoothes the tooth also prevents future buildup. If you are suffering from the beginning stages of gum disease, schedule a consultation at our Clinic. Regardless of the condition of your mouth and gums, Hi Dental will always help you achieve an attractive smile with constant, professional care.
            </Typography>

                </Grid>
            </Grid>
            {/*end*/}

            {/*start*/}
            <div style={{height: 50}} ref={TMDTherapy}>

            </div>


            <Grid container spacing={4} style={{marginTop: 20, marginBottom: 20}} data-aos="fade-up" data-aos-duration="500" className={classes.rightrow}>
                <Grid item xs={12} sm={8}>
                <Typography className={classes.headertitle2}data-aos="fade-up" data-aos-duration="500"  >TMJ/TMDTherapy</Typography>
            <Typography className={classes.text} data-aos="fade-up" data-aos-duration="500" >
                TMD (temporomandibular joint dysfunction)The temporomandibular joint (TMJ) is the place where your jaw joins to your skull to enable you to open and close your jaw easily. Sometimes the joint is hurt or damaged, leading to severe symptoms and complexities. If you are feeling pain or hearing noises when you move your jaw then you must visit your dentist or schedule a consultation with Hi Dental certified dental professionals to determine if you have TMD. To Diagnose TMD our expert will listen to your concerns carefully and will guide you and then perform physical examinations and digital x-rays of your lower jaw to accurately assess the movement of your jaw, look for signs of misalignment, teeth grinding, also look for the areas for tenderness and inflammation. Based on the x-rays and examination our experts will recommend treatment options.
            </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <img className={classes.card} src={Service16}/>
                </Grid>
            </Grid>
            {/*end*/}

            {/*start*/}
            <div style={{height: 50}} ref={NightGuard}>

            </div>

            <Grid container spacing={4} style={{marginTop: 20, marginBottom: 20}} data-aos="fade-up" data-aos-duration="500" >
                <Grid item xs={12} sm={4} >
                    <img className={classes.card} src={Service9}/>
                </Grid>
                <Grid item xs={12} sm={8}>
                <Typography className={classes.headertitle2}data-aos="fade-up" data-aos-duration="500" >Night Guards</Typography>
                <Typography className={classes.text} data-aos="fade-up" data-aos-duration="500" >
                    At Hi Dental we assure you that we are focused on protecting your teeth from decay. Teeth grinding (also known as bruxism) at night is a basic problem that causes damage to your teeth, gums, and jaw. While the most visible sign of damage is to the surface of the teeth. Custom night guards are made from high-quality materials and high technology to fit your mouth easily and prevent damage from teeth grinding and jaw clenching, supporting you to achieve a good night’s sleep. To diagnose bruxism and determine if you would be benefited from a nightguard or not, our team will perform a dental examination. At Hi dental expert will take impressions and digital x-rays of your mouth, so your custom night guard can be designed. We will provide you all the instructions on how to take care of your night guard, so it remains in a good position for a long time.
                </Typography>
                    </Grid>
                </Grid>
            {/*end*/}
            <Typography className={classes.headertitle2}>FAQs</Typography>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classes.heading}>Why should I visit the dentist every 6 months?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography style={{textAlign: 'left'}}>
                        Most people do not care or think about visiting a dentist until they feel pain or cavities in the mouth as many dental problems do not show any symptoms at an initial stage but in the advanced stage when it becomes severe. They feel that they would save some money out of it but it ends ups in costing more as people become careless. That is why it is necessary to visit a dentist every after 6 months so we can check your oral health and let that flawless smile recapitulate.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>How to prevent cavities?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography style={{textAlign: 'left'}}>
                        To prevent cavities one should always brush their teeth and spend 2 -3 minutes brushing and flossing and rinse their mouth after every meal. One must not forget to visit their dentists for a regular checkup and good oral health.
                    </Typography>
                </AccordionDetails>
            </Accordion><
            Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>Why is flossing important after brushing?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography style={{textAlign: 'left'}}>
                        Flossing is important after brushing because it gets rids of bacteria that are not gone by brushing. if you do not floss between the teeth you allow plaque to remain within the teeth. These microscopic bacteria hide between the tiny spaces of teeth.
                        Ask your dentist to show you the proper way to floss. You will notice the huge difference at the next cleaning appointment.
                    </Typography>
                </AccordionDetails>
            </Accordion>


        </Container>



            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={"sm"} maxWidth={'sm'}>
                {/* <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
                <DialogContent>

                    <Typography className={classes.dialogtitle2}>Book your first appointment for free!</Typography>

                    {/* <Typography className={classes.dialogtitle}>Name</Typography> */}
                    <TextField
                        autoFocus
                        id="name"
                        variant="outlined"
                        fullWidth
                        className={classes.textfeild}
                        placeholder="Enter Name"
                        onChange = {(e)=>setName(e.target.value)}
                        classes={{notchedOutline:classes.input}}
                        disableUnderline={false}
                        InputProps={{
                            classes: {
                                root: classes.textfiledroot,
                                disabled: classes.disabled
                            }
                        }}
                    />

                    {/* <Typography className={classes.dialogtitle}>PhoneNumber</Typography> */}
                    <TextField
                        id="name"
                        variant="outlined"
                        fullWidth
                        className={classes.textfeild}
                        onChange = {(e)=>setPhone(e.target.value)}
                        placeholder="Enter PhoneNumber"
                        classes={{notchedOutline:classes.input}}
                        disableUnderline={false}
                        InputProps={{
                            classes: {
                                root: classes.textfiledroot,
                                disabled: classes.disabled
                            }
                        }}
                    />
                    {/* <Typography className={classes.dialogtitle}>Age</Typography> */}

                    <TextField
                        id="name"
                        variant="outlined"
                        fullWidth
                        className={classes.textfeild}
                        onChange = {(e)=>setAge(e.target.value)}
                        placeholder="Enter Your Age"
                        classes={{notchedOutline:classes.input}}
                        disableUnderline={false}
                        InputProps={{
                            classes: {
                                root: classes.textfiledroot,
                                disabled: classes.disabled
                            }
                        }}
                    />
                    <TextField
                        id="name"
                        variant="outlined"
                        fullWidth
                        className={classes.textfeild}
                        onChange = {(e)=>setEmail(e.target.value)}
                        placeholder="Enter Your Email"
                        classes={{notchedOutline:classes.input}}
                        disableUnderline={false}
                        InputProps={{
                            classes: {
                                root: classes.textfiledroot,
                                disabled: classes.disabled
                            }
                        }}
                    />
                    <TextField
                        id="name"
                        variant="outlined"
                        fullWidth
                        className={classes.textfeild}
                        onChange = {(e)=>setNotes(e.target.value)}
                        placeholder="Enter Description/Notes"
                        classes={{notchedOutline:classes.input}}
                        disableUnderline={false}
                        InputProps={{
                            classes: {
                                root: classes.textfiledroot,
                                disabled: classes.disabled
                            }
                        }}
                    />

                    <Grid container>
                        <Typography className={classes.dialogtitle}>Select a date for appointment</Typography>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justify="space-around">
                                <KeyboardDatePicker
                                    minDate={new Date()}
                                    margin="normal"
                                    id="date-picker-dialog"
                                    label="Pick a Date"
                                    format="MM/dd/yyyy"
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                                <div style={{margin: 40}}>
                                    <select
                                        style={{padding: 5, fontSize: 16, marginRight: 5}}
                                        value={time}
                                        onChange={(e) => settime(e.target.value)}
                                    >
                                        <option value="8">8:00</option>
                                        <option value="9">9:00</option>
                                        <option value="10">10:00</option>
                                        <option value="11">11:00</option>
                                        <option value="12">12:00</option>
                                        <option value="1">1:00</option>
                                        <option value="2">2:00</option>
                                        <option value="3">3:00</option>
                                        <option value="4">4:00</option>
                                        <option value="5">5:00</option>
                                        <option value="6">6:00</option>
                                        <option value="7">7:00</option>
                                    </select>
                                    <select
                                        style={{padding: 5, fontSize: 16, marginRight: 5}}
                                        value={ampm}
                                        onChange={(e) => setampm(e.target.value)}
                                    >
                                        <option value="AM">AM</option>
                                        <option value="PM">PM</option>
                                    </select>
                                    {/*<p>{message}</p>*/}
                                </div>

                            </Grid>
                        </MuiPickersUtilsProvider>
                    </Grid>


                    <FormControl component="fieldset">
                        <RadioGroup aria-label="gender"  name="gender1" value={value} onChange={handleChange}>
                            <div style={{display:'flex',justifyContent:'space-between'}}>
                                <Typography className={classes.dialogtitle} style={{paddingRight:'20px'}}>Emergency ?</Typography>
                                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                <FormControlLabel value="No" control={<Radio />} label="No" />
                            </div>
                        </RadioGroup>
                    </FormControl>

                </DialogContent>
                <div className={classes.dialogbuttoncontainer}>
                    <Button variant="contained" className={classes.bookappointment} onClick={handleSubmit}>Submit</Button>
                    <Button variant="contained" className={classes.bookappointment} onClick={handleClose}>Close</Button>
                </div>
            </Dialog>

            <MainFooter id="contactus"/>

        </div>
    )
}
