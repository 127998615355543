import React, {useRef, useState,useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import MainFooter from "../components/footer";
import MainNavBar from '../components/navBar';
import { Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import BlogImage3 from "../public/assets/blogimages/blogimage3.png";
import BlogImage2 from "../public/assets/blogimages/blogimage2.png";
import BlogImage1 from "../public/assets/blogimages/blogimage1.png";
import BlogImage4 from "../public/assets/blogimages/blogimage4.png";
import BlogImage5 from "../public/assets/blogimages/blogimage5.png";
import BlogImage6 from "../public/assets/blogimages/blogimage6.png";
import BlogImage7 from "../public/assets/blogimages/blogimage7.png";
import BlogImage8 from "../public/assets/blogimages/blogimage8.png";
import BlogImage9 from "../public/assets/blogimages/blogimage9.png";
import BlogImage10 from "../public/assets/blogimages/blogimage10.png";
import BlogImage11 from "../public/assets/blogimages/BlogImage11.png";
import BlogImage12 from "../public/assets/blogimages/blogImage12.png";
import BlogImage13 from "../public/assets/blogimages/blogImage13.png";
import { useHistory } from 'react-router-dom'





const useStyles = makeStyles((theme) => ({
    root: {
      flex:1,
        scrollBehavior: 'smooth',
        overflowX:'hidden'
    },
    blogimage:{
        width:'100%',
        height: '250px',
        borderRadius: 20,
        objectFit: 'cover'
    },
    conatinerheader:{
        marginTop:110,
        backgroundColor:'#DE1C49',
        borderBottomLeftRadius:"50px",
        borderBottomRightRadius:"50px",
    },
    headertitle:{
        fontSize:80,
        fontFamily:'Cinzel',
        letterSpacing:2,
        color:'#fff'
    },
    blogcontainer:{
        padding:'20px',
        textAlign:'start',
        margin:'40px 10px'
    },
    blogcard:{
        padding:'10px',
        textAlign:'start',
        margin:'20px 0px',
        cursor:'pointer'
    },
    blogsubtitle:{
        color:'#DE1C49',
        fontSize:16,
        fontWeight:'500',
        textAlign:'start'
    },
    blogtitle:{
        fontSize:24,
        fontWeight:'900',
        textAlign:'start',
        height:'70px',
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    blognameanddate:{
        fontSize:14,
        paddingTop:10,
        color:'#8c8c8c',
        textAlign:'start'
    },
    blogdesc:{
        fontSize:18,
        fontWeight:400,
        textAlign:'start',
        padding:"15px 0px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        height:'100px',
        "&:after":{
            content:'...'
        }
    },
    blogreadmore:{
        textAlign:'start',
        fontSize:18,
        fontWeight:'600',
        textDecoration:'underline',
        cursor:'pointer',
        "&:hover":{
            color:'#DE1C49'
        }

    },
    seeall:{
        paddingTop:35,
        fontSize:20,
        color:'#000',
        "&:hover":{
            color:'#DE1C49'
        }
    },
    blogtag:{
        position: "absolute",
        marginTop: 20,
        background: "#DE1C49",
        padding: '5px 32px',
        borderRadius: '5px',
        color:'#fff',
        fontFamily: 'Poppins',
        fontStyle: 'italic'
    },

  }));


export default function AllBlogs() {
    const classes = useStyles();
    const history = useHistory();


    return (
        <div className={classes.root}>
            <MainNavBar />
            <div className={classes.conatinerheader}>
                <div className={classes.headertitle}>Blogs</div>
            </div>

            <Container>
            <Grid container className={classes.blogcontainer}>
            <Grid item xs={12} sm={4} className={classes.blogcard} onClick={()=>history.push("/Busting-6-Important-And-Basic-Dental-Myths")}>
            <Typography className={classes.blogtag}>Advice</Typography>
                <img src={BlogImage1} className={classes.blogimage}/>
                <Typography className={classes.blogtitle} style={{ wordWrap: "break-word" }} >Busting 6 Important And Basic Dental Myths</Typography>
                <div className={classes.divider}></div>
                <div className={classes.blognameanddate}>Author | 17th March 2021</div>
                {/*<div className={classes.blogdesc}style={{ wordWrap: "break-word" }} >There are lots of dental myths viral on the internet about oral health and these may seem to be true but they will give serious effects. It is hard to determine which information is true and which is not. So, here we break all the myths by dropping some facts about the dental topic. At Hi Dental, we care about your dazzling smile so, we want to make sure that you have your facts straight when it comes to taking care of your teeth!</div>*/}
               <Link to="/Busting-6-Important-And-Basic-Dental-Myths" style={{color:'#000'}}> <Typography className={classes.blogreadmore}>Read More</Typography></Link>
            </Grid>
            <Grid item xs={12} sm={4} className={classes.blogcard} onClick={()=>history.push("/Does-Diabetes-Affect-Teeth-Diabetes-Teeth-Problems")}>
                        <Typography className={classes.blogtag}>Insight</Typography>
                <img src={BlogImage2} className={classes.blogimage}/>
                <Typography className={classes.blogtitle}>Does Diabetes Affect Teeth - Diabetes & Teeth Problems</Typography>
                <div className={classes.divider}></div>
                <div className={classes.blognameanddate}>Author | 22th March 2021</div>
                {/*<div className={classes.blogdesc}>Diabetes is the most serious problem that affects the whole body in one or another way Including oral health. People with diabetes have a higher risk of getting oral health problems. Diabetes can cause gum infection, cavities, and many more.*/}
                {/*The connection between diabetes and oral health is high blood sugar.*/}
                {/*If blood sugar is not controlled properly oral health is affected because it affects the blood supply and the count of white blood cells that fights bacteria.*/}
                {/*</div>*/}
                <Link to="/Does-Diabetes-Affect-Teeth-Diabetes-Teeth-Problems" style={{color:'#000'}}> <Typography className={classes.blogreadmore}>Read More</Typography></Link>
            </Grid>
            <Grid item xs={12} sm={4} className={classes.blogcard} onClick={()=>history.push("/What-to-eat-and-avoid-after-dental-surgery-wisdom-tooth-extraction-or-implants")}>
                        <Typography className={classes.blogtag}>Insight</Typography>
                <img src={BlogImage3} className={classes.blogimage}/>
                <Typography className={classes.blogtitle}>What to eat and avoid after dental surgery, wisdom tooth extraction, or implants.</Typography>
                <div className={classes.divider}></div>
                <div className={classes.blognameanddate}>Author | 26th March 2021</div>
                <Link to="/What-to-eat-and-avoid-after-dental-surgery-wisdom-tooth-extraction-or-implants" style={{color:'#000'}}> <Typography className={classes.blogreadmore}>Read More</Typography></Link>
            </Grid>
            <Grid item xs={12} sm={4} className={classes.blogcard} onClick={()=>history.push("/Bleeding-gums-Home-remedies")}>
                        <Typography className={classes.blogtag}>Advice</Typography>
                <img src={BlogImage4} className={classes.blogimage}/>
                <Typography className={classes.blogtitle}>Bleeding gums: Home remedies</Typography>
                <div className={classes.divider}></div>
                <div className={classes.blognameanddate}>Author | 10th April 2021</div>
                <Link to="/Bleeding-gums-Home-remedies" style={{color:'#000'}}> <Typography className={classes.blogreadmore}>Read More</Typography></Link>
            </Grid>
            <Grid item xs={12} sm={4} className={classes.blogcard} onClick={()=>history.push("/Painless-Root-Canal-Treatment-is-it-safe-for-you-and-what-rare-complications-can-occur")}>
                        <Typography className={classes.blogtag}>Insight</Typography>
                <img src={BlogImage5} className={classes.blogimage}/>
                <Typography className={classes.blogtitle}>Painless Root Canal Treatment: is it safe for you and what rare complications can occur</Typography>
                <div className={classes.divider}></div>
                <div className={classes.blognameanddate}>Author | 5th May 2021</div>
                <Link to="/Painless-Root-Canal-Treatment-is-it-safe-for-you-and-what-rare-complications-can-occur" style={{color:'#000'}}> <Typography className={classes.blogreadmore}>Read More</Typography></Link>
            </Grid>
            <Grid item xs={12} sm={4} className={classes.blogcard} onClick={()=>history.push("/Dentist-and-Pregnancy-Does-Pregnancy-affect-oral-health")}>
                        <Typography className={classes.blogtag}>Advice</Typography>
                <img src={BlogImage6} className={classes.blogimage}/>
                <Typography className={classes.blogtitle}>Dentist and Pregnancy- Does Pregnancy affect oral health?</Typography>
                <div className={classes.divider}></div>
                <div className={classes.blognameanddate}>Author | 17th march 2021</div>
                <Link to="/Dentist-and-Pregnancy-Does-Pregnancy-affect-oral-health" style={{color:'#000'}}> <Typography className={classes.blogreadmore}>Read More</Typography></Link>
            </Grid>
            <Grid item xs={12} sm={4} className={classes.blogcard} onClick={()=>history.push("/Temporomandibular-Joint-Disorders")}>
                        <Typography className={classes.blogtag}>Insight</Typography>
                <img src={BlogImage7} className={classes.blogimage}/>
                <Typography className={classes.blogtitle}>Temporomandibular Joint Disorders (TMJ, TMD)</Typography>
                <div className={classes.divider}></div>
                <div className={classes.blognameanddate}>Author | 17th march 2021</div>
                <Link to="/Temporomandibular-Joint-Disorders" style={{color:'#000'}}> <Typography className={classes.blogreadmore}>Read More</Typography></Link>
            </Grid>
            <Grid item xs={12} sm={4} className={classes.blogcard} onClick={()=>history.push("/Main-Reasons-For-Tooth-Pain-Toothache-Causes")}>
                        <Typography className={classes.blogtag}>Tips</Typography>
                <img src={BlogImage8} className={classes.blogimage}/>
                <Typography className={classes.blogtitle}>Main Reasons For Tooth Pain & Toothache Causes</Typography>
                <div className={classes.divider}></div>
                <div className={classes.blognameanddate}>Author | 17th march 2021</div>
                <Link to="/Main-Reasons-For-Tooth-Pain-Toothache-Causes" style={{color:'#000'}}> <Typography className={classes.blogreadmore}>Read More</Typography></Link>
            </Grid>
            <Grid item xs={12} sm={4} className={classes.blogcard} onClick={()=>history.push("/How-To-Get-Through-With-Braces-Life-With-Braces")}>
                        <Typography className={classes.blogtag}>Insight</Typography>
                <img src={BlogImage9} className={classes.blogimage}/>
                <Typography className={classes.blogtitle}>How To Get Through With Braces- Life With Braces</Typography>
                <div className={classes.divider}></div>
                <div className={classes.blognameanddate}>Author | 17th march 2021</div>
                <Link to="/How-To-Get-Through-With-Braces-Life-With-Braces" style={{color:'#000'}}> <Typography className={classes.blogreadmore}>Read More</Typography></Link>
            </Grid>
            <Grid item xs={12} sm={4} className={classes.blogcard} onClick={()=>history.push("/Getting-Your-Perfect-Smile-Cosmetic-Dentist-Treatments")}>
                        <Typography className={classes.blogtag}>Insight</Typography>
                <img src={BlogImage10} className={classes.blogimage}/>
                <Typography className={classes.blogtitle}>Getting Your Perfect Smile- Cosmetic Dentist Treatments</Typography>
                <div className={classes.divider}></div>
                <div className={classes.blognameanddate}>Author | 17th march 2021</div>
                <Link to="/Getting-Your-Perfect-Smile-Cosmetic-Dentist-Treatments" style={{color:'#000'}}> <Typography className={classes.blogreadmore}>Read More</Typography></Link>
            </Grid>
            <Grid item xs={12} sm={4} className={classes.blogcard} onClick={()=>history.push("/What-Should-You-Eat-After-a-Root-Canal")}>
                        <Typography className={classes.blogtag}>Advice</Typography>
                <img src={BlogImage11} className={classes.blogimage}/>
                <Typography className={classes.blogtitle}>Getting Your Perfect Smile- Cosmetic Dentist Treatments</Typography>
                <div className={classes.divider}></div>
                <div className={classes.blognameanddate}>Author | 17th march 2021</div>
                <Link to="/What-Should-You-Eat-After-a-Root-Canal" style={{color:'#000'}}> <Typography className={classes.blogreadmore}>Read More</Typography></Link>
            </Grid>
            <Grid item xs={12} sm={4} className={classes.blogcard} onClick={()=>history.push("/All-About-Laser-Dentistry")}>
                        <Typography className={classes.blogtag}>Insight</Typography>
                <img src={BlogImage12} className={classes.blogimage}/>
                <Typography className={classes.blogtitle}>All About Laser Dentistry</Typography>
                <div className={classes.divider}></div>
                <div className={classes.blognameanddate}>Author | 17th march 2021</div>
                <Link to="/All-About-Laser-Dentistry" style={{color:'#000'}}> <Typography className={classes.blogreadmore}>Read More</Typography></Link>
            </Grid>
            <Grid item xs={12} sm={4} className={classes.blogcard} onClick={()=>history.push("/Facts-and-Tips-about-fighting-Tooth-Decay")}>
                        <Typography className={classes.blogtag}>Tips</Typography>
                <img src={BlogImage13} className={classes.blogimage}/>
                <Typography className={classes.blogtitle}>Facts and Tips about fighting Tooth Decay </Typography>
                <div className={classes.divider}></div>
                <div className={classes.blognameanddate}>Author | 17th march 2021</div>
                <Link to="/Facts-and-Tips-about-fighting-Tooth-Decay" style={{color:'#000'}}> <Typography className={classes.blogreadmore}>Read More</Typography></Link>
            </Grid>

        </Grid>
            </Container>



            <MainFooter />

        </div>
    )
}
