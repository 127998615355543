import React, {useRef, useState,useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Button , Container ,Typography,Grid,Paper,TextField}from '@material-ui/core';
import MainFooter from "../components/BlogFooter";
import "aos/dist/aos.css"
import AOS from 'aos';
import MainNavBar from '../components/navBar';
import {primaryColor} from "../constants";
import DateFnsUtils from '@date-io/date-fns';

import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
  import Radio from '@material-ui/core/Radio';
  import RadioGroup from '@material-ui/core/RadioGroup';
  import FormControlLabel from '@material-ui/core/FormControlLabel';
  import FormControl from '@material-ui/core/FormControl';
  import FormLabel from '@material-ui/core/FormLabel';
  import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
  import { Map, GoogleApiWrapper,InfoWindow, Marke } from 'google-maps-react';
  import { styles } from '@material-ui/pickers/views/Calendar/Calendar';
  import GoogleMapReact from 'google-map-react';
  import Snackbar from '@material-ui/core/Snackbar';
  import MuiAlert from '@material-ui/lab/Alert';
  import {firestore} from "../firebase"
  import BlogImage6 from "../public/assets/blogimages/blogimage6.png";



const useStyles = makeStyles((theme) => ({
    root: {
        flex:1,
    },
    gridcontainer:{
        marginTop:120,
        width:'100%'
    },
    grid1:{
        padding:'20px 30px'
    },
    grid2:{
        backgroundColor:'white',
        right:0,
        padding:'10px 20px',
        zIndex:0,
        [theme.breakpoints.only('xs')]: {
            display: 'none'
        },
    },
    form:{
        padding:10
    },
    formContainer:{
        borderRadius:20,
        position:'fixed',

    },
    formheader:{
        backgroundColor:'#DE1C49',
        padding:12,
        color:"#fff",
        fontSize:20,
        fontWeight:600,
        borderTopLeftRadius:16,
        borderTopRightRadius:16
    },
    textfeild:{
        border:'none',
        fontFamily: 'Poppins',
        "&, .MuiOutlinedInput-input":{
            padding:"10px 5px"
        }
    },
    textfiledroot: {
        '& $disabled': {
           borderColor: 'orange'
        }
     },
     disabled: {},
     dialogbuttoncontainerform:{
        display:'flex',
        flexDirection:'row',
        fontFamily: 'Poppins',
        justifyContent:'center',
        margin:'5px 0px',
        paddingBottom:20
    },
    miantitle:{
        fontSize:24,
        fontWeight:'800',
        padding:'30px 0px',
        textAlign:'start'
    },
    miandesc:{
        fontSize:18,
        fontWeight:'400',
        textAlign:'start'
    },
    submitbutton:{
        backgroundColor:'#DE1C49',
        color:'#fff',
        fontFamily: 'Poppins',
        fontWeight: "800",
        margin:'10px 10px',
        padding:"5px 30%",
        "&:hover": {
            backgroundColor: "#DE1C49"
        }
    },
    bannerImage:{
        width:'100%',
        height:300,
        objectFit:'cover',
        borderRadius:20
    }
}))
export default function BlogPage6() {
    const classes = useStyles();
    const [index, setIndex] = useState(0);
    const [open, setOpen] = React.useState(false);
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [value, setValue] = React.useState('No');
    const [appointmentDate,setAppointmentDate] = useState("")
    const [appointmentTime,setAppointmentTime] = useState("")
    const [name,setName] = useState("")
    const [phone,setPhone] = useState("")
    const [ampm,setampm] = useState("AM")
    const [time,settime] = useState("9")
    const [notes,setNotes] = useState("")
    const [email,setEmail] = useState("")
     const [age,setAge] = useState("")
    const [openSnackbar, setOpenSnackBar] = React.useState(false);
    const [bottom,setBottom] = useState(true)

    useEffect(() => {
        AOS.init({
            duration : 2000
        });
        window.scrollTo(0, 0);
    }, []);

    const handleDateChange = (date) => {
        console.log(date)
        var localTime = date.toString();
        setAppointmentDate(localTime.slice(0,15))
        console.log(localTime.slice(15,25))
        setAppointmentTime(localTime.slice(15,25))
        setSelectedDate(date);
    };

   const handleSubmit = () => {
       console.log(phone,name,appointmentDate,appointmentTime,value)
       if(phone =="" || name == ""){
           alert("Please enter Phone, Name and Date")
       }
       else{
      firestore.collection('leads').doc(Date.now().toString()).set({
          name: name ,
          phone: phone,
          age: age,
          appointmentDate: appointmentDate,
          appointmentTime: time + " " + ampm,
          emergency:value,
          email:email,
          notes:notes,
          timestamp: Date.now()
          },{merge: true})

      setTimeout(function(){ handleClickSnackbar() }, 300);
      handleClickSnackbar()
   }
  }

  const handleClickSnackbar = () => {
    setOpenSnackBar(true);
  };

    const handleChange = (event) => {
      setValue(event.target.value);
    };

    const K_HEIGHT = 40;
    const K_WIDTH = 40;
  const greatPlaceStyle = {
      // initially any map object has left top corner at lat lng coordinates
      // it's on you to set object origin to 0,0 coordinates
      position: 'absolute',
      width: K_WIDTH,
      height: K_HEIGHT,
      left: -K_WIDTH / 2,
      top: -K_HEIGHT / 2,
      objectFit:'contain',
      border: '1px solid #f44336',
      borderRadius: K_HEIGHT,
      backgroundColor: 'white',
      textAlign: 'center',
      color: '#3f51b5',
      fontSize: 16,
      fontWeight: 'bold',
      padding: 4
    };


    return (
        <div>
        <div className={classes.root}>
        <MainNavBar />
            <div>
            <Grid container className={classes.gridcontainer}>
                <Grid item xs={12} sm={8} className={classes.grid1}>
                <img src={BlogImage6} className={classes.bannerImage} />
                   <Typography className={classes.miantitle}>Dentist and Pregnancy- Does Pregnancy affect oral health?</Typography>
                   <Typography className={classes.miandesc}>
                   Worried About Pregnancy Affecting your oral health, In Pregnancy, women might get periodontal (gum) disease and cavities. It is a crucial part of prenatal care, also poor oral health during pregnancy can affect poor health consequences for both mother and baby.
                Certain Dental problems during pregnancy can lead to premature birth.
                There are several changes in a woman's body during pregnancy mainly
                increased levels of certain hormones and due to these Hormonal changes, there is an increased risk of certain oral health problems. Eating habits change during this period as you do not eat certain food regularly which you eat in pregnancy this affects the dental health. Hi Dental Care, recommends pregnant women must Take regular care of their mouth, teeth, and gums which helps them to have a healthy pregnancy, a healthy baby, and healthy teeth.
                Symptoms are something you observe or feel yourself that others cannot see or feel, like having a sore throat or feeling dizzy.
                Some of the common problems and symptoms that can occur during pregnancy are:
                   </Typography>
                   <ul>
                       <li><Typography className={classes.miandesc}>Cavities </Typography></li>
                       <li><Typography className={classes.miandesc}>Gingivitis</Typography></li>
                       <li><Typography className={classes.miandesc}>Loose teeth</Typography></li>
                       <li><Typography className={classes.miandesc}>Periodontal disease</Typography></li>
                       <li><Typography className={classes.miandesc}>Tooth erosion</Typography></li>
                       <li><Typography className={classes.miandesc}>Bad breath</Typography></li>
                       <li><Typography className={classes.miandesc}>Mouth sores or lumps on the gums</Typography></li>
                       <li><Typography className={classes.miandesc}>New spaces between teeth</Typography></li>
                       <li><Typography className={classes.miandesc}>Red, swollen, tender, or shiny Gums; gums that bleed easily</Typography></li>
                   </ul>
                   <Typography className={classes.miandesc}>
                    When pregnant you are more likely to have cavities. You can pass the infection that causes cavities to your baby during pregnancy and after birth also. This can create problems for your baby’s roots later in life.
                    If you feel any kind of pain or swelling in your mouth, call your dentist right away. If you have an infection, you need quick treatment to help stop problems for you and your baby. Dental X-rays help in showing problems inside your teeth, gums, and around the mouth. Dental X-rays are reliable during pregnancy as very small amounts of radiation are used. At Hi Dental, our experts during this procedure take full care and responsibility. Firstly they will cover you with a special apron and collar to protect you and your baby from any radiation. Make sure that your dentist must know about your pregnancy or you are trying to get pregnant when going for a checkup.
                    </Typography>

                   <Typography className={classes.miandesc}>
                   "Hi Dental Care", makes sure that they provide the best quality and hassle-free services to their patients at affordable prices. Don't forget to book your first appointment as it is on us!!
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.grid2} >
                <Paper className={classes.formContainer}>
                <div className={classes.formheader}>
                    Contact Us
                </div>
                <div  className={classes.form}>
                <TextField
                    autoFocus
                    id="name"
                    variant="outlined"
                    fullWidth
                    className={classes.textfeild}
                    placeholder="Enter Name"
                    onChange = {(e)=>setName(e.target.value)}
                    classes={{notchedOutline:classes.input}}
                    // disableUnderline={false}
                    InputProps={{
                        classes: {
                        root: classes.textfiledroot,
                        // disabled: classes.disabled
                        }
                    }}
                />
                <TextField

                    id="name"
                    variant="outlined"
                    fullWidth

                    className={classes.textfeild}
                    onChange = {(e)=>setPhone(e.target.value)}
                    placeholder="Enter PhoneNumber"
                    classes={{notchedOutline:classes.input}}
                    disableUnderline={false}
                    InputProps={{
                        classes: {
                        root: classes.textfiledroot,
                        // disabled: classes.disabled
                        }
                    }}
                />
                <TextField
                id="name"
                variant="outlined"
                fullWidth
                className={classes.textfeild}
                onChange = {(e)=>setAge(e.target.value)}
                placeholder="Enter Your Age"
                classes={{notchedOutline:classes.input}}
                // disableUnderline={false}
                InputProps={{
                    classes: {
                        root: classes.textfiledroot,
                        // disabled: classes.disabled
                    }
                }}
                    />
                <TextField
                id="name"
                variant="outlined"
                fullWidth
                className={classes.textfeild}
                onChange = {(e)=>setEmail(e.target.value)}
                placeholder="Enter Your Email"
                classes={{notchedOutline:classes.input}}
                // disableUnderline={false}
                InputProps={{
                    classes: {
                        root: classes.textfiledroot,
                        // disabled: classes.disabled
                    }
                }}
                    />
                    <TextField
                id="name"
                variant="outlined"
                fullWidth
                className={classes.textfeild}
                onChange = {(e)=>setNotes(e.target.value)}
                placeholder="Enter Descriptons/Notes"
                classes={{notchedOutline:classes.input}}
                // disableUnderline={false}
                InputProps={{
                    classes: {
                        root: classes.textfiledroot,
                        // disabled: classes.disabled
                    }
                }}
                    />
            <Grid container>
                <Typography className={classes.dialogtitle}>Select a date for appointment</Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around" style={{height:'70px'}}>
                        <Grid item xs={12} sm={6}>
                        <KeyboardDatePicker
                            minDate={new Date()}
                        margin="normal"
                        id="date-picker-dialog"
                        label="Pick a Date"
                        format="MM/dd/yyyy"
                        value={selectedDate}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <div style={{margin: 40,display:'flex'}}>
                            <select
                                style={{padding: 5, fontSize: 16, marginRight: 5,}}
                                value={time}
                                onChange={(e) => settime(e.target.value)}
                            >
                                <option value="8">8:00</option>
                                <option value="9">9:00</option>
                                <option value="10">10:00</option>
                                <option value="11">11:00</option>
                                <option value="12">12:00</option>
                                <option value="1">1:00</option>
                                <option value="2">2:00</option>
                                <option value="3">3:00</option>
                                <option value="4">4:00</option>
                                <option value="5">5:00</option>
                                <option value="6">6:00</option>
                                <option value="7">7:00</option>
                            </select>
                            <select
                                style={{padding: 5, fontSize: 16, marginRight: 5}}
                                value={ampm}
                                onChange={(e) => setampm(e.target.value)}
                            >
                                <option value="AM">AM</option>
                                <option value="PM">PM</option>
                            </select>
                        </div>
                        </Grid>
                    </Grid>
                    </MuiPickersUtilsProvider>
            </Grid>
            <FormControl component="fieldset">
                <RadioGroup aria-label="gender"  name="gender1" value={value} onChange={handleChange}>
                    <div style={{display:'flex',justifyContent:'space-between',paddingTop:10}}>
                    <Typography className={classes.dialogtitle} style={{paddingRight:'20px'}}>Emergency ?</Typography>
                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="No" control={<Radio />} label="No" />
                    </div>
                </RadioGroup>
                    </FormControl>
                    </div>

                    <div className={classes.dialogbuttoncontainerform}>
                    <Button variant="contained" className={classes.submitbutton} onClick={handleSubmit}>Submit</Button>
                    </div>
                    </Paper>
                </Grid>

            </Grid>
        <MainFooter id="contactus"/>
        </div>

        </div>

        </div>
    )
}
