import React, {useRef, useState,useEffect} from 'react'
import { Navbar, Nav , Carousel,Row, Col } from 'react-bootstrap'
import { makeStyles } from '@material-ui/core/styles';
import Logo from "../public/assets/images/newlogo.png"
import { Button , Container ,Typography,Grid,Paper}from '@material-ui/core';
import MainFooter from "../components/footer";
import Banner from "../public/assets/serviceImages/banner1.png"
import { Link } from 'react-router-dom'
import {firestore} from "../firebase"
import { useLocation, useHistory } from 'react-router-dom'

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import "aos/dist/aos.css"
import AOS from 'aos';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import Offer1 from "../public/assets/OffersImages/offer1.png"
import Offer2 from "../public/assets/OffersImages/offer2.png"
import Offer3 from "../public/assets/OffersImages/offer3.png"
import Offer4 from "../public/assets/OffersImages/offer4.png"
import Offer5 from "../public/assets/OffersImages/offer5.png"
import Offer6 from "../public/assets/OffersImages/offer6.png"
import Offer7 from "../public/assets/OffersImages/offer7.png"
import Offer8 from "../public/assets/OffersImages/offer8.png"
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import MainNavBar from '../components/navBar';






const useStyles = makeStyles((theme) => ({
    root: {
      flex:1,
        scrollBehavior: 'smooth',
        overflow:'hidden',
    },
    navbar:{
        backgroundColor:'#fff',
        zIndex:1
    },
    navbar2:{
        backgroundColor:'#fff',
        marginTop: 75,
        zIndex:1
    },
    logoimage:{
        width:200,
        height:60,
        objectFit:'contain'
    },
    headetitle:{
        fontSize:30,
        marginLeft:30,
        fontFamily: 'Poppins',
        fontStyle:'italic',
        [theme.breakpoints.only('xs')]: {
            fontSize: 15
        },
    },
    bookappointment:{
        backgroundColor:'#DE1C49',
        color:'#fff',
        fontFamily: 'Poppins',
        padding: 12,
        fontWeight: "800",
        margin:'0px 20px',
        "&:hover": {
            backgroundColor: "#DE1C49"
        },
        [theme.breakpoints.only('xs')]: {
           marginTop:20
        },
    },
    submitbutton:{
        backgroundColor:'#DE1C49',
        color:'#fff',
        fontFamily: 'Poppins',
        fontWeight: "800",
        margin:'0px 20px',
        padding:"10px 30%",
        "&:hover": {
            backgroundColor: "#DE1C49"
        }
    },
    navlist:{
        fontSize:20,
        fontFamily: 'Poppins',
        fontWeight:600
    },
    navlist2:{
        fontSize:16,
        marginRight: 30,
        fontFamily: 'Poppins',
        fontWeight:600,
        color: 'white',
        [theme.breakpoints.only('xs')]: {
            fontSize: 10
        },
    },
    linkbutton:{
        "&:hover":{
            textDecoration:'none'
        }
    },
    conatinerheader:{
        marginTop:100,
        backgroundColor:'#DE1C49',
        borderBottomLeftRadius:"50px",
        borderBottomRightRadius:"50px",
    },
    headertitle:{
        fontSize:80,
        fontFamily:'Cinzel',
        letterSpacing:2,
        color:'#fff'
    },
    cardroot: {
        maxWidth: "100%",
        margin:'50px 0px',
        borderRadius: 30,
        overflowY: 'hidden',
        "&:hover": {
            background: 'transaparent',
          },
          height:300,
          "&,.MuiCardContent-root":{
              paddingBottom:0
          }
      },
      media: {
        height: 150,
      },
      cardprice:{
        textAlign:'center',
        fontSize:45,
        fontFamily:'Poppins',
        color:'#fff',
        fontStyle:'italic',
        paddingTop:30
      },
      onlytext:{
          fontSize:20,
          marginTop:20,
          position:'absolute',
          marginLeft:5
      },
      cardtitle:{
          fontSize:20,
          fontFamily:'Poppins',
          fontWeight:600,
          minHeight:50,
          textAlign:'center',
          width:'100%'
      },
      cardbutton:{
          backgroundColor:'#DE1C49',
          margin:"10px 0px",
          padding:"5px 50px",
          "&:hover":{
            backgroundColor:'#DE1C49',
        }
      },
      dialogtitle:{
        fontSize:18,
        fontFamily: 'Poppins',
        fontWeight:600,
        padding:'15px 0px'
    },
    dialogtitle3:{
        fontSize:18,
        fontFamily: 'Poppins',
        fontWeight:600,
        padding:'15px 0px',
        textAlign: 'left',
    },
    dialogtitle2:{
        fontSize:18,
        fontFamily: 'Poppins',
        fontWeight:600,
        padding:'10px 0px',
        textAlign: 'center',
        color: '#DE1C49'
    },
    dialogtitle3:{
        fontSize:13,
        fontFamily: 'Poppins',
        fontWeight:400,
        textAlign: 'center',
        color: '#DE1C49'
    },
    textfeild:{
        border:'none',
        fontFamily: 'Poppins',
        backgroundColor:'#FFF8F2',
        margin:'5px 0px'
    },
    textfiledroot: {
        '& $disabled': {
           borderColor: 'orange'
        }
     },
     disabled: {},

     dialogbuttoncontainer:{
         display:'flex',
         flexDirection:'row',
         fontFamily: 'Poppins',
         justifyContent:'center',
         margin:'20px 0px',
     },
     dialogbuttoncontainerform:{
        display:'flex',
        flexDirection:'row',
        fontFamily: 'Poppins',
        justifyContent:'center',
        margin:'20px 0px',
        paddingBottom:20
    },
    termsandconditions:{
    //    display:'flex',
    //    paddingLeft:20,
       fontSize:12,
       textAlign: 'center'
    }
  }));


export default function OfferPage() {
    const classes = useStyles();
    const [index, setIndex] = useState(0);
    const [open, setOpen] = React.useState(false);
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [value, setValue] = React.useState('No');
    const [appointmentDate,setAppointmentDate] = useState("")
    const [appointmentTime,setAppointmentTime] = useState("")
    const [name,setName] = useState("")
    const [phone,setPhone] = useState("")
    const [age,setAge] = useState("")
    const [ampm,setampm] = useState("AM")
    const [time,settime] = useState("9")
    const [offerType,setOfferType] = useState("")
    const [notes,setNotes] = useState("")
    const [email,setEmail] = useState("")
    const [openSnackbar, setOpenSnackBar] = React.useState(false);
    const history = useHistory();


    useEffect(() => {
        
        document.title = "Get Special Offers at Hi Dental Care, Houston, TX"
       
        AOS.init({
          duration : 2000
        });
        window.scrollTo(0, 0);
      }, []);


    const handleClickOpen = (type) => {
        history.push('./new-patient-form')
        // console.log(type)
        // setOpen(true);
        // setOfferType(type)
      };

      const handleClose = () => {
        setOpen(false);
      };

      const handleChange = (event) => {
        setValue(event.targe.value);
      };
      const handleDateChange = (date) => {
        console.log(date)
        var localTime = date.toString();
        setAppointmentDate(localTime.slice(0,15))
        console.log(localTime.slice(15,25))
        setAppointmentTime(localTime.slice(15,25))
        setSelectedDate(date);
    };


    const handleSubmit = () => {
        console.log(phone,name,appointmentDate,appointmentTime,value)
        if(phone =="" || name == ""){
            alert("Please enter Phone, Name and Date")
        }
        else{
       firestore.collection('leads').doc(Date.now().toString()).set({
           name: name ,
           phone: phone,
           age: age,
           appointmentDate: appointmentDate,
           appointmentTime: time + " " + ampm,
           emergency:value,
           email:email,
           notes:notes,
           timestamp: Date.now()
           },{merge: true})

       setTimeout(function(){ handleClickSnackbar() }, 300);
       handleClose()
       handleClickSnackbar()
    }
   }


   function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

const handleClickSnackbar = () => {
    setOpenSnackBar(true);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackBar(false);
  };


    return (
        <div className={classes.root}>

            <MainNavBar />

            <div className={classes.conatinerheader}>
                <div className={classes.headertitle}>Special Offers</div>
            </div>


            <Container>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={3}>

                    <Card className={classes.cardroot}>
                        <CardActionArea>
                            <CardMedia
                            className={classes.media}
                            image={Offer1}
                            >
                            <Typography className={classes.cardprice}>$0 <span className={classes.onlytext}>only</span></Typography>
                            </CardMedia>
                            <CardContent>
                            <Typography gutterBottom variant="h5" component="h2" className={classes.cardtitle}>
                                Free X-ray and Consultation
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                        <a href="/privacypolicy" className={classes.termsandconditions}>terms and conditions*</a>
                        <Button size="small" color="primary" variant="contained" className={classes.cardbutton} onClick={()=>handleClickOpen("Out-of-pocket cleaning & exam")}>
                                Save Now
                        </Button>
                        </Card>

                    </Grid>
                    <Grid item xs={12} sm={3}>
                    <Card className={classes.cardroot}>
                        <CardActionArea>
                            <CardMedia
                            className={classes.media}
                            image={Offer2}
                            >
                            <Typography className={classes.cardprice}>$129/month <span className={classes.onlytext}>only</span></Typography>
                            </CardMedia>
                            <CardContent>
                            <Typography gutterBottom variant="h5" component="h2" className={classes.cardtitle}>
                                Braces
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                        <a href="/privacypolicy" className={classes.termsandconditions}>terms and conditions*</a>
                        <Button size="small" color="primary" variant="contained" className={classes.cardbutton}  onClick={()=>handleClickOpen("Veeners")} >
                            Save Now
                            </Button>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                    <Card className={classes.cardroot}>
                        <CardActionArea>
                            <CardMedia
                            className={classes.media}
                            image={Offer3}
                            >
                            <Typography className={classes.cardprice}>$12445 <span className={classes.onlytext}>only</span></Typography>
                            </CardMedia>
                            <CardContent>
                            <Typography gutterBottom variant="h5" component="h2" className={classes.cardtitle}>
                                Full Jaw Implant Fixed Denture
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                        <a href="/privacypolicy" className={classes.termsandconditions}>terms and conditions*</a>

                        <Button size="small" color="primary" variant="contained" className={classes.cardbutton}  onClick={()=>handleClickOpen("Fixed implant & denture")}>
                            Save Now
                            </Button>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                    <Card className={classes.cardroot}>
                        <CardActionArea>
                            <CardMedia
                            className={classes.media}
                            image={Offer4}
                            >
                            <Typography className={classes.cardprice}>$875 <span className={classes.onlytext}>only</span></Typography>
                            </CardMedia>
                            <CardContent>
                            <Typography gutterBottom variant="h5" component="h2" className={classes.cardtitle}>
                                Single Implant
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                        <a href="/privacypolicy" className={classes.termsandconditions}>terms and conditions*</a>

                        <Button size="small" color="primary" variant="contained" className={classes.cardbutton}  onClick={()=>handleClickOpen("Kids under 12")}>
                            Save Now
                            </Button>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Card className={classes.cardroot}>
                        <CardActionArea>
                            <CardMedia
                            className={classes.media}
                            image={Offer5}
                            >
                            <CardContent>
                            <Typography className={classes.cardprice}>$1345 <span className={classes.onlytext}>only</span></Typography>
                            </CardContent>
                            </CardMedia>
                        </CardActionArea>
                        <CardActions>
                        <Typography gutterBottom variant="h5" component="h2" className={classes.cardtitle}>
                            Implant Snap on Denture
                        </Typography>
                        </CardActions>
                        <a href="/privacypolicy" className={classes.termsandconditions}>terms and conditions*</a>

                        <Button size="small" color="primary" variant="contained" className={classes.cardbutton}  onClick={()=>handleClickOpen("Cleaning, Exam & X-ray")}>
                            Save Now
                            </Button>
                        </Card>
                    </Grid>
                    {/*<Grid item xs={12} sm={3}>*/}
                    {/*    <Card className={classes.cardroot}>*/}
                    {/*    <CardActionArea>*/}
                    {/*        <CardMedia*/}
                    {/*        className={classes.media}*/}
                    {/*        image={Offer6}*/}
                    {/*        >*/}
                    {/*        <CardContent>*/}
                    {/*        <Typography className={classes.cardprice}>$149 <span className={classes.onlytext}>only</span></Typography>*/}
                    {/*        </CardContent>*/}
                    {/*        </CardMedia>*/}
                    {/*    </CardActionArea>*/}
                    {/*    <CardActions>*/}
                    {/*    <Typography gutterBottom variant="h5" component="h2" className={classes.cardtitle}>*/}
                    {/*    Braces*/}
                    {/*    </Typography>*/}
                    {/*    </CardActions>*/}
                    {/*    <a href="/privacypolicy" className={classes.termsandconditions}>terms and conditions*</a>*/}

                    {/*    <Button size="small" color="primary" variant="contained" className={classes.cardbutton}  onClick={()=>handleClickOpen("Braces")}>*/}
                    {/*        Save Now*/}
                    {/*    </Button>*/}
                    {/*    </Card>*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={12} sm={3}>*/}
                    {/*    <Card className={classes.cardroot}>*/}
                    {/*    <CardActionArea>*/}
                    {/*        <CardMedia*/}
                    {/*        className={classes.media}*/}
                    {/*        image={Offer7}*/}

                    {/*        >*/}
                    {/*        <CardContent>*/}
                    {/*        <Typography className={classes.cardprice}>$2999 <span className={classes.onlytext}>only</span></Typography>*/}
                    {/*        </CardContent>*/}
                    {/*        </CardMedia>*/}
                    {/*    </CardActionArea>*/}
                    {/*    <CardActions>*/}
                    {/*    <Typography gutterBottom variant="h5" component="h2" className={classes.cardtitle}>*/}
                    {/*    Single tooth implant & crown*/}
                    {/*    </Typography>*/}
                    {/*    </CardActions>*/}
                    {/*    <a href="/privacypolicy" className={classes.termsandconditions}>terms and conditions*</a>*/}

                    {/*    <Button size="small" color="primary" variant="contained" className={classes.cardbutton}  onClick={()=>handleClickOpen("Single tooth implant & crown")}>*/}
                    {/*        Save Now*/}
                    {/*    </Button>*/}
                    {/*    </Card>*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={12} sm={3}>*/}
                    {/*    <Card className={classes.cardroot}>*/}
                    {/*    <CardActionArea>*/}
                    {/*        <CardMedia*/}
                    {/*        className={classes.media}*/}
                    {/*        image={Offer8}*/}

                    {/*        >*/}
                    {/*        <CardContent>*/}
                    {/*        <Typography className={classes.cardprice}>$25 <span className={classes.onlytext}>only</span></Typography>*/}
                    {/*        </CardContent>*/}
                    {/*        </CardMedia>*/}
                    {/*    </CardActionArea>*/}
                    {/*    <CardActions>*/}
                    {/*    <Typography gutterBottom variant="h5" component="h2" className={classes.cardtitle}>*/}
                    {/*    Focused problem, Emergency Exam & X-ray*/}
                    {/*    </Typography>*/}
                    {/*    </CardActions>*/}
                    {/*    <a href="/privacypolicy" className={classes.termsandconditions}>terms and conditions*</a>*/}

                    {/*    <Button size="small" color="primary" variant="contained" className={classes.cardbutton}  onClick={()=>handleClickOpen("Focused problem, Emergency Exam & X-ray")}>*/}
                    {/*        Save Now*/}

                    {/*    </Button>*/}
                    {/*    </Card>*/}
                    {/*</Grid>*/}
                </Grid>
                <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackBar}>
                <Alert onClose={handleCloseSnackBar} severity="success">
                    SuccessFully Submited!!
                </Alert>
            </Snackbar>
            </Container>



            <MainFooter />

            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={"sm"} maxWidth={'sm'}>
        {/* <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
        <DialogContent>

            <Typography className={classes.dialogtitle2}>Book your first appointment for free!</Typography>

            {/* <Typography className={classes.dialogtitle}>Name</Typography> */}
                <TextField
                    autoFocus
                    id="name"
                    variant="outlined"
                    fullWidth
                    className={classes.textfeild}
                    placeholder="Enter Name"
                    onChange = {(e)=>setName(e.target.value)}
                    classes={{notchedOutline:classes.input}}
                    disableUnderline={false}
                    InputProps={{
                        classes: {
                           root: classes.textfiledroot,
                           disabled: classes.disabled
                        }
                     }}
                />

                {/* <Typography className={classes.dialogtitle}>PhoneNumber</Typography> */}
                <TextField

                    id="name"
                    variant="outlined"
                    fullWidth
                    className={classes.textfeild}
                    onChange = {(e)=>setPhone(e.target.value)}
                    placeholder="Enter PhoneNumber"
                    classes={{notchedOutline:classes.input}}
                    disableUnderline={false}
                    InputProps={{
                        classes: {
                           root: classes.textfiledroot,
                           disabled: classes.disabled
                        }
                     }}
                />
            {/* <Typography className={classes.dialogtitle}>Age</Typography> */}

            <TextField
                id="name"
                variant="outlined"
                fullWidth
                className={classes.textfeild}
                onChange = {(e)=>setAge(e.target.value)}
                placeholder="Enter Your Age"
                classes={{notchedOutline:classes.input}}
                disableUnderline={false}
                InputProps={{
                    classes: {
                        root: classes.textfiledroot,
                        disabled: classes.disabled
                    }
                }}
            />
            <TextField
                id="name"
                variant="outlined"
                fullWidth
                className={classes.textfeild}
                onChange = {(e)=>setEmail(e.target.value)}
                placeholder="Enter Your Email"
                classes={{notchedOutline:classes.input}}
                disableUnderline={false}
                InputProps={{
                    classes: {
                        root: classes.textfiledroot,
                        disabled: classes.disabled
                    }
                }}
            />
            <TextField
                id="name"
                variant="outlined"
                fullWidth
                className={classes.textfeild}
                onChange = {(e)=>setNotes(e.target.value)}
                placeholder="Enter Description/Notes"
                classes={{notchedOutline:classes.input}}
                disableUnderline={false}
                InputProps={{
                    classes: {
                        root: classes.textfiledroot,
                        disabled: classes.disabled
                    }
                }}
            />

              <Grid container>
                  <Typography className={classes.dialogtitle}>Select a date for appointment</Typography>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around">
                        <KeyboardDatePicker
                            minDate={new Date()}
                        margin="normal"
                        id="date-picker-dialog"
                        label="Pick a Date"
                        format="MM/dd/yyyy"
                        value={selectedDate}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        />
                        <div style={{margin: 40}}>
                            <select
                                style={{padding: 5, fontSize: 16, marginRight: 5}}
                                value={time}
                                onChange={(e) => settime(e.target.value)}
                            >
                                <option value="8">8:00</option>
                                <option value="9">9:00</option>
                                <option value="10">10:00</option>
                                <option value="11">11:00</option>
                                <option value="12">12:00</option>
                                <option value="1">1:00</option>
                                <option value="2">2:00</option>
                                <option value="3">3:00</option>
                                <option value="4">4:00</option>
                                <option value="5">5:00</option>
                                <option value="6">6:00</option>
                                <option value="7">7:00</option>
                            </select>
                            <select
                                style={{padding: 5, fontSize: 16, marginRight: 5}}
                                value={ampm}
                                onChange={(e) => setampm(e.target.value)}
                            >
                                <option value="AM">AM</option>
                                <option value="PM">PM</option>
                            </select>
                            {/*<p>{message}</p>*/}
                        </div>

                    </Grid>
                    </MuiPickersUtilsProvider>
              </Grid>


              <FormControl component="fieldset">
                <RadioGroup aria-label="gender"  name="gender1" value={value} onChange={handleChange}>
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                    <Typography className={classes.dialogtitle} style={{paddingRight:'20px'}}>Emergency ?</Typography>
                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="No" control={<Radio />} label="No" />
                    </div>
                </RadioGroup>
            </FormControl>

        </DialogContent>
        <div className={classes.dialogbuttoncontainer}>
        <Button variant="contained" className={classes.bookappointment} onClick={handleSubmit}>Submit</Button>
        <Button variant="contained" className={classes.bookappointment} onClick={handleClose}>Close</Button>
        </div>
         </Dialog>
        </div>
    )
}
