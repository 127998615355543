import React, { Component } from 'react';
import ChatBot from 'react-simple-chatbot';
import { ThemeProvider } from 'styled-components';
import Review from "./Review";
import Logo from '../public/assets/images/hident.png'
import {primaryColor} from "../constants";
const theme = {
    background: '#f5f8fb',
    fontFamily: 'Helvetica Neue',
    headerBgColor: '#DE1C49',
    headerFontColor: '#fff',
    headerFontSize: '15px',
    botBubbleColor: '#DE1C49',
    botFontColor: '#fff',
    userBubbleColor: '#fff',
    userFontColor: '#4a4a4a',
    textAlign:'left',
    height:200,
    zIndex:2
};

class SimpleForm extends Component {

    render() {
        return (
            <ThemeProvider theme={theme}>
            <ChatBot
                botAvatar={Logo}
                steps={[
                    {
                        id: '1',
                        message: 'Welcome to Hi Dental, we appreciate you for trusting us! We are happy to help you! What is your name?',
                        trigger: 'name',
                    },
                    {
                        id: 'name',
                        user: true,
                        trigger: '3',
                    },
                    {
                        id: '3',
                        message: 'Hi {previousValue}! What are you looking for?',
                        trigger: 'intrested',
                    },
                    {
                        id: 'intrested',
                        options: [
                            { value: 'Invisalign', label: 'Invisalign', trigger: '5' },
                            { value: 'DentalImplant', label: 'Dental Implant', trigger: '5' },
                            { value: 'FixedDentures', label: 'Fixed Dentures', trigger: '5' },
                            { value: 'Veeners', label: 'Veeners', trigger: '5' },
                            { value: 'TeethWhitening', label: 'Teeth Whitening', trigger: '5' },
                            { value: 'Painless Root Canals', label: 'Painless Root Canals', trigger: '5' },
                        ],
                    },
                    {
                        id: '5',
                        message: 'What is your phone number?',
                        trigger: 'phone',
                    },
                    {
                        id: 'phone',
                        user: true,
                        trigger: 'end-message',
                        validator: (value) => {
                            if (isNaN(value)) {
                                return 'value must be a number';
                            } else if (value < 0) {
                                return 'value must be positive';
                            } else if (value.length < 10) {
                                return `${value}? Come on!`;
                            }
                            return true;
                        },
                    },
                    {
                        id: '7',
                        message: 'Great! Check out your summary',
                        trigger: 'review',
                    },
                    {
                        id: 'review',
                        component: <Review />,
                        asMessage: true,
                        trigger: 'end-message',
                    },
                    {
                        id: 'update',
                        message: 'Would you like to update some field?',
                        trigger: 'update-question',
                    },
                    {
                        id: 'update-question',
                        options: [
                            { value: 'yes', label: 'Yes', trigger: 'update-yes' },
                            { value: 'no', label: 'No', trigger: 'end-message' },
                        ],
                    },
                    {
                        id: 'update-yes',
                        message: 'What field would you like to update?',
                        trigger: 'update-fields',
                    },
                    {
                        id: 'update-fields',
                        options: [
                            { value: 'name', label: 'Name', trigger: 'update-name' },
                            { value: 'gender', label: 'Gender', trigger: 'update-gender' },
                            { value: 'age', label: 'Age', trigger: 'update-age' },
                        ],
                    },
                    {
                        id: 'update-name',
                        update: 'name',
                        trigger: '7',
                    },
                    {
                        id: 'update-gender',
                        update: 'gender',
                        trigger: '7',
                    },
                    {
                        id: 'update-age',
                        update: 'age',
                        trigger: '7',
                    },
                    {
                        id: 'end-message',
                        component: <Review />,
                        asMessage: true,
                        end: true,
                    },
                ]}
            />
            </ThemeProvider>
        );
    }
}

export default SimpleForm;
