import React,{useState,useEffect} from "react";
import {MDBCol, MDBContainer, MDBFooter, MDBRow} from "mdbreact";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import BusinessIcon from '@material-ui/icons/Business';
import {Nav} from "react-bootstrap";
import CallIcon from '@material-ui/icons/Call';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import Logo from "../public/assets/images/newlogo.png";
import { makeStyles } from '@material-ui/core/styles';
import {firestore} from "../firebase"
import xtype from 'xtypejs'
import { Widget } from '@typeform/embed-react'

import { useLocation, useHistory } from 'react-router-dom'
import { Button , Container ,Typography,Grid,Paper}from '@material-ui/core';
import Banner from "../public/assets/serviceImages/banner1.png"
import { Link } from 'react-router-dom'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import "aos/dist/aos.css"
import AOS from 'aos';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import Offer1 from "../public/assets/OffersImages/offer1.png"
import Offer2 from "../public/assets/OffersImages/offer2.png"
import Offer3 from "../public/assets/OffersImages/offer3.png"
import Offer4 from "../public/assets/OffersImages/offer4.png"
import Offer5 from "../public/assets/OffersImages/offer5.png"
import Offer6 from "../public/assets/OffersImages/offer6.png"
import Offer7 from "../public/assets/OffersImages/offer7.png"
import Offer8 from "../public/assets/OffersImages/offer8.png"
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {primaryColor} from "../constants";
import MapImage from "../public/assets/images/map1.png"
import RoomIcon from '@material-ui/icons/Room';
import CarouselCardLogo from "../public/assets/images/customerlogo2.png"





const useStyles = makeStyles((theme) => ({
    root: {
      flex:1,
        scrollBehavior: 'smooth',
        overflowX:'hidden'
    },
    navbar:{
        backgroundColor:'#fff',
        zIndex:1
    },
    navbar2:{
        backgroundColor:'#fff',
        marginTop: 75,
        zIndex:1
    },
    logoimage:{
        width:200,
        height:60,
        objectFit:'contain'
    },
    headetitle:{
        fontSize:30,
        marginLeft:30,
        fontFamily: 'Poppins',
        fontStyle:'italic',
        [theme.breakpoints.only('xs')]: {
            fontSize: 15
        },
    },
    bookappointment:{
        backgroundColor:'#DE1C49',
        color:'#fff',
        fontFamily: 'Poppins',
        padding: 12,
        fontWeight: "800",
        margin:'0px 20px',
        "&:hover": {
            backgroundColor: "#DE1C49",
            textDecoration:'none'
        },
        [theme.breakpoints.only('xs')]: {
           marginTop:20
        },
    },
    submitbutton:{
        backgroundColor:'#DE1C49',
        color:'#fff',
        fontFamily: 'Poppins',
        fontWeight: "800",
        margin:'10px 10px',
        padding:"5px 30%",
        "&:hover": {
            backgroundColor: "#DE1C49"
        }
    },
    navlist:{
        fontSize:20,
        fontFamily: 'Poppins',
        fontWeight:600
    },
    navlist2:{
        fontSize:16,
        marginRight: 30,
        fontFamily: 'Poppins',
        fontWeight:600,
        color: 'white',
        [theme.breakpoints.only('xs')]: {
            fontSize: 10
        },
    },
    carousel:{
        padding:'20px 10px',
        marginTop: 120,
        fontFamily: 'Poppins',

    },
    carouselimage:{
        borderRadius:40,
        // marginTop: 100,
    },
    pagetitles:{
        textAlign:'start',
        fontFamily: 'Poppins',
        margin:'0px 10px',
        fontSize:26,
        fontWeight:600,
        color: '#000',
        marginTop:100,
        scrollMarginTop: '120px',
        marginBottom: '-15px',
    },
    pagetitlesTop:{
        textAlign:'center',
        margin:'10px 5px',
        fontSize:32,
        fontFamily: 'Poppins',
        fontWeight:600,
        color: 'black',
        fontStyle:'italic',
        [theme.breakpoints.only('xs')]: {
            fontSize: 24,
            margin:'2px 2px',
        },
    },
    pagetitles2:{
        textAlign:'start',
        margin:'20px 10px',
        fontFamily: 'Poppins',
        fontSize:20,
        fontWeight:600
    },
    cardimage:{
        objectFit:'cover',
        borderRadius:20,
        width:'100%',
        fontFamily: 'Poppins',
        height:400,
        verticalAlign:'middle',
    },
    happyfacetitle:{
        fontSize:26,
        fontWeight:700,
        fontFamily: 'Poppins',
        fontStyle: 'italic'

    },
    happyfacesubtitle:{
        fontSize:20,
        fontWeight:400,
        fontFamily: 'Poppins',
       fontStyle: 'italic'
    },
    happyfacedesc:{
        fontSize:16,
        margin:'20px 0px',
        fontFamily: 'Poppins',
       fontStyle: 'italic',
        textAlign:'justify'
    },
    serviceimage:{
        objectFit:'cover',
        borderRadius:20,
        width:'100%',
        height:250,
        verticalAlign:'middle',
        fontFamily: 'Poppins',
        zIndex:1,
    } ,
    servicecard:{
        padding:'20px',
        fontFamily: 'Poppins',
        borderRadius: 20,
        [theme.breakpoints.only('xs')]: {
            padding:'5px',
        },
        "&:hover":{
            // transform: "scale(1.2)",
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            height: "100%",
            width: "100%",
            transition: ".5s ease",
            backgroundColor: "#DE1C49",
        }
    },
    servicecardopen:{
        padding:'20px',
        fontFamily: 'Poppins',
    },
    cardtitle:{
        fontSize:20,
        fontWeight:600,
        fontFamily: 'Poppins',
        textAlign:'start',
        paddingLeft:10,
        paddingTop:10
    },
    carddesc:{
        fontSize:16,
        fontFamily: 'Poppins',
        textAlign:'start',
        paddingLeft:10,
        paddingTop:10
    },
    serviceimagepen:{
        objectFit:'cover',
        width:'100%',
        fontFamily: 'Poppins',
        height:250,
        verticalAlign:'middle',
        zIndex:1,
        borderRadius: 16,
    },
    buttonbelowcontainer:{
        margin:"40px 0px"
    },
    buttonbelowcontainerform:{
        margin:"10px 0px",
        paddingBottom:"20px"
    },
    buttonbelowcontainerform2:{
        margin:"10px 0px",
        paddingBottom:"20px",
        display:'none',
            [theme.breakpoints.only('xs')]: {
                display:'block',
                flexDirection:'column'
        },
    },
    footerroot :{
        backgroundColor:'#DE1C49',
        padding:'30px 20px',
        display:'flex',
        fontFamily: 'Poppins',
        justifyContent:'space-around',
        flexWrap:'wrap',
        [theme.breakpoints.only('xs')]: {
          display:'flex',
          flexDirection:'column'
        },
    },
    div1:{
        width:'40%',
        padding:'0% 0px',
        [theme.breakpoints.only('xs')]: {
          width:'100%'
        },
    },
    // div2:{
    //     width:'5%',
    // },
    logoview:{
        display:'flex',
    },
    appname:{
        fontSize:30,
        marginLeft:10,
        fontFamily: 'Poppins',
        fontWeight: 600,
        color:'#fff'
    },
    footertext:{
        fontSize:16,
        width:'100%',
        margin:'30px 0px',
        textAlign:'start',
        fontFamily: 'Poppins',
        color:'#fff',
        [theme.breakpoints.only('xs')]: {
            textAlign:'center',
            margin:'10px 0px',

        },
    },
    aboutAppstext:{
        fontSize:20,
        marginLeft:0,
        fontFamily: 'Poppins',
        fontWeight:600,
        margin:'20px 0px',  textAlign:'start',
        color:'#fff',
    },
    linksview:{
        marginTop:'30%',
        fontFamily: 'Poppins',
        color:'#fff',
        [theme.breakpoints.only('xs')]: {
            marginTop:20
        },
    },
    aboutustitle:{
        fontSize:35,
        padding:'30px 0px',
        fontFamily: 'Poppins',
        fontWeight:'600',
        [theme.breakpoints.only('xs')]: {
            fontSize: 24
        },
    },
    abouttextspacing:{
        fontSize:16,
        fontFamily: 'Poppins',
        letterSpacing:1,
        padding:'10px 0px',
        textAlign: 'left'
    },
    abouttext:{
        fontSize:18,
        letterSpacing:1,
        fontFamily: 'Poppins',
        textAlign: 'justify',
    },
    abouttextfooter:{
        position:'absolute',
        fontSize:18,
        letterSpacing:1,
        fontFamily: 'Poppins',
        textAlign: 'justify',
        marginTop:60,
        fontWeight:'600',
        paddingLeft:10
    },
    carousellist:{
        fontSize:20,
        fontWeight:'600',
        fontFamily: 'Poppins',
        padding:"10px 20px",
        margin:'20px 0px',
        cursor:'pointer'
    },
    listlink:{
        color:'#000',
        "&:hover":{
            color:'#000000',
            textDecoration:'none'
        }
    },
    dialogtitle:{
        fontSize:12,
        fontFamily: 'Poppins',
        fontWeight:600,
        padding:'10px 0px'
    },
    dialogtitle3:{
        fontSize:12,
        fontFamily: 'Poppins',
        fontWeight:600,
        padding:'10px 0px',
        textAlign: 'left',
    },
    dialogtitle2:{
        fontSize:16,
        fontFamily: 'Poppins',
        fontWeight:600,
        padding:'15px 0px',
        textAlign: 'center',
        color: '#DE1C49'
    },
    textfeild:{
        border:'none',
        fontFamily: 'Poppins',
        backgroundColor:'#FFF8F2',
        margin:'5px 0px'
    },
    textfiledroot: {
        '& $disabled': {
           borderColor: 'orange'
        }
     },
     disabled: {},

     dialogbuttoncontainer:{
         display:'flex',
         flexDirection:'row',
         fontFamily: 'Poppins',
         justifyContent:'center',
         margin:'20px 0px',
     },
     dialogbuttoncontainerform:{
        display:'flex',
        flexDirection:'row',
        fontFamily: 'Poppins',
        justifyContent:'center',
        margin:'5px 0px',
        paddingBottom:20
    },
     knowmore:{
         display:'flex',
         flexDirection:'row',
         fontFamily: 'Poppins',
         justifyContent:'center',
         fontSize:20,
         paddingTop:10,
         cursor:'pointer'
     },
     knowmoretitle:{
         fontWeight:'600',
         fontFamily: 'Poppins',
     },
     carouseltitle:{
        top: "30%",
        bottom: 'auto',
        position:'absolute',
        width:'400px',
        fontSize:'40px',
        marginLeft:'10%',
        fontWeight:'800',
         fontFamily: 'Poppins',
         textAlign:'start',
        letterSpacing:1,
        [theme.breakpoints.only('xs')]: {
            top: "60%",
            bottom: 'auto',
            position:'absolute',
            fontSize:'12px',
            textAlign:'center',
            letterSpacing:1,
            width:'80%'
        },

     },
    carouseltitle3:{
        top: "10%",
        bottom: 'auto',
        fontFamily: 'Poppins',
        position:'absolute',
        width:'400px',
        fontSize:'40px',
        marginLeft:'60%',
        fontWeight:'800',
        textAlign:'start',
        letterSpacing:1,
        [theme.breakpoints.only('xs')]: {
            top: "15%",
            bottom: 'auto',
            position:'absolute',
            fontFamily: 'Poppins',
            fontSize:'12px',
            marginLeft:'40%',
            textAlign:'center',
            letterSpacing:1,
            width:'60%'
        },
    },
    carouseltitle2:{
        top: "30%",
        bottom: 'auto',
        position:'absolute',
        width:'400px',
        fontFamily: 'Poppins',
        fontSize:'40px',
        marginLeft:'60%',
        fontWeight:'800',
        textAlign:'start',
        letterSpacing:1,
        [theme.breakpoints.only('xs')]: {
            top: "15%",
            bottom: 'auto',
            position:'absolute',
            marginLeft:'40%',
            fontSize:'12px',
            textAlign:'center',
            letterSpacing:1,
            width:'60%'
        },
    },
    carouselcard:{
        height:300,
        borderRadius:20,
        padding:'20px',
        width:'95%',
        border:'1px solid #efefef',
        zIndex:2,
        [theme.breakpoints.only('xs')]: {
            height:350,
            padding:'20px',
        }
    },
    carouselcardContact:{
        height:230,
        borderRadius:20,
        padding:'20px',
        width:220,
        marginBottom: 10,
        justifyContent: 'center',
        border:'1px solid #efefef',
        [theme.breakpoints.only('xs')]: {
            height:350,
            padding:'20px',
        }
    },
    carouselcard2:{
        height:300,
        borderRadius:20,
        padding:'50px',
        width:'40%',
        border:'1px solid #efefef',
        [theme.breakpoints.only('xs')]: {
            height:350,
            padding:'20px',
        }
    },
    statement:{
        fontSize:15,
        fontWeight:600,
        textAlign:'left',
        fontFamily: 'Helvetica'
    },
    statementname:{
        position:'absolute',
        fontSize:20,
        fontWeight:500,
        bottom:20
    },
    form:{
        padding:10
    },
    formContainer:{
        borderRadius:20
    },
    formheader:{
        backgroundColor:'#DE1C49',
        padding:10,
        color:"#fff",
        fontSize:16,
        fontWeight:600,
        borderTopLeftRadius:16,
        borderTopRightRadius:16
    },
    mapStyles:{
        height: '500px',
        width: '90%',
        [theme.breakpoints.only('xs')]: {
           display: 'none'
        }
    },
    mapStyles2:{
        height: '500px',
        width: '90%',
        display: 'none',
        [theme.breakpoints.only('xs')]: {
           display: 'block'
        }
    },
    mapimage:{
        height: '550px',
        width: '90%',
        [theme.breakpoints.only('xs')]: {
            height:400
        }
    },
    rightrow:{
        display:'flex',
        [theme.breakpoints.only('xs')]: {
            flexDirection:'column-reverse'
        }
    },
    linkbutton:{
        "&:hover":{
            textDecoration:'none'
        }
    },
    contactCard:{
        padding: 16,
        marginBottom: 20,
        borderColor: '#000000',
        borderRadius:20,
        borderWidth: 1,
        fontFamily: 'Poppins',
        fontWeight: 600,
        display:'flex'
    },
    contactCard2:{
        padding: 16,
        marginBottom: 20,
        borderColor: '#000000',
        borderRadius:20,
        borderWidth: 1,
        fontFamily: 'Poppins',
        fontWeight: 600,
        display:'flex',
        flexDirection: 'column'
    },
    blogcontainer:{
        padding:'20px',
        textAlign:'start',
        margin:'40px 10px'
    },
    blogcard:{
        padding:'10px',
        textAlign:'start',
        margin:'20px 0px'
    },
    blogsubtitle:{
        color:'#DE1C49',
        fontSize:16,
        fontWeight:'500',
        textAlign:'start'
    },
    blogtitle:{
        fontSize:24,
        fontWeight:'900',
        textAlign:'start',
        height:'70px',
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    blogimage:{
        width:'100%',
        height:'70%',
        borderRadius: 20
    },
    blognameanddate:{
        fontSize:14,
        paddingTop:10,
        color:'#8c8c8c',
        textAlign:'start'
    },
    blogdesc:{
        fontSize:18,
        fontWeight:400,
        textAlign:'start',
        padding:"15px 0px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        height:'100px',
        "&:after":{
            content:'...'
        }
    },
    blogreadmore:{
        textAlign:'start',
        fontSize:18,
        fontWeight:'600',
        textDecoration:'underline',
        cursor:'pointer',
        "&:hover":{
            color:'#DE1C49'
        }

    },
    seeall:{
        paddingTop:35,
        fontSize:20,
        color:'#000',
        "&:hover":{
            color:'#DE1C49'
        }
    },
    carouselcardsmile:{
        width: '50%',
        bottom: '30px',
        position: 'absolute',
        left: '20%',
        zIndex: 0,
        opacity: 0.4,
    },
    chooseimage:{
        objectFit:'cover',
        borderRadius:20,
        width:'100%',
        fontFamily: 'Poppins',
        height:250,
        verticalAlign:'middle',
    },
    righttext:{
        padding:'40px 40px',
        fontSize:20,
        textAlign:'center',
        [theme.breakpoints.only('xs')]: {
            padding:'10px',
        },
    },
    button:{
        marginTop:2,
        backgroundColor:'#DE1C49',
        color:'#ffffff',
        fontSize:18,
        fontWeight:600,
        fontFamily:'Poppins',
        letterSpacing:1,
        "&:hover":{
            backgroundColor:'#fff',
            color:'#DE1C49',
            fontSize:19,
            fontWeight:800,

        }
    },
    titlesmilelogomedium:{
        width: "130px",
        position: 'relative',
        opacity: '1',
        zIndex:-1,
        [theme.breakpoints.only('xs')]: {
        },
    }


  }));

function MainFooter(props){
    const classes = useStyles()
    const [index, setIndex] = useState(0);
    const [open, setOpen] = React.useState(false);
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [value, setValue] = React.useState('No');
    const [appointmentDate,setAppointmentDate] = useState("")
    const [appointmentTime,setAppointmentTime] = useState("")
    const [name,setName] = useState("")
    const [phone,setPhone] = useState("")
    const [promotionDialog,setPromotionDialog] = useState(false)
    const [ampm,setampm] = useState("AM")
    const [time,settime] = useState("9")
    const [notes,setNotes] = useState("")
    const [email,setEmail] = useState("")
     const [age,setAge] = useState("")
    const [openSnackbar, setOpenSnackBar] = React.useState(false);
    const history = useHistory();



    useEffect(() => {
        AOS.init({
          duration : 2000
        });
        window.scrollTo(0, 0);
        setTimeout(() => {
            setPromotionDialog(true)
        }, 3000)
      }, []);

    const executeScroll = (ref) => ref.current.scrollIntoView({behavior: 'smooth'})


    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };


    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
      }

    const handleClickSnackbar = () => {
        setOpenSnackBar(true);
      };

      const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSnackBar(false);
      };



    //   const handleClickOpen = () => {
    //     setOpen(true);
    //   };

      const handleClose = () => {
        setOpen(false);
      };


      const handleDateChange = (date) => {
          console.log(date)
          var localTime = date.toString();
          setAppointmentDate(localTime.slice(0,15))
          console.log(localTime.slice(15,25))
          setAppointmentTime(localTime.slice(15,25))
          setSelectedDate(date);
      };

     const handleSubmit = () => {
         if(phone =="" || name == ""){
             alert("Please enter Phone, Name and Date")
         }
         else{
        firestore.collection('leads').doc(Date.now().toString()).set({
            name: name ,
            phone: phone,
            age: age,
            appointmentDate: appointmentDate,
            appointmentTime: time + " " + ampm,
            emergency:value,
            email:email,
            notes:notes,
            timestamp: Date.now()
            },{merge: true})

        setTimeout(function(){ handleClickSnackbar() }, 300);
        history.push('./thankyou')
        handleClose()
        handleClickSnackbar()
     }
    }

      const handleChange = (event) => {
        setValue(event.target.value);
      };

      const K_HEIGHT = 40;
      const K_WIDTH = 40;
    const greatPlaceStyle = {
        // initially any map object has left top corner at lat lng coordinates
        // it's on you to set object origin to 0,0 coordinates
        position: 'absolute',
        width: K_WIDTH,
        height: K_HEIGHT,
        left: -K_WIDTH / 2,
        top: -K_HEIGHT / 2,
        objectFit:'contain',
        border: '1px solid #f44336',
        borderRadius: K_HEIGHT,
        backgroundColor: 'white',
        textAlign: 'center',
        color: '#3f51b5',
        fontSize: 16,
        fontWeight: 'bold',
        padding: 4
      };

    return(

        <div>
             <Container>
             <div style={{display:'flex', flexDirection: 'row', justifyContent:'flex-start'}}>
            <div style={{display:'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Typography className={classes.pagetitles}  >
              Contact us
            </Typography>
            <img src={CarouselCardLogo} style={{width: 100}} className={classes.titlesmilelogomedium} />
            </div>
        </div>
        
       
        <Grid container>
            <Grid item xs={12} sm={6}>
                <Grid container spacing={1}>
                  <Grid item xs={5}>
                        <div className={classes.contactCard}>
                            <RoomIcon style={{color: primaryColor, fontSize: 20,}}/>
                             <Typography style={{fontSize:14,fontWeight:600}}> Hi Dental Care, 13319 East Freeway
                            Houston, Texas 77015 </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.contactCard2}>
                            <Typography style={{fontSize:14,fontWeight:600}}><CallIcon style={{color: primaryColor, fontSize: 20,}}/>  +1-713-820-3360 </Typography>
                            <Typography style={{fontSize:14,fontWeight:600}}><MailOutlineIcon style={{color: primaryColor, fontSize: 20,}}/>  contact@hidentalhouston.com</Typography>
                        </div>
                    </Grid> 
                     {/* <Grid item xs={4} sm={4}>
                        <div className={classes.contactCard}>
                            
                        </div>
                    </Grid> */}

                </Grid>
                <div className={classes.mapStyles}>
                     {/* <GoogleMapReact
                        bootstrapURLKeys={{ key: "AIzaSyAXfzhB1vIR33nVz6cUq6mi4vU0cqxzOaE"}}
                        defaultCenter={{lat:29.77183074220161,lng: -95.188092088877}}
                        defaultZoom={12}>
                        <AnyReactComponent
                            lat={29.77183074220161}
                            lng={-95.188092088877}
                            text="My Marker"
                        />
                    </GoogleMapReact>  */}
                    <iframe src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d3463.2060911584754!2d-95.19024858515421!3d29.771681738361718!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e0!4m0!4m5!1s0x8640a379067aaaab%3A0x6c138b79e6bbbf4d!2sHi%20Dental%20Care%2C%2013319%20East%20Fwy%2C%20Houston%2C%20TX%2077015%2C%20United%20States!3m2!1d29.7716757!2d-95.1881719!5e0!3m2!1sen!2sin!4v1629378217105!5m2!1sen!2sin" width="600" height="450" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                    {/* <iframe style={{border:0}} src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d3463.2060911584754!2d-95.19024858515421!3d29.771681738361718!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e0!4m0!4m5!1s0x8640a379067aaaab%3A0x6c138b79e6bbbf4d!2sHi%20Dental%20Care%2C%2013319%20East%20Fwy%2C%20Houston%2C%20TX%2077015%2C%20United%20States!3m2!1d29.7716757!2d-95.1881719!5e0!3m2!1sen!2sin!4v1629378217105!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe> */}
                    {/* <img src={MapImage} alt="map image" className={classes.mapimage}/> */}
                </div>
                <div className={classes.mapStyles2}>
                     {/* <GoogleMapReact
                        bootstrapURLKeys={{ key: "AIzaSyAXfzhB1vIR33nVz6cUq6mi4vU0cqxzOaE"}}
                        defaultCenter={{lat:29.77183074220161,lng: -95.188092088877}}
                        defaultZoom={12}>
                        <AnyReactComponent
                            lat={29.77183074220161}
                            lng={-95.188092088877}
                            text="My Marker"
                        />
                    </GoogleMapReact>  */}
                    <iframe src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d3463.2060911584754!2d-95.19024858515421!3d29.771681738361718!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e0!4m0!4m5!1s0x8640a379067aaaab%3A0x6c138b79e6bbbf4d!2sHi%20Dental%20Care%2C%2013319%20East%20Fwy%2C%20Houston%2C%20TX%2077015%2C%20United%20States!3m2!1d29.7716757!2d-95.1881719!5e0!3m2!1sen!2sin!4v1629378217105!5m2!1sen!2sin" width="350" height="450" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                    {/* <iframe style={{border:0}} src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d3463.2060911584754!2d-95.19024858515421!3d29.771681738361718!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e0!4m0!4m5!1s0x8640a379067aaaab%3A0x6c138b79e6bbbf4d!2sHi%20Dental%20Care%2C%2013319%20East%20Fwy%2C%20Houston%2C%20TX%2077015%2C%20United%20States!3m2!1d29.7716757!2d-95.1881719!5e0!3m2!1sen!2sin!4v1629378217105!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe> */}
                    {/* <img src={MapImage} alt="map image" className={classes.mapimage}/> */}
                </div>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Paper className={classes.formContainer}>
                <div className={classes.formheader}>
                    Contact Us
                </div>
                <div  className={classes.form}>
                <Container>
                <Widget id="upSDfLnd" style={{ width: '100%' , height: 500}} className="my-form" />
            </Container>
            </div>

        
            </Paper>
          </Grid>
        </Grid>
        </Container>

        {/* <div className={classes.buttonbelowcontainerform} data-aos="zoom-in" data-aos-duration="500">
            <Button variant="container"  className={classes.bookappointment} style={{marginTop:40}} onClick={props.handleOpen}>Book Appointment</Button>
        </div> */}


        <MDBFooter color="stylish-color-dark" style={{backgroundColor: '#DE1C49',position:'absolute',zIndex:2}} className="page-footer font-small pt-4 mt-4">
            <MDBContainer fluid className="text-center text-md-left">
                <MDBRow>
                    <MDBCol md="4">
                        {/* <h5 style={{color: '#ffffff'}}  className="text-uppercase mb-4 mt-3 font-weight-bold">
                            Hi Dental
                        </h5> */}
                        <img src={Logo} style={{height:40,width:200,backgroundColor:'#fff',borderRadius:8,objectFit:'contain'}} />
                        <p style={{color: '#ffffff',marginTop:30}}>
                            At Hi Dental we always want our clients to be happy, that is why we have several treatments that are flawlessly designed to your needs, lifestyle, and budget.
                        </p>
                        <div style={{display:'flex', flex:1,flexDirection:'row',justifyContent:'center',width:'200px'}}>
                            {/*<LinkedInIcon style={{color:'#fff',fontSize:30}} />*/}
                            <a href="https://www.facebook.com/hidentalhouston/"><FacebookIcon style={{color:'#fff',fontSize:30}} /></a>
                            <a href="https://www.instagram.com/hidentalhouston/"><InstagramIcon style={{color:'#fff',fontSize:30}} /></a>
                        </div>
                    </MDBCol>
                    <hr className="clearfix w-100 d-md-none" />
                    <MDBCol md="4">
                        <h5 style={{color: '#ffffff'}} className="text-uppercase mb-4 mt-3 font-weight-bold">
                            Important Links
                        </h5>
                        <ul style={{color: '#ffffff'}} className="list-unstyled">
                            <li>
                                <a style={{color: '#ffffff'}} href="/">About us</a>
                            </li>
                            <li>
                                <a style={{color: '#ffffff'}} href="/">Our Services</a>
                            </li>
                            <li>
                                <a style={{color: '#ffffff'}} href="/privacypolicy">Privacy Policy</a>
                            </li>
                            <li>
                                <a style={{color: '#ffffff'}} href="/new-patient-form">New Patient Form</a>
                            </li>
                            {/* <li>
                                <a style={{color: '#ffffff'}} href="/public/sitemap.xml">SiteMap</a>
                            </li> */}
                            {/*<li>*/}
                            {/*    <a href="/admin">Admin Login</a>*/}
                            {/*</li>*/}
                        </ul>
                    </MDBCol>
                    <hr className="clearfix w-100 d-md-none" />
                    <MDBCol style={{color: '#ffffff'}} md="4">
                        <h5 className="text-uppercase mb-4 mt-3 font-weight-bold">
                            Contact Us!
                        </h5>
                        <ul className="list-unstyled">
                            <li>
                                <p style={{color: '#ffffff'}}><a href="tel:713-820-3360" style={{color: '#ffffff', marginBottom: 20}}><CallIcon/>{'  '} +1-713-820-3360</a></p>
                            </li>
                            <li>
                                <p style={{color: '#ffffff'}}><MailOutlineIcon/><a style={{color: '#ffffff'}} href = "mailto:contact@hidentalhouston.com">
                                    {'  '}contact@hidentalhouston.com</a></p>

                            </li>
                            {/*<li>*/}
                            {/*    <a href="#!">+91-9587917917</a>*/}
                            {/*</li>*/}
                            <li>
                                <p href="#!">
                                    <BusinessIcon/>
                                    {'  '}Hi Dental Care, 13319 East Freeway</p>
                                <p href="#!">
                                    Houston, Texas 77015</p>
                            </li>
                        </ul>
                    </MDBCol>
                    <hr className="clearfix w-100 d-md-none" />
                    {/*<MDBCol md="3">*/}
                    {/*    <h5 className="text-uppercase mb-4 mt-3 font-weight-bold">*/}
                    {/*        Download our app now!*/}
                    {/*    </h5>*/}
                    {/*    <ul className="list-unstyled">*/}
                    {/*        <li>*/}
                    {/*            <a href={'https://play.google.com/store/apps/details?id=com.xpertfinance&hl=en_IN'}><img src={'googleplay2.png'} width={'200'}/></a>*/}
                    {/*        </li>*/}
                    {/*    </ul>*/}
                    {/*</MDBCol>*/}
                </MDBRow>
            </MDBContainer>
            <hr />
        </MDBFooter>
        </div>
    )
}

export default MainFooter;
