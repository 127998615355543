import  React,{useState} from 'react';
import Link from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from './Title';
import {useEffect} from "react";
import {firestore} from '../firebase'
import { Button, IconButton } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { TextField } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// Generate Order Data
function createData(profession, name, phone, email,city, timestamp, businessName, dsaBank) {
  return { profession, name, phone, email,city, timestamp, businessName, dsaBank };
}



function preventDefault(event) {
  event.preventDefault();
}

export default function ExpertOrders() {
  const [allLeads, setAllLeads] = React.useState([]);
  const [AddNotes,setAddNotes] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [timestamp,setTimestamp] = useState("")
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [timeId,setTimeId] = useState("")

  const handleClickMenu = (event,time) => {
    setTimeId(time)
    setAnchorEl(event.currentTarget);
    console.log(time)
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClickOpen = (time,id) => {
    setOpen(true);
    console.log(time)
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getLeads();
  },[])

  const getLeads = () => {
    firestore.collection("leads").get().then(snapshot => {
      var leads = [];
      snapshot.docs.map(doc => {
        console.log('lead item', doc.data())
          leads.push({name:doc.data().name, phone:doc.data().phone, age:doc.data().age, email:doc.data().email, emergency:doc.data().emergency, appointmentDate:doc.data().appointmentDate, appointmentTime:doc.data().appointmentTime, notes:doc.data().notes, timestamp:doc.data().timestamp, status:doc.data().status})
      })
      setAllLeads(leads.reverse())
      console.log('all heads in dahsboard',leads);
  })
  }


  const AddNotedByDoctor = (timestamp) => {
    firestore.collection("leads").doc(timestamp.toString()).set({
      notes: AddNotes
    }, {merge: true})
    handleClose()
    setTimeout(()=>{
      getLeads()
    },500)
  }

  const handleChangeStatus = (status,time) => {
    console.log(status,time,"status change");
    firestore.collection("leads").doc(time.toString()).set({
      status: status
    }, {merge: true})
    handleCloseMenu()
    setTimeout(()=>{
      getLeads()
    },500)
   
    }
  return (
    <React.Fragment>
      <Title>All Expert Enquiries</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
          <TableCell>Sr.</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Phone number</TableCell>
            <TableCell>Age</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Emergency</TableCell>
            <TableCell>Appointment Date</TableCell>
            <TableCell>Appointment Time</TableCell>
            <TableCell>Notes</TableCell>
            {/* <TableCell></TableCell> */}
            <TableCell>Created At</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Change Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allLeads.map((row,index) => (
            <>
            <TableRow key={row.profession}>
              <TableCell>{index}</TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.phone}</TableCell>
              <TableCell>{row.age}</TableCell>
              <TableCell>{row.email}</TableCell>
              <TableCell>{row.emergency}</TableCell>
              <TableCell>{row.appointmentDate}</TableCell>
              <TableCell>{row.appointmentTime}</TableCell>
              <TableCell onClick={() => {handleClickOpen(row.timestamp); setTimestamp(row.timestamp)}} style={{cursor:'pointer'}}>{row.notes}</TableCell>
              {/* <TableCell>{row.notesByDoctor}</TableCell>       */}
              <TableCell align="right">{new Date(row.timestamp).toLocaleString()}</TableCell>
              <TableCell>{row.status === undefined || row.status === "" ? "NEW" : row.status.toUpperCase()}</TableCell>
                <TableCell width="20%"><Button aria-controls="simple-menu" variant="contained" color="primary" aria-haspopup="true" onClick={(e)=>handleClickMenu(e,row.timestamp)}>change</Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleCloseMenu}
                  elevation={1}
                >
                  <MenuItem onClick={()=>handleChangeStatus("hot",timeId)}>Hot</MenuItem>
                  <MenuItem onClick={()=>handleChangeStatus("followUp1",timeId)}>Follow up1</MenuItem>
                  <MenuItem onClick={()=>handleChangeStatus("followUp2",timeId)}>Follow up2</MenuItem>
                  <MenuItem onClick={()=>handleChangeStatus("followUp3",timeId)}>Follow up3</MenuItem>
                  <MenuItem onClick={()=>handleChangeStatus("closed",timeId)}>Closed</MenuItem>
                  <MenuItem onClick={()=>handleChangeStatus("converted",timeId)}>Converted</MenuItem>
                </Menu>
                </TableCell>               
            </TableRow>
            </>
          ))}
        </TableBody>
      </Table>
           <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={"sm "}
                maxWidth={"sm"}
              >
                <DialogTitle id="alert-dialog-title">{"Add Notes"}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                  <TextField id="outlined-basic" label="Notes" variant="outlined" autoComplete={false} style={{width:'100%'}} onChange={(e)=>setAddNotes(e.target.value)}/>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button variant="contained" onClick={handleClose} color="primary">
                    Close
                  </Button>
                  <Button variant="contained" onClick={handleClose} color="primary" autoFocus onClick={(e)=>AddNotedByDoctor(timestamp)}>
                    Add
                  </Button>
                </DialogActions>
              </Dialog>
    
    </React.Fragment>
  );
}
