import React,{useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Button , Container ,Typography,Grid,Paper}from '@material-ui/core';
import { primaryColor } from '../constants';
import Logo from "../public/assets/images/newlogo.png"
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import DateFnsUtils from '@date-io/date-fns';
import Banner from "../public/assets/images/landingbackground.png"
import Imag from "../public/assets/images/smilelanding.png"
import {firestore} from "../firebase"
import {Link, useHistory} from 'react-router-dom'
import { withRouter } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';




import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
    root:{
        flex:1,
        // backgroundImage: `url(${Banner})`,
        height:'100vh',
        backgroundRepeat:'no-repeat',
        backgroundSize:'cover'
    },
    logo:{
        height:80,
        width:180,
        display:'flex',
        flexDirection:'row',
        justifyContent:'start',
        padding:10,
        objectFit:'contain'
    },
    formpaper:{
        backgroundColor:"#DE1C49",
        marginTop:20,
        padding:20,
        borderRadius:20
    },
    formtitle:{
        color:'#fff',
        fontSize:20,
        fontWeight:500,
        textAlign:'start',
        fontFamily:'Poppins',
        marginTop:20
    },
    textfeild:{
        border:'none',
        fontFamily: 'Poppins',
        backgroundColor:'#FFF8F2',
        margin:'5px 0px',
        borderRadius:8
    },
    textfiledroot: {
        '& $disabled': {
           borderColor: 'orange'
        }
     },
     datediv:{
         display:'flex',
         flexDirection:'row',
         justifyContent:'space-between',
         [theme.breakpoints.only('xs')]: {
            flexDirection:'column'
        },
     },
     righttext:{
         padding:'40px 40px',
         color:'#fff',
         fontSize:20,
         textAlign:'center',
         [theme.breakpoints.only('xs')]: {
            padding:'10px',
        },
     },
     button:{
         marginTop:2,
         backgroundColor:'#fff',
         color:'#DE1C49',
         fontSize:18,
         fontWeight:600,
         fontFamily:'Poppins',
         letterSpacing:1,
         "&:hover":{
            backgroundColor:'#fff',
            color:'#DE1C49',
            fontSize:19,
            fontWeight:800,

         }
     },
     radio: {
        '&$checked': {
          color: '#fff'
        }
      },
    landingImage:{
        width: '100%',
        height: 300,
        objectFit: 'cover',
        borderRadius: 16,
        [theme.breakpoints.only('xs')]: {
            display:'none'
        }
    },
      checked: {}


}))


 function LandingPage() {
    const classes = useStyles()
     const history = useHistory();
    const [value, setValue] = React.useState('Male');
    const [Name,setName] = useState("")
    const [Phone,setPhone] = useState("")
    const [Email,setEMail] = useState("")
    const [Age,setAge] = useState("")
    const [Desc,setDesc] = useState("")
    const [openSnackbar, setOpenSnackBar] = React.useState(false);





    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const handleSubmit = () => {
        console.log(Phone,Name,Age,value)
        if(Phone =="" || Name == ""){
            alert("please enter the name and phoneNumber")
        }
        else{
       firestore.collection('leads').doc(Date.now().toString()).set({
           name: Name ,
           phone: Phone,
           emergency:"Gender:" +  value,
           email:Email,
           notes:Desc,
           age:Age,
           appointmentDate:"",
           appointmentTime:'',
           timestamp: Date.now()
           },{merge: true})
            history.push('./thankyou')
            setTimeout(function(){ handleClickSnackbar() }, 300);
    }
    setName("")
    setPhone("")
    setValue("Male")
    setAge("")
    setDesc("")
    setEMail("")
   }

   function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

   const handleClickSnackbar = () => {
    setOpenSnackBar(true);
  };
  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackBar(false);
  };


    return (
        <div className={classes.root}>
            <img src={Logo} alt="Logo" className={classes.logo} />
            <Container>
            <Paper className={classes.formpaper} elevation={4}>
                <Grid container >
                    <Grid xs={12} sm={6}>
                        <Typography className={classes.formtitle}>Name</Typography>
                        <TextField
                            autoFocus
                            id="name"
                            variant="outlined"
                            fullWidth
                            className={classes.textfeild}
                            placeholder="Enter Name"
                            onChange = {(e)=>setName(e.target.value)}
                            classes={{notchedOutline:classes.input}}
                            disableUnderline={false}
                            InputProps={{
                                classes: {
                                root: classes.textfiledroot,
                                disabled: classes.disabled
                                }
                            }}
                        />
                           <div className={classes.datediv}>
                               <div>
                               <Typography className={classes.formtitle}>Phone Number</Typography>
                                <TextField
                                    id="name"
                                    variant="outlined"
                                    fullWidth
                                    className={classes.textfeild}
                                    placeholder="Enter Phone Number"
                                    onChange = {(e)=>setPhone(e.target.value)}
                                    classes={{notchedOutline:classes.input}}
                                    disableUnderline={false}
                                    InputProps={{
                                        classes: {
                                        root: classes.textfiledroot,
                                        disabled: classes.disabled
                                        }

                                    }}
                                    inputProps={{ maxLength: 12 }}

                                />
                               </div>
                               <div>
                               <Typography className={classes.formtitle}>Emai Address</Typography>
                                <TextField
                                    id="name"
                                    variant="outlined"
                                    fullWidth
                                    className={classes.textfeild}
                                    placeholder="Enter Email Address"
                                    onChange = {(e)=>setEMail(e.target.value)}
                                    classes={{notchedOutline:classes.input}}
                                    disableUnderline={false}
                                    InputProps={{
                                        classes: {
                                        root: classes.textfiledroot,
                                        disabled: classes.disabled
                                        }
                                    }}

                                />
                               </div>
                           </div>
                        <div className={classes.datediv}>
                            <div>
                            <Typography className={classes.formtitle}>Age</Typography>
                            <TextField
                                    id="name"
                                    variant="outlined"
                                    fullWidth
                                    className={classes.textfeild}
                                    placeholder="Enter Age"
                                    onChange = {(e)=>setAge(e.target.value)}
                                    classes={{notchedOutline:classes.input}}
                                    disableUnderline={false}
                                    InputProps={{
                                        classes: {
                                        root: classes.textfiledroot,
                                        disabled: classes.disabled
                                        }
                                    }}
                                    inputProps={{ maxLength: 2 }}
                                />
                            </div>
                            <div>
                            <Typography className={classes.formtitle}>Gender</Typography>
                            <FormControl component="fieldset">
                                <RadioGroup aria-label="gender"  name="gender1" value={value} onChange={handleChange}>
                                    <div style={{display:'flex',justifyContent:'space-between'}}>
                                    <FormControlLabel  value="Male" control={<Radio classes={{root: classes.radio, checked: classes.checked}} style={{marginTop:20}} />} label={<Typography className={classes.formtitle} >Male</Typography>}  />
                                    <FormControlLabel value="Female" control={<Radio classes={{root: classes.radio, checked: classes.checked}} style={{marginTop:20}}/>} label= {<Typography className={classes.formtitle} >Female</Typography>} />
                                    </div>
                                </RadioGroup>
                            </FormControl>
                            </div>
                        </div>

                        <Typography className={classes.formtitle}>Description</Typography>
                            <TextField
                                    id="name"
                                    variant="outlined"
                                    fullWidth
                                    className={classes.textfeild}
                                    placeholder="Enter Description"
                                    multiline={true}
                                    rows={3}
                                    onChange = {(e)=>setDesc(e.target.value)}
                                    classes={{notchedOutline:classes.input}}
                                    disableUnderline={false}
                                    InputProps={{
                                        classes: {
                                        root: classes.textfiledroot,
                                        disabled: classes.disabled
                                        }
                                    }}
                                />


                    </Grid>
                    <Grid xs={12} sm={6}>
                        <Typography className={classes.righttext}>
                            Where smiles come alive!
                            <img src={Imag} className={classes.landingImage} style={{width: '100%', height: 300,objectFit: 'cover', borderRadius: 16}}/>
                            <p style={{fontSize: 12}}>Disclaimer: This website is designed to let you know more about our practice and our team and provide some general information about the treatment we provide.
                            The website is made available subject to this disclaimer, our terms of use, privacy policy and booking and cancellation policy.
                            </p>
                        </Typography>
                        <div>
                            <Button variant="contained" color="primary" onClick={handleSubmit} className={classes.button}>Book Appointment</Button>
                        </div>
                    </Grid>
                </Grid>
            </Paper>
            </Container>
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackBar}>
            <Alert onClose={handleCloseSnackBar} severity="success">
                SuccessFully Submited!!
            </Alert>
      </Snackbar>
        </div>
    )
}

export default withRouter(LandingPage);
