import React, {useRef, useState,useEffect} from 'react'
import { Navbar, Nav , Carousel,Row, Col } from 'react-bootstrap'
import { makeStyles } from '@material-ui/core/styles';
import Logo from "../public/assets/images/newlogo.png"
import Service1 from "../public/assets/serviceIamgesUpdated/image18.png"
import Service2 from "../public/assets/serviceImages/image21.jpg"
import Service3 from "../public/assets/serviceImages/image1.jpg"
import Service4 from "../public/assets/serviceIamgesUpdated/image26.png"
import Service5 from "../public/assets/serviceImages/image17.jpg"
import Service6 from "../public/assets/serviceImages/image21.jpg"
import Service7 from "../public/assets/serviceImages/image15.png"
import Service8 from "../public/assets/serviceImages/image24.jpg"
import Service9 from "../public/assets/serviceImages/image16.jpg"
import Service10 from "../public/assets/serviceImages/image123.jpg"
import Service11 from "../public/assets/serviceImages/image5.png"
import Service12 from "../public/assets/serviceImages/image25.jpg"
import Service13 from "../public/assets/serviceIamgesUpdated/image9.jpg"
import Service14 from "../public/assets/serviceImages/image3.jpg"
import Service15 from "../public/assets/serviceImages/image44.jpg"
import Service16 from "../public/assets/serviceImages/image49.jpg"
import LogoSmile from "../public/assets/serviceImages/servicesmile.png"
import { Button , Container ,Typography,Grid,Paper}from '@material-ui/core';
import MainFooter from "../components/footer";
import Banner from "../public/assets/serviceImages/banner3.png"
import { Link } from 'react-router-dom'
import {firestore} from "../firebase"


import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import CallIcon from "@material-ui/icons/Call";
import "aos/dist/aos.css"
import AOS from 'aos';
import MainNavBar from '../components/navBar';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import {primaryColor} from "../constants";


const useStyles = makeStyles((theme) => ({
    root: {
      flex:1,
    },
    navbar:{
        backgroundColor:'#fff'
    },
    navbar2:{
        backgroundColor:'#fff',
        marginTop: 75
    },
    logoimage:{
        width:200,
        height:60,
        objectFit:'contain'
    },
    navlist2:{
        fontSize:16,
        marginRight: 30,
        fontFamily: 'Poppins',
        fontWeight:600,
        color: 'white',
        [theme.breakpoints.only('xs')]: {
            fontSize: 10
        },
    },
    headetitle:{
        fontSize:30,
        marginLeft:30,
        fontFamily: 'Poppins',
    },
    bookappointment:{
        backgroundColor:'#DE1C49',
        color:'#fff',
        fontFamily: 'Poppins',
        margin:'0px 40px',
        "&:hover": {
            backgroundColor: "#DE1C49"
        }
    },
    bookappointment2:{
        backgroundColor:'#DE1C49',
        color:'#fff',
        fontFamily: 'Poppins',
        margin:'0px 40px',
        [theme.breakpoints.only('xs')]: {
            display: 'none'
        },
        "&:hover": {
            backgroundColor: "#DE1C49"
        }
    },
    navlist:{
        fontSize:20,
        fontWeight:600,
        fontFamily: 'Poppins',
    },
    headerbanner:{
        marginTop:"0px",
        [theme.breakpoints.only('xs')]: {
            marginTop:"0px",
            display: "none",
        },
    },
    headerbannerMobile:{
        marginTop:"0px",
        display: "none",
        [theme.breakpoints.only('xs')]: {
            marginTop:"0px",
            display: "block",
        },
    },
    banner:{
        width:'100%',
        fontFamily: 'Poppins',
    },
    bannerImage:{
        width:'100%',
        height:400,
        objectFit:'cover',
        fontFamily: 'Poppins',
        marginTop:110,
        [theme.breakpoints.only('xs')]: {
            height:'100%',
            objectFit:'contain',
        },
    },
    header:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        padding:"30px 100px",
        fontFamily: 'Poppins',
        [theme.breakpoints.only('xs')]: {
            padding:"10px 6px",
        },
    },
    headertitle:{
        fontSize:24,
        fontWeight:'600',
        fontFamily: 'Poppins',
        [theme.breakpoints.only('xs')]: {
            padding: 6,
        },
    },

    headertitle2:{
        fontSize:24,
        fontWeight:'600',
        textAlign:'left',
        padding: 10,
        fontFamily: 'Poppins',
    },
    headertitle3:{
        fontSize:20,
        fontWeight:'600',
        textAlign:'center',
        padding: 10,
        fontFamily: 'Poppins',
        // backgroundColor: "#efefef",
        borderRadius: 8,
        cursor:'pointer',
        "&:hover":{
            textDecoration:'none'
        }

    },
    text:{
        fontSize:16,
        fontWeight:500,
        textAlign:'justify',
        fontFamily: 'Poppins',
    },
    card:{
        width:'100%',
        height:300,
        marginTop:20,
        objectFit: 'cover',
        borderRadius:8,
        backgroundColor:'#cdcdcd',
        fontFamily: 'Poppins',
        [theme.breakpoints.only('xs')]: {
            height:150,
        },
    },
    dialogtitle:{
        fontSize:18,
        fontWeight:600,
        padding:'15px 0px',
        fontFamily: 'Poppins',
    },
    textfeild:{
        border:'none',
        backgroundColor:'#FFF8F2',
        fontFamily: 'Poppins',
        margin:'5px 0px'
    },
    textfiledroot: {
        '& $disabled': {
           borderColor: 'orange'
        }
     },
     disabled: {},

     dialogbuttoncontainer:{
         display:'flex',
         flexDirection:'row',
         fontFamily: 'Poppins',
         justifyContent:'center',
         margin:'20px 0px'
     },
    gridnames:{
        marginTop: 30,
        marginBottom: 30,
        [theme.breakpoints.only('xs')]: {
            margin: 0,
        },
    },
    logo:{
        height:350,
        marginTop:160,
        marginLeft:50,
        objectFit:'contain',
        [theme.breakpoints.only('xs')]: {
          height:100
        },
    },
    listtileheader:{
        width:'100px',
        color:"#000",
        marginTop:130,
        fontSize:30,
        fontFamily:'Poppins',
        marginLeft:"10%",
        fontWeight:700,
        [theme.breakpoints.only('xs')]: {
            fontSize:12,
            marginLeft:"5px",
        },
    },

    listtitlecontainer:{
        color:"#000",
        top:200,
        marginLeft:"13%",
        marginTop:20,
        [theme.breakpoints.only('xs')]: {

        },

    },
    listtitle:{
        fontSize:16,
        fontFamily:'Poppins',
        fontWeight:700,
        marginTop:10,
        cursor:'pointer',
        textAlign:'left',
        // paddingLeft:'12%',
        [theme.breakpoints.only('xs')]: {
            fontSize:8,
        },
        "&:hover":{
            color:primaryColor
        }

    },
    rightrow:{
        display:'flex',
        [theme.breakpoints.only('xs')]: {
            flexDirection:'column-reverse'
        }
    },
    dialogtitle2:{
        fontSize:16,
        fontFamily: 'Poppins',
        fontWeight:600,
        padding:'15px 0px',
        textAlign: 'center',
        color: '#DE1C49'
    },


}))
export default function Pediatric() {
    const classes = useStyles();
    const [index, setIndex] = useState(0);
    const [open, setOpen] = React.useState(false);
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [value, setValue] = React.useState('No');
    const [appointmentDate,setAppointmentDate] = useState("")
    const [appointmentTime,setAppointmentTime] = useState("")
    const [name,setName] = useState("")
    const [phone,setPhone] = useState("")
    const [ampm,setampm] = useState("AM")
    const [time,settime] = useState("9")
    const [notes,setNotes] = useState("")
    const [email,setEmail] = useState("")
     const [age,setAge] = useState("")

    useEffect(() => {
        AOS.init({
          duration : 2000
        });
        window.scrollTo(0, 0);
      }, []);

    const handleClickOpen = () => {
        setOpen(true);
      };

      const handleClose = () => {
        setOpen(false);
      };



      const handleDateChange = (date) => {
          console.log(date)
          var localTime = date.toString();
          setAppointmentDate(localTime.slice(0,15))
          console.log(localTime.slice(15,25))
          setAppointmentTime(localTime.slice(15,25))
          setSelectedDate(date);
      };

      const handleSubmit = () => {
        console.log(phone,name,appointmentDate,appointmentTime,value)
        if(phone =="" || name == ""){
            alert("Please enter Phone, Name and Date")
        }
        else{
       firestore.collection('leads').doc(Date.now().toString()).set({
           name: name ,
           phone: phone,
           age: age,
           appointmentDate: appointmentDate,
           appointmentTime: time + " " + ampm,
           emergency:value,
           email:email,
           notes:notes,
           timestamp: Date.now()
           },{merge: true})
    }
   }

      const handleChange = (event) => {
        setValue(event.target.value);
      };

    const PediatricRef = useRef(null)
    const OralRef = useRef(null)
    const TeethRef = useRef(null)
    const ToothColoredRef = useRef(null)
    const TMDTherapy = useRef(null)
    const RootPlanning = useRef(null)
    const NightGuard = useRef(null)

    const executeScroll = (ref) => ref.current.scrollIntoView({behavior: 'smooth',block: 'start', inline: 'start'})

    return (
        <div className={classes.root}>
            <MainNavBar />
            {/* <Navbar collapseOnSelect expand="lg" variant="light" fixed="top" style={{backgroundColor:'#fff'}} className={classes.navbar}>
                <img
                    className={classes.logoimage}
                    src={Logo}
                    alt="React Bootstrap logo"
                />
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ml-auto">
                        <Nav.Link href="/" className={classes.navlist}>Home</Nav.Link>
                        <Nav.Link href="/#aboutus" className={classes.navlist}>About Us</Nav.Link>
                        <Nav.Link href="/#teams" className={classes.navlist}>Team</Nav.Link>
                        <Nav.Link href="/#services" className={classes.navlist}>Our Services</Nav.Link>
                        <Nav.Link href="/#contactus" className={classes.navlist}>Contact us</Nav.Link>
                        <Button variant="contained" className={classes.bookappointment} onClick={handleClickOpen}>Book Appointment</Button>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <Navbar fixed="top" collapseOnSelect variant="light" style={{backgroundColor: '#DE1C49'}} className={[classes.navbar2, {color:'#DE1C49'}]}>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ml-auto">
                        <Nav.Link href="#" style={{color:'#ffffff'}} className={classes.navlist2}>CALL NOW +1 713-451-3333</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar> */}


        <Grid container className={classes.headerbanner}>
            <Grid xs={8}>
            <img src={Banner} className={classes.bannerImage} />
            </Grid>
            <Grid xs={4}>
                <div >
                <Typography className={classes.listtileheader}>Periodontics</Typography>
                {/* <img src={LogoSmile} class="service-header-smile" className={classes.logo} /> */}
                <div className={classes.listtitlecontainer}>
                    <ul>
                        <li><Typography className={classes.listtitle} onClick={()=> executeScroll(PediatricRef)} >Laser Gum Surgery</Typography></li>
                        <li><Typography className={classes.listtitle} onClick={()=> executeScroll(OralRef)}>Bone Grafting</Typography></li>
                        <li><Typography className={classes.listtitle} onClick={()=> executeScroll(TeethRef)}>Crown Lengthening</Typography></li>
                    </ul>
                </div>
                </div>
            </Grid>
        </Grid>

            <Grid container className={classes.headerbannerMobile}>
                <Grid xs={12}>
                    <img src={Banner} className={classes.bannerImage} />
                </Grid>
            </Grid>

        {/* <div className={classes.banner}>
            <img src={Banner} className={classes.bannerImage} />
        </div> */}

        <div className={classes.header}>
            <Typography className={classes.headertitle}>Periodontics</Typography>
            <Button variant="contained" className={classes.bookappointment2} onClick={handleClickOpen}>Book Appointment</Button>
        </div>
            <Container>
            <Typography className={classes.text}>
                Periodontics is a branch of dentistry specially trained in the placement, maintenance, and repair of dental implants. They specialize in severe gum disease or complex medical history, they do the treatment of periodontal disease, and the placement of dental implants. Periodontics provides a broad range of treatments and works on the most advanced methods of diagnosing and treating periodontal disease. They are also trained in performing cosmetic periodontal procedures.
            </Typography>
            </Container>
        <Container>

            <div style={{height: 50}} ref={PediatricRef}>

            </div>


            <Grid container spacing={4} data-aos="fade-up" data-aos-duration="500"  style={{marginTop: 20, marginBottom: 20,overflowX:'hidden', scrollMargin: "220px",}} data-aos="fade-up" data-aos-duration="500" >
                <Grid item xs={12} sm={4}>
                    <img className={classes.card} src={Service1}/>
                </Grid>
                <Grid item xs={12} sm={8}>
                <Typography data-aos="fade-up" data-aos-duration="500" className={classes.headertitle2} >Laser Gum Surgery</Typography>
                <Typography  data-aos="fade-up" data-aos-duration="500"  className={classes.text}>
                    The Laser Gum Treatment is a unique technique because the periodontist uses a laser to access and remove the inflamed gum tissue from around your tooth's root, eliminating excess gum tissue and exhibiting more of the tooth for a charming smile. This surgery is painless and is done in one day.  Gum contouring not only leads to an appealing smile but also improves your oral health by eliminating areas in the mouth where bacteria may have been trapped, leading to a gum infection. Even If your gums look too low, gum surgery can create even more gum lines.
                    Before Starting with the surgery, our Hi dental team will ask you to be seated in one of our comfy treatment chairs, wear Noise-cancellation Headphones, perform a comprehensive oral checkup, and create a treatment plan to help you achieve optimal oral health and reach your aesthetic goals.
                    After completing this laser therapy, your mouth will need some time to heal and regenerate. Through this process of healing, your gums should be infection-free and will tighten back up around your tooth bringing back your healthy and beautiful smile.

                </Typography>
                </Grid>
            </Grid>
            {/*end*/}

            {/*start*/}

            <div style={{height: 50}} ref={OralRef}>

            </div>

            <Grid container spacing={4}  style={{marginTop: 20, marginBottom: 20,overflowX:'hidden'}} data-aos="fade-up" data-aos-duration="500" className={classes.rightrow}>
                <Grid item xs={12} sm={8}>
                <Typography data-aos="fade-up" data-aos-duration="500"  className={classes.headertitle2}>Bone Grafting</Typography>
                <Typography className={classes.text} data-aos="fade-up" data-aos-duration="500" >
                    A bone graft is done only if someone has lost one or more adult teeth or has a gum infection. Both these conditions can cause bone loss in the jaw. Mainly Bone loss in the jaw is more common among older adults, By missing teeth or physical trauma, the jaw can be weak and you may require bone graft surgery to support your jawbone so that it can be strong enough to hold both removable dentures or dental implants. Bone grafting treatment is a lengthy procedure and you need to be calm in this process,
                    Before Starting with the surgery our Hi Dental professionals will perform a complete oral checkup and create a treatment plan to help you achieve optimal oral health and reach your aesthetic goals. You may be sedated during the procedure, so you won’t feel any pain until after the anesthesia wears off. After the treatment, the natural bone in your jaw will combine with the graft. It is highly recommended to follow a good oral care routine at home with brushing and flossing and must come for regular follow-ups so that your beautiful smile never fades away.

                </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <img className={classes.card} src={Service4}/>
                </Grid>
            </Grid>
            {/*end*/}

            {/*start*/}


            <div style={{height: 50}} ref={TeethRef}>

            </div>
            <Grid container spacing={4} style={{marginTop: 20, marginBottom: 20,overflowX:'hidden'}} data-aos="fade-up" data-aos-duration="500" >
                <Grid item xs={12} sm={4}>
                    <img className={classes.card} src={Service13}/>
                </Grid>
                <Grid item xs={12} sm={8}>
                <Typography className={classes.headertitle2} data-aos="fade-up" data-aos-duration="500" >Crown Lengthening</Typography>
            <Typography className={classes.text} data-aos="fade-up" data-aos-duration="500" >
                Crown lengthening surgery is for people with a gummy smile having short teeth covering most of the area with excess gum tissue.
                The crown is that part of the tooth that's visible above the gumline. Some people ask for crown lengthening for cosmetic purposes, while others need the proper checkup to expose decayed or broken areas of teeth. A periodontist or a gum health expert will perform a crown lengthening surgery to show more tooth structure before fitting a bridge or may perform another restorative dental procedure according to the checkup. The treatment requires eliminating soft tissue and sometimes bone to increase the mass of the tooth that is visible.
                The procedure may be done on one tooth, various teeth, or the complete gum line by the gum specialist. Hi Dental experts, will do a thorough presurgical analysis before starting the surgery to determine the new location of the gumline. After the surgery Hi Dental Experts will recommend applying a cold compress to the area to help with soreness and swelling because Minor swelling and pain can be expected in the initial days after a crown lengthening treatment.

            </Typography>
                </Grid>
            </Grid>
            {/*end*/}
            <Typography className={classes.headertitle2}>FAQs</Typography>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classes.heading}>Why should I visit the dentist every 6 months?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography style={{textAlign: 'left'}}>
                        Most people do not care or think about visiting a dentist until they feel pain or cavities in the mouth as many dental problems do not show any symptoms at an initial stage but in the advanced stage when it becomes severe. They feel that they would save some money out of it but it ends ups in costing more as people become careless. That is why it is necessary to visit a dentist every after 6 months so we can check your oral health and let that flawless smile recapitulate.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>How to prevent cavities?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography style={{textAlign: 'left'}}>
                        To prevent cavities one should always brush their teeth and spend 2 -3 minutes brushing and flossing and rinse their mouth after every meal. One must not forget to visit their dentists for a regular checkup and good oral health.
                    </Typography>
                </AccordionDetails>
            </Accordion><
            Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
            >
                <Typography className={classes.heading}>Why is flossing important after brushing?</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography style={{textAlign: 'left'}}>
                    Flossing is important after brushing because it gets rids of bacteria that are not gone by brushing. if you do not floss between the teeth you allow plaque to remain within the teeth. These microscopic bacteria hide between the tiny spaces of teeth.
                    Ask your dentist to show you the proper way to floss. You will notice the huge difference at the next cleaning appointment.
                </Typography>
            </AccordionDetails>
        </Accordion>

        </Container>



<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={"sm"} maxWidth={'sm'}>
{/* <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
<DialogContent>

    <Typography className={classes.dialogtitle2}>Book your first appointment for free!</Typography>

    {/* <Typography className={classes.dialogtitle}>Name</Typography> */}
        <TextField
            autoFocus
            id="name"
            variant="outlined"
            fullWidth
            className={classes.textfeild}
            placeholder="Enter Name"
            onChange = {(e)=>setName(e.target.value)}
            classes={{notchedOutline:classes.input}}
            disableUnderline={false}
            InputProps={{
                classes: {
                   root: classes.textfiledroot,
                   disabled: classes.disabled
                }
             }}
        />

        {/* <Typography className={classes.dialogtitle}>PhoneNumber</Typography> */}
        <TextField

            id="name"
            variant="outlined"
            fullWidth
            className={classes.textfeild}
            onChange = {(e)=>setPhone(e.target.value)}
            placeholder="Enter PhoneNumber"
            classes={{notchedOutline:classes.input}}
            disableUnderline={false}
            InputProps={{
                classes: {
                   root: classes.textfiledroot,
                   disabled: classes.disabled
                }
             }}
        />
    {/* <Typography className={classes.dialogtitle}>Age</Typography> */}

    <TextField
        id="name"
        variant="outlined"
        fullWidth
        className={classes.textfeild}
        onChange = {(e)=>setAge(e.target.value)}
        placeholder="Enter Your Age"
        classes={{notchedOutline:classes.input}}
        disableUnderline={false}
        InputProps={{
            classes: {
                root: classes.textfiledroot,
                disabled: classes.disabled
            }
        }}
    />
    <TextField
        id="name"
        variant="outlined"
        fullWidth
        className={classes.textfeild}
        onChange = {(e)=>setEmail(e.target.value)}
        placeholder="Enter Your Email"
        classes={{notchedOutline:classes.input}}
        disableUnderline={false}
        InputProps={{
            classes: {
                root: classes.textfiledroot,
                disabled: classes.disabled
            }
        }}
    />
    <TextField
        id="name"
        variant="outlined"
        fullWidth
        className={classes.textfeild}
        onChange = {(e)=>setNotes(e.target.value)}
        placeholder="Enter Description/Notes"
        classes={{notchedOutline:classes.input}}
        disableUnderline={false}
        InputProps={{
            classes: {
                root: classes.textfiledroot,
                disabled: classes.disabled
            }
        }}
    />

      <Grid container>
          <Typography className={classes.dialogtitle}>Select a date for appointment</Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justify="space-around">
                <KeyboardDatePicker
                    minDate={new Date()}
                margin="normal"
                id="date-picker-dialog"
                label="Pick a Date"
                format="MM/dd/yyyy"
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                />
                <div style={{margin: 40}}>
                    <select
                        style={{padding: 5, fontSize: 16, marginRight: 5}}
                        value={time}
                        onChange={(e) => settime(e.target.value)}
                    >
                        <option value="8">8:00</option>
                        <option value="9">9:00</option>
                        <option value="10">10:00</option>
                        <option value="11">11:00</option>
                        <option value="12">12:00</option>
                        <option value="1">1:00</option>
                        <option value="2">2:00</option>
                        <option value="3">3:00</option>
                        <option value="4">4:00</option>
                        <option value="5">5:00</option>
                        <option value="6">6:00</option>
                        <option value="7">7:00</option>
                    </select>
                    <select
                        style={{padding: 5, fontSize: 16, marginRight: 5}}
                        value={ampm}
                        onChange={(e) => setampm(e.target.value)}
                    >
                        <option value="AM">AM</option>
                        <option value="PM">PM</option>
                    </select>
                    {/*<p>{message}</p>*/}
                </div>

            </Grid>
            </MuiPickersUtilsProvider>
      </Grid>


      <FormControl component="fieldset">
        <RadioGroup aria-label="gender"  name="gender1" value={value} onChange={handleChange}>
            <div style={{display:'flex',justifyContent:'space-between'}}>
            <Typography className={classes.dialogtitle} style={{paddingRight:'20px'}}>Emergency ?</Typography>
            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="No" control={<Radio />} label="No" />
            </div>
        </RadioGroup>
    </FormControl>

</DialogContent>
<div className={classes.dialogbuttoncontainer}>
<Button variant="contained" className={classes.bookappointment} onClick={handleSubmit}>Submit</Button>
<Button variant="contained" className={classes.bookappointment} onClick={handleClose}>Close</Button>
</div>
 </Dialog>

            <MainFooter id="contactus"/>

        </div>
    )
}
