import React, {useRef, useState,useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Button , Container ,Typography,Grid,Paper}from '@material-ui/core';
import MainFooter from "../../components/footer";
import MainNavBar from "../../components/navBar"
import Banner from "../../public/assets/images/banner_2022_desktop.png"
import Bannermobile from "../../public/assets/images/banner_2022.png"
import Banner2 from "../../public/assets/images/mobilebanner2.png"
import Banner3 from "../../public/assets/images/mobilebanner3.png"
import Banner4 from "../../public/assets/images/mobilebanner4.png"
import googleReview from "../../public/assets/images/googlereview.png"
import stars from "../../public/assets/images/stars.png"
import { Link } from 'react-router-dom'
import { Widget } from '@typeform/embed-react'
import { useLocation, useHistory } from 'react-router-dom'
import Team2 from '../../public/assets/images/team2.jpeg'
import Gamble from '../../public/assets/images/drgamble.png'
import Arun from '../../public/assets/images/drarun.jpg'
import {firestore} from "../../firebase"
import SimpleForm from "../SimpleForm";
import Hident  from "../../public/assets/images/hident.png"
import Logo from "../../public/assets/images/newlogo.png"

import CloseIcon from '@material-ui/icons/Close';


import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import CallIcon from "@material-ui/icons/Call";
import "aos/dist/aos.css"
import AOS from 'aos';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import {primaryColor} from "../../constants";
import CarouselCardLogo from "../../public/assets/images/customerlogo.png"
import CarouselCard from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ChooseUs1 from "../../public/assets/images/whynew1.png"
import ChooseUs2 from "../../public/assets/images/whynew2.png"
import ChooseUs3 from "../../public/assets/images/whynew3.png"
import ChooseUs4 from "../../public/assets/images/whynew4.png"
import ChooseUs5 from "../../public/assets/images/whynew5.png"
import ChooseUs6 from "../../public/assets/images/whynew6.png"


const useStyles = makeStyles((theme) => ({
    root: {
        flex:1,
        overflowX: 'hidden'
    },
    
    navbar:{
        backgroundColor:'#fff'
    },
    navbar2:{
        backgroundColor:'#fff',
        marginTop: 75
    },
    logoimage:{
        width:200,
        height:60,
        objectFit:'contain'
    },
    navlist2:{
        fontSize:16,
        marginRight: 30,
        fontFamily: 'Poppins',
        fontWeight:600,
        color: 'white',
        [theme.breakpoints.only('xs')]: {
            fontSize: 10
        },
    },
    googlereviewstyle:{
        display: 'flex',
        flexDirection: 'row',
        alignSelf: 'flex-star',
        width: '200px'
    },
    carouselcardsmile:{
        width: '50%',
        bottom: '20px',
        marginTop: 20,
        // position: 'absolute',
        // right: '20%',
        zIndex: 0,
        opacity: 1,
    },
    carouselcard:{
        height:450,
        borderRadius:20,
        padding:'20px',
        width:'95%',
        border:'1px solid #efefef',
        zIndex:2,
        justifyContent: 'space-between',
        [theme.breakpoints.only('xs')]: {
            height:500,
            padding:'20px',
            marginTop: 20
        }
    },
    starstyle:{
        display: 'flex',
        flexDirection: 'row',
        alignSelf: 'flex-star',
        marginTop: 10,
        width: '180px'
    },
    headetitle:{
        fontSize:30,
        marginLeft:30,
        fontFamily: 'Poppins',
    },
    bookappointment:{
        backgroundColor:'#DE1C49',
        color:'#fff',
        fontFamily: 'Poppins',
        margin:'0px 40px',
        "&:hover": {
            backgroundColor: "#DE1C49"
        }
    },
    statement:{
        fontSize:15,
        fontWeight:600,
        textAlign:'left',
        fontFamily: 'Helvetica'
    },
    statementname:{
        // position:'absolute',
        fontSize:20,
        fontWeight:500,
        bottom:20,
        textAlign:'left'
    },
    bookappointment2:{
        backgroundColor:'#DE1C49',
        color:'#fff',
        fontFamily: 'Poppins',
        fontWeight: 'bold',
        margin:'0px 40px',
        [theme.breakpoints.only('xs')]: {
            display: 'none'
        },
        "&:hover": {
            backgroundColor: "#DE1C49"
        }
    },
    pagetitles:{
        textAlign:'start',
        fontFamily: 'Poppins',
        margin:'0px 10px',
        fontSize:26,
        fontWeight:600,
        color: '#000',
        marginTop:100,
        scrollMarginTop: '120px',
        marginBottom: '-15px',
    },
    pagetitlesTop:{
        textAlign:'center',
        margin:'10px 5px',
        fontSize:32,
        fontFamily: 'Poppins',
        fontWeight:600,
        color: 'black',
        fontStyle:'italic',
        scrollMarginTop: '120px',
        [theme.breakpoints.only('xs')]: {
            fontSize: 24,
            margin:'2px 2px',
        },
    },
    pagetitles2:{
        textAlign:'start',
        margin:'20px 10px',
        fontFamily: 'Poppins',
        fontSize:20,
        fontWeight:600,
        scrollMarginTop: '120px',
    },
    cardimage:{
        objectFit:'cover',
        borderRadius:20,
        width:'100%',
        fontFamily: 'Poppins',
        height:400,
        verticalAlign:'middle',
    },
    happyfacetitle:{
        fontSize:26,
        fontWeight:700,
        fontFamily: 'Poppins',
        fontStyle: 'italic'

    },
    happyfacesubtitle:{
        fontSize:20,
        fontWeight:400,
        fontFamily: 'Poppins',
       fontStyle: 'italic'
    },
    happyfacedesc:{
        fontSize:16,
        margin:'20px 0px',
        fontFamily: 'Poppins',
       fontStyle: 'italic',
        textAlign:'start'
    },
    bookappointment2button:{
        backgroundColor:'#DE1C49',
        color:'#fff',
        fontFamily: 'Poppins',
        // width: '200px',
        fontWeight: 'bold',
        margin:'0px 500px',
        alignItems:'center',
        [theme.breakpoints.only('xs')]: {
            margin:'0px',
        },
        "&:hover": {
            backgroundColor: "#DE1C49"
        }
    },
    bookappointment3button:{
        backgroundColor:'#DE1C49',
        color:'#fff',
        fontFamily: 'Poppins',
        fontSize: 20,
        // width: '200px',
        fontWeight: 'bold',
        margin:'30px 500px',
        alignItems:'center',
        [theme.breakpoints.only('xs')]: {
            margin:'30px',
        },
        "&:hover": {
            backgroundColor: "#DE1C49"
        }
    },
    navlist:{
        fontSize:20,
        fontWeight:600,
        fontFamily: 'Poppins',
    },
    headerbanner:{
        marginTop:"0px",
        [theme.breakpoints.only('xs')]: {
            marginTop:"0px",
            display: "none",
        },
    },
    headerbannerMobile:{
        marginTop:"0px",
        display: "none",
        [theme.breakpoints.only('xs')]: {
            marginTop:"0px",
            display: "block",
            // position:"relative",
            // top: 12
        },
    },
    banner:{
        width:'100%',
        fontFamily: 'Poppins',
    },
    bannerImage:{
        width:'100%',
        // height:460,
        objectFit:'cover',
        fontFamily: 'Poppins',
        marginTop:110,
        [theme.breakpoints.only('xs')]: {
            marginTop: '125px',
            width:'100%',
            objectFit:'contain',
        },
    },
    bannerImageBetween:{
        width:'100%',
        // height:460,
        objectFit:'contain',
        fontFamily: 'Poppins',
        // marginTop:110,
        [theme.breakpoints.only('xs')]: {
            // marginTop: '125px',
            width:'100%',
            objectFit:'contain',
        },
    },
    header:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        padding:"30px 100px",
        fontFamily: 'Poppins',
        [theme.breakpoints.only('xs')]: {
            padding:"10px 6px",
        },
    },
    headertitle:{
        fontSize:24,
        fontWeight:'600',
        fontFamily: 'Poppins',
        [theme.breakpoints.only('xs')]: {
            padding: 6,
        },
    },
    headertitlefree:{
        fontSize:16,
        fontWeight:'600',
        fontFamily: 'Poppins',
        [theme.breakpoints.only('xs')]: {
            padding: 6,
            display: 'none'
        },
    },

    headertitle2:{
        fontSize:24,
        fontWeight:'600',
        textAlign:'left',
        padding: 10,
        fontFamily: 'Poppins',
        scrollMargin: "220px",
        marginTop:80,
        marginBottom:30,
        [theme.breakpoints.only('xs')]: {
            marginTop:30,
            marginBottom:20
        },
    },
    headertitle2examine:{
        fontSize:24,
        fontWeight:'600',
        textAlign:'center',
        padding: 10,
        fontFamily: 'Poppins',
        scrollMargin: "220px",
        marginTop:80,
        marginBottom:30,
        [theme.breakpoints.only('xs')]: {
            marginTop:30,
            marginBottom:20
        },
    },
    headertitle3:{
        fontSize:20,
        fontWeight:'600',
        textAlign:'left',
        // padding: 10,
        fontFamily: 'Poppins',
        // backgroundColor: "#efefef",
        borderRadius: 8,
        cursor:'pointer',
        "&:hover":{
            textDecoration:'none'
        }

    },
    text:{
        fontSize:16,
        fontWeight:500,
        textAlign:'justify',
        fontFamily: 'Poppins',
    },
    card:{
        width:'100%',
        height:300,
        marginTop:20,
        objectFit: 'cover',
        borderRadius:8,
        backgroundColor:'#cdcdcd',
        fontFamily: 'Poppins',
        [theme.breakpoints.only('xs')]: {
            height:150,
        },
    },
    dialogtitle:{
        fontSize:18,
        fontWeight:600,
        padding:'15px 0px',
        fontFamily: 'Poppins',
    },
    textfeild:{
        border:'none',
        backgroundColor:'#FFF8F2',
        fontFamily: 'Poppins',
        margin:'5px 0px'
    },
    textfiledroot: {
        '& $disabled': {
            borderColor: 'orange'
        }
    },
    disabled: {},

    dialogbuttoncontainer:{
        display:'flex',
        flexDirection:'row',
        fontFamily: 'Poppins',
        justifyContent:'center',
        margin:'20px 0px'
    },
    gridnames:{
        marginTop: 30,
        marginBottom: 30,
        [theme.breakpoints.only('xs')]: {
            margin: 0,
        },
    },
    logo:{
        height:350,
        marginTop:160,
        marginLeft:50,
        objectFit:'contain',
        [theme.breakpoints.only('xs')]: {
            height:100
        },
    },
    listtileheader:{
        width:'100px',
        color:"#000",
        marginTop:130,
        fontSize:30,
        fontFamily:'Poppins',
        marginLeft:"10%",
        fontWeight:700,
        [theme.breakpoints.only('xs')]: {
            fontSize:12,
            marginLeft:"5px",
        },
    },

    listtitlecontainer:{
        color:"#000",
        top:200,
        marginLeft:"13%",
        marginTop:20,
        [theme.breakpoints.only('xs')]: {

        },

    },
    listtitle:{
        fontSize:16,
        fontFamily:'Poppins',
        fontWeight:700,
        marginTop:10,
        cursor:'pointer',
        textAlign:'left',
        // paddingLeft:'12%',
        [theme.breakpoints.only('xs')]: {
            fontSize:8,
        },
        "&:hover":{
            color:primaryColor
        }

    },
    linkbutton:{
        "&:hover":{
            textDecoration:'none'
        }
    },
    rightrow:{
        display:'flex',
        [theme.breakpoints.only('xs')]: {
            flexDirection:'column-reverse'
        }
    },
    dialogtitle2:{
        fontSize:16,
        fontFamily: 'Poppins',
        fontWeight:600,
        padding:'15px 0px',
        textAlign: 'center',
        color: '#DE1C49'
    },
    titlesmilelogolarge:{
        width: "150px",
        position: 'absolute',
        left: '15%',
        opacity: '1',
        zIndex:-1,
        [theme.breakpoints.only('xs')]: {
            left: '15%',
        },
    },
    titlesmilelogosmall:{
        width: "50px",
        position: 'absolute',
        left: '5%',
        opacity: '1',
        zIndex:-1,
        marginTop:15,
        [theme.breakpoints.only('xs')]: {
            left: '10%',
        },
    },
    titlesmilelogomedium:{
        width: "130px",
        position: 'absolute',
        left: '8%',
        opacity: '1',
        zIndex:-1,
        [theme.breakpoints.only('xs')]: {
            left: '14%',
        },
    },
    girdrow:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        [theme.breakpoints.only('xs')]: {
            // padding:30
        },
    },
    gridlefttitle:{
        color:'#DE1C49',
        fontSize:80,
        fontWeight:800,
        fontFamily:"Poppins",
        paddingRight:10,
    },
    gridlefttitlenew:{
        color:'#DE1C49',
        fontSize:40,
        fontWeight:800,
        fontFamily:"Poppins",
        paddingRight:10,
        marginTop: 30
    },
    gridrighttitle:{
        textAlign:'start',
        fontSize:25,
        fontWeight:800,
        paddingTop:20,
        lineHeight: 1.1,
        color: '#DE1C49'
    },
    gridrightdesc:{
        textAlign:'start',
        fontSize:20,
        width:300,
        [theme.breakpoints.only('xs')]: {
           width:'100%'
        },
    },
    choosecard:{
        width:'250px',
        marginLeft:'2%',
        [theme.breakpoints.only('xs')]: {
            marginTop:10,
            width:'300px',
        }
    },
    chooseimage:{
        objectFit:'contain',
        borderRadius:20,
        width:'100%',
        fontFamily: 'Poppins',
        // height:250,
        verticalAlign:'middle',
        // boxShadow: '15px 15px #DE1C49'
    },
    cardtitle:{
        fontSize:18,
        fontWeight:600,
        fontFamily: 'Poppins',
        textAlign:'start',
        paddingLeft:10,
        paddingTop:20
    },
    bookappointmentfixed:{
       display:'none',
       [theme.breakpoints.only('xs')]: {
        display:'flex',
        position:'absolute'
    },
    },
    heading:{
        fontWeight:'900',
        color: 'crimson',
        fontSize: 20
    }

}))
export default function Service1() {

    const classes = useStyles();
    const [index, setIndex] = useState(0);
    const [open, setOpen] = React.useState(false);
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [value, setValue] = React.useState('No');
    const [appointmentDate,setAppointmentDate] = useState("")
    const [appointmentTime,setAppointmentTime] = useState("")
    const [name,setName] = useState("")
    const [phone,setPhone] = useState("")
    const [ampm,setampm] = useState("AM")
    const [time,settime] = useState("9")
    const [notes,setNotes] = useState("")
    const [email,setEmail] = useState("")
     const [age,setAge] = useState("")
     const history = useHistory();
    const [openSnackbar, setOpenSnackBar] = React.useState(false);
    let [showChat, setShowChat] = React.useState(false);

    const startChat = () => { setShowChat(true); }
    const hideChat = () => { setShowChat(false); }

    useEffect(() => {
        document.title = "Dental Implants, Houston | Oral Health Foundation Near You"
        AOS.init({
            duration : 2000
        });
        window.scrollTo(0, 0);
    }, []);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };



    const handleDateChange = (date) => {
        console.log(date)
        var localTime = date.toString();
        setAppointmentDate(localTime.slice(0,15))
        console.log(localTime.slice(15,25))
        setAppointmentTime(localTime.slice(15,25))
        setSelectedDate(date);
    };

    const handleSubmit = () => {
        console.log(phone,name,appointmentDate,appointmentTime,value)
        if(phone =="" || name == ""){
            alert("Please enter Phone, Name and Date")
        }
        else{
       firestore.collection('leads').doc(Date.now().toString()).set({
           name: name ,
           phone: phone,
           age: age,
           appointmentDate: appointmentDate,
           appointmentTime: time + " " + ampm,
           emergency:value,
           email:email,
           notes:notes,
           timestamp: Date.now()
           },{merge: true})

    }
   }

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const responsivechooseus = {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };
    return (
        <div className={classes.root}>
            <MainNavBar />

            <Grid container className={classes.headerbanner}>
                <Grid xs={12}>
                    <img src={Banner} className={classes.bannerImage} />
                </Grid>
            </Grid>

            <Grid container className={classes.headerbannerMobile}>
                <Grid xs={12}>
                    <img src={Bannermobile} className={classes.bannerImage} />
                </Grid>
            </Grid>

            <div className={classes.header}>
                <Typography style={{textAlign: 'left'}} className={classes.headertitle}>Dental Implant by Top Prosthodontists at most affordable price</Typography>
                {/* <img src={CarouselCardLogo} className={classes.titlesmilelogomedium} style={{left:'10%'}} /> */}
                <div>
                <Typography className={classes.headertitlefree}>Free Consultation</Typography>
                <Button variant="contained" className={classes.bookappointment2} onClick={() =>  history.push('./new-patient-form')}>Book Now</Button>
                </div>
            </div>
            
            <Container>
                <Typography style={{paddingRight: 10}} className={classes.text}>
                    <ul>
                        <li>The biggest difference between a <em style={{fontWeight: 'bold'}}>Prosthodontist</em> and a <em style={{fontWeight: 'bold'}}>General Dentist</em> is that a prosthodontist deals with <em style={{fontWeight: 'bold'}}>replacing</em> teeth and <em style={{fontWeight: 'bold'}}>restoring</em> your oral health and function throughout your entire mouth. General dentists focus on <em style={{fontWeight: 'bold'}}>repairing</em> teeth.</li>
                        <li>Up to <em style={{fontWeight: 'bold'}}>three years of additional training</em> through an <em style={{fontWeight: 'bold'}}>ADA-accredited</em> program is required to become a prosthodontist</li>
                        <li>With more than <em style={{fontWeight: 'bold'}}>3000+</em> smiles restored with dental implants by Hi Dental Care Prosthodontists, We provide <em style={{fontWeight: 'bold'}}>affordable dental implants</em> near you.</li>
                    </ul>
                    <Typography style={{ textAlign:'left', justifyContent: 'flex-start', color:'#DE1C49'}} className={classes.headertitle}>Do you really need a Dental Implant? </Typography>
               <Typography style={{fontSize: 16}} className={classes.headertitle}>Take this quick quiz to know </Typography>

                <Widget id="T0vJrWrZ" style={{ width: '100%' , height: 600}} className="my-form" />
               
                <Typography style={{fontSize: 16, textAlign:'center'}}>Our <em style={{fontWeight: 'bold'}}>treatment process breakdown</em> serves as a way to provide our patients with a better understanding of the <em style={{fontWeight: 'bold'}}>overall procedure and cost</em> of treatment.</Typography>
                </Typography>
                <div className={classes.header}>
                    <Typography className={classes.headertitle}>Treatment process</Typography>
               </div>
               <Container>
                <Grid container spacing={4} alignContent="center" alignItems="center">
                    <Grid item xs={12} sm={6}>
                        <div className={classes.girdrow}>
                            <Typography className={classes.gridlefttitlenew}>1</Typography>
                            <div>
                            <Typography className={classes.gridrighttitle}>Consultation, X-ray and Investigations</Typography>
                            <Typography style={{fontSize: 16}} className={classes.gridrightdesc}>At Hi Dental Care, we provide a free consultation for all. So you don't have to think twice if dental implants suits you or not. Choosing the right treatment plan for your case is a top priority & is  Free</Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div className={classes.girdrow}>
                            <Typography className={classes.gridlefttitlenew}>2</Typography>
                            <div>
                            <Typography className={classes.gridrighttitle}>Surgical Implant Placement</Typography>
                            <Typography style={{fontSize: 16}} className={classes.gridrightdesc}>Dental implants being our most performed procedure for years, we get best deals on all Implant components, making Implant procedure more affordable for our patients. 
                            </Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div className={classes.girdrow}>
                            <Typography className={classes.gridlefttitlenew}>3</Typography>
                            <div>
                            <Typography className={classes.gridrighttitle}>Implant Uncover, Abutment, Crown</Typography>
                            <Typography style={{fontSize: 16}} className={classes.gridrightdesc}>The inhouse team of Prosthodontists being always available ensures reduction in number of visits of patients saving a lot of time and money for our patients. Depending on the actual condition of the patients, multiple procedures can be performed in a single day.</Typography>
                            </div>
                        </div>
                    </Grid>
                     <Grid item xs={12} sm={6}>
                      <div className={classes.girdrow}>
                            <Typography className={classes.gridlefttitlenew}>4</Typography>
                            <div>
                           <Typography className={classes.gridrighttitle}>Post Procedure Follow-ups</Typography>
                          <Typography style={{fontSize: 16}} className={classes.gridrightdesc}>There are no hidden charges in the name of post procedure follow ups or investigations. Every charge and pricing is explained during the initial consultation before the treatment starts. </Typography>
                          </div>
                       </div>
                    </Grid> 
                </Grid>
                </Container>
                </Container>

               


                <div className={classes.header}>
                    <Typography style={{fontSize: 16, lineHeight: 1.1, color:'#DE1C49'}} className={classes.headertitle}>Cost saving at every stage makes Dental 
Implants more Affordable at Hi Dental Care</Typography>
               </div>
               <Grid container className={classes.headerbannerMobile}>
                    <Grid xs={12}>
                        <img src={Banner2} className={classes.bannerImageBetween} />
                    </Grid>
                </Grid>
                <Button variant="contained" className={classes.bookappointment3button} onClick={() => history.push('./new-patient-form')}>BOOK FREE APPOINTMENT</Button>
                <Container>
                <Grid container spacing={5}>
                <Grid item sm={3} xs={12} data-aos="fade-up" data-aos-duration="1000" >
                    <img src={Team2} alt="happyface1" className={classes.cardimage} />
                </Grid>
                <Grid item sm={9} xs={12} style={{textAlign:'start',overflowX:'hidden'}} data-aos="fade-left" data-aos-duration="1000" data-aos-delay="500">
                    <Typography className={classes.happyfacetitle}>Dr. Nakul Rathi BDS. MS.</Typography>
                    <Typography className={classes.happyfacesubtitle}>Prosthodontist 10+yrs</Typography>
                    <Typography className={classes.happyfacedesc}>
                    <ul>
                        <li>2500+ Implants </li>
                        <li>Owns Get Implant LLC </li>
                        <li>Most renowned Implantologist of Houston </li>
                        <li>International speaker & academician</li>
                    </ul>
                    </Typography>
                    </Grid>
                <Grid>
                </Grid>
            </Grid>


            <Grid container spacing={5} className={classes.rightrow} >
                <Grid item sm={9} xs={12}  style={{textAlign:'left',overflowX:'hidden'}} data-aos="fade-right" data-aos-duration="3000" >
                    <Typography className={classes.happyfacetitle}>Dr. Arun Vashisht, BDS, MDS</Typography>
                    <Typography className={classes.happyfacesubtitle}>Prosthodontist 13+yrs</Typography>
                    <Typography className={classes.happyfacedesc}>
                        <ul>
                        <li>3000+ Smile Restored</li>
                        <li>Founder of Smile Secure Inc.</li>
                        <li>Fully trained in Cerec </li>
                        <li>Digital Smile Design Expert</li>
                        <li>International speaker & academician</li>
                        </ul>
                    </Typography>
                </Grid>
                <Grid item sm={3} xs={12}>
                    <img src={Arun} alt="happyface1" className={classes.cardimage} data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1000" />
                </Grid>
                <Grid>
                </Grid>
            </Grid>
            </Container>
            <Grid container className={classes.headerbannerMobile}>
                    <Grid xs={12}>
                        <img src={Banner3} className={classes.bannerImageBetween} />
                    </Grid>
            </Grid>
            <Button variant="contained" className={classes.bookappointment3button} onClick={() => history.push('./new-patient-form')}>BOOK FREE APPOINTMENT</Button>
        <Container>
        <div className={classes.header}>
                    <Typography  style={{textAlign:'left',overflowX:'hidden', color:'#DE1C49'}}  className={classes.headertitle}>Benefits of Dental Implant </Typography>
               </div>
                

                <Container>
                <Grid container spacing={4} alignContent="center" alignItems="center">
                    <Grid item xs={12} sm={6}>
                        <div className={classes.girdrow}>
                            <Typography className={classes.gridlefttitle}>1</Typography>
                            <div>
                            <Typography className={classes.gridrighttitle}>Prevents Bone Loss</Typography>
                            <Typography className={classes.gridrightdesc}>You tend to lose bone mass in your jaw when you lose a tooth. Your jawbone needs your teeth connected to sustain its mass. Dental implant is the only option that also replaces the jawbone stimulation which helps to prevent bone loss.</Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div className={classes.girdrow}>
                            <Typography className={classes.gridlefttitle}>2</Typography>
                            <div>
                            <Typography className={classes.gridrighttitle}>Permanent Solution</Typography>
                            <Typography className={classes.gridrightdesc}>Dental implants will last for the rest of your life, unlike other tooth replacement options that need to be repaired or replaced periodically. This makes dental implants one of the best solution for tooth lose. Implants are also made of materials that wont decay so you wont get cavities ever.
                            </Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div className={classes.girdrow}>
                            <Typography className={classes.gridlefttitle}>3</Typography>
                            <div>
                            <Typography className={classes.gridrighttitle}>Supports natural facial structure </Typography>
                            <Typography className={classes.gridrightdesc}>Implants fill in the gap allowing you to enjoy a straight, even smile. Implants gives similar support to your face just like your natural teeth which helps to prevent the shape of your face.</Typography>
                            </div>
                        </div>
                    </Grid>
                     <Grid item xs={12} sm={6}>
                      <div className={classes.girdrow}>
                            <Typography className={classes.gridlefttitle}>4</Typography>
                            <div>
                           <Typography className={classes.gridrighttitle}>Keeps your natural speech intact</Typography>
                          <Typography className={classes.gridrightdesc}>In most of the missing tooth cases there is an alteration in the natural speech of the individual. Even tooth replacement options like dentures may result into change in your speech. Dental implants function exactly the way your natural teeth would function enabling you to speak easily.</Typography>
                          </div>
                       </div>
                    </Grid> 
                    <Grid item xs={12} sm={6}>
                      <div className={classes.girdrow}>
                            <Typography className={classes.gridlefttitle}>5</Typography>
                            <div>
                           <Typography className={classes.gridrighttitle}>Bite like You never Broke a teeth</Typography>
                          <Typography className={classes.gridrightdesc}>Dental implants are affixed into your jaw through a titanium post, they let you bite with the same amount of force as your natural teeth because they are firmly attached to your jaw. The other alternates are no where close to the firmness as they only sit on the top of your gums.   </Typography>
                          </div>
                       </div>
                    </Grid> 
                    <Grid item xs={12} sm={6}>
                      <div className={classes.girdrow}>
                            <Typography className={classes.gridlefttitle}>6</Typography>
                            <div>
                           <Typography className={classes.gridrighttitle}>No extra Care</Typography>
                          <Typography className={classes.gridrightdesc}>You don’t need any product to wash or maintain the Dental Implants. Nothing like a tablet or an adhesive is required to take care of them. You just brush and floss the way you would do with your natural teeth.</Typography>
                          </div>
                       </div>
                    </Grid> 
                    
                </Grid>
                
                </Container>

                </Container>
                <Grid style={{marginTop: 40, marginBottom: 40}} container className={classes.headerbannerMobile}>
                    <Grid xs={12}>
                        <img src={Banner4} className={classes.bannerImageBetween} />
                    </Grid>
            </Grid>
            <Container>
        <div className={classes.header}>
        <Typography style={{textAlign:'left'}} className={classes.headertitle}>Why choose us?</Typography>
        <img src={CarouselCardLogo} className={classes.titlesmilelogomedium} style={{left:'10%'}} />
        </div>
        <div className={classes.rootGrid}>
        <Grid container >
        <Grid item xs={12} sm={3}>
        <div className={classes.choosecard}>
                    <img src={ChooseUs1} alt="happyface1" className={classes.chooseimage} />
            </div>
        </Grid>
        <Grid item xs={12} sm={3}>
        <div className={classes.choosecard}>
                    <img src={ChooseUs2} alt="happyface1" className={classes.chooseimage} />
                </div>
        </Grid>
    
        <Grid item xs={12} sm={3}>
        <div className={classes.choosecard}>
                    <img src={ChooseUs3} alt="happyface1" className={classes.chooseimage} />
                </div>
        </Grid>
        <Grid item xs={12} sm={3}>
        <div className={classes.choosecard}>
                    <img src={ChooseUs4} alt="happyface1" className={classes.chooseimage} />
                </div>
        </Grid>
        <Grid item xs={12} sm={3}>
        <div className={classes.choosecard}>
                    <img src={ChooseUs5} alt="happyface1" className={classes.chooseimage} />
                </div>
        </Grid>
        <Grid item xs={12} sm={3}>
        <div className={classes.choosecard}>
                    <img src={ChooseUs6} alt="happyface1" className={classes.chooseimage} />
                </div>
        </Grid>
      </Grid>
      </div>
        </Container>
        <Container>
                <div>
                    <Typography  style={{textAlign:'center',overflowX:'hidden', fontSize: 40, color: '#DE1C49', fontStyle:'italic', marginTop: 40, borderWidth:1, borderColor:'#DE1C49'}}  className={classes.headertitle}>15+ Locations</Typography>
                    <Typography  style={{textAlign:'center',overflowX:'hidden',fontSize: 16, marginBottom: 40}}  className={classes.headertitle}>with state-of-the-art dental clinics across Houston</Typography>
               </div>
        <Container>
        <div className={classes.header}>
                <Typography style={{textAlign:'left'}} className={classes.headertitle}>Your Neighbourhood’s FeedBack</Typography>
                <img src={CarouselCardLogo} className={classes.titlesmilelogomedium} style={{left:'10%'}} />
        </div>

            <Grid container>

            <Grid item xs={12} sm={4}>
            <Paper elevation={1} className={classes.carouselcard}>
                    <img src={googleReview} className={classes.googlereviewstyle}/>
                    <div className={classes.statement}>
                    Dr. Arun and his team is amazing! From the time you walk in the door! Very good with relaxing the patient and making every part of the visit comfortable!  They took their time and made sure I stayed at ease the entire time. After my treatment, they even followed up checked on me the following day to make sure I was still at ease! Such a great staff!! Thanks so much!!
                    </div>
                    <img src={stars} alt= "logo" className={classes.starstyle}/>
                    <div style={{display:'flex', justifyContent: 'flex-end'}}>
                    <img src={CarouselCardLogo} alt= "logo" className={classes.carouselcardsmile}/>
                    </div>
                
                    <div className={classes.statementname}>Ansul Chandra</div>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={4}>
            <Paper elevation={1} className={classes.carouselcard}>
            <img src={googleReview} className={classes.googlereviewstyle}/>

            <div className={classes.statement}>

            Dr Arun made sure my gums, bone, teeth, and overall health  were intact. I had a headache for years and now its gone. Then, after making sure I was healthy and happy, he gave me a beautiful and healthy smile. Loved it and you will too !!       
            <img src={stars} alt= "logo" className={classes.starstyle}/>     
            <div style={{display:'flex', justifyContent: 'flex-end'}}>
                <img src={CarouselCardLogo} alt= "logo" className={classes.carouselcardsmile}/>
                </div>
           
                <div style={{marginTop: 70}} className={classes.statementname}>Jan Christopher</div>
                </div>
            </Paper>
            </Grid>
            <Grid item xs={12} sm={4}>
            <Paper elevation={1} className={classes.carouselcard}>
                <img src={googleReview} className={classes.googlereviewstyle}/>
                <div className={classes.statement}>
                Dr. Arun was great! He was very kind and patient. He had given me my smile back and my confidence! I feel 10 years younger! His technology and his planning was great and was perfectly executed
                </div>
                <img src={stars} alt= "logo" className={classes.starstyle}/>
                <div style={{display:'flex', justifyContent: 'flex-end'}}>
                <img src={CarouselCardLogo} alt= "logo" className={classes.carouselcardsmile}/>
                </div>
               
                <div style={{marginTop: 100}} className={classes.statementname}>Paskal Setinov</div>
            </Paper>
            </Grid>

            </Grid>
    

        </Container>


                
            </Container>
           
            <Button variant="contained" className={classes.bookappointment3button} onClick={() => history.push('./new-patient-form')}>BOOK FREE APPOINTMENT</Button>

            <Container>

            <Typography className={classes.headertitle2}>More about Dental Implants</Typography>
                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>What is the lifespan of dental implants?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography style={{textAlign: 'left'}}>
                        The longevity of dental implants is known to be very long, especially if they are cared for properly. Their lifespan can reach 25 years. You can get dental implants from a Hi Dental Care Clinic professionals.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>Is it possible to get full mouth dental implants?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography style={{textAlign: 'left'}}>
                        A full mouth of dental implants with 4 or 6 titanium posts on your jaw bone can be achieved with procedures like all-on-four or all-on-six if your mouth is missing most or all of your teeth.</Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>Does dental implant surgery hurt?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography style={{textAlign: 'left'}}>
                    An expert implantologist will perform dental implants to the patient with little or no pain. It is important to provide the patient with a local anaesthetic prior to implant placement to prevent pain.
                </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>How Much Does A Dental Implant Cost?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography style={{textAlign: 'left'}}>
                    Hi Dental Care offers the best quality of dental care. Our patients are guaranteed to get the most from their dental implants investment. Also, Hi Dental Care maintains authenticity and quality.
                </Typography>
                </AccordionDetails>
            </Accordion>

            <div style={{display:'flex',justifyContent:'center', flexDirection:'column', alignContent:'center'}}>
                {/* <Typography className={classes.headertitle2examine}>Are you a good candidate for Dental Implants?</Typography>
                <Button variant="contained" className={classes.bookappointment2button} onClick={() => history.push('./new-patient-form')}>Let's examine it!</Button> */}
                </div>
            </Container>



            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={"sm"} maxWidth={'sm'}>
        <DialogContent>
            <div>
                <img style={{width: '100px', height: '40px', objectFit: 'contain'}} src={Logo}/>
            </div>
            <Typography className={classes.dialogtitle2}>Book your first appointment for free!</Typography>

            {/* <Typography className={classes.dialogtitle}>Name</Typography> */}
                <TextField
                   autoFocus
                    id="name"
                    variant="outlined"
                    fullWidth
                    className={classes.textfeild}
                    placeholder="Enter Name"
                    onChange = {(e)=>setName(e.target.value)}
                    classes={{notchedOutline:classes.input}}
                    disableUnderline={false}
                    InputProps={{
                        classes: {
                           root: classes.textfiledroot,
                           disabled: classes.disabled
                        }
                     }}
                />

                {/* <Typography className={classes.dialogtitle}>PhoneNumber</Typography> */}
                <TextField

                    id="name"
                    variant="outlined"
                    fullWidth
                    className={classes.textfeild}
                    onChange = {(e)=>setPhone(e.target.value)}
                    placeholder="Enter PhoneNumber"
                    classes={{notchedOutline:classes.input}}
                    disableUnderline={false}
                    InputProps={{
                        classes: {
                           root: classes.textfiledroot,
                           disabled: classes.disabled
                        }
                     }}
                />
            {/* <Typography className={classes.dialogtitle}>Age</Typography> */}

            <TextField

                id="name"
                variant="outlined"
                fullWidth
                className={classes.textfeild}
                onChange = {(e)=>setAge(e.target.value)}
                placeholder="Enter Your Age"
                classes={{notchedOutline:classes.input}}
                disableUnderline={false}
                InputProps={{
                    classes: {
                        root: classes.textfiledroot,
                        disabled: classes.disabled
                    }
                }}
            />
            <TextField

                id="name"
                variant="outlined"
                fullWidth
                className={classes.textfeild}
                onChange = {(e)=>setEmail(e.target.value)}
                placeholder="Enter Your Email"
                classes={{notchedOutline:classes.input}}
                disableUnderline={false}
                InputProps={{
                    classes: {
                        root: classes.textfiledroot,
                        disabled: classes.disabled
                    }
                }}
            />
            <TextField

                id="name"
                variant="outlined"
                fullWidth
                className={classes.textfeild}
                onChange = {(e)=>setNotes(e.target.value)}
                placeholder="Enter Description/Notes"
                classes={{notchedOutline:classes.input}}
                disableUnderline={false}
                InputProps={{
                    classes: {
                        root: classes.textfiledroot,
                        disabled: classes.disabled
                    }
                }}
            />

              <Grid container>
                  <Typography className={classes.dialogtitle}>Select a date for appointment</Typography>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around">
                        <KeyboardDatePicker
                        minDate={new Date()}
                        margin="normal"
                        id="date-picker-dialog"
                        label="Pick a Date"
                        format="MM/dd/yyyy"
                        value={selectedDate}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        />
                        <div style={{margin: 40}}>
                            <select
                                style={{padding: 5, fontSize: 16, marginRight: 5}}
                                value={time}
                                onChange={(e) => settime(e.target.value)}
                            >
                                <option value="8">8:00</option>
                                <option value="9">9:00</option>
                                <option value="10">10:00</option>
                                <option value="11">11:00</option>
                                <option value="12">12:00</option>
                                <option value="1">1:00</option>
                                <option value="2">2:00</option>
                                <option value="3">3:00</option>
                                <option value="4">4:00</option>
                                <option value="5">5:00</option>
                                <option value="6">6:00</option>
                                <option value="7">7:00</option>
                            </select>
                            <select
                                style={{padding: 5, fontSize: 16, marginRight: 5}}
                                value={ampm}
                                onChange={(e) => setampm(e.target.value)}
                            >
                                <option value="AM">AM</option>
                                <option value="PM">PM</option>
                            </select>
                            {/*<p>{message}</p>*/}
                        </div>

                    </Grid>
                    </MuiPickersUtilsProvider>
              </Grid>


              <FormControl component="fieldset">
                <RadioGroup aria-label="gender"  name="gender1" value={value} onChange={handleChange}>
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                    <Typography className={classes.dialogtitle} style={{paddingRight:'20px'}}>Emergency ?</Typography>
                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="No" control={<Radio />} label="No" />
                    </div>
                </RadioGroup>
            </FormControl>
            <p style={{fontSize: 12}}>Disclaimer: This website is designed to let you know more about our practice and our team and provide some general information about the treatment we provide.
                                    The website is made available subject to this disclaimer, our terms of use, privacy policy and booking and cancellation policy.
                                </p>
        </DialogContent>
        <div className={classes.dialogbuttoncontainer}>
        <Button variant="contained" className={classes.bookappointmentbutton} onClick={handleClose}>Close</Button>
        <Button variant="contained" className={classes.bookappointment} onClick={handleSubmit}>Submit</Button>
        </div>
         </Dialog>
 <MainFooter id="contactus"/>
 <div className = "bot">
                <div style ={{display: showChat ? "" : "none"}}>
                    <SimpleForm></SimpleForm>
                </div>
                {/* <div> {showChat ? <SimpleForm></SimpleForm> : null} </div> */}
                <div>
                    {!showChat
                        ? <button className="btn" onClick={() => startChat()}><div style={{backgroundColor: "#DE1C49", padding: 20, borderRadius: 100}}>
                            <img src={Hident} alt="logo" style={{width:30, height:30, backgroundColor: '#ffffff',borderRadius:30,}} />
                            {/* <QuestionAnswerIcon style={{width:30, height:30, color: '#ffffff',}}/> */}
                        </div></button>
                        : <button className="btn" onClick={() => hideChat()}><div style={{backgroundColor:"#DE1C49", padding: 20, borderRadius: 100}}>
                            <CloseIcon style={{width:30, height:30, color: '#ffffff',}}/>
                        </div></button>}
                </div>
            </div>


</div>
    )
}
